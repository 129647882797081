import { action } from '@ember/object';
import { service } from '@ember/service';
// import AccountLinkingSlr from 'tio-employee/components/account-linking/slr';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';
import type RouterService from 'tio-employee/services/router';
import { t } from 'ember-intl';
import Alert from 'tio-ui/components/alert';
import { Header } from 'tio-ui/components/layout';

class AccountActivityLinkingRoute extends Component {
  @service declare router: RouterService;

  @action
  didLink(institution: FinancialInstitutionModel) {
    this.router.transitionTo('authenticated.slr.linking-confirmation', institution.legacyId);
  }

  @action
  didCancel() {
    this.router.transitionTo('authenticated.dashboard');
  }

  <template>
    <Header>{{t "account_linking.add_account"}}</Header>

    {{! disabling the no-bare-strings rule rather than adding translations for this
      because this is theoretically temporary }}
    {{! template-lint-disable no-bare-strings }}
    <Alert @intent="warning" class="border">
      <p class="mb-2">
        Loan linking for federal student loan servicers is currently unavailable
        <a
          class="font-semibold hover:underline"
          href="https://fsapartners.ed.gov/knowledge-center/library/electronic-announcements/2024-04-02/updates-borrowers-federal-student-loan-and-grant-web-experience-updated-july-3-2024"
        >
          due to Department of Education account changes</a>. We're working as quickly as possible
        to provide a solution.
      </p>
      <p>
        If you have federal student loans, please reach out to
        <a
          class="font-semibold hover:underline"
          href="mailto:support@tuition.io"
          target="_blank"
          rel="noopener noreferrer"
        >support@tuition.io</a>
        with a copy of your most recent billing statement and our support team can assist you with
        setting up this loan in the meantime.
      </p>
    </Alert>

    {{!-- <AccountLinkingSlr @onSuccess={{this.didLink}} @onCanceled={{this.didCancel}} /> --}}
  </template>
}

export default RouteTemplate(AccountActivityLinkingRoute);
