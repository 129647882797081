import { action } from '@ember/object';
import { service } from '@ember/service';
// import AccountLinkingSyf from 'tio-employee/components/account-linking/syf';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type RouterService from 'tio-employee/services/router';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';
import { t } from 'ember-intl';
import { Section } from 'tio-ui/components/layout';

class SyfAccountLinkingRouteComponent extends Component {
  @service declare router: RouterService;

  @action
  didLink(institution: FinancialInstitutionModel) {
    this.router.transitionTo('authenticated.syf.linking-confirmation', institution.legacyId);
  }

  @action
  didCancel() {
    this.router.transitionTo('authenticated.syf');
  }

  <template>
    <Section>
      <:header>{{t "account_linking.add_account"}}</:header>
      <:body>
        {{!-- <AccountLinkingSyf @onSuccess={{this.didLink}} @onCanceled={{this.didCancel}} /> --}}
      </:body>
    </Section>
  </template>
}

export default RouteTemplate(SyfAccountLinkingRouteComponent);
