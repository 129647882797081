import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';
import RouteTemplate from 'ember-route-template';

const expandViewPort = modifier(() => {
  const nestedMain = document.querySelector('main main');

  if (nestedMain) {
    nestedMain.classList.remove('max-w-7xl');
  }
  return () => {
    if (nestedMain) {
      nestedMain.classList.add('max-w-7xl');
    }
  };
});

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
class ReportingIndexRoute extends Component {
  <template>
    {{! We normall constain the viewport, but reporting is a special case where
    we want to expand the viewport as reports can be massive and general expectation
    is they will have a large screen. }}
    <span {{expandViewPort}} class="hidden" />
    {{outlet}}
  </template>
}

export default RouteTemplate(ReportingIndexRoute);
