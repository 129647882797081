import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';

type Resolved<P> = P extends Promise<infer T> ? T : P;
export type AccountActivityAccountsRouteModel = Resolved<
  ReturnType<AuthenticatedAccountActivityAccountsRoute['model']>
>;

export default class AuthenticatedAccountActivityAccountsRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async model() {
    const personId = this.sessionContext.user.person.id;

    return this.store.findRecord('person', personId, {
      include: 'accounts.loans,accounts.financial-institution,accounts.postal-address',
      reload: true,
    });
  }
}
