import RouteTemplate from 'ember-route-template';
import AppPagePlanDetailsCollapsible from 'tio-employee/components/app/page/plan-details-collapsible';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type FeaturesService from 'tio-common/services/features';

const sections = [
  {
    title: 'questions_you_have.repayment_plans.keep_in_mind',
    description: 'questions_you_have.repayment_plans.income_driven.keep_in_mind',
  },
  {
    title: 'questions_you_have.repayment_plans.make_the_switch.title',
    description: 'questions_you_have.repayment_plans.make_the_switch.description',
  },
  {
    title: 'questions_you_have.repayment_plans.need_more_help.title',
    description: 'questions_you_have.repayment_plans.need_more_help.description',
  },
];

// PAYE Section Data
const payeTitle = 'questions_you_have.repayment_plans.income_driven.paye.title';
const payeDescription = 'questions_you_have.repayment_plans.income_driven.paye.description';
const payeDetails = [
  {
    title: 'questions_you_have.repayment_plans.eligible_loan_types',
    description: 'questions_you_have.repayment_plans.income_driven.paye.eligible_loan_types',
  },
  {
    title: 'questions_you_have.repayment_plans.years_to_repay',
    description: 'questions_you_have.repayment_plans.income_driven.paye.years_to_repay',
  },
  {
    title: 'questions_you_have.repayment_plans.loan_forgiveness',
    description: 'questions_you_have.repayment_plans.income_driven.paye.loan_forgiveness',
  },
  {
    title: 'questions_you_have.repayment_plans.monthly_payment',
    description: 'questions_you_have.repayment_plans.income_driven.paye.monthly_payment',
  },
];
const payePros = [
  'questions_you_have.repayment_plans.income_driven.paye.pro_1',
  'questions_you_have.repayment_plans.income_driven.paye.pro_2',
];
const payeCons = ['questions_you_have.repayment_plans.income_driven.paye.con_1'];

// REPAYE Section Data
const repayeTitle = 'questions_you_have.repayment_plans.income_driven.repaye.title';
const repayeDescription = 'questions_you_have.repayment_plans.income_driven.repaye.description';
const repayeDetails = [
  {
    title: 'questions_you_have.repayment_plans.eligible_loan_types',
    description: 'questions_you_have.repayment_plans.income_driven.repaye.eligible_loan_types',
  },
  {
    title: 'questions_you_have.repayment_plans.years_to_repay',
    description: 'questions_you_have.repayment_plans.income_driven.repaye.years_to_repay',
  },
  {
    title: 'questions_you_have.repayment_plans.loan_forgiveness',
    description: 'questions_you_have.repayment_plans.income_driven.repaye.loan_forgiveness',
  },
  {
    title: 'questions_you_have.repayment_plans.monthly_payment',
    description: 'questions_you_have.repayment_plans.income_driven.repaye.monthly_payment',
  },
];
const repayePros = [
  'questions_you_have.repayment_plans.income_driven.repaye.pro_1',
  'questions_you_have.repayment_plans.income_driven.repaye.pro_2',
];
const repayeCons = [
  'questions_you_have.repayment_plans.income_driven.repaye.con_1',
  'questions_you_have.repayment_plans.income_driven.repaye.con_2',
];

// Income-Based Section Data
const basedTitle = 'questions_you_have.repayment_plans.income_driven.based.title';
const basedDescription = 'questions_you_have.repayment_plans.income_driven.based.description';
const basedDetails = [
  {
    title: 'questions_you_have.repayment_plans.eligible_loan_types',
    description: 'questions_you_have.repayment_plans.income_driven.based.eligible_loan_types',
  },
  {
    title: 'questions_you_have.repayment_plans.years_to_repay',
    description: 'questions_you_have.repayment_plans.income_driven.based.years_to_repay',
  },
  {
    title: 'questions_you_have.repayment_plans.loan_forgiveness',
    description: 'questions_you_have.repayment_plans.income_driven.based.loan_forgiveness',
  },
  {
    title: 'questions_you_have.repayment_plans.monthly_payment',
    description: 'questions_you_have.repayment_plans.income_driven.based.monthly_payment',
  },
];
const basedPros = [
  'questions_you_have.repayment_plans.income_driven.based.pro_1',
  'questions_you_have.repayment_plans.income_driven.based.pro_2',
];
const basedCons = [
  'questions_you_have.repayment_plans.income_driven.based.con_1',
  'questions_you_have.repayment_plans.income_driven.based.con_2',
];

// Income-Contingent Section Data
const contingentTitle = 'questions_you_have.repayment_plans.income_driven.contingent.title';
const contingentDescription =
  'questions_you_have.repayment_plans.income_driven.contingent.description';
const contingentDetails = [
  {
    title: 'questions_you_have.repayment_plans.eligible_loan_types',
    description: 'questions_you_have.repayment_plans.income_driven.contingent.eligible_loan_types',
  },
  {
    title: 'questions_you_have.repayment_plans.years_to_repay',
    description: 'questions_you_have.repayment_plans.income_driven.contingent.years_to_repay',
  },
  {
    title: 'questions_you_have.repayment_plans.loan_forgiveness',
    description: 'questions_you_have.repayment_plans.income_driven.contingent.loan_forgiveness',
  },
  {
    title: 'questions_you_have.repayment_plans.monthly_payment',
    description: 'questions_you_have.repayment_plans.income_driven.contingent.monthly_payment',
  },
];
const contingentPros = [
  'questions_you_have.repayment_plans.income_driven.contingent.pro_1',
  'questions_you_have.repayment_plans.income_driven.contingent.pro_2',
  'questions_you_have.repayment_plans.income_driven.contingent.pro_3',
];
const contingentCons = [
  'questions_you_have.repayment_plans.income_driven.contingent.con_1',
  'questions_you_have.repayment_plans.income_driven.contingent.con_2',
];

// Income-Sensitive Section Data
const sensitiveTitle = 'questions_you_have.repayment_plans.income_driven.sensitive.title';
const sensitiveDescription =
  'questions_you_have.repayment_plans.income_driven.sensitive.description';
const sensitiveDetails = [
  {
    title: 'questions_you_have.repayment_plans.eligible_loan_types',
    description: 'questions_you_have.repayment_plans.income_driven.sensitive.eligible_loan_types',
  },
  {
    title: 'questions_you_have.repayment_plans.years_to_repay',
    description: 'questions_you_have.repayment_plans.income_driven.sensitive.years_to_repay',
  },
  {
    title: 'questions_you_have.repayment_plans.loan_forgiveness',
    description: 'questions_you_have.repayment_plans.income_driven.sensitive.loan_forgiveness',
  },
  {
    title: 'questions_you_have.repayment_plans.monthly_payment',
    description: 'questions_you_have.repayment_plans.income_driven.sensitive.monthly_payment',
  },
];
const sensitivePros = ['questions_you_have.repayment_plans.income_driven.sensitive.pro_1'];
const sensitiveCons = ['questions_you_have.repayment_plans.income_driven.sensitive.con_1'];

// Undergrad 5% Section Data
const undergradTitle = 'questions_you_have.repayment_plans.income_driven.undergrad.title';
const undergradDescription =
  'questions_you_have.repayment_plans.income_driven.undergrad.description';
const undergradDetails = [
  {
    title: 'questions_you_have.repayment_plans.eligible_loan_types',
    description: 'questions_you_have.repayment_plans.income_driven.undergrad.eligible_loan_types',
  },
  {
    title: 'questions_you_have.repayment_plans.years_to_repay',
    description: 'questions_you_have.repayment_plans.income_driven.undergrad.years_to_repay',
  },
  /**
    {
      title: 'questions_you_have.repayment_plans.loan_forgiveness',
        description:
          'questions_you_have.repayment_plans.income_driven.undergrad.loan_forgiveness',
    },
     */
  {
    title: 'questions_you_have.repayment_plans.monthly_payment',
    description: 'questions_you_have.repayment_plans.income_driven.undergrad.monthly_payment',
  },
];

class RepaymentPlansIncomeDrivenRoute extends Component {
  @service declare features: FeaturesService;

  get isEnabledTuitionAssistance() {
    return this.features.isEnabled('tuitionAssistance');
  }

  <template>
    <div>
      <AppPagePlanDetailsCollapsible
        @title={{payeTitle}}
        @description={{payeDescription}}
        @details={{payeDetails}}
        @pros={{payePros}}
        @cons={{payeCons}}
        @moreSections={{sections}}
      />
      <AppPagePlanDetailsCollapsible
        @title={{repayeTitle}}
        @description={{repayeDescription}}
        @details={{repayeDetails}}
        @pros={{repayePros}}
        @cons={{repayeCons}}
        @moreSections={{sections}}
      />
      <AppPagePlanDetailsCollapsible
        @title={{basedTitle}}
        @description={{basedDescription}}
        @details={{basedDetails}}
        @pros={{basedPros}}
        @cons={{basedCons}}
        @moreSections={{sections}}
      />
      <AppPagePlanDetailsCollapsible
        @title={{contingentTitle}}
        @description={{contingentDescription}}
        @details={{contingentDetails}}
        @pros={{contingentPros}}
        @cons={{contingentCons}}
        @moreSections={{sections}}
      />
      <AppPagePlanDetailsCollapsible
        @title={{sensitiveTitle}}
        @description={{sensitiveDescription}}
        @details={{sensitiveDetails}}
        @pros={{sensitivePros}}
        @cons={{sensitiveCons}}
        @moreSections={{sections}}
      />
      {{#if this.isEnabledTuitionAssistance}}
        <AppPagePlanDetailsCollapsible
          @title={{undergradTitle}}
          @description={{undergradDescription}}
          @details={{undergradDetails}}
          @moreSections={{sections}}
        />
      {{/if}}
    </div>
  </template>
}

export default RouteTemplate(RepaymentPlansIncomeDrivenRoute);
