import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { fn } from '@ember/helper';
import { Modal } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountsLinkedLoansSummary from 'tio-employee/components/accounts/linked-loans-summary';
import CardInfo from '../card-info';
import Component from '@glimmer/component';
import NumberBadge from '../todo/number-badge';
import Todo from '../todo';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type PersonModel from 'tio-common/models/person';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';

export interface PslfDashboardActionsAccountsTodoSignature {
  Args: {
    accounts: AccountModel[];
    itemNo: string;
    person: PersonModel;
    loanSummary: LoanSummary;
  };
  Element: HTMLElement;
}

export default class PslfDashboardActionsAccountsTodoComponent extends Component<PslfDashboardActionsAccountsTodoSignature> {
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;

  @tracked showAccountsLinkedDialog = false;

  @action
  toggleDialog(action: 'open' | 'close') {
    if (action === 'open') {
      this.showAccountsLinkedDialog = true;
    }
    if (action === 'close') {
      this.showAccountsLinkedDialog = false;
    }
  }

  get hasLinkedAccounts() {
    return !!this.args.accounts.length;
  }

  get iconText() {
    return this.hasLinkedAccounts ? 'link' : 'link_off';
  }

  get accountInstitutionDisplayNames() {
    return this.args.accounts.slice().map((account: AccountModel) => account.institutionName);
  }

  <template>
    <Todo
      @title={{t "pslf_dashboard.actions.account_linking_status"}}
      @isComplete={{this.hasLinkedAccounts}}
    >
      <:leftHeader>
        <NumberBadge @number={{@itemNo}} />
      </:leftHeader>
      <:body>
        <CardInfo @icon={{this.iconText}}>
          <:info>
            <p>{{t "pslf_dashboard.actions.accounts_linked"}}</p>
            <ul class="font-bold">
              {{#each this.accountInstitutionDisplayNames as |name|}}
                <li>{{name}}</li>
              {{else}}
                <li>{{t "number_zero"}}</li>
              {{/each}}
            </ul>
            {{#if this.hasLinkedAccounts}}
              <Button class="w-72" @intent="primary" {{on "click" (fn this.toggleDialog "open")}}>
                {{t "pslf_dashboard.actions.view_details"}}
              </Button>
            {{/if}}
          </:info>
        </CardInfo>
      </:body>
      <:actions>
        <Button
          class="w-72"
          @intent="primary"
          {{on "click" (transitionTo "/observability/upload?source=pslf")}}
        >
          {{t "pslf_dashboard.actions.add_accounts"}}
        </Button>
      </:actions>
    </Todo>

    <Modal
      @isOpen={{this.showAccountsLinkedDialog}}
      @onClose={{fn this.toggleDialog "close"}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>
        {{t "pslf_dashboard.actions.accounts_linked"}}
      </m.Header>
      <m.Body>
        <AccountsLinkedLoansSummary
          @accounts={{@accounts}}
          @allowOverrideEdits={{true}}
          @person={{@person}}
          @loanSummary={{@loanSummary}}
        />
      </m.Body>
    </Modal>
  </template>
}
