import RouteTemplate from 'ember-route-template';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import RecommendationsResultsRecommendationHistory from 'tio-employee/components/recommendations/results/recommendation-history';
import type RecommendationModel from 'tio-common/models/recommendation';

export interface S {
  Args: {
    model: {
      allRecommendations: RecommendationModel[];
    };
  };
}
export default RouteTemplate<S>(
  <template>
    <TioPageBreadcrumbs as |b|>
      <b.crumb @route="authenticated.idr.dashboard.index" @label="Dashboard" />
      <b.crumb
        @route="authenticated.idr.dashboard.recommendation-history"
        @label="Recommendation History"
      />
    </TioPageBreadcrumbs>
    <RecommendationsResultsRecommendationHistory
      @allRecommendations={{@model.allRecommendations}}
      @programType="idr"
    />
  </template>
);
