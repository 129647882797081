import { action } from '@ember/object';
import { Modal } from 'tio-ui/components/modal';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { type IntlService } from 'ember-intl';
import Component from '@glimmer/component';
import EmailLookupInput from 'tio-employee/components/auth/email-lookup';
import emailSchema from 'tio-common/validation-schema/models/email';
import formatSubdomainRedirectUrl from 'tio-common/utils/format-subdomain-redirect-url';
import TioFooter from 'tio-common/components/tio/footer';
import toggle from 'ember-composable-helpers/helpers/toggle';
import type { NotificationsService } from 'tio-ui/components/notifications';
import type PreRegisterService from '../../services/pre-register.ts';

interface EmailLookupWrapperComponentSignature {
  Args: {
    route: string;
    disabled?: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class EmailLookupWrapper extends Component<EmailLookupWrapperComponentSignature> {
  @service declare preRegister: PreRegisterService;
  @service declare intl: IntlService;
  @service declare notifications: NotificationsService;

  @tracked email = '';
  @tracked emailLookupIsLoading = false;
  @tracked showUnregisteredModal = false;

  get validationErrors() {
    try {
      // @ts-expect-error: same as email-field.gts
      emailSchema.fields.email?.validateSync?.(this.email);
    } catch (err) {
      return err.errors || [];
    }
    return [];
  }

  get showEmailLookup() {
    const { domain } = this.preRegister.parseHostname(window.location.hostname);
    return this.preRegister.isEmployeeSubdomain && domain !== 'localhost' && !this.args.disabled;
  }

  @action
  async emailLookup() {
    if (this.validationErrors.length) return;
    this.emailLookupIsLoading = true;
    const { valid, sub_domain, unregistered } = await this.preRegister.emailLookup(this.email);

    if (unregistered) {
      this.showUnregisteredModal = true;
      this.emailLookupIsLoading = false;
      return;
    }

    if (valid) {
      const redirectUrl = formatSubdomainRedirectUrl({
        subDomain: sub_domain,
        domain: this.preRegister.parseHostname(window.location.hostname).domain,
        email: this.email,
        route: this.args.route,
      });
      window.location.href = redirectUrl;
    } else {
      this.notifications.add(this.intl.t('login.email_not_found'), {
        appearance: 'error',
      });
    }
    this.emailLookupIsLoading = false;
    return;
  }

  @action
  submitEmailLookup(e?: Event) {
    e?.preventDefault();
    this.emailLookup();
  }

  <template>
    <div class="flex flex-col h-screen container mx-auto pb-20 w-full overflow-auto">
      <div class="login flex flex-col space-y-10 justify-center items-center mt-20">
        <div class="bg-white w-full max-w-xl shadow-2xl p-5">
          {{#if this.showEmailLookup}}
            <h2 class="text-lg text-center mb-4">
              {{t "login.what_email"}}
            </h2>
            <EmailLookupInput
              @email={{this.email}}
              @emailLookupIsLoading={{this.emailLookupIsLoading}}
              @validationErrors={{this.validationErrors}}
              @submitEmailLookup={{this.submitEmailLookup}}
            />
          {{else}}
            {{yield}}
          {{/if}}
        </div>
      </div>
      <Modal
        @isOpen={{this.showUnregisteredModal}}
        @onClose={{toggle "showUnregisteredModal" this}}
        @backdrop="faded"
        as |m|
      >
        <m.Header>{{t "login.unregistered_email"}}</m.Header>
        <m.Body>{{t "login.unregistered_email_body"}}</m.Body>
      </Modal>
      <TioFooter />
    </div>
  </template>
}
