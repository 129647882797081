import { action } from '@ember/object';
import { array } from '@ember/helper';
import { dropTask } from 'ember-concurrency';
import { or } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import type RouterService from '@ember/routing/router-service';
import RouteTemplate from 'ember-route-template';
import type Store from '@ember-data/store';
import TioAlert from 'tio-common/components/tio/alert';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TuitionAssistanceFormsCoursesCourseDuration from 'tio-common/components/tuition-assistance/forms/courses/course-duration';
import TuitionAssistanceProgramDetailsCancelRequest from 'tio-employee/components/tuition-assistance/program-details/cancel-request';
import type TasApplication from 'tio-common/models/tas-application';
import { Header, Section, VStack } from 'tio-ui/components/layout';

interface TAProgramInstanceCourseApplicationsNewRouteComponentSignature {
  Args: {
    model: TasApplication;
  };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
class TuitionAssistanceProgramInstanceCourseApplicationsNewRouteComponent extends Component<TAProgramInstanceCourseApplicationsNewRouteComponentSignature> {
  @service declare store: typeof Store;
  @service declare router: RouterService;

  @tracked hasSubmitted = false;

  @action
  cancel() {
    this.router.transitionTo('authenticated.tuition-assistance.programs.instance.index');
  }

  @action
  async saveForLater(
    fieldsCopy: TasApplication['fields'],
    customFieldsCopy: TasApplication['customFields']
  ) {
    await this.saveFields.perform(fieldsCopy, customFieldsCopy);
    this.router.transitionTo('authenticated.tuition-assistance.programs.instance.index');
  }

  @action
  async saveAndNext(
    fieldsCopy: TasApplication['fields'],
    customFieldsCopy: TasApplication['customFields'],
    isFormValid: boolean
  ) {
    if (!isFormValid) {
      return;
    }

    const modelId = await this.saveFields.perform(fieldsCopy, customFieldsCopy);
    this.router.transitionTo(
      'authenticated.tuition-assistance.programs.instance.course-applications.show.pre-approval.add-course',
      modelId
    );
  }

  saveFields = dropTask(async (fieldsCopy, customFieldsCopy) => {
    this.args.model.fields = fieldsCopy;
    this.args.model.customFields = customFieldsCopy;

    try {
      await this.args.model.save();
      this.hasSubmitted = true;
    } catch (e) {
      console.error(e);
    }
    return this.args.model.id;
  });
  <template>
    {{pageTitle (t "tuition_assistance.program_details.courses.courses_application")}}

    <VStack>
      <Header>{{t "tuition_assistance.default"}}</Header>
      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb @route="authenticated.tas.dashboard" @label={{t "dashboard"}} />
        <b.crumb
          @route="authenticated.tuition-assistance.programs.instance.index"
          @label={{@model.tasProgramInstance.programName}}
        />
        <b.crumb
          @route="authenticated.tuition-assistance.programs.instance.course-applications.new"
          @label={{or @model.displayName "New"}}
        />
      </TioPageBreadcrumbs>

      <Section>
        <:header>
          {{t "tuition_assistance.program_details.courses.course_information"}}
        </:header>
        <:body>
          <h4 class="font-semibold">
            {{t "tuition_assistance.program_details.courses.please_enter_begin_and_end"}}
          </h4>
          <TioAlert @type="info" @allowDismiss={{false}} class="mt-4 mb-8">
            <:header>
              <p class="font-bold uppercase">
                {{t "tuition_assistance.program_details.courses.attention_required"}}
              </p>
            </:header>
            <:body>
              <p class="text-sm">
                {{t "tuition_assistance.program_details.courses.course_duration_alert"}}
              </p>
            </:body>
          </TioAlert>

          <TuitionAssistanceFormsCoursesCourseDuration
            @application={{@model}}
            @saveAndNext={{this.saveAndNext}}
            @saveForLater={{this.saveForLater}}
            @cancel={{this.cancel}}
            @showActionButtons={{true}}
            @saveTaskIsRunning={{this.saveFields.isRunning}}
            @hasSubmitted={{this.hasSubmitted}}
          />
          <TuitionAssistanceProgramDetailsCancelRequest
            @application={{@model}}
            @modelTypes={{array "tas-application"}}
            @linkText={{t "tuition_assistance.program_details.cancel_app.delete_course"}}
            @canCancel={{@model.canCancelApplication}}
          />
        </:body>
      </Section>
    </VStack>
  </template>
}

export default RouteTemplate(TuitionAssistanceProgramInstanceCourseApplicationsNewRouteComponent);
