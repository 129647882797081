import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { eq } from 'tio-ui/utilities';
import { fn } from '@ember/helper';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
import { Modal, Footer } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import downloadFile from 'tio-common/utils/download-file';
import MaterialIcon from 'tio-common/components/material-icon';
import pageTitle from 'ember-page-title/helpers/page-title';
import Paginator from 'tio-common/components/tio/paginator';
import RouteTemplate from 'ember-route-template';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import SelectGeneric from 'tio-common/components/select/generic';
import Table from 'tio-common/components/table/index';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type CompanyModel from 'tio-common/models/company';
import type Owner from '@ember/owner';
import type PaymentModel from 'tio-common/models/tas-payment';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';

const PAYMENT_STATES = [
  'PROCESSING',
  'CREATED',
  'ABANDONED',
  'CANCELED',
  'PAID',
  'EMPTY',
  'ERRORED',
];

interface AdminTuitionAssistancePaymentsSignature {
  Args: {
    model: {
      tasPayments: PaymentModel[];
      total: number;
    };
    controller: {
      state: string;
      page: number;
    };
  };
}

class AdminTuitionAssistancePayments extends Component<AdminTuitionAssistancePaymentsSignature> {
  @service declare router: RouterService;
  @service declare store: StoreService;

  @tracked state?: string;
  @tracked selectedPayment?: PaymentModel;
  @tracked selectedCompany?: CompanyModel;

  @tracked createModalOpen: boolean = false;
  @tracked settleModalOpen: boolean = false;
  @tracked cancelModalOpen: boolean = false;
  @tracked isSettlingPayment: boolean = false;
  @tracked isCancelingPayment: boolean = false;

  get settleablePayments() {
    const { tasPayments } = this.args.model;
    return tasPayments.filter((payment) => payment.state === 'CREATED');
  }

  constructor(owner: Owner, args: AdminTuitionAssistancePaymentsSignature['Args']) {
    super(owner, args);
    this.state = args.controller.state;
  }

  @action
  setState(state: string) {
    // needs to explicitly be undefined instead of null, otherwise the router will
    // include a blank state query param
    const newState = state ? state : undefined;
    this.state = newState;
    this.router.transitionTo({ queryParams: { state: newState } });
  }

  @action
  setSelectedPayment(payment: PaymentModel) {
    this.selectedPayment = payment;
  }

  @action toggleSettleModal() {
    this.settleModalOpen = !this.settleModalOpen;
  }

  @action closeSettleModal() {
    delete this.selectedPayment;
    this.toggleSettleModal();
  }

  @action async settlePayment() {
    this.isSettlingPayment = true;
    const paymentId = this.selectedPayment?.id;
    if (!paymentId) return;
    await this.store.adapterFor('tas-payment').settlePayment({ paymentId });
    this.isSettlingPayment = false;
    this.closeSettleModal();
    this.router.refresh(this.router.currentRoute?.name);
  }

  @action toggleCancelModal() {
    this.cancelModalOpen = !this.cancelModalOpen;
  }

  @action closeCancelModal() {
    delete this.selectedPayment;
    this.toggleCancelModal();
  }

  @action async cancelPayment() {
    const paymentId = this.selectedPayment?.id;
    if (!paymentId) return;
    this.isCancelingPayment = true;
    await this.store.adapterFor('tas-payment').cancelPayment({ paymentId });
    this.isCancelingPayment = false;
    this.closeCancelModal();
    this.router.refresh(this.router.currentRoute?.name);
  }

  @action async downloadPaymentFiles(paymentId: string) {
    if (!paymentId) return;
    const adapter = this.store.adapterFor('tas-payment');
    // wrong usage of adapter!
    const headers = adapter.headers;
    // wrong usage of adapter!
    const url = [adapter.buildURL('tas-payments'), paymentId, 'download_files'].join('/');

    const response = await fetch(url, { headers });
    if (response.ok) {
      downloadFile(await response.blob(), 'tas_payment_files.zip', 'application/x-zip');
    }
  }

  <template>
    {{pageTitle (t "common.tuition_assistance.payments.default")}}
    <VStack>
      <TioPageBreadcrumbs as |b|>
        <b.crumb
          @route="authenticated.admin.tuition-assistance"
          @label={{t "tuition_assistance.default"}}
        />
        <b.crumb
          @route="authenticated.admin.tuition-assistance.payments"
          @label={{t "tuition_assistance.payments"}}
        />
      </TioPageBreadcrumbs>
      <Section>
        <:body>
          <VStack>
            <SelectGeneric
              @label="Filter by status"
              @options={{PAYMENT_STATES}}
              @selected={{this.state}}
              @searchEnabled={{true}}
              @onChange={{this.setState}}
            >
              <:option as |option|>
                <span class="text-sm">
                  {{option}}
                </span>
              </:option>
            </SelectGeneric>
            <HStack>
              <Button @intent="primary" {{on "click" this.toggleSettleModal}}>
                {{t "tuition_assistance.admin.payments.settle"}}
              </Button>
              <Button @intent="danger" @appearance="outlined" {{on "click" this.toggleCancelModal}}>
                {{t "tuition_assistance.admin.payments.cancel"}}
              </Button>
            </HStack>
          </VStack>
        </:body>
      </Section>
      <Section>
        <:body>
          <Table @isLoading={{false}}>
            <:thead as |options|>
              <options.tr>
                <options.th>{{t
                    "common.tuition_assistance.payments.attribute_label.id"
                  }}</options.th>
                <options.th>{{t
                    "common.tuition_assistance.payments.attribute_label.payment_date"
                  }}</options.th>
                <options.th>{{t
                    "common.tuition_assistance.payments.attribute_label.status"
                  }}</options.th>
                <options.th>{{t
                    "common.tuition_assistance.payments.attribute_label.type"
                  }}</options.th>
                <options.th>{{t
                    "common.tuition_assistance.payments.attribute_label.created_by"
                  }}</options.th>
                <options.th>{{t
                    "common.tuition_assistance.payments.attribute_label.download"
                  }}</options.th>
              </options.tr>
            </:thead>
            <:tbody as |options|>
              {{#each @model.tasPayments as |payment|}}
                <options.tr>
                  <options.td>{{payment.id}}</options.td>
                  <options.td>
                    {{#if payment.paymentDate}}
                      {{safeFormatDate
                        payment.paymentDate
                        month="long"
                        day="2-digit"
                        year="numeric"
                      }}
                    {{/if}}
                  </options.td>
                  <options.td>{{payment.state}}</options.td>
                  <options.td>{{payment.paymentType}}</options.td>
                  <options.td>{{payment.createdBy}}</options.td>
                  <options.td>
                    <button type="button" {{on "click" (fn this.downloadPaymentFiles payment.id)}}>
                      <MaterialIcon @icon="download" class="mx-2 text-ocean-600" />
                    </button>
                  </options.td>
                </options.tr>
              {{/each}}
              {{#if (eq @model.tasPayments.length 0)}}
                <options.tr>
                  <options.td colspan="6">
                    {{t "common.no_data"}}
                  </options.td>
                </options.tr>
              {{/if}}
            </:tbody>
            <:tfoot as |options|>
              <options.tr>
                <td colspan="6">
                  <Paginator @totalItems={{@model.tasPayments.length}} @summary={{true}} />
                </td>
              </options.tr>
            </:tfoot>
          </Table>
        </:body>
      </Section>
    </VStack>
    <Modal
      @isOpen={{this.settleModalOpen}}
      @onClose={{this.closeSettleModal}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Header>{{t "tuition_assistance.admin.payments.settle"}}</m.Header>
      <m.Body>
        <SelectGeneric
          @label="Payment Runs"
          @options={{this.settleablePayments}}
          @selected={{this.selectedPayment}}
          @onChange={{this.setSelectedPayment}}
          @searchEnabled={{true}}
          @renderInPlace={{false}}
        >
          <:option as |option|>
            {{safeFormatDate option.paymentDate}}
            -
            {{option.id}}
          </:option>
        </SelectGeneric>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.isSettlingPayment}}
          @onCancel={{this.closeSettleModal}}
          @onSubmit={{this.settlePayment}}
          @submitText="Settle Payment"
        />
      </m.Footer>
    </Modal>
    <Modal
      @isOpen={{this.cancelModalOpen}}
      @onClose={{this.closeCancelModal}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Header>{{t "tuition_assistance.admin.payments.cancel"}}</m.Header>
      <m.Body>
        <SelectGeneric
          @label="Payment Runs"
          @options={{this.settleablePayments}}
          @selected={{this.selectedPayment}}
          @onChange={{this.setSelectedPayment}}
          @searchEnabled={{true}}
          @renderInPlace={{false}}
        >
          <:option as |option|>
            {{safeFormatDate option.paymentDate}}
            -
            {{option.id}}
          </:option>
        </SelectGeneric>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.isCancelingPayment}}
          @onCancel={{this.closeCancelModal}}
          @onSubmit={{this.cancelPayment}}
          @submitText="Cancel Payment"
        />
      </m.Footer>
    </Modal>
  </template>
}

export default RouteTemplate(AdminTuitionAssistancePayments);
