import { action } from '@ember/object';
import { getTranslationKeyForReportedSalaryForMatchPlan } from 'tio-common/utils/syf';
import { Modal, Footer } from 'tio-ui/components/modal';
import { object, number } from 'yup';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import TioForm from 'tio-common/components/tio/form';
import type { IntlService } from 'ember-intl';
import type MatchParticipantModel from 'tio-common/models/match-participant';
import type MatchPlanModel from 'tio-common/models/match-plan';
import type Store from '@ember-data/store';
import type Owner from '@ember/owner';

interface SyfDashboardInsightsParticipantFormDialogSignature {
  Args: {
    matchParticipant: MatchParticipantModel;
    matchPlan: MatchPlanModel;
    didSubmit?: (savedModel: MatchParticipantModel) => void;
    onCancel?: () => void;
  };
  Element: HTMLDivElement;
}

type SyfDashboardInsightsParticipantFormData = {
  reportedSalary: number;
  reportedMonthlyPayment: number;
};

class SyfDashboardInsightsParticipantFormDialog extends Component<SyfDashboardInsightsParticipantFormDialogSignature> {
  @service declare store: typeof Store;
  @service declare intl: IntlService;

  @tracked isSaving = false;

  formSubmitAction: (() => void) | undefined = undefined;

  initialFormData: SyfDashboardInsightsParticipantFormData | Record<string, unknown> = {};

  constructor(owner: Owner, args: SyfDashboardInsightsParticipantFormDialogSignature['Args']) {
    super(owner, args);
    const { matchParticipant } = this.args;
    this.initialFormData = {
      reportedSalary: (matchParticipant.reportedSalary || 0) / 100,
      reportedMonthlyPayment: (matchParticipant.reportedMonthlyPayment || 0) / 100,
    };
  }

  get reportedSalaryFieldLabel() {
    return this.intl.t(getTranslationKeyForReportedSalaryForMatchPlan(this.args.matchPlan));
  }

  @action
  setFormSubmitAction(submitAction?: () => SyfDashboardInsightsParticipantFormData) {
    this.formSubmitAction = submitAction;
  }

  @action
  async onSubmit(formData: SyfDashboardInsightsParticipantFormData) {
    this.isSaving = true;
    const model = this.args.matchParticipant;
    this.updateModelWithFormValues(model, formData);
    const saved = await model.save();
    this.args.didSubmit?.(saved);
    this.isSaving = false;
  }

  @action
  updateModelWithFormValues(
    matchParticipant: MatchParticipantModel,
    formData: SyfDashboardInsightsParticipantFormData
  ) {
    const { reportedSalary, reportedMonthlyPayment } = formData;
    matchParticipant.reportedSalary = reportedSalary * 100;
    matchParticipant.reportedMonthlyPayment = reportedMonthlyPayment * 100;
  }

  formSchema = object().shape({
    reportedSalary: number().nullable(),
    reportedMonthlyPayment: number().nullable(),
  });

  <template>
    <Modal
      @isOpen={{true}}
      @onClose={{@onCancel}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      data-test-edit-dependent-dialog
      as |m|
    >
      <m.Body>
        <TioForm
          @i18nPath="syf.plan_form"
          @data={{this.initialFormData}}
          @schema={{this.formSchema}}
          {{! @glint-expect-error}}
          @onSubmit={{this.onSubmit}}
          as |form|
        >
          {{! @glint-expect-error}}
          {{this.setFormSubmitAction form.submit}}
          <form.CurrencyField
            @containerClass="mb-4"
            @name="reportedMonthlyPayment"
            @label={{t "syf.dashboard.insights.estimated_monthly_payment"}}
          />
          <form.CurrencyField
            @containerClass="mb-4"
            @name="reportedSalary"
            @label={{this.reportedSalaryFieldLabel}}
          />

          <form.Errors class="my-6" />
        </TioForm>
      </m.Body>
      <m.Footer>
        {{! @glint-expect-error: formSubmitAction needs to be redone, why is it optional?}}
        <Footer
          @isRunning={{this.isSaving}}
          @onSubmit={{this.formSubmitAction}}
          @onCancel={{@onCancel}}
        />
      </m.Footer>
    </Modal>
  </template>
}

export default SyfDashboardInsightsParticipantFormDialog;
