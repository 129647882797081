import Component from '@glimmer/component';
import type { UploadFile } from 'ember-file-upload';
import { service } from '@ember/service';
import { hash } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import type IntlService from 'ember-intl/services/intl';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import Document from 'ember-static-heroicons/components/outline-24/document';
import ExclamationTriangle from 'ember-static-heroicons/components/outline-24/exclamation-triangle';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import { or } from 'tio-ui/utilities';
import { Button } from 'tio-ui/components/buttons';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import { EXTRACTION_STATES } from 'tio-common/models/observable-document';
import DocumentUploadProgressBar from 'tio-employee/components/observability/upload-progress-bar';
import type RouterService from '@ember/routing/router-service';
import ActionableTranslation from 'tio-ui/components/actionable-translation';
import Alert from 'tio-ui/components/alert';
import { VStack } from 'tio-ui/components/layout';

const parseState = (state: keyof typeof EXTRACTION_STATES): string => {
  return state.substring(state.indexOf('.') + 1);
};

const getProviderTranslation = (provider?: string): string => {
  switch (provider) {
    case 'statement':
      return 'observability.statement';
    case 'nslds':
      return 'observability.my_student_data';
    case 'transaction_history':
      return 'observability.transaction_history';
    default:
      return 'observability.default_document';
  }
};

interface UploadProgressSignature {
  Args: {
    upload: UploadFile;
    observableDocument?: ObservableDocumentModel;
    source?: string;
    providers?: string[];
    uploadError?: Error;
    onRetry: () => void;
    timedOut: boolean;
  };
  Element: HTMLDivElement;
}

export default class UploadProgress extends Component<UploadProgressSignature> {
  @service declare router: RouterService;
  @service declare intl: IntlService;

  // what kind of document do we want from the user?
  get preferredProvider() {
    return this.args.providers?.at(0);
  }

  get documentFailed() {
    const { observableDocument } = this.args;
    const state = observableDocument?.extractionState;
    return state === 'ExtractionState.FAILED';
  }

  get documentInvalid() {
    const { uploadError, observableDocument } = this.args;
    const state = observableDocument?.extractionState;
    return !!uploadError || state === 'ExtractionState.INVALID';
  }

  // was the user supposed to upload a transaction history for SYF but did not?
  get transactionHistoryFailedOrInvalid() {
    return [
      // ensure this is for syf payments since this is the only thing we need txn
      // histories for and the manual payments link is currently hard coded; if
      // this needs to support other products that link will have to get smarter
      // so that this condition can be removed - James 20250212
      this.args.source === 'syf_payments',
      this.preferredProvider === 'transaction_history',
      this.documentFailed || this.documentInvalid,
    ].every(Boolean);
  }

  get documentSuccessful() {
    return this.args.observableDocument?.extractionState === 'ExtractionState.PROCESSED';
  }

  get processingTimedOut() {
    const { timedOut } = this.args;
    const terminalStates = [this.documentSuccessful, this.documentInvalid, this.documentFailed];
    return timedOut && !terminalStates.some(Boolean);
  }

  // join preferred provider translations with string ' or ' in order to interpolate them into
  // message in error alert
  get translatedPreferredProviders(): string {
    const { providers = [] } = this.args;
    if (!providers.length) return this.intl.t(getProviderTranslation());
    return providers
      .map((p) => this.intl.t(getProviderTranslation(p))) // map to observability doc type
      .join(` ${this.intl.t('or')} `);
  }

  get progressBarStatus() {
    const { observableDocument } = this.args;
    const translation = 'observability.uploads.progress.status';

    if (observableDocument) {
      const state = parseState(observableDocument.extractionState);
      return this.intl.t(translation, { state });
    }
    return this.intl.t(translation, { state: null });
  }

  get manualTransactionUrl() {
    // only SYF for now; generate URLS for other products here if necessary, likely
    // can map from product source parameter
    return this.router.urlFor('authenticated.syf.payments.new');
  }

  <template>
    <VStack>
      <div class="flex items-center text-tio-gray-600">
        <Document class="w-6 mr-2" />
        <span>{{@upload.file.name}}</span>
        {{#if (or this.documentInvalid this.documentFailed)}}
          <ExclamationTriangle class="w-6 ml-2 text-red-700" />
        {{else if this.documentSuccessful}}
          <CheckCircle class="w-6 ml-2 text-green-700" />
        {{/if}}
      </div>
      <DocumentUploadProgressBar
        @observableDocument={{@observableDocument}}
        @upload={{@upload}}
        @uploadError={{@uploadError}}
      />
      {{#if this.documentSuccessful}}
        <Alert @intent="success">
          <h3 class="tio-h4 my-0 uppercase font-semibold">
            {{t "observability.uploads.progress.success.header"}}
          </h3>
        </Alert>
      {{else if this.processingTimedOut}}
        <Alert @intent="info">
          <h3 class="tio-h4 uppercase font-semibold">
            {{t "observability.uploads.progress.timeout.header"}}
          </h3>
          <p class="my-4">{{t "observability.uploads.progress.timeout.body"}}</p>
        </Alert>
      {{else if (or this.documentInvalid this.documentFailed)}}
        <Alert @intent="error">
          <h3 class="tio-h4 uppercase font-semibold">
            {{t "observability.uploads.progress.failure.header"}}
          </h3>
          <p class="my-4">{{t
              "observability.uploads.progress.failure.body"
              providers=this.translatedPreferredProviders
            }}</p>
          {{#if this.transactionHistoryFailedOrInvalid}}
            <p class="my-4"><ActionableTranslation
                @t="observability.confirmation.manual_prompt"
                @links={{hash manualPaymentsLink=(hash t="here" href=this.manualTransactionUrl)}}
              /></p>
          {{/if}}
          <Button {{on "click" @onRetry}} class="w-auto">
            {{t "observability.uploads.retry"}}
          </Button>
        </Alert>
      {{else}}
        <h3 class="tio-h4 my-0 uppercase font-semibold">
          {{t "observability.uploads.progress.processing.header"}}
        </h3>
      {{/if}}
      {{#if this.documentFailed}}
        <p class="my-4">
          {{t "observability.uploads.support.prompt"}}
          <span class="block text-sm my-2">
            {{htmlSafe
              (t
                "observability.uploads.support.email"
                email="support@tuition.io"
                linkClass="tio-link"
              )
            }}
          </span>
          <span class="block text-sm my-2">
            {{t "observability.uploads.support.phone"}}
          </span>
        </p>
      {{/if}}
    </VStack>
  </template>
}
