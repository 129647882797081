import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import RouteTemplate from 'ember-route-template';
import transitionTo from 'ember-router-helpers/helpers/transition-to';

export default RouteTemplate(
  <template>
    <EmailLookupWrapper @route="reset-password/confirmation">
      <div class="flex flex-col p-6">
        <h1 class="text-lg mb-6">{{t "login.reset_password_confirmation_title"}}</h1>
        <p class="mb-6">{{t "login.reset_password_confirmation_body"}}</p>
        <Button {{on "click" (transitionTo "login")}} @appearance="outlined" class="w-36 mx-auto">
          {{t "login.back_to_login"}}
        </Button>
      </div>
    </EmailLookupWrapper>
  </template>
);
