import { action } from '@ember/object';
import { isAfter } from 'tio-common/utils/date/is-after';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import awsUrl from 'tio-employee/helpers/aws-url';
import Service from '@ember/service';
import type CompanyModel from 'tio-common/models/company';
import type CompanySettingModel from 'tio-common/models/company-setting';
import type ConditionModel from 'tio-common/models/condition';
import type PartnerService from './partner';
import type SessionContextService from './session-context';
import type StoreService from 'tio-common/services/store';

export type HostnameParts = {
  tld: string;
  domain: string;
  subdomain: string;
};

export type PreRegisterSettings = Partial<CompanySettingModel>;
export type PreRegisterCompany = Partial<CompanyModel>;

export default class PreRegisterService extends Service {
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare partner: PartnerService;

  @tracked sparseCompany?: PreRegisterCompany;
  @tracked conditions: ConditionModel[] = [];

  /**
   * Parses the hostname into its individual components
   * @param hostname - A hostname consisting of one or more domain parts
   * @returns an object containing { subdomain, domain, tld }
   */
  parseHostname(hostname: string): HostnameParts {
    // Basic validation of the hostname
    if (
      !hostname ||
      typeof hostname !== 'string' ||
      hostname.includes('/') ||
      hostname.includes(':')
    ) {
      throw new Error(`'${hostname}' is not a valid hostname`);
    }

    // Handle localhost separately
    if (hostname === 'localhost') {
      return { domain: 'localhost', tld: '', subdomain: '' };
    }

    // Split the hostname into parts
    const parts = hostname.split('.').filter(Boolean); // filter(Boolean) removes empty strings

    // Ensure there are enough parts for a valid domain
    if (parts.length < 2) {
      throw new Error(`'${hostname}' is not a valid domain`);
    }

    let tld = '';
    let domain = '';
    let subdomain = '';

    if (hostname !== 'localhost') {
      const parts = hostname.split('.').filter(Boolean);

      if (parts.length > 1) {
        tld = parts.pop() || ''; // Safe because parts.length > 1
      }

      if (parts.length > 0) {
        domain = parts.pop() || ''; // Safe because parts.length > 0
      }

      subdomain = parts.join('.');
    } else {
      domain = 'localhost';
    }

    return { tld, domain, subdomain };
  }

  get subdomain() {
    const { subdomain } = this.parseHostname(window.location.hostname);

    // TODO: This can be removed and we can return `subdomain` directly
    //       after the shell app is released and we do not have to support
    //       subdomains like `nike.shell.tio-stg.io`. - Julia 11/01/2022
    const firstSegment = subdomain?.split('.')[0];
    return firstSegment || '';
  }

  get settings() {
    return this.sparseCompany?.settings;
  }

  get companyName() {
    return this.sparseCompany?.name;
  }

  get programTitle() {
    return this.sparseCompany?.programTitle;
  }

  get logo() {
    return awsUrl(`partners/${this.subdomain || 'tuition'}.png`);
  }

  get termsOfUse() {
    return this.conditions.find((condition) => condition.classification === 'app_terms_of_use');
  }

  get privacyPolicy() {
    return this.conditions.find((condition) => condition.classification === 'app_privacy_policy');
  }

  get customTerms() {
    return this.conditions.filter((condition) => condition.classification === 'custom');
  }

  get isEmployeeSubdomain() {
    return this.subdomain === 'employee' || this.subdomain === '';
  }

  get securitySettings() {
    return this.settings?.security;
  }

  get launchDate() {
    return this.sparseCompany?.launchDate ?? '';
  }

  get isLaunched() {
    return this?.launchDate ? isAfter(new Date(), this.launchDate) : true;
  }

  get daysUntilLaunch() {
    if (!this.launchDate || this.isLaunched) return 0;

    const targetDate = new Date(this.launchDate);
    const currentDate = new Date();
    const timeDifference = targetDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  }

  @action
  async emailLookup(email: string) {
    const { host } = this.store.adapterFor('application');

    const response = await fetch(`${host}/preregister/validate-email`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  }

  @action
  async load() {
    const { headers, host } = this.store.adapterFor('application');
    const subdomain = this.subdomain || 'employee';
    const response = await fetch(`${host}/preregister/${subdomain}`, {
      headers,
    });
    if (response.ok) {
      this.sparseCompany = await response.json();
    }

    await this.loadTermsAndConditions();
  }

  @action
  async loadTermsAndConditions() {
    const { headers, host } = this.store.adapterFor('application');
    const subdomain = this.subdomain || 'employee';

    const conditionResponse = await fetch(`${host}/preregister/${subdomain}/conditions`, {
      headers,
    });
    if (conditionResponse.ok) {
      const payload = await conditionResponse.json();
      this.store.pushPayload(payload);
      const models = payload?.data?.map((record: Record<string, unknown>) => {
        return this.store.peekRecord('condition', record.id);
      });
      this.conditions = models || [];
    }
  }
}
