import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type UserModel from 'tio-common/models/user';
import type EmployeeModel from 'tio-common/models/employee';
import { t } from 'ember-intl';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import type SessionContextService from '../../../../services/session-context';
import type SessionService from '../../../../services/session';
import MaterialIcon from 'tio-common/components/material-icon';
import FormInput from '@frontile/forms-legacy/components/form-input';
import formatPhone from 'tio-common/helpers/format-phone';
import { Section } from 'tio-ui/components/layout';
import { on } from '@ember/modifier';
import { Button } from 'tio-ui/components/buttons';

export interface SecuritySettingsSignature {
  Args: {
    user: UserModel;
    employee: EmployeeModel;
  };
}

export default class SecuritySettings extends Component<SecuritySettingsSignature> {
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;

  @tracked isOpen = true;
  @tracked isLoading = false;
  @tracked error = null;
  @tracked isEditingContact = false;
  @tracked isEditingPassword = false;
  @tracked phoneValue = '';
  @tracked emailValue = '';
  @tracked hasSubmitted = false;
  // @tracked currentPasswordValue = '';
  // @tracked newPasswordValue = '';
  // @tracked confirmPasswordValue = '';

  get login() {
    return this.args.user.logins[0];
  }

  get userName() {
    return this.login ? this.login.userName : '';
  }

  @action
  toggleExpand() {
    this.isOpen = !this.isOpen;
  }

  @action
  toggleContactEdit() {
    this.isEditingContact = !this.isEditingContact;
  }

  @action
  togglePasswordEdit() {
    this.isEditingPassword = !this.isEditingPassword;
  }

  @action
  setEmailValue(value: string) {
    this.emailValue = value;
  }

  @action
  setPhoneValue(value: string) {
    this.phoneValue = value;
  }

  get validationErrors() {
    return [];
  }

  saveEmployeeWithChangelog = dropTask(
    async (attrName, value, changelog = 'updated by employee') => {
      this.args.employee[attrName] = value;
      await this.args.employee.save({ adapterOptions: { changelog } });
      this.isEditingContact = false;
    }
  );

  @action
  cancel() {
    this.hasSubmitted = false;
  }

  <template>
    <Section>
      <:header>{{t "profile.security_settings"}}</:header>
      <:body>
        <div class="flex flex-col">
          <div class="flex flex-row mx-8 my-4">
            <div class="self-center w-1/4 font-semibold text-gray-700">
              {{t "profile.personal_info"}}
            </div>
            <div class="flex flex-col w-1/5">
              <span class="text-xs">{{t "login.first_name"}}</span>
              <span class="ml-4 text-xl my-4">{{@user.person.firstName}}</span>
            </div>
            <div class="flex flex-col w-1/5">
              <span class="text-xs">{{t "login.last_name"}}</span>
              <span class="ml-4 text-xl my-4">{{@user.person.lastName}}</span>
            </div>
            <div class="flex-col ml-auto w-1/6 text-gray-500 grid justify-items-center">
              <MaterialIcon @icon="lock" />
              <span class="text-xs">{{t "profile.to_edit_contact_support"}}</span>
            </div>
          </div>
          <hr />
          <div class="flex flex-row mx-8 my-4">
            <div class="self-center w-1/4 font-semibold text-gray-700">
              {{t "profile.username_login"}}
            </div>
            <div class="flex flex-col w-1/5">
              <span class="text-xs">{{t "profile.username"}}</span>
              <span class="text-xl my-4 ml-4">{{this.userName}}</span>
            </div>
            <div class="flex-col ml-auto w-1/6 text-gray-500 grid justify-items-center">
              <MaterialIcon @icon="lock" />
              <span class="text-xs">{{t "profile.to_edit_contact_support"}}</span>
            </div>
          </div>
          <hr />
          <div class="flex flex-row mx-8 my-4">
            <div class="self-center w-1/4 font-semibold text-gray-700">
              {{t "profile.contact_info"}}
            </div>
            <div class="flex flex-col">
              <span class="text-xs">{{t "profile.mobile_phone"}}</span>
              {{#if this.isEditingContact}}
                <FormInput
                  data-legacy-input
                  @value={{formatPhone this.phoneValue}}
                  @onChange={{this.setPhoneValue}}
                  @errors={{this.validationErrors}}
                  type="tel"
                  class="my-1"
                />
              {{else if @employee.phoneNumber}}
                <span class="text-xl my-4 ml-4">
                  {{formatPhone @employee.phoneNumber}}
                </span>
              {{else}}
                <span class="text-xs my-4 ml-4 text-red-500">
                  {{t "profile.no_phone"}}
                </span>
              {{/if}}
            </div>
            <div class="flex flex-row justify-end self-center ml-auto w-1/6 gap-x-2">
              {{#if this.isEditingContact}}
                <Button
                  @appearance="outlined"
                  @intent="primary"
                  {{on
                    "click"
                    (fn this.saveEmployeeWithChangelog.perform "phoneNumber" this.phoneValue)
                  }}
                >
                  {{t "save"}}
                </Button>
                <Button
                  @appearance="outlined"
                  @intent="danger"
                  {{on "click" this.toggleContactEdit}}
                >
                  {{t "cancel"}}
                </Button>
              {{else}}
                <Button
                  @appearance="outlined"
                  @intent="primary"
                  {{on "click" this.toggleContactEdit}}
                  class="self-auto"
                >
                  {{t "edit"}}
                </Button>
              {{/if}}
            </div>
          </div>
        </div>
      </:body>
    </Section>
  </template>
}
