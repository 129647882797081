import Component from '@glimmer/component';
import { action } from '@ember/object';
import { t } from 'ember-intl';
import { Form, Switch } from 'tio-ui/components/forms';

export interface ShouldIRefinanceComponentSignature {
  Args: {
    recommendation: (arg0: boolean) => void;
  };
}

class ShouldIRefinanceComponent extends Component<ShouldIRefinanceComponentSignature> {
  @action
  switchChanged(form: Record<string, boolean>) {
    let match = [true, true, true, true, false, false, false, false];

    return this.args?.recommendation(Object.values(form).toString() === match.toString());
  }

  <template>
    {{! @glint-expect-error: TODO look into how to pass in a generic}}
    <Form @onChange={{this.switchChanged}} class="grid grid-cols-12 gap-4">
      <div class="mt-4 col-span-4 sm:col-span-2">
        <div class="flex gap-2">
          <label>{{t "no"}}</label>
          <Switch name="choice1" @label={{t "yes"}} @intent="success" />
        </div>
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_good_credit_score"}}
        </h4>
        <p>{{t "wellness.refinancing_good_credit_score_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <div class="flex gap-2">
          <label>{{t "no"}}</label>
          <Switch name="choice2" @label={{t "yes"}} @intent="success" />
        </div>
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">{{t "wellness.refinancing_stable_income"}}</h4>
        <p>{{t "wellness.refinancing_stable_income_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <div class="flex gap-2">
          <label>{{t "no"}}</label>
          <Switch name="choice3" @label={{t "yes"}} @intent="success" />
        </div>
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_loan_balance_at_least"}}
        </h4>
        <p>{{t "wellness.refinancing_loan_balance_at_least_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <div class="flex gap-2">
          <label>{{t "no"}}</label>
          <Switch name="choice4" @label={{t "yes"}} @intent="success" />
        </div>
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_interest_be_lower"}}
        </h4>
        <p>{{t "wellness.refinancing_interest_be_lower_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <div class="flex gap-2">
          <label>{{t "no"}}</label>
          <Switch name="choice5" @label={{t "yes"}} @intent="success" />
        </div>
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_have_federal_loans_trouble"}}
        </h4>
        <p>{{t "wellness.refinancing_have_federal_loans_trouble_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <div class="flex gap-2">
          <label>{{t "no"}}</label>
          <Switch name="choice6" @label={{t "yes"}} @intent="success" />
        </div>
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_eligible_loan_forgiveness"}}
        </h4>
        <p>{{t "wellness.refinancing_eligible_loan_forgiveness_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <div class="flex gap-2">
          <label>{{t "no"}}</label>
          <Switch name="choice7" @label={{t "yes"}} @intent="success" />
        </div>
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_interest_rate_comfortable"}}
        </h4>
        <p>{{t "wellness.refinancing_interest_rate_comfortable_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <div class="flex gap-2">
          <label>{{t "no"}}</label>
          <Switch name="choice8" @label={{t "yes"}} @intent="success" />
        </div>
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">{{t "wellness.refinancing_back_to_school"}}</h4>
        <p>{{t "wellness.refinancing_back_to_school_description"}}</p>
      </div>
    </Form>
  </template>
}

export default ShouldIRefinanceComponent;
