import { on } from '@ember/modifier';
import MaterialIcon from 'tio-common/components/material-icon';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';

interface PslfDashboardTodoCheckboxSignature {
  Args: {
    checked: boolean;
    onClick: () => void;
  };
  Element: HTMLButtonElement;
}

const PslfDashboardTodoCheckboxComponent: TOC<PslfDashboardTodoCheckboxSignature> = <template>
  <button class="mr-2" ...attributes type="button" {{on "click" @onClick}}>
    <MaterialIcon
      @icon={{if @checked "check_box" "check_box_outline_blank"}}
      class="text-ocean-600"
    />
  </button>
</template>;

export default PslfDashboardTodoCheckboxComponent;
