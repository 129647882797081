import Service from '@ember/service';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from './session-context';

export default class AnswerProfileService extends Service {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  @action
  async storeAnswers(providingApp = 'DASHBOARD', answers = {}) {
    let profile = this.sessionContext.user.answerProfile;

    if (!profile) {
      profile = await this.findOrCreateAnswerProfileForCurrentUser();
    }

    if (!profile) {
      throw new Error('Could not find or create answer profile');
    }

    profile.providingApp = providingApp;
    for (const key in answers) {
      // @ts-expect-error: Find out possible values.
      profile[key] = answers[key];
    }
    await profile.save();
    return profile;
  }

  @action
  async findOrCreateAnswerProfileForCurrentUser() {
    if (this.sessionContext.user.answerProfile) {
      return this.sessionContext.user.answerProfile;
    }

    const queryResults = await this.store.query('answer-profile', {
      filter: {
        user: this.sessionContext.user.id,
      },
      include: 'user',
    });

    if (queryResults.length) {
      return queryResults[0];
    } else {
      const answerProfile = this.store.createRecord('answer-profile', {
        // TODO:
        // Remove non-null constaint from tio-api / database.
        // The database requires a value for `providing_app` and it must be an enum value
        // and therefore we have to have some default here to avoid errors on save if no
        // additional value is provided. - Julia 03/06/2023
        providingApp: 'DASHBOARD',
        user: this.sessionContext.user,
      });
      return answerProfile;
    }
  }
}
