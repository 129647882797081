import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import TuitionAssistanceFormsCoursesCourseReview from 'tio-employee/components/tuition-assistance/forms/courses/course-review';
import type TasApplicationModel from 'tio-common/models/tas-application';

interface S {
  Args: {
    model: TasApplicationModel;
  };
}

export default RouteTemplate<S>(
  <template>
    <h3 class="font-semibold text-midnight text-lg md:text-xl">
      {{t "tuition_assistance.program_details.courses.application_review"}}
    </h3>
    <TuitionAssistanceFormsCoursesCourseReview @application={{@model}} />
  </template>
);
