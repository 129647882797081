import { action } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { boolean, object } from 'yup';
import { cached, tracked } from '@glimmer/tracking';
import { fn } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { not, or } from 'tio-ui/utilities';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { setSemesterCodeString } from 'tio-common/utils/format';
import { t } from 'ember-intl';
import { TrackedObject } from 'tracked-built-ins';
import {
  buildCustomFieldsFormModelForValidation,
  buildValidationSchemaForCustomFields,
  buildValidationSchemaForProgramTemplateFields,
  copyFieldsAndUpdatePerProgramTemplate,
  getSingleValueForTasField,
  setSingleValueForTasField,
} from 'tio-common/utils/tuition-assistance/fields';
import AcceptConditions from '../accept-conditions';
import CardsGeneric from 'tio-common/components/tuition-assistance/cards/generic';
import Component from '@glimmer/component';
import CourseCard from './course-review/course-card';
import CourseSubmitted from './course-submitted';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormInput from '@frontile/forms-legacy/components/form-input';
import HeldParticipantWarning from 'tio-common/components/tuition-assistance/forms/courses/held-participant-warning';
import LimitsSummary from 'tio-common/components/tuition-assistance/limits-summary';
import MaterialIcon from 'tio-common/components/material-icon';
import ProgramFormField from 'tio-common/components/tuition-assistance/program-form-field';
import ReimbursementCalculationIfApplicable from 'tio-common/components/tuition-assistance/reimbursement-calculation-if-applicable';
import ScholarshipCard from 'tio-common/components/tuition-assistance/program-details/app-details/scholarship-card';
import TioAlert from 'tio-common/components/tio/alert';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import type { FieldName, ValidationField } from 'tio-common/utils/tuition-assistance/fields';
import type { ReceivedScholarship } from 'tio-common/types/tuition-assistance';
import type ConditionModel from 'tio-common/models/condition';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasCourseModel from 'tio-common/models/tas-course';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import type FeaturesService from 'tio-common/services/features';
import type { CourseDateValidationResult } from 'tio-common/components/tuition-assistance/forms/courses/course-duration';
import type Owner from '@ember/owner';
import { Button } from 'tio-ui/components/buttons';

export interface TuitionAssistanceFormsCoursesCourseReviewSignature {
  Args: {
    application: TasApplicationModel;
  };
}

const courseAttachmentSchema = object({
  attachments: boolean().oneOf([true]).required(),
});

export default class TuitionAssistanceFormsCoursesCourseReview extends Component<TuitionAssistanceFormsCoursesCourseReviewSignature> {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare tuitionAssistance: TuitionAssistanceService;
  @service declare features: FeaturesService;

  @tracked hasSubmitted = false;
  @tracked didSubmitSuccessfully = false;
  @tracked submitError = '';
  @tracked acceptedConditions: ConditionModel[] = [];

  applicationFieldsCopy!: TasApplicationModel['fields'];

  constructor(owner: Owner, args: TuitionAssistanceFormsCoursesCourseReviewSignature['Args']) {
    super(owner, args);
    this.applicationFieldsCopy = new TrackedObject(this.args.application.fields);
  }

  getSingleValueForTasField = getSingleValueForTasField;

  get isClaimsFinancialsApplication() {
    return this.args.application.tasProgramInstance.tasProgramTemplate
      .calculateTotalByClaimsFinancials;
  }

  get conditionsForSubmit() {
    return (
      this.args.application.tasProgramInstance.tasProgramTemplate
        .applicationApproveCoursesConditions || []
    );
  }

  get hasAgreedAllConditions() {
    if (!this.conditionsForSubmit.length) {
      return true;
    }
    return this.conditionsForSubmit.length === this.acceptedConditions.length;
  }

  get courseApprovalRequired() {
    return this.args.application.tasProgramInstance.tasProgramTemplate.courseApprovalRequired;
  }

  get showHeldParticipantWarning() {
    return !!this.args.application?.tasProgramInstance?.employee?.tasParticipant?.isHeld;
  }

  get courseFormFields() {
    const numberTypeError = 'Value must be a number';
    return [
      { name: 'COURSE_NAME', mask: null, rules: { type: 'string' } },
      { name: 'COURSE_NUMBER', mask: null, rules: { type: 'string' } },
      { name: 'COURSE_DESCRIPTION', mask: null, rules: { type: 'string' } },
      {
        name: 'COURSE_CREDIT',
        mask: null,
        rules: { type: 'number', typeError: numberTypeError },
      },
      {
        name: 'COURSE_TUITION',
        mask: 'currency',
        rules: { type: 'number', typeError: numberTypeError },
      },
      {
        name: 'COURSE_BOOKS',
        mask: 'currency',
        rules: { type: 'number', typeError: numberTypeError },
      },
      {
        name: 'COURSE_LABS',
        mask: 'currency',
        rules: { type: 'number', typeError: numberTypeError },
      },
      {
        name: 'COURSE_SOFTWARE',
        mask: 'currency',
        rules: { type: 'number', typeError: numberTypeError },
      },
      {
        name: 'COURSE_FEES',
        mask: 'currency',
        rules: { type: 'number', typeError: numberTypeError },
      },
    ] as TasCourseModel['formFields'];
  }

  get requireAttachmentUploads() {
    return this.args.application.tasProgramInstance.tasProgramTemplate
      .requireAttachmentsForCourseApproval;
  }

  get semesterCode() {
    return getSingleValueForTasField('SEMESTER_CODE', this.applicationFieldsCopy) || '';
  }

  @action
  setSemesterCode(date: string) {
    const semesterCode = setSemesterCodeString(date);
    setSingleValueForTasField('SEMESTER_CODE', semesterCode, this.applicationFieldsCopy);
  }

  @action
  hasAttachments(course: TasCourseModel) {
    return !!course?.approvalAssets?.length;
  }

  @action
  formModel(course: TasCourseModel) {
    const customCourseFields = this.getCustomFieldsForCourse(course);
    const customFieldsFormModelForProvince =
      buildCustomFieldsFormModelForValidation(customCourseFields);

    return {
      COURSE_NAME: getSingleValueForTasField('COURSE_NAME', course.fields),
      COURSE_NUMBER: getSingleValueForTasField('COURSE_NUMBER', course.fields),
      COURSE_DESCRIPTION: getSingleValueForTasField('COURSE_DESCRIPTION', course.fields),
      COURSE_CREDIT: getSingleValueForTasField('COURSE_CREDIT', course.fields),
      COURSE_TUITION: getSingleValueForTasField('COURSE_TUITION', course.fields),
      COURSE_BOOKS: getSingleValueForTasField('COURSE_BOOKS', course.fields),
      COURSE_LABS: getSingleValueForTasField('COURSE_LABS', course.fields),
      COURSE_SOFTWARE: getSingleValueForTasField('COURSE_SOFTWARE', course.fields),
      COURSE_FEES: getSingleValueForTasField('COURSE_FEES', course.fields),
      attachments: this.hasAttachments(course),
      ...customFieldsFormModelForProvince,
    };
  }

  @action
  getCustomFieldsForCourse(course: TasCourseModel) {
    const programTemplateCustomFieldsForCourse =
      this.args.application.tasProgramInstance?.tasProgramTemplate?.courseCustomFields || [];

    // Resolve any new custom fields since they still have a chance to edit at this point, so we can report errors
    return copyFieldsAndUpdatePerProgramTemplate(
      course.customFields || [],
      programTemplateCustomFieldsForCourse
    );
  }

  @cached
  get dynamicValidationSchemaForCourse() {
    const template = this.args.application.tasProgramInstance.tasProgramTemplate;
    const fields = this.courseFormFields;

    return buildValidationSchemaForProgramTemplateFields(fields, template);
  }

  @cached
  get dynamicValidationSchemaForCourseCustomFields() {
    return buildValidationSchemaForCustomFields(this.customFieldsForCourseProvince);
  }

  get instance() {
    return this.args.application?.tasProgramInstance;
  }

  get template() {
    return this.instance?.tasProgramTemplate;
  }

  get programHasCoursesPreApprovalSubmissionMaxLeadTime() {
    return !!this.template.coursesPreApprovalSubmissionMaxLeadTime;
  }

  formatDateForValidation(date: string) {
    return date ? new Date(date) : undefined;
  }

  get ineligibleBasedOnWaitingPeriod() {
    return this.tuitionAssistance.ineligibleBasedOnWaitingPeriod(
      this.template.eligibilityWaitingPeriod,
      this.instance.employee?.tasParticipant
    );
  }

  get waitingPeriod() {
    return String(
      getSingleValueForTasField('ELIGIBILITY_WAITING_PERIOD', this.template.fields) || 0
    );
  }

  get dateEligible() {
    const date = this.tuitionAssistance.waitingPeriodEndDate(
      this.template.eligibilityWaitingPeriod,
      this.instance.employee?.tasParticipant
    );
    return this.intl.formatDate(date, { month: 'short', day: 'numeric', year: 'numeric' });
  }

  get firstDayToSubmitApplicationForPreApproval(): CourseDateValidationResult | null {
    if (!this.programHasCoursesPreApprovalSubmissionMaxLeadTime) {
      return null;
    }

    const numberOfDays = Number(this.template.coursesPreApprovalSubmissionMaxLeadTime) || null;
    if (!numberOfDays) {
      return null;
    }

    const today = new Date();
    const firstDayToSubmit = new Date(today.getTime() + numberOfDays * 24 * 60 * 60 * 1000);
    const formattedDate = this.intl.formatDate(firstDayToSubmit, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    return {
      date: firstDayToSubmit,
      message: `Your employer requires that you submit your application within ${numberOfDays} days of the start date. Change date to be on or before ${formattedDate}.`,
    };
  }

  get courseMaximumBeginDateWithErrorMessaging(): CourseDateValidationResult {
    if (!this.firstDayToSubmitApplicationForPreApproval) {
      return {
        message: this.intl.t(
          'tuition_assistance.program_details.courses.validation.defaults.begin_date'
        ),
        date: new Date('2099-01-01'),
      };
    }

    return this.firstDayToSubmitApplicationForPreApproval;
  }

  get lastPossibleBeginDateWithErrorMessaging(): CourseDateValidationResult | null {
    let numberOfDays: number | null = null;
    let policyMessage = '';

    const { lastDayToSubmitApplicationForPreApproval, coursesPreApprovalSubmissionMinLeadTime } =
      this.template;

    if (lastDayToSubmitApplicationForPreApproval) {
      numberOfDays = -Number(lastDayToSubmitApplicationForPreApproval) || null;
      policyMessage = numberOfDays
        ? `Your employer requires that you submit your application within ${Math.abs(
            numberOfDays
          )} days after the start date.`
        : '';
    } else if (coursesPreApprovalSubmissionMinLeadTime) {
      numberOfDays = Number(coursesPreApprovalSubmissionMinLeadTime) || null;
      policyMessage = numberOfDays
        ? `Your employer requires that you submit your application at a minimum of ${numberOfDays} days before the start date.`
        : '';
    }

    if (!numberOfDays) {
      return null;
    }

    const today = new Date();
    const lastDayToSubmit = new Date(today.getTime() + numberOfDays * 24 * 60 * 60 * 1000);

    return {
      date: lastDayToSubmit,
      message: policyMessage,
    };
  }

  get courseMinimumBeginDateWithErrorMessaging(): CourseDateValidationResult {
    const lastDayToSubmit = this.lastPossibleBeginDateWithErrorMessaging;

    if (!lastDayToSubmit) {
      return {
        message: this.intl.t(
          'tuition_assistance.program_details.courses.validation.defaults.begin_date'
        ),
        date: new Date('2000-01-01'),
      };
    }

    return lastDayToSubmit;
  }

  @cached
  get dynamicValidationSchema() {
    const courseEndDateMinimum = this.template.isCertificateProgram
      ? '2000-01-01'
      : 'COURSES_BEGIN_DATE';

    const fields: ValidationField[] = [
      {
        name: 'COURSES_BEGIN_DATE',
        rules: {
          type: 'date',
          max: this.courseMaximumBeginDateWithErrorMessaging.date,
          min: this.courseMinimumBeginDateWithErrorMessaging.date,
        },
        errors: {
          max: this.courseMaximumBeginDateWithErrorMessaging.message,
          min: this.courseMinimumBeginDateWithErrorMessaging.message,
        },
      },
      {
        name: 'COURSES_END_DATE',
        rules: { type: 'date', min: courseEndDateMinimum, max: '2099-01-01' },
        errors: {
          min: 'Date must after Start Date',
          max: 'Date must be before Jan 1, 2099',
        },
      },
      {
        name: 'COURSES_RECEIPT_DATE',
        rules: { type: 'date' },
      },
      {
        name: 'SEMESTER_CODE',
        rules: { type: 'string' },
      },
    ];

    return buildValidationSchemaForProgramTemplateFields(fields, this.template);
  }

  get customFieldsForCourseProvince() {
    return this.args.application.tasProgramInstance.tasProgramTemplate?.customFields?.filter(
      // @ts-expect-error province is not defined
      (field: TasCourseModel['customFields']) => field.province === 'COURSE'
    );
  }

  get attachmentUploadsSchema() {
    return this.requireAttachmentUploads ? courseAttachmentSchema : object();
  }

  get isFormValid() {
    return (
      this.isActiveParticipant &&
      this.hasAgreedAllConditions &&
      this.args.application.activeCourses.length > 0 &&
      !this.showHeldParticipantWarning &&
      !this.ineligibleBasedOnWaitingPeriod &&
      this.formValidationErrors.length === 0 &&
      this.args.application.hasRequiredPreApprovalAttachments
    );
  }

  get courseDateValidation() {
    const fields = this.applicationFieldsCopy;
    const courseStartDate = getSingleValueForTasField('COURSES_BEGIN_DATE', fields) as string;
    const courseEndDate = getSingleValueForTasField('COURSES_END_DATE', fields) as string;
    const courseReceiptDate = getSingleValueForTasField('COURSES_RECEIPT_DATE', fields) as string;
    const semesterCode = getSingleValueForTasField('SEMESTER_CODE', fields) as string;

    return {
      COURSES_BEGIN_DATE: this.formatDateForValidation(courseStartDate),
      COURSES_END_DATE: this.formatDateForValidation(courseEndDate),
      COURSES_RECEIPT_DATE: this.formatDateForValidation(courseReceiptDate),
      SEMESTER_CODE: semesterCode,
    };
  }

  get formValidationErrors() {
    const schema = this.dynamicValidationSchema;
    const courses = this.args.application.activeCourses;
    const courseSchema = (this.dynamicValidationSchemaForCourse || object())
      .concat(this.dynamicValidationSchemaForCourseCustomFields || object())
      .concat(this.attachmentUploadsSchema);
    try {
      schema?.validateSync?.(this.courseDateValidation, {
        abortEarly: false,
      });
    } catch (err) {
      console.log('err', err);
      return err.inner || [];
    }
    const errors = [];
    for (let i = 0; i < courses.length; i++) {
      try {
        // @ts-expect-error: not sure. I think it wants to check calue first
        const currentFormModel = this.formModel(courses[i]);
        courseSchema?.validateSync?.(currentFormModel, { abortEarly: false });
      } catch (err) {
        // create error showing index so it can be tracked to the specific card
        // @ts-expect-error: not sure
        const courseError = (err.inner || []).map((error) => {
          return {
            ...error,
            courseIndex: i,
          };
        });
        errors.push(...courseError);
      }
    }
    return errors;
  }

  get calculationFormulaNoteIfApplicable() {
    if (this.args.application.tasProgramInstance?.tasProgramTemplate?.calculateTotalByCredit) {
      return `${this.args.application.requestedTotalCalculationFormulaNote} (Pre-calculated requested total before scholarships)`;
    }

    if (this.args.application.tasProgramInstance?.tasProgramTemplate?.calculateTotalByPercent) {
      return `${this.args.application.requestedTotalCalculationFormulaNote} (Pre-calculated requested total)`;
    }

    return '';
  }

  get isActiveParticipant() {
    return this.tuitionAssistance.hasActiveEligibilityForProgramTemplate(
      this.args.application.tasProgramInstance.tasProgramTemplate
    );
  }

  @action
  didUpdateAcceptedConditions(conditions: ConditionModel[] = []) {
    this.acceptedConditions = conditions;
  }

  @action
  cancel() {
    this.router.transitionTo('authenticated.tuition-assistance.programs.instance.index');
  }

  @action
  async removeCourse(course: TasCourseModel) {
    if (!course) {
      return;
    }
    return course.destroyRecord();
  }

  @action
  async saveForLater() {
    await this.saveFields.perform();
    this.router.transitionTo('authenticated.tuition-assistance.programs.instance.index');
  }

  // @action
  // formatDate(date: string) {
  //   return date ? new Date(date) : undefined;
  // }

  @action
  formatMoney(cents: number) {
    const dollars = cents / 100;
    return this.intl.formatNumber(dollars, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    });
  }

  @action
  updateValueForField(fieldName: FieldName, value: string) {
    // @ts-expect-error: function needs generic support
    setSingleValueForTasField(fieldName, value, this.applicationFieldsCopy);
    if (fieldName === 'COURSES_BEGIN_DATE' && this.template.semesterCodeIsVisible) {
      this.setSemesterCode(value);
    }
  }

  @action
  didUpdateScholarships(editableScholarships: ReceivedScholarship[]) {
    this.applicationFieldsCopy['SCHOLARSHIPS_RECEIVED'] = {
      values: editableScholarships,
    };
    this.saveScholarships.perform();
  }

  saveScholarships = dropTask(async () => {
    this.args.application.fields = this.applicationFieldsCopy;
    try {
      await this.args.application.save();
    } catch (e) {
      console.error(e);
    }
  });

  submitTask = dropTask(async () => {
    this.hasSubmitted = true;
    this.submitError = '';

    if (!this.isFormValid || !this.hasAgreedAllConditions) {
      return;
    }

    try {
      const limitsValidationResponse = await this.validateLimits.perform();
      if (!limitsValidationResponse.can_submit) {
        this.displayLimitsValidationError(limitsValidationResponse);
        return;
      }
      await this.saveAgreementsForConditions.perform();
      await this.saveFields.perform();
      await this.store
        .adapterFor('tas-application')
        .requestCoursesApproval(this.args.application, this.calculationFormulaNoteIfApplicable);
      this.didSubmitSuccessfully = true;
    } catch (e) {
      console.error(e);
      this.submitError = e;
    }
  });

  saveAgreementsForConditions = dropTask(async () => {
    const user = this.sessionContext.user;
    const agreements = this.acceptedConditions.map((condition) => {
      return this.store.createRecord('agreement', {
        user: user,
        condition: condition,
      });
    });
    const promises = agreements.map((agreement) => {
      return agreement.save();
    });
    const saved = await all(promises);
    return saved;
  });

  saveFields = dropTask(async () => {
    // Store pre-approved total upon submission for book-keeping
    // Claims financials applications are initialized with 0 since the requested total is irrelevant
    setSingleValueForTasField(
      'PRE_APPROVED_TOTAL',
      this.isClaimsFinancialsApplication ? 0 : this.args.application.requestedTotal,
      this.applicationFieldsCopy
    );
    this.args.application.fields = this.applicationFieldsCopy;
    await this.args.application.save();
  });

  validateLimits = dropTask(async () => {
    const results = await this.tuitionAssistance.validateAmountRequestedAgainstCurrentLimitsStatus(
      this.args.application,
      this.args.application.tasProgramInstance.employee
    );
    return results;
  });

  displayLimitsValidationError(validationResponse = {}) {
    // TODO: Eventually this message will be configurable via the program template.
    this.submitError = `You have exceeded your employer's maximum limit. Please reduce your requested amount by ${this.formatMoney(
      // @ts-expect-error: not sure about this one
      validationResponse.reduction_needed
    )}.`;
  }

  <template>
    {{#if this.didSubmitSuccessfully}}
      <CourseSubmitted
        @application={{@application}}
        @courseApprovalRequired={{this.courseApprovalRequired}}
      />
    {{else}}
      {{#unless this.isActiveParticipant}}
        <TioAlert @type="warning" @allowDismiss={{false}} class="my-4">
          <:header>
            <p class="font-bold">
              {{t "tuition_assistance.inactive_participant_warnings.dashboard_title"}}
            </p>
          </:header>
          <:body>
            <p class="text-sm">
              {{t "tuition_assistance.inactive_participant_warnings.dashboard_description"}}
            </p>
          </:body>
        </TioAlert>
      {{/unless}}
      <TioAlert @type="info" @allowDismiss={{false}} class="my-4">
        <:header>
          <p class="font-bold uppercase">
            {{t "tuition_assistance.program_details.courses.attention"}}
          </p>
        </:header>
        <:body>
          <p class="text-sm">
            {{t "tuition_assistance.program_details.courses.course_duration_alert"}}
          </p>
        </:body>
      </TioAlert>

      {{#if this.ineligibleBasedOnWaitingPeriod}}
        <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
          <:header>
            <p class="font-bold">
              {{t "tuition_assistance.program_eligibility_warnings.dashboard_title"}}
            </p>
          </:header>
          <:body>
            <p class="text-sm">
              {{t
                "tuition_assistance.program_eligibility_warnings.dashboard_description"
                time=this.waitingPeriod
                date=this.dateEligible
                htmlSafe=true
              }}
            </p>
          </:body>
        </TioAlert>
      {{/if}}
      <h4 class="font-semibold">
        {{t "tuition_assistance.program_details.courses.please_ensure_your_application"}}
      </h4>
      <section class="flex flex-col flex-wrap gap-2 md:gap-4 justify-center md:justify-start">
        <h3 class="font-semibold mt-6 uppercase">
          {{t "tuition_assistance.program_details.courses.my_courses"}}
        </h3>
        <div class="flex flex-wrap gap-4 mx-16 md:mx-0">
          <div class="w-full md:w-64">
            <ProgramFormField
              @name="COURSES_BEGIN_DATE"
              @programTemplate={{@application.tasProgramInstance.tasProgramTemplate}}
              as |field|
            >
              <FormInput
                data-legacy-input
                id={{field.inputId}}
                type="date"
                @value={{this.getSingleValueForTasField field.name this.applicationFieldsCopy}}
                @onInput={{fn this.updateValueForField field.name}}
                @errors={{errorsForField
                  "COURSES_BEGIN_DATE"
                  schemaErrors=this.formValidationErrors
                }}
              />
            </ProgramFormField>
          </div>

          {{#if @application.tasProgramInstance.tasProgramTemplate.courseReceiptDateVisible}}
            <div class="w-full md:w-64">
              <ProgramFormField
                @name="COURSES_RECEIPT_DATE"
                @programTemplate={{@application.tasProgramInstance.tasProgramTemplate}}
                as |field|
              >
                <FormInput
                  data-legacy-input
                  id={{field.inputId}}
                  type="date"
                  @value={{this.getSingleValueForTasField field.name this.applicationFieldsCopy}}
                  @onInput={{fn this.updateValueForField field.name}}
                  @errors={{errorsForField
                    "COURSES_RECEIPT_DATE"
                    schemaErrors=this.formValidationErrors
                  }}
                />
              </ProgramFormField>
            </div>
          {{/if}}
          <div class="w-full md:w-64">
            <ProgramFormField
              @name="COURSES_END_DATE"
              @programTemplate={{@application.tasProgramInstance.tasProgramTemplate}}
              as |field|
            >
              <FormInput
                data-legacy-input
                id={{field.inputId}}
                type="date"
                @value={{this.getSingleValueForTasField field.name this.applicationFieldsCopy}}
                @onInput={{fn this.updateValueForField field.name}}
                @errors={{errorsForField "COURSES_END_DATE" schemaErrors=this.formValidationErrors}}
              />
            </ProgramFormField>
          </div>
          <div class="w-full md:w-64">
            <ProgramFormField
              @name="SEMESTER_CODE"
              @programTemplate={{@application.tasProgramInstance.tasProgramTemplate}}
              as |field|
            >
              <FormInput
                data-legacy-input
                id={{field.inputId}}
                @value="{{this.semesterCode}}"
                @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
                disabled={{true}}
              />
            </ProgramFormField>
          </div>
        </div>

        <div class="flex flex-wrap gap-5 justify-center md:justify-start my-4">
          {{#each @application.activeCourses as |course index|}}
            {{#if course.id}}
              <CourseCard
                @course={{course}}
                @onRemove={{fn this.removeCourse course}}
                @errors={{this.formValidationErrors}}
                @index={{index}}
              />
            {{/if}}
          {{/each}}
          <div>
            <ScholarshipCard
              @application={{@application}}
              @isEditable={{true}}
              @onUpdateScholarships={{this.didUpdateScholarships}}
            />
          </div>
          <LinkTo
            @route="authenticated.tuition-assistance.programs.instance.course-applications.show.pre-approval.add-course"
          >
            <CardsGeneric class="h-full border-dashed border-4 border-tio-gray-400 !shadow-none">
              <:body>
                <div class="h-full flex justify-center items-center">
                  <div class="flex items-center">
                    <p class="text-2xl font-semibold text-tio-gray-600 uppercase">
                      {{t "tuition_assistance.program_details.courses.add_course"}}
                    </p>
                    <MaterialIcon @icon="add_circle_outline" class="ml-1 text-ocean-600 text-5xl" />
                  </div>
                </div>
              </:body>
            </CardsGeneric>
          </LinkTo>
        </div>
      </section>

      <LimitsSummary
        @application={{@application}}
        @employee={{@application.tasProgramInstance.employee}}
        @labelClass="col-span-5"
        @valueClass="col-span-2 text-violet-800"
        as |Summary|
      >
        <Summary.Container class="mx-auto my-4 md:max-w-md">
          {{#unless Summary.isUnlimitedMaximumAnnualBenefit}}
            <Summary.MaxAnnualBenefitLineItem />
          {{/unless}}
          {{#if Summary.hasLimitsErrorForEmployee}}
            <Summary.RemainingBenefitLineItem />
          {{/if}}
          <Summary.ApplicationAmountRequestedLineItem />
          <Summary.LimitsAlertForEmployeeIfApplicable class="text-sm col-span-full" />
          <ReimbursementCalculationIfApplicable @application={{@application}} />
        </Summary.Container>

        {{#if this.conditionsForSubmit.length}}
          <AcceptConditions
            @conditions={{this.conditionsForSubmit}}
            @onChange={{this.didUpdateAcceptedConditions}}
          />
        {{/if}}

        {{#if this.showHeldParticipantWarning}}
          <HeldParticipantWarning class="my-4 mx-auto max-w-md" />
        {{/if}}

        <div class="flex flex-col justify-center items-center my-5">
          <TioErrorMessages
            @showErrors={{this.hasSubmitted}}
            @error={{this.submitError}}
            @icon="warning"
            class="mb-2"
          />
          <Button
            {{on "click" this.submitTask.perform}}
            @isRunning={{this.submitTask.isRunning}}
            disabled={{or Summary.isDisabledSubmitForEmployee (not this.isFormValid)}}
            class="mx-8 w-48"
          >
            {{t "submit"}}
          </Button>
          <button
            type="button"
            {{on "click" this.saveForLater}}
            class="text-xs tio-copy text-center my-4"
          >
            {{t "save_for_later"}}
          </button>
        </div>
      </LimitsSummary>
    {{/if}}
  </template>
}
