import RouteTemplate from 'ember-route-template';
import AppPageHeroSection from 'tio-employee/components/app/page/hero-section';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import { t } from 'ember-intl';
import { Section, VStack } from 'tio-ui/components/layout';

export default RouteTemplate(
  <template>
    <VStack>
      <Section>
        <AppPageHeroSection>
          <:title>
            {{t "questions_you_have.paying_for_college.what_is_federal_loan_q"}}
          </:title>
          <:body>
            {{t "questions_you_have.paying_for_college.what_is_federal_loan_a"}}
          </:body>
        </AppPageHeroSection>
        <div class="pt-10 p-4">
          <h1 class="text-xl text-midnight font-medium">
            {{t "questions_you_have.types_of_student_loans.default"}}
          </h1>
        </div>
        <TioPageTabs class="px-4" as |tabs|>
          <tabs.tab
            @route="authenticated.questions-you-have.paying-for-college.index"
            @label="{{t 'questions_you_have.types_of_student_loans.federal_aid'}}"
          />
          <tabs.tab
            @route="authenticated.questions-you-have.paying-for-college.private-loans"
            @label="{{t 'questions_you_have.types_of_student_loans.private_loans'}}"
          />
          <tabs.tab
            @route="authenticated.questions-you-have.paying-for-college.plus-loans"
            @label="{{t 'questions_you_have.types_of_student_loans.plus_loans'}}"
          />
        </TioPageTabs>
        <div class="mb-4">
          {{outlet}}
        </div>
      </Section>
    </VStack>
  </template>
);
