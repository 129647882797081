import RouteTemplate from 'ember-route-template';
import type PslfFormModel from 'tio-common/models/pslf-form';
import PslfSignFormPage from 'tio-employee/components/pslf/sign-form-page';
import { t } from 'ember-intl';
import { Header, Section, VStack } from 'tio-ui/components/layout';

interface S {
  Args: {
    model: PslfFormModel;
  };
}
export default RouteTemplate<S>(
  <template>
    <VStack>
      <Header>{{t "pslf.default_full"}}</Header>
      <Section>
        <:body>
          <PslfSignFormPage @form={{@model.form}} @signer={{@model.signer}} />
        </:body>
      </Section>
    </VStack>
  </template>
);
