import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';

interface PslfDashboardTodoNumberBadgeSignature {
  Args: {
    number: string;
  };
  Element: HTMLDivElement;
}

const PslfDashboardTodoNumberBadgeComponent: TOC<PslfDashboardTodoNumberBadgeSignature> = <template>
  <div
    class="rounded-full bg-ocean-600 text-white mr-2 w-6 h-6 flex items-center justify-center"
    ...attributes
  >
    {{@number}}
  </div>
</template>;

export default PslfDashboardTodoNumberBadgeComponent;
