import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import TuitionAssistanceProgramCard from 'tio-common/components/tuition-assistance/program-card';
import type IntlService from 'ember-intl/services/intl';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import { Chip } from 'tio-ui/components/buttons';

export interface TuitionAssistanceApplyToProgramsSignature {
  Args: {
    programInstances?: TasProgramInstanceModel[];
    programTemplates?: TasProgramTemplateModel[];
  };
  Element: HTMLDivElement;
}

export default class TuitionAssistanceApplyToPrograms extends Component<TuitionAssistanceApplyToProgramsSignature> {
  @service declare intl: IntlService;

  get instances() {
    return this.args.programInstances || [];
  }

  get activeTemplates() {
    return this.instances.map((instance) => instance.tasProgramTemplate.code);
  }

  get programTemplates() {
    return this.args.programTemplates || [];
  }

  get programs() {
    const programs = this.programTemplates;

    return programs.map((template) => {
      const templateActive = this.activeTemplates.includes(template.code);
      // this is a temporary solution to differentiate between legacy and typed programs
      const typeClassificationSet = !!template.typeClassification;

      const buttonRoute = typeClassificationSet
        ? 'authenticated.tas.getting-started'
        : 'authenticated.tuition-assistance.program-overview';
      return {
        title: template.programName,
        isTrial: template.isTrial,
        buttonRoute: buttonRoute,
        buttonRouteModel: template.id,
        buttonText: templateActive
          ? this.intl.t('tuition_assistance.view')
          : this.intl.t('tuition_assistance.view_and_apply'),
        description: template.programDescription,
      };
    });
  }

  <template>
    <div ...attributes>
      <div class="font-semibold mb-4">
        {{t "tuition_assistance.dashboard.programs_i_can_apply"}}
      </div>
      <div class="flex flex-wrap gap-4">
        {{#each this.programs as |program|}}
          <TuitionAssistanceProgramCard
            @buttonClass="w-48"
            @buttonText={{program.buttonText}}
            @buttonRoute={{program.buttonRoute}}
            @buttonRouteModel={{program.buttonRouteModel}}
            @title={{program.title}}
            @titleClass="font-semibold"
            @useTextLinkTo={{false}}
            class="mt-4 mb-2"
          >
            {{#if program.isTrial}}
              <div class="flex justify-center items-center">
                <Chip @size="sm" @intent="danger">
                  {{t "common.trial"}}
                </Chip>
              </div>
            {{/if}}
            <div class="flex items-center h-full">
              <p class="mx-2 font-medium content-center">{{program.description}}</p>
            </div>
          </TuitionAssistanceProgramCard>
        {{else}}
          <TuitionAssistanceProgramCard class="mt-4 mb-2">
            <p class="text-center my-2 font-medium">
              {{t "tuition_assistance.dashboard.no_eligible_programs"}}
            </p>
          </TuitionAssistanceProgramCard>
        {{/each}}
      </div>
    </div>
  </template>
}
