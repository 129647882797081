import { action } from '@ember/object';
import { eq } from 'tio-ui/utilities';
import { fn } from '@ember/helper';
import type { NotificationsService } from 'tio-ui/components/notifications';
import { restartableTask, lastValue } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Card from './my-forms-table/card';
import ChangeApprover from './my-forms-table/change-approver';
import Component from '@glimmer/component';
import embedPdf from 'tio-common/modifiers/embed-pdf';
import Row from './my-forms-table/row';
import screenIs from 'tio-common/helpers/screen-is';
import TioConfirmAlert from 'tio-common/components/tio/confirm-alert';
import TioPdfModal from 'tio-common/components/tio/pdf-modal';
import type { IntlService } from 'ember-intl';
import type EmployeeModel from 'tio-common/models/employee';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import Table from 'tio-common/components/table/index';
import TablePaginator from 'tio-common/components/table/paginator';
import type Owner from '@ember/owner';

export interface PslfMyDocumentsMyFormsTableSignature {
  Args: {
    employee: EmployeeModel;
    limit: number;
  };
  Element: HTMLTableElement;
}

export default class PslfMyDocumentsMyFormsTableComponent extends Component<PslfMyDocumentsMyFormsTableSignature> {
  /////////////////////////////////////
  //////// Services ///////////////////

  @service declare store: typeof Store;
  @service declare router: RouterService;
  @service declare notifications: NotificationsService;
  @service declare intl: IntlService;

  /////////////////////////////////////
  //////// TRACKED VARIABLES //////////

  @tracked showPreview = false;
  @tracked pdfSrc = '';
  @tracked formToChangeApprover?: PslfFormModel;
  @tracked showDisableConfirm = false;
  @tracked formToDisable?: PslfFormModel;
  @tracked formToResend?: PslfFormModel;
  @tracked page = 1;

  constructor(owner: Owner, args: PslfMyDocumentsMyFormsTableSignature['Args']) {
    super(owner, args);
    this.loadRecords.perform();
  }

  /////////////////////////////////////
  //////// GETTERS  ///////////////////

  get isLoading() {
    return this.loadRecords.isRunning;
  }

  get apiHost() {
    return this.store.adapterFor('document')?.host;
  }

  /////////////////////////////////////
  //////// COMPUTED VARIABLES /////////

  @lastValue('loadRecords')
  forms!: PslfFormModel[];

  loadRecords = restartableTask(async (): Promise<PslfFormModel[]> => {
    const sort = '-created-at';
    const page = {
      limit: this.args.limit,
      offset: this.args.limit * (this.page - 1),
    };
    const filter = {
      // Employee could be an ObjectProxy, therefore must use 'get'
      'employee-id': this.args.employee.get('id'),
      'exclude-created': true,
    };
    const include = 'attachments';
    const query = { filter, page, include, sort };
    return await this.store.query('pslfForm', query);
  });

  @action
  doFormAction(action: string, model: PslfFormModel) {
    if (action === 'changeApprover') {
      this.openChangeApprover(model);
    }
    if (action === 'cancel') {
      this.formToDisable = model;
      this.showDisableConfirm = true;
    }
    if (action === 'resend') {
      this.resendEmployerEmail(model);
    }
  }

  @action
  async openChangeApprover(model: PslfFormModel) {
    this.formToChangeApprover = model;
  }

  @action
  async openPreviewModal(model: PslfFormModel) {
    const url = model.attachments?.[0]?.signedUrl;
    this.pdfSrc = url || '';
    this.showPreview = true;
  }

  @action
  closePreviewModal() {
    this.showPreview = false;
    this.pdfSrc = '';
  }

  @action
  async disableSelectedForm() {
    await this.store.adapterFor('pslf-form').cancel(this.formToDisable);
    this.showDisableConfirm = false;
  }

  @action
  cancelDisable() {
    delete this.formToDisable;
    this.showDisableConfirm = false;
  }

  async resendEmployerEmail(model: PslfFormModel) {
    const successMessage = this.intl.t('pslf.document_repo.resend_email_success', {
      email: model.approverDisplayEmail,
    });
    const errorMessage = this.intl.t('pslf.document_repo.resend_email_error', {
      email: model.approverDisplayEmail,
    });
    this.formToResend = model;
    try {
      await this.store.adapterFor('pslf-form').resendEmployerEmail(model);
      this.notifications.add(successMessage, {
        appearance: 'success',
      });
    } catch (err) {
      this.notifications.add(errorMessage, {
        appearance: 'error',
      });
    } finally {
      delete this.formToResend;
    }
  }

  @action
  didChangeApprover(model: PslfFormModel) {
    delete this.formToChangeApprover;
    this.resendEmployerEmail(model);
  }

  @action
  previousPage() {
    this.page = Math.max(1, this.page - 1);
    this.loadRecords.perform();
  }

  @action
  nextPage() {
    ++this.page;
    this.loadRecords.perform();
  }

  get totalPages() {
    // @ts-expect-error: ed needs types
    const meta = this.forms?.meta;

    if (meta?.page.total) {
      return Math.ceil(meta.page.total / this.args.limit);
    }
    return 0;
  }

  <template>
    {{#if (screenIs "md")}}
      <Table @isLoading={{this.isLoading}} ...attributes>
        <:thead as |options|>
          <options.tr>
            <options.th class="w-4" />
            <options.th>{{t "pslf.document_repo.date_employer_signed"}}</options.th>
            <options.th>{{t "pslf.document_repo.date_you_signed"}}</options.th>
            <options.th>{{t "status"}}</options.th>
            <options.th>{{t "pslf.document_repo.approver_info"}}</options.th>
            <options.th>{{t "pslf.document_repo.form_type_and_employer"}}</options.th>
            <options.th class="whitespace-nowrap w-[240px]" />
          </options.tr>
        </:thead>
        <:tbody as |options|>
          {{#each this.forms as |form|}}
            <Row
              @doFormAction={{this.doFormAction}}
              @model={{form}}
              @onPreview={{this.openPreviewModal}}
              @isResending={{eq this.formToResend.id form.id}}
              @isLoading={{false}}
              @cellClass={{options.cellClass}}
            />
          {{else}}
            <options.tr>
              <options.td colspan="7">
                {{t "pslf.document_repo.no_forms_found"}}
              </options.td>
            </options.tr>
          {{/each}}
        </:tbody>
        <:tfoot>
          <tr>
            <td class="text-right" colspan="9">
              <TablePaginator
                @page={{this.page}}
                @totalPages={{this.totalPages}}
                @nextPage={{this.nextPage}}
                @previousPage={{this.previousPage}}
                @limit={{@limit}}
                {{! @glint-expect-error: ed needs types }}
                @total={{this.forms.meta.page.total}}
              />
            </td>
          </tr>
        </:tfoot>
      </Table>
    {{else}}
      {{#each this.forms as |form|}}
        <Card
          @model={{form}}
          @openPreviewModal={{this.openPreviewModal}}
          {{! @glint-expect-error }}
          @formToResend={{this.formToResend}}
          @doFormAction={{this.doFormAction}}
          {{! @glint-expect-error: This is weird as we are appying attrs to table and card}}
          ...attributes
        />
      {{/each}}
    {{/if}}

    <TioPdfModal @show={{this.showPreview}} @onClose={{this.closePreviewModal}}>
      {{#if this.pdfSrc}}
        <div {{embedPdf this.pdfSrc}}></div>
      {{/if}}
    </TioPdfModal>

    <TioConfirmAlert
      @show={{this.showDisableConfirm}}
      @onConfirm={{this.disableSelectedForm}}
      @onCancel={{this.cancelDisable}}
      @confirmButtonText={{t "disable"}}
      @isDestructive={{true}}
      @disabled={{false}}
    >
      <p class="mb-4 font-semibold text-lg">
        {{t "pslf.document_repo.confirm_disable_title"}}
      </p>
      <p>{{t "pslf.document_repo.confirm_disable_prompt_1"}}</p>
      <p class="font-semibold">
        {{t "pslf.document_repo.confirm_disable_prompt_2"}}
      </p>
    </TioConfirmAlert>

    {{#if this.formToChangeApprover}}
      <ChangeApprover
        {{! @glint-expect-error: need to research mut helper}}
        @didCancel={{fn (mut this.formToChangeApprover) null}}
        {{! @glint-expect-error: not sure why we don't pass model here}}
        @didSave={{this.didChangeApprover}}
        @form={{this.formToChangeApprover}}
      />
    {{/if}}
  </template>
}
