import { action } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { Button } from 'tio-ui/components/buttons';
import { currencyStringToNumber } from 'tio-common/utils/format';
import { Divider } from 'tio-ui/components/utilities';
import { fn } from '@ember/helper';
import { Input } from 'tio-ui/components/forms';
import { Modal, Footer } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { or } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { setSemesterCodeString } from 'tio-common/utils/format';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import {
  getAllValuesForTasField,
  getConfigForTasField,
  getSingleValueForTasField,
  setSingleValueForTasField,
  getFieldLabelForFieldName,
  type FieldValue,
  type TemplateFieldName,
} from 'tio-common/utils/tuition-assistance/fields';
import { Header, HStack, Section, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import { capitalizeWords } from 'tio-employee/utils/tas/textUtilities';
import { not } from 'tio-ui/utilities';
import AcceptConditions from 'tio-employee/components/tuition-assistance/forms/accept-conditions';
import ActionableList from 'tio-ui/components/actionable-list';
import AddEducationDrawer from 'tio-employee/components/tas/add-education-drawer';
import AddScholarshipsDrawer from 'tio-employee/components/tas/add-scholarships-drawer';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import CheckCircleSolid from 'ember-static-heroicons/components/solid-24/check-circle';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import ClipboardDocumentList from 'ember-static-heroicons/components/outline-24/clipboard-document-list';
import Component from '@glimmer/component';
import divide from 'ember-math-helpers/helpers/div';
import inputmask from 'tio-common/modifiers/inputmask';
import LimitsSummary from 'tio-common/components/tuition-assistance/limits-summary';
import PencilSquare from 'ember-static-heroicons/components/outline-24/pencil-square';
import Plus from 'ember-static-heroicons/components/solid-24/plus';
import ReimbursementCalculationIfApplicable from 'tio-common/components/tuition-assistance/reimbursement-calculation-if-applicable';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import TioAlert from 'tio-common/components/tio/alert';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import Trash from 'ember-static-heroicons/components/outline-24/trash';
import TuitionAssistanceFormsCustomFieldsForProvince from 'tio-common/components/tuition-assistance/forms/custom-fields-for-province';
import lowercase from 'ember-cli-string-helpers/helpers/lowercase';
import type { ReceivedScholarship } from 'tio-common/types/tuition-assistance';
import type { TASProgramTemplateModelFieldsSignature } from 'tio-common/models/tas-program-template';
import type ConditionModel from 'tio-common/models/condition';
import type IntlService from 'ember-intl/services/intl';
import type Owner from '@ember/owner';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasCourseModel from 'tio-common/models/tas-course';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import UploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';
import XCircle from 'ember-static-heroicons/components/outline-24/x-circle';

type ProgramInstanceFieldName = keyof TASProgramTemplateModelFieldsSignature;
const string = String;

export type FormField = {
  name: ProgramInstanceFieldName;
  mask: string | null;
  type: string | null;
  rules: {
    type: string;
    typeError?: string;
  };
};

export interface NewTypeFourSignature {
  Args: {
    model: {
      application: TasApplicationModel;
      programInstance: TASProgramInstanceModel;
    };
  };
}

interface Scholarship extends ReceivedScholarship {
  id?: string;
}

export default class NewTypeFourComponent extends Component<NewTypeFourSignature> {
  programTemplate: TasProgramTemplateModel;

  constructor(owner: Owner, args: NewTypeFourSignature['Args']) {
    super(owner, args);
    const { programInstance } = args.model;
    this.programTemplate = programInstance.tasProgramTemplate;
    this.scholarshipToDelete = null;
    this.scholarshipToEdit = null;
    this.updateEligibility();
    if (this.showGPAField) {
      this.setGPA(
        getSingleValueForTasField('GRADE_POINT_AVERAGE', args.model.application.fields) || '0'
      );
    }
    this.updateCustomRequiredFields(args.model.application.customFields);
  }

  @tracked acceptedConditions: ConditionModel[] = [];
  @tracked course: TasCourseModel | null = null;
  @tracked eligibility = false;
  @tracked hasSubmitted = false;
  @tracked isDeleteApplicationOpen = false;
  @tracked isDeleteCourseOpen = false;
  @tracked isDeleteScholarshipOpen = false;
  @tracked isEditScholarshipOpen = false;
  @tracked isEducationOpen = false;
  @tracked isOpen = false;
  @tracked isScholarshipOpen = false;
  @tracked scholarshipEditError = '';
  @tracked scholarshipToDelete: Scholarship | null;
  @tracked scholarshipToEdit: Scholarship | null;
  @tracked submitError: string | null = null;
  @tracked gpaBasedAmount: number = 0;
  @tracked requiredCustomFieldsFilled = false;

  @tracked dynamicEligibility?: boolean;
  @tracked programEligibility: [string, string][] = [];

  @service declare intl: IntlService;
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  calculateEligibility() {
    if (!this.programEligibility.length) {
      return false;
    }
    // Use today's date for 'submission' lock status, otherwise use the provided start or end date.
    const enteredDate =
      this.lockStatus === 'submission' ? new Date() : new Date(this.startOrEndDate);

    return this.programEligibility.some(([start, end]) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return enteredDate >= startDate && enteredDate <= endDate;
    });
  }

  async updateEligibility(): Promise<void> {
    // Don't check eligibility if the application is in ignore policies mode
    if (this.application.ignorePolicies || this.lockStatus === 'fulfilled' || !this.lockStatus) {
      this.eligibility = true;
      return;
    }
    try {
      if (!this.dynamicEligibility || !this.programEligibility.length) {
        const { id } = this.instance.employee.tasParticipant;
        const { code } = this.programTemplate;

        const [fetchDynamicEligibility, fetchProgramEligibility] = await Promise.all([
          this.store.adapterFor('tas-participant').dynamicEligibility(id, code),
          this.store.adapterFor('tas-participant').periodOfEligibility(id, code),
        ]);

        this.dynamicEligibility = fetchDynamicEligibility;
        this.programEligibility = fetchProgramEligibility;
      }

      this.eligibility = this.dynamicEligibility! && this.calculateEligibility();
    } catch (error) {
      console.error('Error fetching eligibility:', error);
      this.eligibility = false;
    }
  }

  get application() {
    return this.args.model.application;
  }

  get instance() {
    return this.args.model.programInstance;
  }

  get applicationFields(): TasApplicationModel['fields'] {
    return this.application.fields;
  }

  get applicationName() {
    return getSingleValueForTasField('APPLICATION_NAME', this.applicationFields);
  }

  get educationSectionTitle() {
    return this.application.expenseType || 'Education';
  }

  get typeClassification() {
    return this.programTemplate.typeClassification || '';
  }

  get conditionsForSubmit() {
    return this.programTemplate.typeTwoApplicationConditions || [];
  }

  get hasAgreedAllConditions() {
    if (!this.conditionsForSubmit.length) {
      return true;
    }
    return this.conditionsForSubmit.length === this.acceptedConditions.length;
  }

  saveAgreementsForConditions = dropTask(async () => {
    const user = this.sessionContext.user;
    const agreements = this.acceptedConditions.map((condition) => {
      return this.store.createRecord('agreement', {
        user: user,
        condition: condition,
      });
    });
    const promises = agreements.map((agreement) => {
      return agreement.save();
    });
    const saved = await all(promises);
    return saved;
  });

  get requireAttachmentUploads() {
    if (this.typeClassification === 'TAS.ProgramType.4.2') {
      return (
        this.programTemplate.requireAttachmentsForCourseApproval ||
        this.programTemplate.requireAttachmentsForCourseCompletion
      );
    }
    return this.programTemplate.requireAttachmentsForCourseApproval;
  }

  get attachmentClassificationString() {
    return this.typeClassification === 'TAS.ProgramType.4.2'
      ? 'COMPLETION_DOCUMENTS'
      : 'COURSE_APPROVAL_DOCUMENTS';
  }

  get attachmentsListDescription() {
    const attachmentString =
      `${this.attachmentClassificationString}_ATTACHMENT_FIELD` as keyof TASProgramTemplateModelFieldsSignature;
    return (
      getAllValuesForTasField(attachmentString, this.programTemplate.fields).join(', ') ||
      'None Required'
    );
  }

  get applicationCustomFields() {
    return this.application.customFields;
  }

  get lockEmployeeStatusBasedOn() {
    return this.programTemplate.lockEmployeeStatusBasedOn;
  }

  get lockStatus() {
    const lockType: Record<string, string> = {
      COURSE_START_DATE: 'start',
      COURSE_END_DATE: 'end',
      FULFILLED_DATE: 'fulfilled',
      ON_COURSES_SUBMISSION: 'submission',
      ON_SUBMISSION: 'submission',
    };
    return this.lockEmployeeStatusBasedOn in lockType
      ? lockType[this.lockEmployeeStatusBasedOn]
      : '';
  }

  get expenseTypeField() {
    let expenseType = getSingleValueForTasField(
      'EXPENSE_TYPE',
      this.programTemplate.fields
    ) as string;
    return expenseType || 'Education';
  }

  get scholarships() {
    let savedScholarships = getAllValuesForTasField(
      'SCHOLARSHIPS_RECEIVED',
      this.application.fields
    );
    return savedScholarships.map((scholarship, index) => ({
      ...(scholarship as ReceivedScholarship),
      id: `scholarship-${index + 1}`,
    }));
  }

  get scholarshipName() {
    return this.programTemplate.fields['SCHOLARSHIP_NAME'];
  }

  get scholarshipAmount() {
    return this.programTemplate.fields['SCHOLARSHIP_AMOUNT'];
  }

  get startDateLabel() {
    return getFieldLabelForFieldName('COURSES_BEGIN_DATE', this.programTemplate.fields) as string;
  }

  get endDateLabel() {
    return getFieldLabelForFieldName('COURSES_END_DATE', this.programTemplate.fields) as string;
  }

  get scholarshipReceived() {
    return this.programTemplate.fields['SCHOLARSHIPS_RECEIVED'];
  }

  // get eligibleBasedOnWaitingPeriod() {
  //   return !this.tuitionAssistance.ineligibleBasedOnWaitingPeriod(
  //     this.eligibilityWaitingPeriod,
  //     this.instance.employee?.tasParticipant
  //   );
  // }

  // get eligibilityWaitingPeriod() {
  //   return (getSingleValueForTasField('ELIGIBILITY_WAITING_PERIOD', this.programTemplate.fields) ||
  //     0) as number;
  // }

  // get dateEligible() {
  //   const date = this.tuitionAssistance.waitingPeriodEndDate(
  //     this.eligibilityWaitingPeriod,
  //     this.instance.employee?.tasParticipant
  //   );
  //   return this.intl.formatDate(date, { month: 'short', day: 'numeric', year: 'numeric' });
  // }

  get isActiveParticipant() {
    return this.instance.employee?.tasParticipant?.isActive || false;
  }

  @action
  updateCustomRequiredFields(customFields: TasApplicationModel['customFields']) {
    this.requiredCustomFieldsFilled = customFields.every((field) => {
      if (field.required) {
        return field.values[0] !== null && field.values[0] !== undefined && field.values[0] !== '';
      }
      return true;
    });
  }

  @action
  didUpdateCustomFields(customFields: TasApplicationModel['customFields']) {
    this.updateCustomRequiredFields(customFields);
    this.args.model.application.customFields = customFields;
  }

  @action
  didUpdateAcceptedConditions(conditions: ConditionModel[] = []) {
    this.acceptedConditions = conditions;
  }

  setSemesterCode(date: string) {
    const semesterCode = setSemesterCodeString(date);
    setSingleValueForTasField('SEMESTER_CODE', semesterCode, this.applicationFields);
  }

  @action
  saveStartDate(value: string) {
    setSingleValueForTasField('COURSES_BEGIN_DATE', value, this.applicationFields);
    if (this.programTemplate.semesterCodeIsVisible) {
      this.setSemesterCode(value);
    }
    this.application.save();
    this.updateEligibility();
  }

  @action
  saveEndDate(value: string) {
    setSingleValueForTasField('COURSES_END_DATE', value, this.applicationFields);
    this.application.save();
    this.updateEligibility();
  }

  @action
  updateAppName(value: string) {
    setSingleValueForTasField('APPLICATION_NAME', value, this.applicationFields);
  }

  @action
  openEducationDrawer(course?: TasCourseModel | null) {
    if (course) {
      this.course = course;
    } else {
      this.course = this.store.createRecord('tas-course', {
        tasApplication: this.application,
        fields: this.programTemplate.courseFieldsHash,
        customFields: this.programTemplate.courseCustomFields,
      });
    }
    this.isEducationOpen = true;
  }

  @action
  closeEducationDrawer() {
    this.course = null;
    this.isEducationOpen = false;
  }

  @action
  toggleScholarshipDrawer() {
    this.isScholarshipOpen = !this.isScholarshipOpen;
  }

  get requiredFieldsSet() {
    const fieldsToCheck: TemplateFieldName[] = [
      'APPLICATION_NAME',
      'COURSES_BEGIN_DATE',
      'COURSES_END_DATE',
    ];

    return fieldsToCheck.every((field) => {
      const fieldConfig = getConfigForTasField(
        field as TemplateFieldName,
        this.programTemplate.fields
      );

      if (!fieldConfig.required) {
        return true; // Skips non-required
      }

      const value = getSingleValueForTasField(field, this.application.fields);

      return value !== null && value !== undefined && value !== '';
    });
  }

  get hasRequiredAttachments() {
    let hasRequiredAttachments: boolean = false;
    switch (this.typeClassification) {
      case 'TAS.ProgramType.4.2':
        hasRequiredAttachments = this.application.hasRequiredEvidenceAttachments;
        break;
      case 'TAS.ProgramType.4.3':
        hasRequiredAttachments = this.application.hasRequiredPreApprovalAttachments;
        break;
      default:
        console.error(`Unhandled typeClassification: ${this.typeClassification}`);
        hasRequiredAttachments = false;
    }
    return hasRequiredAttachments;
  }

  get canSubmitApplication() {
    const stateToApplyMap = {
      'TAS.ProgramType.4.2': 'TAS.ApplicationState.ATTEND',
      'TAS.ProgramType.4.3': 'TAS.ApplicationState.DEFINE_COURSES',
    };

    const requiredState = stateToApplyMap[this.typeClassification as keyof typeof stateToApplyMap];
    return (
      this.isActiveParticipant &&
      !!this.application.activeCourses.length &&
      this.hasAgreedAllConditions &&
      this.hasRequiredAttachments &&
      this.application.state === requiredState &&
      this.requiredFieldsSet &&
      this.hasGpaIfRequired &&
      this.requiredCustomFieldsFilled
    );
  }

  get startOrEndDate(): string {
    if (this.lockStatus === 'start') {
      return getSingleValueForTasField('COURSES_BEGIN_DATE', this.application.fields);
    }
    if (this.lockStatus === 'end') {
      return getSingleValueForTasField('COURSES_END_DATE', this.application.fields);
    }
    return new Date().toLocaleDateString('en-CA');
  }

  get startDate(): string {
    return getSingleValueForTasField('COURSES_BEGIN_DATE', this.application.fields);
  }

  get startDateConfig() {
    return getConfigForTasField('COURSES_BEGIN_DATE', this.programTemplate.fields);
  }

  get endDate(): string {
    return getSingleValueForTasField('COURSES_END_DATE', this.application.fields);
  }

  get endDateConfig() {
    return getConfigForTasField('COURSES_END_DATE', this.programTemplate.fields);
  }

  get isClaimsFinancialsApplication() {
    return this.programTemplate.calculateTotalByClaimsFinancials;
  }

  get calculateTotalByGPA() {
    return this.programTemplate.calculateTotalByGPA;
  }

  get setRequestedTotalAmount() {
    if (this.isClaimsFinancialsApplication) {
      return 0;
    }

    if (!this.calculateTotalByGPA) {
      return this.application.requestedTotal;
    }
    return Math.min(this.gpaBasedAmount, this.application.requestedTotal);
  }

  get hasGpaIfRequired(): boolean {
    // checks if the GPA field is required
    if (!this.showGPAField) {
      return true;
    }

    const hasField = Boolean(
      getSingleValueForTasField('GRADE_POINT_AVERAGE', this.applicationFields)
    );

    return hasField;
  }

  get showGPAField() {
    return this.calculateTotalByGPA && this.typeClassification === 'TAS.ProgramType.4.2';
  }

  get approvedTotalString() {
    if (this.typeClassification === 'TAS.ProgramType.4.2') {
      return 'FINAL_APPROVED_TOTAL';
    } else {
      return 'PRE_APPROVED_TOTAL';
    }
  }

  @action
  setGPA(value: string) {
    const gpa = Number(value);
    setSingleValueForTasField('GRADE_POINT_AVERAGE', gpa, this.applicationFields);
    this.gpaBasedAmount = this.tuitionAssistance.setTotalBasedOnGPA(gpa);
  }

  @action
  submitApplication() {
    if (!this.canSubmitApplication) {
      return;
    }
    this.saveAgreementsForConditions.perform();
    this.submitTask.perform();
  }

  get adapterMethod() {
    const actionMap = {
      'TAS.ProgramType.4.2': 'requestCourseEvidence',
      'TAS.ProgramType.4.3': 'requestCoursesApproval',
    };
    return actionMap[this.typeClassification as keyof typeof actionMap] || '';
  }

  submitTask = dropTask(async () => {
    this.hasSubmitted = true;
    this.submitError = null;
    try {
      const limitsValidationResponse = await this.validateLimits.perform();
      if (!limitsValidationResponse.can_submit) {
        this.displayLimitsValidationError(limitsValidationResponse);
        return;
      }

      setSingleValueForTasField(
        this.approvedTotalString,
        this.setRequestedTotalAmount,
        this.applicationFields
      );
      await this.application.save();
      await this.store.adapterFor('tas-application')[this.adapterMethod](this.application);

      this.router.transitionTo('authenticated.tas.confirmation', {
        queryParams: { tasType: 'four', applicationId: this.application.id },
      });
    } catch (error) {
      console.error('Failed to submit application:', error);
      this.submitError = error;
    }
  });

  validateLimits = dropTask(async () => {
    const results = await this.tuitionAssistance.validateAmountRequestedAgainstCurrentLimitsStatus(
      this.args.model.application,
      this.args.model.application.tasProgramInstance.employee
    );
    return results;
  });

  displayLimitsValidationError(validationResponse = {}) {
    // TODO: Eventually this message will be configurable via the program template.
    this.submitError = `You have exceeded your employer's maximum limit. Please reduce your requested amount by ${this.formatMoney(
      // @ts-expect-error: not sure about this one
      validationResponse.reduction_needed
    )}.`;
  }

  formatMoney(cents: number) {
    const dollars = cents / 100;
    return this.intl.formatNumber(dollars, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    });
  }

  @action
  async onSave() {
    try {
      await this.application.save();
    } catch (error) {
      console.error('Failed to save courses or application:', error);
    }
  }

  @action
  onTrash() {
    this.isDeleteApplicationOpen = true;
  }

  @action
  toggleDeleteApplication() {
    this.isDeleteApplicationOpen = !this.isDeleteApplicationOpen;
  }

  @action
  toggleDeleteCourse(course: TasCourseModel | null) {
    this.isDeleteCourseOpen = !this.isDeleteCourseOpen;
    if (course) {
      this.course = course;
    }
  }

  deleteApplication = dropTask(async () => {
    const adapter = this.store.adapterFor('tas-application');
    try {
      await adapter.abandonCourse(this.application);
      // TAS.TODO: temporary routing until tas dashboard is complete
      this.router.transitionTo('authenticated.tas.dashboard');
    } catch (e) {
      console.error(e);
    }
  });

  deleteCourse = dropTask(async () => {
    if (!this.course) {
      return;
    }
    try {
      if (this.course.isNew) {
        await this.course.rollbackAttributes();
      } else {
        await this.course.destroyRecord();
      }
      this.isDeleteCourseOpen = false;
      this.course = null;
    } catch (e) {
      console.error(e);
    }
  });

  // TAS.TODO: need custom field validation
  get customFieldValidationErrors() {
    return [];
  }

  @action
  openDeleteScholarship(scholarship: ReceivedScholarship) {
    this.scholarshipToDelete = scholarship;
    this.isDeleteScholarshipOpen = true;
  }

  @action
  closeDeleteScholarship() {
    this.scholarshipToDelete = null;
    this.isDeleteScholarshipOpen = false;
  }

  @action
  deleteScholarship() {
    let existingScholarships = getAllValuesForTasField(
      'SCHOLARSHIPS_RECEIVED',
      this.args.model.application.fields
    );
    let updatedScholarships = existingScholarships.filter(
      (scholarship: FieldValue) =>
        (scholarship as Record<string, unknown>)?.scholarshipId !==
        this.scholarshipToDelete?.scholarshipId
    ) as ReceivedScholarship[];
    this.applicationFields['SCHOLARSHIPS_RECEIVED'] = {
      values: updatedScholarships.filter((scholarship) => scholarship !== null),
    };
    this.args.model.application.fields = this.applicationFields;
    this.args.model.application.save();
    this.isDeleteScholarshipOpen = false;
  }

  @action
  openEditScholarship(scholarship: ReceivedScholarship) {
    this.scholarshipToEdit = scholarship;
    this.isEditScholarshipOpen = true;
  }

  @action
  closeEditScholarship() {
    this.scholarshipToEdit = null;
    this.isEditScholarshipOpen = false;
  }

  @action
  updateName(value: string) {
    this.scholarshipToEdit!.scholarshipName = value;
  }

  @action
  updateAmount(value: string) {
    this.scholarshipToEdit!.scholarshipAmount = currencyStringToNumber(value);
  }

  @action
  async checkForMissingFields() {
    if (
      (this.scholarshipToEdit?.scholarshipName === '' && this.scholarshipName.required) ||
      (this.scholarshipToEdit?.scholarshipAmount === 0 && this.scholarshipAmount.required)
    ) {
      this.scholarshipEditError = 'A required field is missing';
    } else {
      this.scholarshipEditError = '';
    }
  }
  @action
  async updateScholarship() {
    await this.checkForMissingFields();
    if (this.scholarshipEditError !== '') {
      return;
    }
    const updatedScholarships = this.scholarships.map((scholarship) =>
      scholarship.id === this.scholarshipToEdit?.id
        ? {
            scholarshipName: this.scholarshipToEdit.scholarshipName,
            scholarshipAmount: this.scholarshipToEdit.scholarshipAmount,
          }
        : scholarship
    );
    this.args.model.application.fields['SCHOLARSHIPS_RECEIVED'] = {
      values: updatedScholarships,
    };
    this.scholarshipToEdit = null;
    this.closeEditScholarship();
  }

  @action
  getValue(values: string[] | boolean[]): string {
    return values[0]?.toString() || '';
  }

  inputClasses = { base: 'w-full mb-6' };

  <template>
    <VStack>
      <Header class="flex justify-between items-center">
        <div>
          <LinkTo class="flex justify-left items-center gap-4" @route="authenticated.tas.dashboard">
            <ChevronLeft class="w-4 h-4" />
            {{t "dashboard"}}
          </LinkTo>
        </div>
        <div class="flex items-center gap-4">
          <Button
            data-test-save-application
            @intent="primary"
            @appearance="outlined"
            {{on "click" this.onSave}}
          >
            {{t "save"}}
          </Button>
          <Button
            @intent="danger"
            @appearance="outlined"
            data-test-delete-application
            {{on "click" this.onTrash}}
          >
            <Trash class="w-6 inline" />
          </Button>
        </div>
      </Header>

      <HStack>
        <VStack class="h-full md:!w-2/3">
          <Section>
            <:header>
              <div class="flex justify-between">
                <p>{{t "tas.application.type_two.new.application"}}</p>
                <p class="font-normal">
                  <span class="text-error-400 text-xl">*</span>
                  {{t "tas.application.type_two.new.required_items"}}
                </p>
              </div>
            </:header>
            <:body>
              {{#unless this.isActiveParticipant}}
                <TioAlert @type="warning" @allowDismiss={{false}} class="my-4">
                  <:header>
                    <p class="font-bold">
                      {{t "tuition_assistance.inactive_participant_warnings.dashboard_title"}}
                    </p>
                  </:header>
                  <:body>
                    <p class="text-sm">
                      {{t "tuition_assistance.inactive_participant_warnings.dashboard_description"}}
                    </p>
                  </:body>
                </TioAlert>
              {{/unless}}
              <VStack>
                <p>{{t "tas.application.type_two.new.expense_type_reminder"}}</p>
                <div class="flex justify-between items-center w-full">
                  {{#if this.startDateConfig.visible}}
                    <div class="w-5/12">
                      <label for="start" class="text-gray-600 font-semibold">
                        {{capitalizeWords this.startDateLabel}}
                        {{#if this.startDateConfig.required}}
                          <span class="text-error-400">*</span>
                        {{/if}}
                      </label>
                      <Input
                        @value={{this.startDate}}
                        @onInput={{this.saveStartDate}}
                        @type="date"
                        @classes={{this.inputClasses}}
                        id="start"
                      />
                    </div>
                  {{/if}}
                  {{#if this.endDateConfig.visible}}
                    <div class="w-5/12">
                      <label for="end" class="text-gray-600 font-semibold">
                        {{capitalizeWords this.endDateLabel}}
                        {{#if this.endDateConfig.required}}
                          <span class="text-error-400">*</span>
                        {{/if}}
                      </label>
                      <Input
                        @value={{this.endDate}}
                        @onInput={{this.saveEndDate}}
                        @type="date"
                        @classes={{this.inputClasses}}
                        id="end"
                      />
                    </div>
                  {{/if}}
                </div>
                {{#unless this.eligibility}}
                  <TioAlert @type="error" @allowDismiss={{false}} class="mb-4">
                    <:header>
                      <p class="font-bold" data-test-eligibility-error>
                        {{t
                          "start_date_eligibility.errorHeader"
                          startEndSubmission=this.lockStatus
                        }}
                      </p>
                    </:header>
                    <:body>
                      <p class="text-sm">
                        {{t "start_date_eligibility.errorText"}}
                      </p>
                    </:body>
                  </TioAlert>
                {{/unless}}
                <Divider class="my-2" />
                <div class="flex items-center w-full py-4">
                  <div class="w-1/2">
                    <h2 class="text-gray-600 font-semibold">{{t
                        "tas.application.type_two.new.name"
                      }}
                      <span class="text-error-400">*</span>
                    </h2>
                  </div>
                  <div class="w-1/2">
                    <label for="application-name" class="sr-only">
                      {{t "tas.application.type_two.new.name"}}!!
                    </label>
                    <Input
                      size="lg"
                      data-test-application-name
                      id="application-name"
                      @value={{getSingleValueForTasField
                        "APPLICATION_NAME"
                        @model.application.fields
                      }}
                      class="!bg-transparent"
                      @onChange={{this.updateAppName}}
                    />
                  </div>
                </div>
                {{#if this.showGPAField}}
                  <div class="flex flex-wrap justify-between gap-y-4 items-center">
                    <label
                      for="grade-point-average"
                      class="font-semibold block mb-1 text-balance text-gray-600"
                    >
                      {{t "tas.application.grade_point_average"}}
                      <span class="text-error-400">*</span>
                    </label>
                    <Input
                      size="lg"
                      type="number"
                      min="0"
                      max="4.0"
                      step="0.01"
                      data-test-grade-point-average
                      id="grade-point-average"
                      @value={{string
                        (or
                          (getSingleValueForTasField
                            "GRADE_POINT_AVERAGE" @model.application.fields
                          )
                          0
                        )
                      }}
                      class="!bg-transparent !w-1/2"
                      @onChange={{this.setGPA}}
                    />
                    <Divider class="mb-4" />
                  </div>
                {{/if}}

                <TuitionAssistanceFormsCustomFieldsForProvince
                  @province="APPLICATION"
                  @customFields={{this.applicationCustomFields}}
                  @didUpdateFields={{this.didUpdateCustomFields}}
                  @formValidationErrors={{this.customFieldValidationErrors}}
                  @customFieldModel="applicationCustomFields"
                />
              </VStack>
            </:body>
          </Section>

          <Section>
            <:header>
              <div class="flex justify-between items-center">
                <p>{{this.expenseTypeField}}</p>
              </div>
            </:header>
            <:actions>
              <Button
                @intent="primary"
                data-test-add-education-drawer
                {{on "click" (fn this.openEducationDrawer null)}}
              >
                <Plus class="h-6 w-6 mr-2" />
                {{or this.expenseTypeField (t "tas.application.type_two.new.education")}}
              </Button>
            </:actions>
            <:body>
              <VStack>
                <p>{{t "tas.application.type_two.new.expense_type_description"}}</p>
                <ActionableList @striped={{false}} as |l|>
                  {{#each @model.application.activeCourses as |course|}}
                    <l.Row>
                      <l.Term class="md:w-1/3">{{course.courseName}}</l.Term>
                      <l.Details>
                        <l.Item class="justify-self-center md:justify-self-start">
                          {{safeFormatNumber
                            (divide course.courseTotal 100)
                            style="currency"
                            currency="USD"
                          }}
                        </l.Item>
                        <l.Item
                          class="justify-self-center md:justify-self-start font-semibold"
                        >{{course.courseGrade}}</l.Item>
                      </l.Details>
                      <l.Action>
                        <button
                          type="button"
                          data-test-delete-course
                          {{on "click" (fn this.toggleDeleteCourse course)}}
                        ><XCircle class="h-6 w-6" /></button>
                        <button
                          type="button"
                          data-test-edit-education-drawer
                          {{on "click" (fn this.openEducationDrawer course)}}
                        ><PencilSquare class="h-6 w-6" /></button>
                      </l.Action>
                    </l.Row>
                  {{/each}}
                </ActionableList>
              </VStack>
            </:body>
          </Section>
          {{! Add Scholarships }}
          {{#if this.programTemplate.scholarshipsAreVisible}}
            <Section>
              <:header>
                <div class="flex justify-between items-center">
                  <p>
                    {{this.scholarshipReceived.label}}
                    <span class="text-normal">{{t "tas.new.scholarships.optional"}}</span>
                  </p>
                </div>
              </:header>
              <:actions>
                <Button
                  @intent="primary"
                  data-test-add-scholarship-drawer
                  {{on "click" this.toggleScholarshipDrawer}}
                >
                  <Plus class="h-6 w-6 mr-2" />
                  {{this.scholarshipReceived.label}}
                </Button>
              </:actions>
              <:body>
                <VStack>
                  <p>{{t
                      "tas.new.scholarships.please_enter_any_scholarships"
                      label=(lowercase this.scholarshipReceived.label)
                    }}</p>
                  <ActionableList @striped={{false}} as |l|>
                    {{#each this.scholarships as |scholarship|}}
                      <l.Row>
                        <l.Term>{{scholarship.scholarshipName}}</l.Term>
                        <l.Details>
                          <l.Item class="justify-self-center">
                            {{safeFormatNumber
                              (divide scholarship.scholarshipAmount 100)
                              style="currency"
                              currency="USD"
                            }}
                          </l.Item>
                        </l.Details>
                        <l.Action>
                          <button type="button" data-test-delete-scholarship>
                            <XCircle
                              class="h-6 w-6 text-gray-700"
                              {{on "click" (fn this.openDeleteScholarship scholarship)}}
                            />
                          </button>
                          <button type="button" data-test-edit-scholarship>
                            <PencilSquare
                              class="h-6 w-6 text-gray-700"
                              {{on "click" (fn this.openEditScholarship scholarship)}}
                            />
                          </button>
                        </l.Action>
                      </l.Row>
                    {{/each}}
                  </ActionableList>
                </VStack>
              </:body>
            </Section>
          {{/if}}
          {{! Upload Attachments}}
          <Section>
            <:header>{{t "attachments"}}</:header>
            <:body>
              <VStack>
                <p>
                  {{t "tas.application.type_two.new.attachment_description"}}
                  {{#if this.requireAttachmentUploads}}
                    <span class="text-error-400 text-xl">*</span>
                  {{/if}}
                </p>
                <UploadAttachments
                  @programTemplate={{this.programTemplate}}
                  @classification={{this.attachmentClassificationString}}
                  @attachable={{@model.application}}
                  @attachableType="tasApplication"
                  @useDropzone={{true}}
                  {{!-- @disabled={{this.disableAttachmentUpload}} --}}
                />
              </VStack>
            </:body>
          </Section>
        </VStack>

        <Section class="w-full md:!w-1/3">
          <:header>{{t "tas.benefits_summary.title"}}</:header>
          <:body>
            <LimitsSummary
              @application={{@model.application}}
              @employee={{@model.programInstance.employee}}
              @labelClass="col-span-5"
              @valueClass="col-span-2 text-violet-800"
              class="my-4"
              as |Summary|
            >
              <div class="w-full grid grid-cols-7 gap-2 mt-3">
                {{#unless Summary.isUnlimitedMaximumAnnualBenefit}}
                  <Summary.MaxAnnualBenefitLineItem />
                {{/unless}}
                {{#if Summary.hasLimitsErrorForEmployee}}
                  <Summary.RemainingBenefitLineItem />
                {{/if}}
                <Summary.ApplicationAmountRequestedWithoutScholarshipsLineItem
                  @valueClass="text-tio-gray-800 col-span-2"
                />
                {{#if this.programTemplate.scholarshipsAreVisible}}
                  <Summary.ScholarshipAmountLineItem
                    @valueClass="text-tio-gray-800 !font-normal col-span-2"
                  />
                {{/if}}
                <Summary.LimitsAlertForEmployeeIfApplicable class="text-sm col-span-full" />
                <ReimbursementCalculationIfApplicable @application={{@model.application}} />
                <Divider class="my-2 col-span-7" />
                {{#if this.programTemplate.calculateTotalByGPA}}
                  <Summary.ApplicationAmountBasedOnGPALineItem />
                {{else}}
                  <Summary.ApplicationAmountRequestedLineItem />
                {{/if}}
              </div>

              {{! Required Items Section -ASIDE }}
              <section class="my-5">
                {{#if (or this.requireAttachmentUploads this.conditionsForSubmit.length)}}
                  <header
                    class="border-y bg-tio-gray-25 flex items-center gap-2 py-2 px-5 text-sm font-medium tracking-wide -mx-4"
                  >
                    <ClipboardDocumentList class="h-8 w-8" />
                    <span>{{t "tas.benefits_summary.required_items"}}</span>
                  </header>
                  {{#if this.requireAttachmentUploads}}
                    <div>
                      <div class="flex flex-row justify-between items-center">
                        <p>{{t "tas.benefits_summary.attachments"}}
                          <span class="text-error-400 ml-1">
                            *
                          </span>
                        </p>
                        <div>
                          <UploadAttachments
                            @programTemplate={{this.programTemplate}}
                            @classification={{this.attachmentClassificationString}}
                            @attachable={{@model.application}}
                            @attachableType="tasApplication"
                            @useDropzone={{false}}
                            @iconOnlyUpload={{true}}
                            @hideAttachments={{true}}
                            @hideInstructions={{true}}
                            {{!-- @disabled={{this.disableAttachmentUpload}} --}}
                          />
                        </div>
                      </div>
                      <div class="flex items-center gap-3 -mt-1">
                        {{#if this.hasRequiredAttachments}}
                          <CheckCircleSolid class="h-6 w-6 text-violet-500 shrink-0" />
                        {{else}}
                          <CheckCircle class="h-6 w-6 text-tio-gray-200 shrink-0" />
                        {{/if}}
                        <p>{{this.attachmentsListDescription}}</p>
                      </div>
                    </div>
                  {{/if}}
                  {{#if this.conditionsForSubmit.length}}
                    <AcceptConditions
                      @conditions={{this.conditionsForSubmit}}
                      @onChange={{this.didUpdateAcceptedConditions}}
                    />
                  {{/if}}
                {{/if}}
              </section>

              <div class="py-4">
                <Button
                  @isRunning={{this.submitTask.isRunning}}
                  class="flex justify-center w-full py-3 px-6 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg hover:bg-ocean-400"
                  disabled={{not this.canSubmitApplication}}
                  data-test-submit-application
                  {{on "click" this.submitApplication}}
                >
                  {{t "tas.benefits_summary.submit_application"}}
                </Button>
                <p class="text-sm font-light text-center">
                  {{t "tas.benefits_summary.helper_text"}}
                </p>
                {{#if this.submitError}}
                  <TioErrorMessages
                    @showErrors={{true}}
                    @error={{this.submitError}}
                    class="text-sm"
                    @icon="warning"
                  />
                {{/if}}
              </div>
            </LimitsSummary>
          </:body>
        </Section>
      </HStack>
    </VStack>
    {{#if this.isEducationOpen}}
      <AddEducationDrawer
        @isOpen={{this.isEducationOpen}}
        @onClose={{this.closeEducationDrawer}}
        @application={{@model.application}}
        @course={{this.course}}
        @header={{this.expenseTypeField}}
      />
    {{/if}}
    <AddScholarshipsDrawer
      @isOpen={{this.isScholarshipOpen}}
      @onClose={{this.toggleScholarshipDrawer}}
      @application={{@model.application}}
    />

    {{! Delete Application Modal }}
    <Modal
      @isOpen={{this.isDeleteApplicationOpen}}
      @onClose={{this.toggleDeleteApplication}}
      data-test-delete-application-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.delete_application"}}</m.Header>
      <m.Body>
        <p>{{t "tas.application.type_two.new.confirm_application_delete"}}</p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.deleteApplication.isRunning}}
          @onCancel={{this.toggleDeleteApplication}}
          @onSubmit={{this.deleteApplication.perform}}
        />
      </m.Footer>
    </Modal>

    <Modal
      @isOpen={{this.isDeleteCourseOpen}}
      @onClose={{fn this.toggleDeleteCourse null}}
      data-test-delete-course-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.delete_course"}}</m.Header>
      <m.Body>
        <p>{{t "tas.application.type_two.new.confirm_delete_expense"}}:</p>
        <p class="font-semibold px-2">{{this.course.courseName}}</p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.deleteCourse.isRunning}}
          @onCancel={{fn this.toggleDeleteCourse null}}
          @onSubmit={{this.deleteCourse.perform}}
        />
      </m.Footer>
    </Modal>
    {{! Delete Scholarship Modal }}
    <Modal
      @isOpen={{this.isDeleteScholarshipOpen}}
      @onClose={{this.closeDeleteScholarship}}
      data-test-delete-scholarship-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.delete_scholarship"}}</m.Header>
      {{#let this.scholarshipToDelete as |scholarship|}}
        <m.Body>
          {{#if scholarship}}
            <p>{{t "tas.application.type_two.new.confirm_scholarship_delete"}}</p>
            <div class="p-8 flex w-2/3 justify-between">
              <p class="font-medium">{{scholarship.scholarshipName}}</p>
              <p class="font-medium">
                {{safeFormatNumber
                  (divide scholarship.scholarshipAmount 100)
                  style="currency"
                  currency="USD"
                }}
              </p>
            </div>
          {{/if}}
        </m.Body>
      {{/let}}
      <m.Footer>
        <Button @appearance="minimal" class="mr-4" {{on "click" this.closeDeleteScholarship}}>
          {{t "cancel"}}
        </Button>
        <Button @intent="danger" {{on "click" this.deleteScholarship}}>
          {{t "delete"}}
        </Button>
      </m.Footer>
    </Modal>
    {{! Edit Scholarship Modal }}
    <Modal
      @isOpen={{this.isEditScholarshipOpen}}
      @onClose={{this.closeEditScholarship}}
      data-test-edit-scholarship-modal
      as |m|
    >
      <m.Header>{{t "tas.application.type_two.new.edit_scholarship"}}</m.Header>
      <m.Body>
        {{#if this.scholarshipToEdit}}
          <div class="flex flex-col w-full">
            <label for="scholarship_name4" class="font-semibold text-gray-600">
              {{this.scholarshipName.label}}
              {{#if this.scholarshipName.required}}
                <span class="text-error-400">*</span>
              {{/if}}
            </label>
            <Input
              @value={{this.scholarshipToEdit.scholarshipName}}
              @classes={{this.inputClasses}}
              @onChange={{this.updateName}}
              @isRequired={{true}}
              data-test-edit-scholarship-name
            />
            <label for="scholarship_amount4" class="font-semibold text-gray-600">
              {{this.scholarshipAmount.label}}
              {{#if this.scholarshipAmount.required}}
                <span class="text-error-400">*</span>
              {{/if}}
            </label>
            <Input
              @value="{{safeFormatNumber
                (divide this.scholarshipToEdit.scholarshipAmount 100)
                style="currency"
                currency="USD"
              }}"
              @classes={{this.inputClasses}}
              @onChange={{this.updateAmount}}
              @isRequired={{true}}
              data-test-edit-scholarship-amount
              {{inputmask alias="currency" prefix="$" unmaskAsNumber=true digits="2"}}
            />
            <p class="text-danger">{{this.scholarshipEditError}}</p>
          </div>
        {{/if}}
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onCancel={{this.closeEditScholarship}}
          @onSubmit={{this.updateScholarship}}
        />
      </m.Footer>
    </Modal>
  </template>
}
