import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type RouterService from '@ember/routing/router';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type SessionContextService from 'tio-employee/services/session-context';
import type TasParticipantModel from 'tio-common/models/tas-participant';

export default class AuthenticatedTasProgramsShowRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare sessionContext: SessionContextService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  includes = [
    'tas-program-template.conditions.public-assets',
    'tas-program-template.company.company-setting',
    'tas-program-template.tas-field-options',
    'tas-applications',
    'tas-applications.tags',
    'tas-applications.tas-courses',
    'dependent',
    'employee.tas-participant',
    'employee.company',
    'employee.dependents',
    'tas-assets',
  ];

  async model({ tas_program_instance_id }: { tas_program_instance_id: string }) {
    const programInstance = await this.store.findRecord<TasProgramInstanceModel>(
      'tas-program-instance',
      tas_program_instance_id,
      {
        include: this.includes.join(','),
        reload: true,
      }
    );
    const tasParticipant = await this.store.query<TasParticipantModel>('tas-participant', {
      filter: {
        employee: this.sessionContext.currentEmployee.id,
      },
    });
    await this.tuitionAssistance.loadEligibilitiesForCurrentEmployee();

    return { programInstance, tasParticipant: tasParticipant[0] };
  }
}
