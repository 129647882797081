import RouteTemplate from 'ember-route-template';
import LoadingSpinner from 'tio-employee/components/tas/loading-spinner';

export default RouteTemplate(
  <template>
    <div class="flex h-screen w-screen items-center justify-center">
      <LoadingSpinner />
    </div>
  </template>
);
