import Component from '@glimmer/component';
import Drawer from 'tio-ui/components/drawer';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';
import DescriptionList from 'tio-ui/components/description-list';
import ProgramReadonlyField from 'tio-common/components/tuition-assistance/program-readonly-field';
import ProgramCustomReadonlyField from 'tio-common/components/tuition-assistance/program-custom-readonly-field';
import divide from 'ember-math-helpers/helpers/div';
import { on } from '@ember/modifier';
import { contains } from 'tio-ui/utilities';
import type TasCourse from 'tio-common/models/tas-course';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';

const number = Number;

interface TasShowCourseDrawerArgs {
  Args: {
    isOpen: boolean;
    onClose: () => void;
    course: TasCourse | null;
    programTemplate: TasProgramTemplateModel;
    header: string;
  };
  Element: HTMLElement;
}

export default class TasShowCourseDrawer extends Component<TasShowCourseDrawerArgs> {
  courseFields = this.args.course ? Object.keys(this.args.course.fields) : [];
  currencyFields = [
    'COURSE_FEES',
    'COURSE_LABS',
    'COURSE_BOOKS',
    'COURSE_TUITION',
    'COURSE_SOFTWARE',
    'APPROVED_COURSE_TOTAL',
  ];

  <template>
    <Drawer @isOpen={{@isOpen}} @onClose={{@onClose}} @allowCloseButton={{false}} @size="xl">
      <div class="p-6 bg-gray-50">
        <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button type="button" data-test-close-education-drawer {{on "click" @onClose}}>
            <XMark class="h-6 w-6" />
          </button>
        </div>
        <h2 class="text-gray-600 font-semibold">
          {{@header}}
        </h2>
      </div>
      <DescriptionList @striped={{true}} as |l|>
        {{#each this.courseFields as |field|}}
          <ProgramReadonlyField
            {{! @glint-ignore: Field type error unvoidable due to the nature of fields }}
            @name={{field}}
            @programTemplate={{@programTemplate}}
            {{! @glint-ignore: To reuse this for all courses course needs to be set to null on close }}
            @fields={{@course.fields}}
            as |field|
          >
            <l.Group>
              <l.Term>{{field.programLabel}}</l.Term>
              {{#if (contains this.currencyFields field.name)}}
                <l.Details>{{safeFormatNumber
                    (divide (number field.value) 100)
                    style="currency"
                    currency="USD"
                  }}</l.Details>
              {{else}}
                {{! @glint-ignore: To reuse this for all courses course needs to be set to null on close }}
                <l.Details>{{field.value}}</l.Details>
              {{/if}}
            </l.Group>
          </ProgramReadonlyField>
        {{/each}}
        {{#each @course.customFields as |field|}}
          <ProgramCustomReadonlyField @customField={{field}} as |field|>
            <l.Group>
              {{! @glint-expect-error: field.value is null or record }}
              <l.Term>{{field.programLabel}}</l.Term>
              {{! @glint-expect-error: field.value is null or record }}
              <l.Details>{{field.value}}</l.Details>
            </l.Group>
          </ProgramCustomReadonlyField>
        {{/each}}
      </DescriptionList>
    </Drawer>
  </template>
}
