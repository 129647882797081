import RouteTemplate from 'ember-route-template';
import QuestionsLoanForgiveness from 'tio-employee/components/questions/loan-forgiveness';

export default RouteTemplate(
  <template>
    <QuestionsLoanForgiveness
      @indexRoute="authenticated.pslf.questions.index"
      @incomeRoute="authenticated.pslf.questions.income-driven"
      @teacherRoute="authenticated.pslf.questions.teacher"
      @stateRoute="authenticated.pslf.questions.state-and-local"
    >
      {{outlet}}
    </QuestionsLoanForgiveness>
  </template>
);
