import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

const DashboardWidgetsPublicServiceLoanForgivenessComponent: TOC = <template>
  <LinkTo @route="authenticated.admin.pslf.forms">
    <Tile @headerText={{t "pslf.default_approval"}} @showAdminBadge={{true}}>
      <:description>
        {{t "pslf.dashboard.widgets.approval"}}
      </:description>
      <:image>
        {{svgJar
          "online-test"
          width="100%"
          height="100%"
          role="img"
          desc=(t "svg.decision_making")
          class="lg:max-h-56 xl:max-h-full"
        }}
      </:image>
    </Tile>
  </LinkTo>
</template>;

export default DashboardWidgetsPublicServiceLoanForgivenessComponent;
