import { fn } from '@ember/helper';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router';
import { service } from '@ember/service';
import FormInput from '@frontile/forms-legacy/components/form-input';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import ActionButtons from 'tio-common/components/tuition-assistance/action-buttons';
import CustomFieldsForProvince from 'tio-common/components/tuition-assistance/forms/custom-fields-for-province';
import ProgramFormField from 'tio-common/components/tuition-assistance/program-form-field';
import errorsForField from 'tio-common/helpers/errors-for-field';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type { TasFieldsOrCustomFields } from 'tio-common/types/tuition-assistance';
import type { FieldName, ValidationField } from 'tio-common/utils/tuition-assistance/fields';
import {
  buildCustomFieldsFormModelForValidation,
  buildValidationSchemaForCustomFields,
  buildValidationSchemaForProgramTemplateFields,
  copyFieldsAndUpdatePerProgramTemplate,
  getSingleValueForTasField,
  setSingleValueForTasField,
} from 'tio-common/utils/tuition-assistance/fields';
import { TrackedObject } from 'tracked-built-ins';
import { object } from 'yup';
import type { TemplateKeyForDidUpdateCallback } from '../certificate/certificate-details';
import type { CustomFieldSignature } from 'tio-common/types/tuition-assistance';
import type Owner from '@ember/owner';

export interface TuitionAssistanceFormsEmployeeInformationSignature {
  Args: {
    programInstance: TasProgramInstanceModel;
    cancel?: () => void;
    saveAndNext?: (
      fieldsCopy: TasProgramInstanceModel['fields'],
      customFieldsCopy: TasProgramInstanceModel['customFields'],
      isFormValid: boolean
    ) => void;
    saveForLater?: (
      fieldsCopy: TasProgramInstanceModel['fields'],
      customFieldsCopy: TasProgramInstanceModel['customFields']
    ) => void;
    showActionButtons: boolean;
    didUpdate?: (field: TasFieldsOrCustomFields, key: TemplateKeyForDidUpdateCallback) => void;
    saveTaskIsRunning?: boolean;
    sectionValid?: (arg0: boolean) => void;
  };
}

export default class TuitionAssistanceFormsEmployeeInformation extends Component<TuitionAssistanceFormsEmployeeInformationSignature> {
  @service declare router: RouterService;

  @tracked customFieldsCopy: TasProgramInstanceModel['customFields'] = [];

  fieldsCopy!: TasProgramInstanceModel['fields'];
  customFieldsProvince = 'EMPLOYEE';

  constructor(owner: Owner, args: TuitionAssistanceFormsEmployeeInformationSignature['Args']) {
    super(owner, args);
    this.fieldsCopy = new TrackedObject(this.args.programInstance.fields);
    const programTemplateCustomFields =
      this.args.programInstance.tasProgramTemplate?.instanceCustomFields || [];
    this.customFieldsCopy = copyFieldsAndUpdatePerProgramTemplate(
      this.args.programInstance.customFields,
      programTemplateCustomFields
    );
    if (this.args.sectionValid) {
      this.args.sectionValid?.(this.isFormValid);
    }
  }

  get formModelForValidation() {
    const customFieldsFormModelForProvince = buildCustomFieldsFormModelForValidation(
      this.customFieldsForProvince
    );
    return {
      EMPLOYEE_EMAIL: getSingleValueForTasField('EMPLOYEE_EMAIL', this.fieldsCopy),
      FT_PT_OTHER: getSingleValueForTasField('FT_PT_OTHER', this.fieldsCopy),
      EMPLOYEE_ID: getSingleValueForTasField('EMPLOYEE_ID', this.fieldsCopy),
      NAME: getSingleValueForTasField('NAME', this.fieldsCopy),
      ...customFieldsFormModelForProvince,
    };
  }

  @cached
  get dynamicValidationSchema() {
    const fields: ValidationField[] = [
      {
        name: 'EMPLOYEE_EMAIL',
        rules: { type: 'string', format: 'email' },
        errors: { format: 'Please provide a valid email address' },
      },
      {
        name: 'FT_PT_OTHER',
        rules: { type: 'string' },
      },
      {
        name: 'EMPLOYEE_ID',
        rules: { type: 'string' },
      },
      {
        name: 'NAME',
        rules: { type: 'string' },
      },
    ];
    return buildValidationSchemaForProgramTemplateFields(
      fields,
      this.args.programInstance?.tasProgramTemplate
    );
  }

  @cached
  get dynamicValidationSchemaForCustomFields() {
    return buildValidationSchemaForCustomFields(this.customFieldsForProvince);
  }

  get customFieldsForProvince() {
    return this.customFieldsCopy.filter((field) => field.province === this.customFieldsProvince);
  }

  get isFormValid() {
    return !this.formValidationErrors.length;
  }

  get formValidationErrors() {
    const schema = (this.dynamicValidationSchema || object()).concat(
      this.dynamicValidationSchemaForCustomFields || object()
    );
    try {
      schema?.validateSync?.(this.formModelForValidation, {
        abortEarly: false,
      });
    } catch (err) {
      return err.inner || [];
    }
    return [];
  }

  @action
  updateValueForField(fieldName: FieldName, value: string) {
    // @ts-expect-error: not sure
    setSingleValueForTasField(fieldName, value, this.fieldsCopy);
    this.didUpdateFields(this.fieldsCopy, 'tasProgramInstanceFields');
  }

  @action
  didUpdateCustomFields(updated: CustomFieldSignature[] = []) {
    this.customFieldsCopy = updated;
    this.didUpdateFields(this.customFieldsCopy, 'tasProgramInstanceCustomFields');
  }

  @action
  didUpdateFields(
    fields: TasProgramInstanceModel['fields'] | TasProgramInstanceModel['customFields'],
    key: TemplateKeyForDidUpdateCallback
  ) {
    this.args.didUpdate?.(fields, key);
    this.args.sectionValid?.(this.isFormValid);
  }

  <template>
    <form class="grid md:grid-cols-3 gap-6">
      <div class="md:col-start-2 md:col-span-1">
        <ProgramFormField
          @name="APPLICATION_ID"
          @programTemplate={{@programInstance.tasProgramTemplate}}
          class="my-4"
          as |field|
        >
          <FormInput
            data-legacy-input
            id={{field.inputId}}
            @value={{@programInstance.id}}
            disabled={{true}}
          />
        </ProgramFormField>
        <ProgramFormField
          @name="NAME"
          @programTemplate={{@programInstance.tasProgramTemplate}}
          class="my-4"
          as |field|
        >
          <FormInput
            data-legacy-input
            id={{field.inputId}}
            @value={{getSingleValueForTasField field.name this.fieldsCopy}}
            @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
            disabled={{true}}
          />
        </ProgramFormField>
        <ProgramFormField
          @name="EMPLOYEE_ID"
          @programTemplate={{@programInstance.tasProgramTemplate}}
          class="my-4"
          as |field|
        >
          <FormInput
            data-legacy-input
            id={{field.inputId}}
            @value={{getSingleValueForTasField field.name this.fieldsCopy}}
            @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
            disabled={{true}}
          />
        </ProgramFormField>
        <ProgramFormField
          @name="FT_PT_OTHER"
          @programTemplate={{@programInstance.tasProgramTemplate}}
          class="my-4"
          as |field|
        >
          <FormInput
            data-legacy-input
            id={{field.inputId}}
            @value={{getSingleValueForTasField field.name this.fieldsCopy}}
            @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
            disabled={{true}}
          />
        </ProgramFormField>
        <ProgramFormField
          @name="EMPLOYEE_EMAIL"
          @programTemplate={{@programInstance.tasProgramTemplate}}
          class="my-4"
          as |field|
        >
          <FormInput
            data-legacy-input
            id={{field.inputId}}
            @value={{getSingleValueForTasField field.name this.fieldsCopy}}
            @onInput={{fn this.updateValueForField field.name}}
            @errors={{errorsForField field.name schemaErrors=this.formValidationErrors}}
          />
          <p class="text-xs text-tio-gray-600 mt-3">
            {{t "tuition_assistance.preapproval_app.email_hint"}}
          </p>
        </ProgramFormField>
        <CustomFieldsForProvince
          @province={{this.customFieldsProvince}}
          @customFields={{this.customFieldsCopy}}
          @didUpdateFields={{this.didUpdateCustomFields}}
          @formValidationErrors={{this.formValidationErrors}}
          @fieldContainerClass="my-4"
        />
      </div>
    </form>

    {{#if @showActionButtons}}
      <ActionButtons
        @cancel={{@cancel}}
        {{! @glint-expect-error type mismatch}}
        @saveAndNext={{@saveAndNext}}
        {{! @glint-expect-error type mismatch}}
        @saveForLater={{@saveForLater}}
        @fieldsCopy={{this.fieldsCopy}}
        @customFieldsCopy={{this.customFieldsCopy}}
        @isFormValid={{this.isFormValid}}
        @saveTaskIsRunning={{@saveTaskIsRunning}}
      />
    {{/if}}
  </template>
}
