import { service } from '@ember/service';
import { t } from 'ember-intl';
import AuthRegisterSso from 'tio-employee/components/auth/register/sso';
import Component from '@glimmer/component';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import EmployeeRegisterForm from 'tio-employee/components/auth/register/employee-register-form';
import LoginHere from 'tio-employee/components/auth/login-here';
import NotLaunched from 'tio-employee/components/auth/register/not-launched';
import PayrollIdForm from 'tio-employee/components/auth/register/payroll-id-form';
import RouteTemplate from 'ember-route-template';
import type { IntlService } from 'ember-intl';
import type PreRegisterService from '../../services/pre-register.ts';
import type RegisterController from 'tio-employee/controllers/register';

interface RegisterComponentSignature {
  Args: {
    controller: RegisterController;
  };
}

class RegisterComponent extends Component<RegisterComponentSignature> {
  @service declare preRegister: PreRegisterService;
  @service declare intl: IntlService;

  get settings() {
    return this.preRegister.settings;
  }

  get company() {
    return this.preRegister.sparseCompany;
  }

  get companyName() {
    return this.company?.name ?? '';
  }

  get daysUntilLaunch() {
    const days = this.preRegister.daysUntilLaunch || 0;
    return `${days} day${days === 1 ? '' : 's'}`;
  }

  get emailFromQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    return decodeURIComponent(urlParams.get('email') || '');
  }

  get isSso() {
    return !!(
      this.settings?.ssoAuthUrl &&
      this.settings?.idProvider &&
      this.settings?.idProvider !== 'NONE'
    );
  }

  get isPayrollIdAndLastNameAllowed() {
    return !!this.settings?.register?.isPayrollIdAndLastNameAllowed;
  }

  get isEmailRegisterAllowed() {
    return !!this.settings?.register?.isEmailRegisterAllowed;
  }

  get showNotLaunched() {
    return !this.preRegister.isLaunched && !!this.preRegister.launchDate;
  }

  <template>
    <div class="flex flex-col h-screen container mx-auto w-full overflow-auto">
      <EmailLookupWrapper @route="register">
        {{#if this.showNotLaunched}}
          <NotLaunched
            @companyName={{this.companyName}}
            @date={{this.preRegister.launchDate}}
            @days={{this.daysUntilLaunch}}
          />
        {{else if this.isSso}}
          <AuthRegisterSso @settings={{this.settings}} />
        {{else if this.isEmailRegisterAllowed}}
          <EmployeeRegisterForm @settings={{this.settings}} @email={{this.emailFromQueryParams}} />
        {{else if this.isPayrollIdAndLastNameAllowed}}
          <PayrollIdForm @settings={{this.settings}} @email={{this.emailFromQueryParams}} />
        {{else}}
          <div class="p-5 text-center">
            <h1 class="text-lg mt-2 mb-6 mx-auto">
              {{t "error_occurred"}}
            </h1>
            <LoginHere />
          </div>
        {{/if}}
      </EmailLookupWrapper>
    </div>
  </template>
}

export default RouteTemplate(RegisterComponent);
