import { Header, VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import TuitionAssistanceProgramTemplateReviewApplicationConfigReview from 'tio-common/components/tuition-assistance/program-template-review/application-config-review';
import TuitionAssistanceProgramTemplateReviewApproverInstructionsAndFaqs from 'tio-common/components/tuition-assistance/program-template-review/approver-instructions-and-faqs';
import TuitionAssistanceProgramTemplateReviewCourseApplicationSetup from 'tio-common/components/tuition-assistance/program-template-review/course-application-setup';
import TuitionAssistanceProgramTemplateReviewNotifications from 'tio-common/components/tuition-assistance/program-template-review/notifications';
import TuitionAssistanceProgramTemplateReviewProgramSetupReview from 'tio-common/components/tuition-assistance/program-template-review/program-setup-review';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';

interface S {
  Args: {
    model: TasProgramTemplate;
  };
}

export default RouteTemplate<S>(
  <template>
    <VStack>
      <Header>{{t "common.program_review"}}</Header>
      <TuitionAssistanceProgramTemplateReviewProgramSetupReview @model={{@model}} />
      <TuitionAssistanceProgramTemplateReviewApplicationConfigReview @model={{@model}} />
      <TuitionAssistanceProgramTemplateReviewCourseApplicationSetup @model={{@model}} />
      <TuitionAssistanceProgramTemplateReviewApproverInstructionsAndFaqs @model={{@model}} />
      <TuitionAssistanceProgramTemplateReviewNotifications @model={{@model}} />
    </VStack>
  </template>
);
