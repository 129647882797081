import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { Section } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import type { Step } from 'tio-common/components/tio/progress-tracker';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TASProgramTemplateModel from 'tio-common/models/tas-program-template';
import VerticalProgressTracker from 'tio-common/components/tio/vertical-progress-tracker';

interface TasApplicationProcessSignature {
  Args: {
    application: TasApplicationModel;
    programTemplate: TASProgramTemplateModel;
  };
}

class TasApplicationProcess extends Component<TasApplicationProcessSignature> {
  @service declare progressBuilder: ProgressBuilder;

  get applicationType() {
    return getSingleValueForTasField('TYPE', this.args.programTemplate.fields);
  }

  get steps() {
    return this.progressBuilder.applicationStepList(this.args.application) as Step[];
  }

  <template>
    <Section>
      <:header>
        {{t "common.progress_tracker.application_process"}}
      </:header>
      <:body>
        <VerticalProgressTracker class="ml-4" @steps={{this.steps}} />
      </:body>
    </Section>
  </template>
}

export default TasApplicationProcess;
