import { eq } from 'tio-ui/utilities';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import TuitionAssistanceFormsMentoring from 'tio-employee/components/tuition-assistance/forms/mentoring';
import TuitionAssistanceMentoringContactFooter from 'tio-employee/components/tuition-assistance/mentoring/contact-footer';
import type EmployeeModel from 'tio-common/models/employee';
import { Header, Section, VStack } from 'tio-ui/components/layout';

export interface S {
  Args: {
    model: {
      employee: EmployeeModel;
      hasSupport: boolean;
    };
  };
}
export default RouteTemplate<S>(
  <template>
    <VStack>
      <Header>{{t "sidebar.tuition_assistance"}}</Header>
      <Section>
        <:header>{{t "tuition_assistance.mentoring.default"}}</:header>
        <:body>
          <VStack @collapsed={{true}}>
            <Section>
              <:header>{{t "tuition_assistance.mentoring.how_can_mentoring_help"}}</:header>
              <:body>
                {{t "tuition_assistance.mentoring.mentoring_blurb"}}
              </:body>
            </Section>
            <Section>
              <:header>{{t "tuition_assistance.mentoring.speak_with_mentor"}}</:header>
              <:body>
                {{t "tuition_assistance.mentoring.answer_coach_questions"}}
                <TuitionAssistanceFormsMentoring @employee={{@model.employee}} />
              </:body>
            </Section>

            {{#if (eq @model.hasSupport true)}}
              <TuitionAssistanceMentoringContactFooter />
            {{/if}}
          </VStack>
        </:body>
      </Section>
    </VStack>
  </template>
);
