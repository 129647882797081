import { action } from '@ember/object';
import { service } from '@ember/service';
import type AccountModel from 'tio-common/models/account';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type RouterService from 'tio-employee/services/router';
import SyfAccountLinked from 'tio-employee/components/syf/account-linked';

interface SyfLinkingConfirmationRouteSignature {
  Args: {
    model: AccountModel;
  };
}

class SyfLinkingConfirmationRouteComponent extends Component<SyfLinkingConfirmationRouteSignature> {
  @service declare router: RouterService;

  @action
  didConfirm() {
    this.router.transitionTo('authenticated.syf.account-confirmation');
  }

  <template><SyfAccountLinked @account={{@model}} @onConfirmation={{this.didConfirm}} /></template>
}

export default RouteTemplate(SyfLinkingConfirmationRouteComponent);
