import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type { IntlService } from 'ember-intl';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type SessionService from 'tio-employee/services/session';
import type Store from '@ember-data/store';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';

export interface NonPartnerSignEmbedSignature {
  Args: {
    borrowerCompletedAt: string;
    borrowerName: string;
    approvalId: string;
    documentId: string;
    url: string;
  };
}

export default class NonPartnerSignEmbedComponent extends Component<NonPartnerSignEmbedSignature> {
  @service declare intl: IntlService;
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;
  @service declare store: typeof Store;

  get alreadySigned() {
    const documentId = this.args.documentId;
    const status = this.store.adapterFor('pslf-form').pollForEmployerStatus(documentId);
    return status === 'employerSigned';
  }

  get employerMessage() {
    return this.intl.t('pslf.complete_pslf.done_employer_subheader', {
      date: this.args.borrowerCompletedAt,
      name: this.args.borrowerName,
    });
  }

  <template>
    <hr class="my-4" />
    {{#if this.alreadySigned}}
      <div>{{this.employerMessage}}</div>
      <Button
        @intent="primary"
        @appearance="outlined"
        class="my-4 w-[312px]"
        {{on "click" (transitionTo "welcome")}}
      >
        {{t "continue"}}
      </Button>
    {{else}}
      <h3 class="mb-8">
        {{t "pslf.complete_pslf.note"}}
      </h3>
      <iframe class="w-full h-[1000px]" title={{t "pslf.complete_pslf.title"}} src={{@url}} />
    {{/if}}
  </template>
}
