import Component from '@glimmer/component';
import add from 'ember-math-helpers/helpers/add';

interface TAProgramDetailsDependentApplicationStepper {
  Args: {
    stage: string;
    showForm?: boolean;
    applicationSubmitted?: boolean;
  };
}

class TuitionAssistanceProgramDetailsDependentApplicationStepper extends Component<TAProgramDetailsDependentApplicationStepper> {
  activeBubble = (title: string) => {
    if (title === 'Applicant' && this.args.showForm === false) {
      return 'size-12 flex justify-center items-center flex-shrink-0 bg-violet-100 border-2 border-midnight font-medium text-gray-800 rounded-full';
    }
    if (
      title === 'Applicant' &&
      (this.args.showForm === true || this.args.stage === 'Program' || this.args.stage === 'Review')
    ) {
      return 'size-12 flex justify-center items-center flex-shrink-0 bg-midnight border-2 border-midnight font-medium text-white rounded-full';
    }
    if (title === 'Information' && this.args.showForm === true) {
      return 'size-12 flex justify-center items-center flex-shrink-0 bg-violet-100 border-2 border-midnight font-medium text-gray-800 rounded-full';
    }
    if (
      title === 'Information' &&
      (this.args.stage === 'Program' || this.args.stage === 'Review')
    ) {
      return 'size-12 flex justify-center items-center flex-shrink-0 bg-midnight border-2 border-midnight font-medium text-white rounded-full';
    }
    if (title === 'Program' && this.args.stage === 'Program') {
      return 'size-12 flex justify-center items-center flex-shrink-0 bg-violet-100 border-2 border-midnight font-medium text-gray-800 rounded-full';
    }
    if (title === 'Program' && this.args.stage === 'Review') {
      return 'size-12 flex justify-center items-center flex-shrink-0 bg-midnight border-2 border-midnight font-medium text-white rounded-full';
    }
    if (title === 'Review' && this.args.applicationSubmitted === true) {
      return 'size-12 flex justify-center items-center flex-shrink-0 bg-midnight border-2 border-midnight font-medium text-white rounded-full';
    }
    if (title === 'Review' && this.args.stage === 'Review') {
      return 'size-12 flex justify-center items-center flex-shrink-0 bg-violet-100 border-2 border-midnight font-medium text-gray-800 rounded-full';
    }
    return 'size-12 flex justify-center items-center flex-shrink-0 bg-gray-100 border-2 border-gray-400 font-medium text-gray-800 rounded-full';
  };

  steps = [
    { title: 'Applicant' },
    { title: 'Information' },
    { title: 'Program' },
    { title: 'Review' },
  ];

  <template>
    <ul class="relative flex flex-row pl-4">
      {{#each this.steps as |step index|}}
        <li class="flex items-baseline shrink basis-0 flex-1 group">
          <div class="flex-col min-w-6 min-h-6 justify-center items-center align-middle">
            <span class={{this.activeBubble step.title}}>
              {{add index 1}}
            </span>
          </div>
          <div class="w-full h-px flex-1 bg-gray-400 group-last:hidden"></div>
        </li>
      {{/each}}
    </ul>
    <ul class="relative flex flex-row pl-4">
      {{#each this.steps as |step|}}
        <li class="flex items-baseline shrink basis-0 flex-1 group">
          <div class="flex-col min-w-7 min-h-7 justify-center items-center align-middle">
            <span
              class="size-12 flex justify-center items-center flex-shrink-0 bg-white font-medium text-gray-800 rounded-full"
            >
              {{step.title}}
            </span>
          </div>
        </li>
      {{/each}}
    </ul>
  </template>
}

export default TuitionAssistanceProgramDetailsDependentApplicationStepper;
