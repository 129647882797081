import JSONAPISerializer from '@ember-data/serializer/json-api';
import { pluralize } from 'ember-inflector';
import { isArray } from '@ember/array';

type Snapshot = {
  adapterOptions: {
    createHasMany?: unknown[];
    destroyHasMany?: unknown[];
    updateRelationship?: string;
    changelog?: string;
  };
  record: unknown;
};

export default class ApplicationSerializer extends JSONAPISerializer {
  serialize(snapshot: Snapshot, options: unknown) {
    const { adapterOptions } = snapshot;

    if (adapterOptions && Array.isArray(adapterOptions.createHasMany)) {
      // @ts-expect-error: Ed needs types
      const hasManyModel = adapterOptions.createHasMany[0]?.constructor.modelName;
      const type = pluralize(hasManyModel);
      const data = adapterOptions.createHasMany.map((record) => {
        // @ts-expect-error: Ed needs types
        return { type, id: record.id };
      });

      return { data };
    }

    if (adapterOptions && isArray(adapterOptions.destroyHasMany)) {
      // @ts-expect-error: Ed needs types
      const hasManyModel = adapterOptions.destroyHasMany[0].constructor.modelName;
      const type: string = pluralize(hasManyModel);
      const data = adapterOptions.destroyHasMany.map((record) => {
        // @ts-expect-error: Ed needs types
        return { type, id: record.id };
      });

      return { data };
    }

    if (adapterOptions?.updateRelationship) {
      const type = pluralize(
        // @ts-expect-error: Ed needs types
        snapshot.record.relationshipFor(adapterOptions?.updateRelationship).type
      );
      return {
        data: {
          type,
          // @ts-expect-error: Ed needs types
          id: snapshot.record[adapterOptions.updateRelationship].get('id'),
        },
      };
    }

    const serialized = super.serialize(snapshot, options);

    // If a changelog string is sent in the adapterOptions, send it as an attribute to the API
    if (adapterOptions?.changelog) {
      serialized.data.attributes._changelog = adapterOptions.changelog;
    }

    return serialized;
  }

  serializeHasMany(snapshot: unknown, json: unknown, relationship: { key: string }) {
    const key = relationship.key;
    if (key === 'versions') {
      return;
    } else {
      super.serializeHasMany(snapshot, json, relationship);
    }
  }
}
