import { action } from '@ember/object';
import { and, not } from 'tio-ui/utilities';
import { Button } from 'tio-ui/components/buttons';
import { Form, Input } from 'tio-ui/components/forms';
import { on } from '@ember/modifier';
import { Section } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { getFieldLabelForFieldName } from 'tio-common/utils/tuition-assistance/fields';
import { flatten, safeParse } from 'valibot';
import Component from '@glimmer/component';
import errorsForValibotField from 'tio-common/helpers/errors-for-valibot-field';
import TioAlert from 'tio-common/components/tio/alert';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import type { Touched } from 'tio-common/helpers/errors-for-valibot-field';
import type IntlService from 'ember-intl/services/intl';
import type StoreService from 'tio-common/services/store';
import type TasParticipantModel from 'tio-common/models/tas-participant';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';

interface StartDateEligibilityComponentArgs {
  Args: {
    canApplyToProgram: boolean;
    applyToProgram: (date: string) => void;
    buttonText: string;
    instanceForExistingApp?: TASProgramInstanceModel;
    isMultipleInstanceProgram: boolean;
    programTemplate: TasProgramTemplate;
    tasParticipant: TasParticipantModel;
  };
}

interface FormData {
  dateEntered: string;
}

export default class StartDateEligibilityComponent extends Component<StartDateEligibilityComponentArgs> {
  @service declare store: StoreService;
  @service declare intl: IntlService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  @tracked eligibilityError = false;
  @tracked loading = false;
  @tracked formData: FormData = { dateEntered: '' };
  @tracked touched: Touched = { dateEntered: false };
  @tracked dynamicEligibility?: boolean;
  @tracked programEligibility: [string, string][] = [];

  inputClasses = { base: 'mr-4 max-w-60', input: 'max-h-10' };

  calculateEligibility() {
    if (!this.programEligibility.length) {
      return false;
    }
    const enteredDate =
      this.lockStatus === 'submission' ? new Date() : new Date(this.formData.dateEntered);

    return this.programEligibility.some(([start, end]) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return enteredDate >= startDate && enteredDate <= endDate;
    });
  }

  get canApply() {
    return this.args.canApplyToProgram && !this.eligibilityError && !this.hasValidationErrors;
  }

  get lockEmployeeStatusBasedOn() {
    return this.args.programTemplate.lockEmployeeStatusBasedOn;
  }

  get lockStatus() {
    const lockType: Record<string, string> = {
      COURSE_START_DATE: 'start',
      COURSE_END_DATE: 'end',
      FULFILLED_DATE: 'fulfilled',
      ON_COURSES_SUBMISSION: 'submission',
      ON_SUBMISSION: 'submission',
    };
    return this.lockEmployeeStatusBasedOn in lockType
      ? lockType[this.lockEmployeeStatusBasedOn]
      : '';
  }

  get startEndSubmission() {
    const startDateLabel = getFieldLabelForFieldName(
      'COURSES_BEGIN_DATE',
      this.args.programTemplate.fields
    ) as string;

    const endDateLabel = getFieldLabelForFieldName(
      'COURSES_END_DATE',
      this.args.programTemplate.fields
    ) as string;

    const lockType: Record<string, string> = {
      COURSE_START_DATE: startDateLabel.toLowerCase(),
      COURSE_END_DATE: endDateLabel.toLowerCase(),
    };

    return lockType[this.lockEmployeeStatusBasedOn] || '';
  }

  get isTypeOneOrFour() {
    const type = this.args.programTemplate.typeClassification ?? '';
    return type === 'TAS.ProgramType.1' || type.startsWith('TAS.ProgramType.4');
  }

  get eligibleBasedOnWaitingPeriod() {
    return !this.tuitionAssistance.ineligibleBasedOnWaitingPeriod(
      this.args.programTemplate.eligibilityWaitingPeriod,
      this.args.tasParticipant,
      this.formData.dateEntered
    );
  }

  get checkEligibility() {
    return (
      !this.lockStatus ||
      this.lockStatus === 'fulfilled' ||
      (this.dynamicEligibility && this.calculateEligibility())
    );
  }

  @action
  async submitTask() {
    if (!this.canApply) {
      return;
    }
    this.loading = true;

    if (!this.dynamicEligibility || !this.programEligibility) {
      const { id } = this.args.tasParticipant;
      const { code } = this.args.programTemplate;

      const [fetchDynamicEligibility, fetchProgramEligibility] = await Promise.all([
        this.store.adapterFor('tas-participant').dynamicEligibility(id, code),
        this.store.adapterFor('tas-participant').periodOfEligibility(id, code),
      ]);

      this.dynamicEligibility = fetchDynamicEligibility;
      this.programEligibility = fetchProgramEligibility;
    }

    if (this.checkEligibility) {
      this.args.applyToProgram(this.formData.dateEntered);
      this.loading = false;
    } else {
      this.loading = false;
      this.eligibilityError = true;
    }
  }

  get beginDateValidationSchema() {
    return this.tuitionAssistance.dynamicBeginDateValidationSchema(this.args.programTemplate);
  }

  get formValidationErrors() {
    const schema = this.beginDateValidationSchema;
    const model = {
      dateEntered: new Date(this.formData.dateEntered),
    };

    const result = safeParse(schema, model, { abortEarly: false });
    if (!result.success) {
      const flattened = flatten(result.issues);
      return flattened.nested ?? {};
    }
    return {};
  }

  get hasValidationErrors(): boolean {
    const errors = this.formValidationErrors;
    return Object.keys(errors || {}).length > 0;
  }

  @action
  onDateChange(
    _data: Record<string, unknown>,
    _eventType?: 'input' | 'submit',
    event?: Event | SubmitEvent
  ) {
    this.eligibilityError = false;
    // pulling the name and value from the event target so that we have better control over the shape of the data
    const target = event?.target as HTMLInputElement;
    const { name, value } = target;

    this.formData = { ...this.formData, [name]: value };
    this.touched = { ...this.touched, [name]: true };

    if (this.dynamicEligibility && this.programEligibility) {
      this.eligibilityError = !this.calculateEligibility();
    }
  }

  <template>
    <Section>
      <:header>
        <div class="flex items center" data-test-eligibility-card>
          <div class="flex-grow font-semibold">
            {{t "eligibility_check"}}
          </div>
        </div>
      </:header>
      <:body>
        <div>
          <p>
            {{t "start_date_eligibility.instruction" startEndSubmission=this.startEndSubmission}}
          </p>
        </div>
        <Form class="flex items-start my-8" @onChange={{this.onDateChange}}>
          <Input
            id="eligibility"
            @type="date"
            @classes={{this.inputClasses}}
            @value={{this.formData.dateEntered}}
            name="dateEntered"
            @errors={{errorsForValibotField
              "dateEntered"
              schemaErrors=this.formValidationErrors
              touched=this.touched
            }}
          />
          <Button
            @intent="primary"
            {{on "click" this.submitTask}}
            disabled={{not this.canApply}}
            @isRunning={{this.loading}}
            class="uppercase w-60 grow-0"
            data-test-submit-eligibility
          >
            {{@buttonText}}
          </Button>
        </Form>
        {{#if (and @instanceForExistingApp this.isTypeOneOrFour)}}
          <div class="flex text-sm py-2">
            <span>
              {{t "tuition_assistance.you_already_have_an_active_program"}}
            </span>
            <span class="text-center mx-1">
              <TioClickableText
                @linkTo={{if
                  @isMultipleInstanceProgram
                  "authenticated.tas.dashboard"
                  "authenticated.tas.programs.show"
                }}
                @linkToModel={{unless @isMultipleInstanceProgram @instanceForExistingApp}}
                @textClass="text-sm font-medium text-ocean-600 hover:text-ocean-800 underline"
              >
                {{t "click_here"}}
              </TioClickableText>
            </span>
            <span>
              {{t "tuition_assistance.to_view_program_details"}}
            </span>
          </div>
        {{/if}}
        {{#if this.eligibilityError}}
          <TioAlert @type="error" @allowDismiss={{false}} class="mb-4">
            <:header>
              <p class="font-bold" data-test-eligibility-error>
                {{t
                  "start_date_eligibility.errorHeader"
                  startEndSubmission=this.startEndSubmission
                }}
              </p>
            </:header>
            <:body>
              <p class="text-sm">
                {{t "start_date_eligibility.errorText"}}
              </p>
            </:body>
          </TioAlert>
        {{/if}}
        {{#if (and this.formData.dateEntered (not this.eligibleBasedOnWaitingPeriod))}}
          <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
            <:header>
              <p class="font-bold">
                {{t
                  "tuition_assistance.application_start_date_eligibility_warnings.dashboard_title"
                }}
              </p>
            </:header>
            <:body>
              <p class="text-sm">
                {{t
                  "tuition_assistance.application_start_date_eligibility_warnings.dashboard_description"
                  days=@programTemplate.eligibilityWaitingPeriod
                  htmlSafe=true
                }}
              </p>
            </:body>
          </TioAlert>
        {{/if}}
      </:body>
    </Section>
  </template>
}
