import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import divide from 'ember-math-helpers/helpers/div';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import type TasApplicationModel from 'tio-common/models/tas-application';
import { Button } from 'tio-ui/components/buttons';

interface S {
  Args: {
    application: TasApplicationModel;
    courseApprovalRequired: boolean;
  };
}

const FormsCoursesCourseSubmittedComponent: TOC<S> = <template>
  <div class="flex justify-center my-4">
    {{svgJar "mail-sent" width="212px" role="img" desc=(t "svg.mail_sent")}}
  </div>
  <section class="my-10">
    <h1 class="text-4xl mb-6 text-center">
      {{t "tuition_assistance.submitted.application_submitted"}}
    </h1>
    <h3 class="font-semibold mb-4 text-center">
      {{t "tuition_assistance.submitted.what_happens_now"}}
    </h3>

    <div class="grid md:grid-cols-6 mx-8">
      <ul class="md:col-start-3 md:col-span-4 list-disc text-sm md:text-base">
        <li class="my-1.5">
          {{! NAME }}
          {{t "tuition_assistance.submitted.your_application_name"}}
          <span class="font-semibold">{{@application.displayName}}</span>
        </li>
        <li class="my-1.5">
          {{t "tuition_assistance.submitted.courses_in_your_app"}}
        </li>
        {{! COURSES }}
        {{#if @application.activeCourses.length}}
          <ul class="list-inside font-semibold list-disc">
            {{#each @application.activeCourses as |course|}}
              <li>
                {{course.displayName}}
              </li>
            {{/each}}
          </ul>
        {{/if}}

        {{#if @application.scholarships.length}}
          <li class="my-1.5">
            {{t "tuition_assistance.submitted.scholarships_in_your_app"}}
          </li>
          <ul class="list-inside font-semibold list-disc">
            {{#each @application.scholarships as |award|}}
              <li>
                {{award.scholarshipName}}
                -
                {{safeFormatNumber
                  (divide award.scholarshipAmount 100)
                  style="currency"
                  currency="USD"
                }}</li>
            {{/each}}
          </ul>
        {{/if}}
        {{! SCHOLARSHIPS }}

        {{#if @courseApprovalRequired}}
          <li class="my-1.5">
            {{t "tuition_assistance.submitted.you_can_review_your_application"}}
          </li>
          <li class="my-1.5">
            {{t "tuition_assistance.submitted.your_application_may_have_multiple_reviewers"}}
          </li>
          <li class="my-1.5">
            {{t "tuition_assistance.submitted.your_application_will_be_reviewed"}}
          </li>
          <li class="my-1.5">
            {{t "tuition_assistance.submitted.once_your_application_is_accepted"}}
          </li>
        {{else}}
          <li class="my-1.5">
            {{t "tuition_assistance.submitted.you_can_now_begin_your_courses"}}
          </li>
        {{/if}}
      </ul>
    </div>
    <div class="flex justify-center">
      <Button
        @intent="primary"
        {{on
          "click"
          (transitionTo
            "authenticated.tuition-assistance.programs.instance.course-applications.show.index"
            @application.id
          )
        }}
        class="m-8 w-48"
      >
        {{t "done"}}
      </Button>
    </div>
  </section>
</template>;

export default FormsCoursesCourseSubmittedComponent;
