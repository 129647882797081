import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';

export default RouteTemplate(
  <template>
    {{pageTitle (t "recommendations_estimator.forgiveness_estimator")}}
    {{outlet}}
  </template>
);
