import { t } from 'ember-intl';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import TuitionAssistanceCardsGeneric from 'tio-common/components/tuition-assistance/cards/generic';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import AddDependent from '../forms/add-dependents';
import type IntlService from 'ember-intl/services/intl';
import type EmployeeModel from 'tio-common/models/employee';

export interface S {
  Args: {
    employee: EmployeeModel;
  };
}

export default class TuitionAssistanceDashboardManageDependents extends Component<S> {
  @service declare intl: IntlService;
  @tracked show = false;

  <template>
    <div>
      <TuitionAssistanceCardsGeneric class="items-center">
        <:headerTitle>
          <p data-test-manage-dependents-header>{{t
              "common.tuition_assistance.dashboard.manage_dependents_header"
            }}</p>
        </:headerTitle>
        <:body>
          <div class="flex flex-col align-center">
            <div class="flex">
              <p data-test-manage-dependents-text>{{t
                  "common.tuition_assistance.dashboard.manage_dependents_text"
                }}.</p>
            </div>
            <div class="flex py-4 justify-center">
              {{svgJar "family" role="img" desc=(t "svg.family") width="150px" height="150px"}}
            </div>
          </div>
        </:body>
        <:footer>
          <div class="flex justify-center">
            <AddDependent @employee={{@employee}} />
          </div>
        </:footer>
      </TuitionAssistanceCardsGeneric>
    </div>
  </template>
}
