import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { VStack, Header } from 'tio-ui/components/layout';

export default RouteTemplate(
  <template>
    <VStack>
      <Header>{{t "pslf.default_full"}}</Header>
      {{outlet}}
    </VStack>
  </template>
);
