import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { Header, VStack } from 'tio-ui/components/layout';

export default RouteTemplate(
  <template>
    <VStack>
      <Header>{{t "idr.default"}}</Header>
      {{outlet}}
    </VStack>
  </template>
);
