import type { TasApplicationState } from 'tio-common/types/tuition-assistance';

const tasApplicationStateBadgeMap = {
  'TAS.ApplicationState.CREATED': { intent: 'success', label: 'Draft' },
  'TAS.ApplicationState.DEFINE_COURSES': { intent: 'success', label: 'Draft' },
  'TAS.ApplicationState.PENDING_COURSES_APPROVAL': { intent: 'primary', label: 'Pending' },
  'TAS.ApplicationState.COURSES_APPROVED': { intent: 'success', label: 'In Progress' },
  'TAS.ApplicationState.COURSES_NOT_APPROVED': { intent: 'error', label: 'Rejected' },
  'TAS.ApplicationState.COURSES_REJECTED': { intent: 'error', label: 'Rejected' },
  'TAS.ApplicationState.ATTEND': { intent: 'success', label: 'In Progress' },
  'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL': { intent: 'primary', label: 'Pending' },
  'TAS.ApplicationState.EVIDENCE_APPROVED': { intent: 'success', label: 'In Progress' },
  'TAS.ApplicationState.EVIDENCE_NOT_APPROVED': { intent: 'error', label: 'Rejected' },
  'TAS.ApplicationState.EVIDENCE_REJECTED': { intent: 'error', label: 'Rejected' },
  'TAS.ApplicationState.FULFILLED': { intent: 'success', label: 'Completed' },
  'TAS.ApplicationState.ABANDONED': { intent: 'warning', label: 'Abandoned' },
};

export const applicationBadgeState = (state: TasApplicationState, approvalRequired: boolean) => {
  if (state === 'TAS.ApplicationState.PENDING_COURSES_APPROVAL' && !approvalRequired) {
    // if 4.2 or 4.3
    return tasApplicationStateBadgeMap['TAS.ApplicationState.COURSES_APPROVED'];
  }
  return tasApplicationStateBadgeMap[state];
};

const tasApplicationStateCourseBadgeMap = {
  'TAS.ApplicationState.CREATED': { intent: 'warning', label: 'Add Courses' },
  'TAS.ApplicationState.DEFINE_COURSES': { intent: 'warning', label: 'Add Courses' },
  'TAS.ApplicationState.PENDING_COURSES_APPROVAL': { intent: 'primary', label: 'Pending' },
  'TAS.ApplicationState.COURSES_APPROVED': { intent: 'success', label: 'Approved' },
  'TAS.ApplicationState.COURSES_NOT_APPROVED': { intent: 'error', label: 'Rejected' },
  'TAS.ApplicationState.COURSES_REJECTED': { intent: 'error', label: 'Rejected' },
  'TAS.ApplicationState.ATTEND': { intent: 'success', label: 'In Progress' },
  'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL': { intent: 'primary', label: 'Pending' },
  'TAS.ApplicationState.EVIDENCE_APPROVED': { intent: 'success', label: 'In Progress' },
  'TAS.ApplicationState.EVIDENCE_NOT_APPROVED': { intent: 'error', label: 'Rejected' },
  'TAS.ApplicationState.EVIDENCE_REJECTED': { intent: 'error', label: 'Rejected' },
  'TAS.ApplicationState.FULFILLED': { intent: 'success', label: 'Completed' },
  'TAS.ApplicationState.ABANDONED': { intent: 'warning', label: 'Abandoned' },
};

export const courseBadgeState = (state: TasApplicationState) => {
  return tasApplicationStateCourseBadgeMap[state];
};
