// @ts-nocheck
// Not sure why freestyle is having signature issues on all parts.
import RouteTemplate from 'ember-route-template';
import FreestyleGuide from 'ember-freestyle/components/freestyle-guide';
import FreestyleSection from 'ember-freestyle/components/freestyle-section';
import FreestyleUsage from 'ember-freestyle/components/freestyle-usage';
import FreestylePalette from 'ember-freestyle/components/freestyle-palette';
import FreestylePaletteItem from 'ember-freestyle/components/freestyle-palette-item';
import FreestyleCollection from 'ember-freestyle/components/freestyle-collection';
import { Button, ButtonGroup } from 'tio-ui/components/buttons';
import { Popover } from 'tio-ui/components/popover';
import CurrencyDollar from 'ember-static-heroicons/components/outline-24/currency-dollar';
import FileUploader from 'tio-ui/components/file-uploader';
import {
  tioGray,
  tioViolet,
  tioMidnight,
  tioPlum,
  tioMaroon,
  tioTeal,
  tioFuscia,
  tioMint,
  tioOcean,
  tioError,
} from '../utils/freestyle-data';
import { HStack, VStack, Section, Header } from 'tio-ui/components/layout';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import SearchSelect from 'tio-ui/components/search-select';

const SearchSelectOptions = [
  {
    label: 'Option 1',
    key: 'option-1',
  },
  {
    label: 'Option 2',
    key: 'option-2',
  },
  {
    label: 'Option 3',
    key: 'option-3',
  },
];

export default RouteTemplate(
  <template>
    {{! template-lint-disable no-bare-strings }}
    <FreestyleGuide @title="Tuition.io" @subtitle="Living Style Guide">
      <FreestyleSection @name="Visual Style" as |Section|>
        <Section.subsection @name="Typography">
          <div class="p-4">
            <p>Tuition.io uses
              <a class="text-blue-600 cursor-pointer" href="https://tailwindcss.com/">Tailwind CSS</a>
              in the application, as well as the
              <a
                class="text-blue-600 cursor-pointer"
                href="https://github.com/tailwindlabs/tailwindcss-typography"
              >Tailwind CSS Typography</a>
              plugin.</p>
            <p>Here are some examples of Tailwind Typography:</p>
          </div>
          <FreestyleUsage @slug="typography-fonts" @title="Font Family">
            <p class="font-sans text-xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="font-serif text-xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="font-mono text-xl">The quick brown fox jumped over the lazy dog.</p>
          </FreestyleUsage>

          <FreestyleUsage @slug="typography-font-size" @title="Font size">
            <p class="text-sm">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-base">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-lg">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-2xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-3xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-4xl">The quick brown fox jumped over the lazy dog.</p>
            <p class="text-5xl">The quick brown fox jumped over the lazy dog.</p>
          </FreestyleUsage>
          <div class="text-xl">
            <FreestyleUsage @slug="typography-font-weight" @title="Font weight">
              <p class="font-light">The quick brown fox jumped over the lazy dog.</p>
              <p class="font-normal">The quick brown fox jumped over the lazy dog.</p>
              <p class="font-medium">The quick brown fox jumped over the lazy dog.</p>
              <p class="font-semibold">The quick brown fox jumped over the lazy dog.</p>
              <p class="font-bold">The quick brown fox jumped over the lazy dog.</p>
            </FreestyleUsage>
          </div>
          <div class="text-xl font-medium">
            <FreestyleUsage @slug="typography-letter-spacing" @title="Letter spacing">
              <p class="tracking-tighter">The quick brown fox jumped over the lazy dog.</p>
              <p class="tracking-tight">The quick brown fox jumped over the lazy dog.</p>
              <p class="tracking-normal">The quick brown fox jumped over the lazy dog.</p>
              <p class="tracking-wide">The quick brown fox jumped over the lazy dog.</p>
              <p class="tracking-wider">The quick brown fox jumped over the lazy dog.</p>
              <p class="tracking-widest">The quick brown fox jumped over the lazy dog.</p>
            </FreestyleUsage>
          </div>
        </Section.subsection>

        <Section.subsection @name="Color">
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioGray}}
              @title="Gray Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
          <div class="flex p-4">
            <div>
              <p class="FreestylePalette-title">Midnight</p>
              <FreestylePaletteItem @color={{tioMidnight}} />
            </div>
            <div>
              <p class="FreestylePalette-title">Plum</p>
              <FreestylePaletteItem @color={{tioPlum}} />
            </div>
            <div>
              <p class="FreestylePalette-title">Maroon</p>
              <FreestylePaletteItem @color={{tioMaroon}} />
            </div>
            <div>
              <p class="FreestylePalette-title">Teal</p>
              <FreestylePaletteItem @color={{tioTeal}} />
            </div>
          </div>
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioViolet}}
              @title="Violet Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioFuscia}}
              @title="Fuscia Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioMint}}
              @title="Mint Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioOcean}}
              @title="Ocean Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
          <div class="flex p-4">
            <FreestylePalette
              @colorPalette={{tioError}}
              @title="Error Color Palette"
              @description="Found in tio.styles tio-shared-tailwind.config"
            />
          </div>
        </Section.subsection>
      </FreestyleSection>
      <FreestyleSection @name="TIO UI" as |Section|>
        <Section.subsection @name="Buttons">
          <p class="p-4">
            Buttons primarily use all Frontile defaults. We currently add our own custom progress
            indicator, but may work to get that in the main library.
          </p>
          <FreestyleCollection
            @title="Button Collection"
            @defaultKey="Primary"
            @inline={{true}}
            as |Collection|
          >
            <Collection.variant @key="Primary">
              <FreestyleUsage>
                <Button @intent="primary" class="mx-1">
                  Button
                </Button>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Outlined">
              <FreestyleUsage>
                <Button @appearance="outlined" class="mx-1">
                  Button
                </Button>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Disabled">
              <FreestyleUsage>
                <Button disabled={{true}} class="mx-1">
                  Button
                </Button>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Danger">
              <FreestyleUsage>
                <Button @intent="danger" class="mx-1">
                  Button
                </Button>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Minimal">
              <FreestyleUsage>
                <Button @appearance="minimal" class="mx-1">
                  Button
                </Button>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Loading">
              <FreestyleUsage>
                <Button @isRunning={{true}} class="mx-1">
                  Button
                </Button>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Icon">
              <FreestyleUsage>
                <Button @intent="primary" @isRunning={{false}}>
                  <CurrencyDollar class="w-5 mr-2" />
                  Button
                </Button>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Info">
              <FreestyleUsage>
                <Button
                  @intent="danger"
                  @description="This button does a lot of confusing stuff. This is a description of the button."
                  @showDescription={{true}}
                >
                  Confusing
                </Button>
              </FreestyleUsage>
            </Collection.variant>
          </FreestyleCollection>
        </Section.subsection>
        <Section.subsection @name="Selects">
          <p class="p-4">
            Add notes
          </p>
          <FreestyleCollection
            @title="Select Collection"
            @defaultKey="Primary"
            @inline={{true}}
            as |Collection|
          >
            <Collection.variant @key="selects">
              <FreestyleUsage @slug="file-uploader" @title="Search Select">
                <SearchSelect
                  @name="search-select"
                  @items={{SearchSelectOptions}}
                  @placeholder="Select an option"
                />
              </FreestyleUsage>
            </Collection.variant>
          </FreestyleCollection>
        </Section.subsection>
        <Section.subsection @name="File Uploader">
          <FreestyleCollection
            @title="File Uploader Variants"
            @defaultKey="Standard"
            as |Collection|
          >
            <Collection.variant @key="dropzone">
              <FreestyleUsage @slug="file-uploader" @title="File Uploader">
                <FileUploader @name="freestyle" @useDropzone={{true}}>
                  Fallback input label for cases that don't support dropzones
                </FileUploader>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="dropzone">
              <FreestyleUsage @slug="file-uploader" @title="File Uploader">
                <FileUploader @name="freestyle" @useDropzone={{false}}>
                  <span class="tio-link">Upload a file</span>
                </FileUploader>
              </FreestyleUsage>
            </Collection.variant>
          </FreestyleCollection>
        </Section.subsection>
      </FreestyleSection>
      <FreestyleSection @name="Layout" as |FSection|>
        <FSection.subsection @name="VStack">
          <FreestyleCollection @title="VStack" @defaultKey="Standard" as |Collection|>
            <Collection.variant @key="Standard">
              <FreestyleUsage>
                <VStack>
                  <Section>
                    <:header>With Header</:header>
                    <:body>Body</:body>
                  </Section>
                  <Section>
                    <:body>Only Body</:body>
                  </Section>
                  <Section>
                    Escape Hatch
                  </Section>
                </VStack>
              </FreestyleUsage>
            </Collection.variant>
            <Collection.variant @key="Collapsed">
              <FreestyleUsage>
                <VStack>
                  {{! would usually be provided by parent component }}
                  <Section>
                    <VStack @collapsed={{true}}>
                      <Section>
                        <:header>With Header</:header>
                        <:body>Body</:body>
                      </Section>
                      <Section>
                        <:body>Only Body</:body>
                      </Section>
                      <Section>
                        Escape Hatch
                      </Section>
                    </VStack>
                  </Section>
                </VStack>
              </FreestyleUsage>
            </Collection.variant>
          </FreestyleCollection>
        </FSection.subsection>
        <FSection.subsection @name="HStack">
          <FreestyleCollection @title="HStack" as |Collection|>
            <Collection.variant @key="With-Container">
              <FreestyleUsage>
                <VStack>
                  <Section>
                    <:header>With Container</:header>
                    <:body>
                      <HStack>
                        <Section class="!bg-rose-300">
                          <:header>Header</:header>
                          <:body>Body</:body>
                        </Section>
                        <Section class="!bg-rose-500">
                          <:body>Only Body</:body>
                        </Section>
                        <Section class="!bg-rose-700">Escape Hatch</Section>
                      </HStack>
                    </:body>
                  </Section>
                </VStack>
              </FreestyleUsage>
            </Collection.variant>

            <Collection.variant @key="Without-Container">
              <FreestyleUsage>
                <VStack>
                  <HStack>
                    <Section class="!bg-rose-300">
                      <:header>Header</:header>
                      <:body>Body</:body>
                    </Section>
                    <Section class="!bg-rose-500">
                      <:body>Only Body</:body>
                    </Section>
                    <Section class="!bg-rose-700">Escape Hatch</Section>
                  </HStack>
                </VStack>
              </FreestyleUsage>
            </Collection.variant>

            <Collection.variant @key="Collapsed">
              <FreestyleUsage>
                <div class={{StackColors}}>
                  <VStack>
                    <Section>
                      <:body>
                        <HStack @collapsed={{true}}>
                          <Section>
                            <:header>Header</:header>
                            <:body>Body</:body>
                          </Section>
                          <Section>
                            <:body>Only Body</:body>
                          </Section>
                          <Section>Escape Hatch</Section>
                        </HStack>
                      </:body>
                    </Section>
                  </VStack>
                </div>
              </FreestyleUsage>
            </Collection.variant>
          </FreestyleCollection>
        </FSection.subsection>
        <FSection.subsection @name="Complex Examples">
          <FreestyleCollection @title="Complex Examples" as |Collection|>
            <Collection.variant @key="">
              <FreestyleUsage>
                <StackDiscussion />
              </FreestyleUsage>
            </Collection.variant>
          </FreestyleCollection>
        </FSection.subsection>
      </FreestyleSection>
    </FreestyleGuide>
  </template>
);

const StackColors = `
  [&>*]:bg-blue-500                   /* Direct children (depth 1) */
  [&>*>*]:bg-green-500                /* Grandchildren (depth 2) */
  [&>*>*>*]:bg-yellow-500             /* Depth 3 */
  [&>*>*>*>*]:bg-red-500              /* Depth 4 */
  [&>*>*>*>*>*]:bg-pink-500           /* Depth 5 */
  [&>*>*>*>*>*>*]:bg-indigo-500       /* Depth 6 */
  [&>*>*>*>*>*>*>*]:bg-purple-500     /* Depth 7 */
  [&>*>*>*>*>*>*>*>*]:bg-orange-500   /* Depth 8 */
  [&>*>*>*>*>*>*>*>*>*]:bg-teal-500   /* Depth 9 */
  [&>*>*>*>*>*>*>*>*>*>*]:bg-gray-500 /* Depth 10 */
`;

class StackDiscussion extends Component {
  @tracked showColors = true;

  @action
  toggleColors() {
    this.showColors = !this.showColors;
  }

  <template>
    {{! template-lint-disable no-bare-strings }}
    <VStack>
      <Button @intent="primary" {{on "click" this.toggleColors}}>Toggle Colors</Button>
      <p>VStack with block elements will be divided 1rem</p>
      <p>Ultimate goal is that we use "magic elements". This way our rule system can be applied and
        does not interfere with addons that may place extra html elements.</p>
      <div class={{if this.showColors StackColors}}>
        <VStack>
          <div>div</div>
          <p>p</p>
          <h1>h1</h1>
          <article>article</article>
          <section>TODO: section gets magic applied, but the magic should only be applied to Section
            data-section.
          </section>
        </VStack>
      </div>

      <p>VStack with "magic elements".</p>
      <div class={{if this.showColors StackColors}}>
        <VStack>
          <Section>
            <:header>Section Header 1</:header>
            <:body>Section Body 1</:body>
          </Section>
          <Section>
            <:header>Section Header 2</:header>
            <:body>
              <div>some div</div>
              <p>some p</p>
              <h1>some h1</h1>
            </:body>
          </Section>
        </VStack>
      </div>

      <p>The body of normal elements was unstyled. Add a VStack!</p>
      <div class={{if this.showColors StackColors}}>
        <VStack>
          <Section>
            <:header>Section Header 1</:header>
            <:body>Section Body 1</:body>
          </Section>
          <Section>
            <:header>Section Header 2</:header>
            <:body>
              <VStack>
                <div>some div</div>
                <p>some p</p>
                <h1>some h1</h1>
              </VStack>
            </:body>
          </Section>
        </VStack>
      </div>
      <p>Thats better, lets have something side by side</p>
      <div class={{if this.showColors StackColors}}>
        <VStack>
          <Section>
            <:header>Section Header 1</:header>
            <:body>Section Body 1</:body>
          </Section>
          <Section>
            <:header>Section Header 2</:header>
            <:body>
              <VStack>
                <div>some div</div>
                <p>some p</p>
                <h1>some h1</h1>
                <HStack>
                  <div>side left</div>
                  <div>side right</div>
                </HStack>
              </VStack>
            </:body>
          </Section>
        </VStack>
      </div>
      <p>Great, but I want items to take up divisible space</p>
      <div class={{if this.showColors StackColors}}>
        <VStack>
          <Section>
            <:header>Section Header 1</:header>
            <:body>Section Body 1</:body>
          </Section>
          <Section>
            <:header>Section Header 2</:header>
            <:body>
              <VStack>
                <div>some div</div>
                <p>some p</p>
                <h1>some h1</h1>
                <HStack>
                  <Section><:body>side left</:body></Section>
                  <Section><:body>side right</:body></Section>
                </HStack>
              </VStack>
            </:body>
          </Section>
        </VStack>
      </div>
      <p>And we hit behavior question. Sections get magic styles when direct descendent of
        HStack/VStack. The designs don't call for that. Was this a good default? Add a collapsed
        option... better, but what is with the line in front of the second section? Often this line
        makes sense, but does it make sense always? On some pages it looked wrong, but is that an
        issue with the rules or bad layout choice? In several cases making the side by side stacked
        always ends up looking a lot cleaner and more predictable. Also now we have double padding
        because I used body</p>
      <div class={{if this.showColors StackColors}}>
        <VStack>
          <Section>
            <:header>Section Header 1</:header>
            <:body>Section Body 1</:body>
          </Section>
          <Section>
            <:header>Section Header 2</:header>
            <:body>
              <VStack>
                <div>some div</div>
                <p>some p</p>
                <h1>some h1</h1>
                <HStack @collapsed={{true}}>
                  {{! should we allow default yield block? could call one
                              "escape-hatch" that makes it clear usage is out of the norm.}}
                  <Section><:body>side left</:body></Section>
                  <Section><:body>side right</:body></Section>
                </HStack>
              </VStack>
            </:body>
          </Section>
        </VStack>
      </div>
      <p>Drop in a VStack after the HStack and then we have something else. Maybe this is a drop in
        component, but do we want the default to have padding?
      </p>
      <div class={{if this.showColors StackColors}}>
        <Section>
          <:header>Section Header 2</:header>
          <:body>
            <VStack>
              <div>some div</div>
              <p>some p</p>
              <h1>some h1</h1>
              <HStack @collapsed={{true}}>
                {{! should we allow default yield block? could call one
                              "escape-hatch" that makes it clear usage is out of the norm.}}
                <Section><:body>side left</:body></Section>
                <Section><:body>side right</:body></Section>
              </HStack>
              <VStack>
                <div>some div</div>
                <p>some p</p>
                <h1>some h1</h1>
              </VStack>
            </VStack>
          </:body>
        </Section>
      </div>
      <p>When a VStack is directly in a VStack then the padding isn't applied to the second stack.
        Maybe a header makes the padding look better? May the horizontal.</p>
      <div class={{if this.showColors StackColors}}>
        <Section>
          <:header>Section Header 2</:header>
          <:body>
            <VStack>
              <VStack>
                <div>some div</div>
                <p>some p</p>
                <h1>some h1</h1>
                <HStack @collapsed={{true}}>
                  {{! should we allow default yield block? could call one
                              "escape-hatch" that makes it clear usage is out of the norm.}}
                  <Section><:body>side left</:body></Section>
                  <Section><:body>side right</:body></Section>
                </HStack>
                <Header>Some other element</Header>
                <VStack>
                  <div>some div</div>
                  <p>some p</p>
                  <h1>some h1</h1>
                </VStack>
              </VStack>
            </VStack>
            <p>Some other element</p>
          </:body>
        </Section>
      </div>
      <p>Try collapse?</p>
      <div class={{if this.showColors StackColors}}>
        <Section>
          <:header>Section Header 2</:header>
          <:body>
            <VStack>
              <VStack @collapsed={{true}}>
                <div>some div</div>
                <p>some p</p>
                <h1>some h1</h1>
                <HStack @collapsed={{true}}>
                  {{! should we allow default yield block? could call one
                              "escape-hatch" that makes it clear usage is out of the norm.}}
                  <Section><:body>side left</:body></Section>
                  <Section><:body>side right</:body></Section>
                </HStack>
                <Header>Some other element</Header>
                <VStack>
                  <div>some div</div>
                  <p>some p</p>
                  <h1>some h1</h1>
                </VStack>
              </VStack>
            </VStack>
            <p>Some other element</p>
          </:body>
        </Section>
      </div>
      <p>The previous layout but with our default styles</p>
      <div class={{if this.showColors StackColors}}>
        <VStack>
          <Section>
            <:header>Section Header 2</:header>
            <:body>
              <VStack>
                <VStack @collapsed={{true}}>
                  <div>some div</div>
                  <p>some p</p>
                  <h1>some h1</h1>
                  <HStack @collapsed={{true}}>
                    {{! should we allow default yield block? could call one
                              "escape-hatch" that makes it clear usage is out of the norm.}}
                    <Section><:body>side left</:body></Section>
                    <Section><:body>side right</:body></Section>
                  </HStack>
                  <Header>Some other element</Header>
                  <VStack>
                    <div>some div</div>
                    <p>some p</p>
                    <h1>some h1</h1>
                  </VStack>
                </VStack>
              </VStack>
              <p>Some other element</p>
            </:body>
          </Section>
        </VStack>
      </div>
      <p>And now with our default styles, no html elements, more collapse</p>
      <div class={{if this.showColors StackColors}}>
        <VStack>
          <Section>
            <:header>Section Header</:header>
            <:body>
              <VStack>
                <VStack @collapsed={{true}}>
                  <Section><:body>Section</:body></Section>
                  <Section><:body>Section</:body></Section>
                  <Section><:body>Section</:body></Section>
                  <HStack @collapsed={{true}}>
                    {{! should we allow default yield block? could call one
                              "escape-hatch" that makes it clear usage is out of the norm.}}
                    <Section><:body>Section left</:body></Section>
                    <Section><:body>Section right</:body></Section>
                  </HStack>
                  <Header>Header</Header>
                  <VStack>
                    <Section><:body>Section</:body></Section>
                    <Section><:body>Section</:body></Section>
                    <Section><:body>Section</:body></Section>
                  </VStack>
                </VStack>
              </VStack>
              <Section><:body>Section</:body></Section>
            </:body>
          </Section>
        </VStack>
      </div>
      <p>Thoughts on an aside</p>
      <div class={{if this.showColors StackColors}}>
        <Section>
          <:header>Section Header - Section with hstack and stacked aside</:header>
          <:body>
            <HStack>
              <Section class="w-2/3">
                <:header>Section Header</:header>
                <:body>Section body of article</:body>
              </Section>
              <VStack class="w-1/3">
                <Section>
                  <:header>Aside</:header>
                  <:body>
                    <p>Aside body</p>
                  </:body>
                </Section>
                <Section>
                  <:header>Aside 2</:header>
                  <:body>
                    <p>Aside body 2</p>
                  </:body>
                </Section>
                <VStack @collapsed={{true}}>
                  <Section>
                    <:header>Aside 3</:header>
                    <:body>
                      <p>Aside body 3</p>
                    </:body>
                  </Section>
                  <Section>
                    <:header>Aside 4</:header>
                    <:body>
                      <p>Aside body 4</p>
                    </:body>
                  </Section>
                </VStack>
              </VStack>
            </HStack>
          </:body>
        </Section>
      </div>
      <p>Wrangle rules</p>
      <div class={{if this.showColors StackColors}}>
        <Section class="border">
          {{! this would be the edge of page}}
          <VStack>
            {{! Usually the top most element on a page.
              provides padding, and enables defaults on magic elements. }}
            <HStack>
              <p>hstack element 1</p>
              <p>hstack element 2</p>
            </HStack>
            <HStack>
              <Section>
                hstack element 1
              </Section>
              <Section>
                hstack element 2
              </Section>
            </HStack>
            <p>something 3</p>
            <Section>
              <:body>
                something 4
              </:body>
            </Section>
            <VStack>
              <Section>
                something 5
              </Section>
              <Section>
                <:header>something 6</:header>
                <:body>
                  something 6 - why do I have space above? (fixed)
                </:body>
              </Section>
            </VStack>
          </VStack>
        </Section>
      </div>
    </VStack>
  </template>
}
