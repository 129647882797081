import { concat } from '@ember/helper';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type EmployeeService from 'tio-employee/services/employee';
import formatPhone from 'tio-common/helpers/format-phone';
import MaterialIcon from 'tio-common/components/material-icon';
import { t } from 'ember-intl';
import Tile from '../tile';

export default class EmploymentAgentComponent extends Component {
  @service declare employee: EmployeeService;

  get employeeMeta() {
    return this.employee.current?.customAttributes || {};
  }

  get agentName() {
    return this.employeeMeta['agent-name'];
  }

  get agentPhone() {
    return this.employeeMeta['agent-phone'];
  }

  get agentEmail() {
    return this.employeeMeta['agent-email'];
  }

  get agentWebsite() {
    return this.employeeMeta['agent-website'];
  }

  get agentSchedule() {
    return this.employeeMeta['agent-schedule'];
  }

  get agentLicense() {
    return this.employeeMeta['agent-license'];
  }

  get agencyName() {
    return this.employeeMeta['agency-name'];
  }

  get firstLineAddress() {
    return this.employeeMeta['agent-address-line1'];
  }

  get secondLineAddress() {
    return this.employeeMeta['agent-address-line2'];
  }

  get lastLineAddress() {
    const city = this.employeeMeta['agent-address-city'] || '';
    const state = this.employeeMeta['agent-address-state'] || '';
    const zip = this.employeeMeta['agent-address-zip-code'] || '';
    return `${city}, ${state} ${zip}`;
  }

  convertPhoneNumber(number: string) {
    if (number.length === 10) {
      return `+1 (${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, 10)}`;
    }
    return number;
  }

  <template>
    <Tile @headerText={{t "dashboard_tiles.your_hm_rep"}}>
      <:body>
        <p class="font-semibold">{{this.agentName}}</p>
        <p>{{this.agencyName}}</p>

        <div class="my-2">
          <p>
            {{this.firstLineAddress}}<br />
            {{this.secondLineAddress}}<br />
            {{this.lastLineAddress}}
          </p>
        </div>

        <div class="my-2">
          {{#if this.agentPhone}}
            <p class="mb-1">{{formatPhone this.agentPhone}}</p>
          {{/if}}

          {{#if this.agentEmail}}
            <a
              href={{concat "mailto:" this.agentEmail}}
              class="flex items-center text-ocean-600 hover:text-ocean-800 mb-1"
            >
              {{this.agentEmail}}
              <MaterialIcon @icon="email" class="ml-2 text-lg" />
            </a>
          {{/if}}

          {{#if this.agentWebsite}}
            <a
              href={{this.agentWebsite}}
              target="_blank"
              rel="noopener noreferrer"
              class="flex items-center text-ocean-600 hover:text-ocean-800 mb-1"
            >
              {{t "dashboard_tiles.hm_agent_site"}}
              <MaterialIcon @icon="open_in_new" class="ml-2 text-lg" />
            </a>
          {{/if}}

          {{#if this.agentSchedule}}
            <a
              href={{this.agentSchedule}}
              target="_blank"
              rel="noopener noreferrer"
              class="flex items-center text-ocean-600 hover:text-ocean-800 mb-1"
            >
              {{t "dashboard_tiles.schedule_time_with_me"}}
              <MaterialIcon @icon="event" class="ml-2 text-lg" />
            </a>
          {{/if}}
        </div>

        <p class="mb-2">{{t "dashboard_tiles.agent_info_line"}}</p>

        {{#if this.agentLicense}}
          <p>{{t "dashboard_tiles.hm_agent_license"}}: {{this.agentLicense}}</p>
        {{/if}}
      </:body>
    </Tile>
  </template>
}
