import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { not } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import urlFor from 'ember-router-helpers/helpers/url-for';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import noLinkedLoansReasonSchema from 'tio-common/validation-schema/forms/no-linked-loans-reason';
import { t } from 'ember-intl';
import TioAlert from 'tio-common/components/tio/alert';
import type Errors from 'tio-employee/types/errors';
import type { IntlService } from 'ember-intl';
import type PersonModel from 'tio-common/models/person';
import type RouterService from '@ember/routing/router-service';
import { VStack } from 'tio-ui/components/layout';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { concat } from '@ember/helper';

export interface AlertNoLinkedLoansSignature {
  Args: {
    person: PersonModel;
    product?: string;
    didSave?: (person: PersonModel) => void;
  };
}

export default class AlertNoLinkedLoansComponent extends Component<AlertNoLinkedLoansSignature> {
  @service declare intl: IntlService;
  @service declare router: RouterService;

  @tracked reason = '';
  @tracked explanation = '';

  @tracked hasSubmitted = false;
  @tracked submitError?: Errors;

  get canSubmit() {
    return this.reason && this.explanation;
  }

  @action
  submit() {
    this.submitTask.perform();
  }

  submitTask = dropTask(async () => {
    delete this.submitError;
    this.hasSubmitted = true;
    if (this.formValidationErrors.length) {
      return;
    }
    try {
      const saved = await this.saveFormValues.perform();
      this.hasSubmitted = false;
      this.args.didSave?.(saved);
    } catch (e) {
      this.submitError = e;
      console.error(e);
    }
  });

  saveFormValues = dropTask(async () => {
    const person = this.args.person;
    const existingPreferences = person.preferences;
    const updatedPreferences = {
      ...existingPreferences,
      'reason-for-not-linking-loans': this.reason,
      'explanation-for-not-linking-loans': this.explanation,
    };

    person.preferences = updatedPreferences;

    await person.save();
    return person;
  });

  get formValidationErrors() {
    const schema = noLinkedLoansReasonSchema;
    const formModel = {
      'reason-for-not-linking-loans': this.reason,
      'explanation-for-not-linking-loans': this.explanation,
    };

    try {
      schema?.validateSync?.(formModel, { abortEarly: false });
    } catch (err) {
      return err.inner || [];
    }
    return [];
  }

  get serverErrorMessages() {
    const genericErrorMessage = this.intl.t('error_occurred');
    if (this.submitError?.errors) {
      return this.submitError.errors.map((err: Errors['errors'][number]) => {
        return err?.detail || genericErrorMessage;
      });
    } else if (this.submitError) {
      return [genericErrorMessage];
    }
    return [];
  }

  get observabilityUploadLinkQuery(): string | undefined {
    const { product } = this.args;
    if (product === 'pslf') return 'pslf';
  }

  <template>
    <TioAlert @type="warning" @allowDismiss={{false}} class="text-sm">
      <:body>
        <VStack>
          <p class="text-md">{{t "no_linked_accounts_alert.warning"}}</p>
          <Button
            @intent="primary"
            {{! NB: this looks like a fragile way to construct this URL; consider changing
            this to a more web canonic link rather than a button and generate url with the
            router spec's arg for query params }}
            {{on
              "click"
              (transitionTo
                (concat
                  (urlFor "authenticated.observability.upload")
                  "?source="
                  this.observabilityUploadLinkQuery
                )
              )
            }}
            class="w-fit"
          >
            {{t "no_linked_accounts_alert.add_loans_btn"}}
          </Button>

          <p class="font-semibold">
            {{t "no_linked_accounts_alert.not_adding_loans"}}
          </p>

          <form class="mx-1 md:mx-4">
            <FormRadioGroup
              data-legacy-input
              @containerClass="my-2 no-linked-loans"
              @hasSubmitted={{this.hasSubmitted}}
              @errors={{errorsForField "reason" schemaErrors=this.formValidationErrors}}
              @required="true"
              @value={{this.reason}}
              {{! @glint-expect-error: look into better option to mut}}
              @onChange={{fn (mut this.reason)}}
              as |Radio|
            >
              {{! @glint-expect-error: look into frontile types}}
              <Radio @label={{t "no_linked_accounts_alert.dont_want_add"}} @value="dont_want_add" />
              {{! @glint-expect-error: look into frontile types}}
              <Radio @label={{t "no_linked_accounts_alert.unable_add"}} @value="unable_add" />
            </FormRadioGroup>
            <FormInput
              data-legacy-input
              @value={{this.explanation}}
              @onInput={{fn (mut this.explanation)}}
              required="true"
              placeholder={{t "no_linked_accounts_alert.enter_reason"}}
              @containerClass="max-w-lg"
              @hasSubmitted={{this.hasSubmitted}}
              @errors={{errorsForField "explanation" schemaErrors=this.formValidationErrors}}
            >
              <Button
                @intent="primary"
                class="px-6 mt-4"
                {{on "click" this.submit}}
                disabled={{not this.canSubmit}}
              >
                {{t "submit"}}
              </Button>
            </FormInput>
          </form>
        </VStack>
      </:body>
    </TioAlert>
  </template>
}
