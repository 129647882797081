import RouteTemplate from 'ember-route-template';
import TioLoadingSpinner from 'tio-common/components/tio/loading-spinner';

export default RouteTemplate(
  <template>
    <div class="flex h-screen w-screen items-center justify-center">
      <TioLoadingSpinner />
    </div>
  </template>
);
