import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { set } from '@ember/object';
import { setSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import type TasProgramInstance from 'tio-common/models/tas-program-instance';
import type { IntlService } from 'ember-intl';
import type Owner from '@ember/owner';
import type SessionContextService from 'tio-employee/services/session-context';
import type TasApplicationModel from 'tio-common/models/tas-application';

export default class AuthenticatedTuitionAssistanceProgramsInstanceCourseApplicationsNewRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare sessionContext: SessionContextService;

  constructor(owner: Owner) {
    super(owner);

    // If a user is transitioning away without saving, delete any unsaved records from the store
    this.router.on('routeDidChange', (transition) => {
      // @ts-expect-error: may be a bug. Name doesn't exist on route.
      if (!transition.to.find((route: Route) => route.name === this.routeName)) {
        const records = this.store.peekAll('tas-application') as TasApplicationModel[];
        const unsaved = records.filter((model) => model.isNew);
        unsaved.forEach((model) => {
          model.rollbackAttributes();
        });
      }
    });
  }

  async model() {
    const programInstance = (await this.modelFor(
      'authenticated.tuition-assistance.programs.instance'
    )) as TasProgramInstance;
    const programTemplate = programInstance.tasProgramTemplate;
    const initialFields = programTemplate.applicationFieldsHash;
    const customFields = programTemplate.applicationCustomFields;
    const employeeId = this.sessionContext.currentEmployee.id;

    const displayName = this.intl.formatDate(Date.now(), {
      month: 'long',
      year: 'numeric',
    });

    // check if employee is already loaded, if not, sets the employee on the instance
    let employee = programInstance.employee;

    // Fetch employee if not already loaded
    if (!employee) {
      employee = await this.store.findRecord('employee', employeeId, {
        include: 'tas-participant',
        reload: true,
      });
      set(programInstance, 'employee', employee);
    }

    // Check if tasParticipant is already loaded, if not, sets the tasParticipant on the instance
    if (!employee.tasParticipant) {
      const tasParticipant = (
        await this.store.query('tas-participant', {
          filter: {
            employee: employeeId,
          },
        })
      )[0];
      set(employee, 'tasParticipant', tasParticipant);
    }

    setSingleValueForTasField('APPLICATION_NAME', displayName, initialFields);

    return this.store.createRecord('tas-application', {
      tasProgramInstance: programInstance,
      fields: initialFields,
      customFields,
    });
  }
}
