import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';

interface AppPageProsVsConsSignature {
  Args: {
    cons?: string[];
    pros?: string[];
  };
}

const AppPageProsVsConsComponent: TOC<AppPageProsVsConsSignature> = <template>
  <div class="flex flex-col sm:grid sm:grid-cols-2">
    <div class="p-3">
      <h1 class="font-semibold text-lg text-gray-600">
        {{t "plan_details.pros"}}
      </h1>
      <ul class="p-4 pt-0 list-disc">
        {{#each @pros as |pro|}}
          <li>{{t pro htmlSafe=true}}</li>
        {{/each}}
      </ul>
    </div>
    <div class="p-3">
      <h1 class="font-semibold text-lg text-gray-600">
        {{t "plan_details.cons"}}
      </h1>
      <ul class="p-4 pt-0 list-disc">
        {{#each @cons as |con|}}
          <li>{{t con htmlSafe=true}}</li>
        {{/each}}
      </ul>
    </div>
  </div>
</template>;

export default AppPageProsVsConsComponent;
