import { array } from '@ember/helper';
import { Button } from 'tio-ui/components/buttons';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import ClaimsFinancialsCalculationsReadonlyCard from 'tio-common/components/tuition-assistance/program-details/claims-financials/calculations-readonly-card';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import ProgressTracker from 'tio-common/components/tio/progress-tracker';
import RouteTemplate from 'ember-route-template';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import TuitionAssistanceLimitsSummary from 'tio-common/components/tuition-assistance/limits-summary';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import TuitionAssistanceProgramDetailsAppDetailsCoursesAndScholarships from 'tio-common/components/tuition-assistance/program-details/app-details/courses-and-scholarships';
import TuitionAssistanceProgramDetailsApplicationInformation from 'tio-common/components/tuition-assistance/program-details/application-information';
import TuitionAssistanceProgramDetailsCancelRequest from 'tio-employee/components/tuition-assistance/program-details/cancel-request';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import type { Step } from 'tio-common/components/tio/progress-tracker';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type Owner from '@ember/owner';

interface S {
  Args: {
    model: TasApplicationModel;
  };
}

class CourseApplicationsShowIndexComponent extends Component<S> {
  constructor(owner: Owner, args: S['Args']) {
    super(owner, args);
    this.progressBuilder.fetchApprovers();
  }
  @service declare progressBuilder: ProgressBuilder;

  get disableSubmitForReview() {
    if (this.args.model.tasProgramInstance.tasProgramTemplate.courseGradeRequired) {
      return this.args.model.isMissingCourseGrades || this.args.model.isMissingRequiredEvidence;
    }
    return this.args.model.isMissingRequiredEvidence;
  }

  get steps() {
    return this.progressBuilder.applicationStepList(this.args.model) as Step[];
  }

  <template>
    {{pageTitle (t "tuition_assistance.program_details.default")}}

    <VStack>
      <Header>{{t "tuition_assistance.default"}}</Header>
      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb @route="authenticated.tas.dashboard" @label={{t "dashboard"}} />
        <b.crumb
          @route="authenticated.tuition-assistance.programs.instance.index"
          @label={{@model.tasProgramInstance.programName}}
        />
        <b.crumb
          @route="authenticated.tuition-assistance.programs.instance.course-applications.show"
          @label={{@model.displayName}}
        />
      </TioPageBreadcrumbs>

      <ProgressTracker class="hidden md:block" @steps={{this.steps}} />
      <Section>
        <:body>
          <div class="flex flex-wrap gap-6">
            <TuitionAssistanceProgramDetailsProgramInfoCard
              @programInstance={{@model.tasProgramInstance}}
              @showAttachments={{true}}
              class="w-full md:w-max"
            />
            <div class="flex flex-col flex-wrap gap-4 w-full md:w-max">
              <TuitionAssistanceLimitsSummary
                @application={{@model}}
                @employee={{@model.tasProgramInstance.employee}}
                @labelClass="col-span-4"
                @valueClass="col-span-3 text-violet-800 !text-left"
                as |Summary|
              >
                {{#unless Summary.isUnlimitedMaximumAnnualBenefit}}
                  <Summary.Container
                    @title={{t "tuition_assistance.program_details.program_benefits"}}
                    @titleClass="font-semibold"
                  >
                    <Summary.MaxAnnualBenefitLineItem />
                  </Summary.Container>
                {{/unless}}
              </TuitionAssistanceLimitsSummary>
              <TuitionAssistanceProgramDetailsApplicationInformation
                @application={{@model}}
                @isAdminView={{false}}
                @canRequestRepayment={{false}}
                @requestingApp="employee"
              />
            </div>
            {{#if @model.tasProgramInstance.tasProgramTemplate.calculateTotalByClaimsFinancials}}
              <ClaimsFinancialsCalculationsReadonlyCard
                class="grow md:max-w-xl"
                @claimsFinancialsFields={{@model.claimsFinancialsCalculatorInput}}
              />
            {{/if}}
          </div>
          <TuitionAssistanceProgramDetailsAppDetailsCoursesAndScholarships @application={{@model}}>
            {{#if @model.canSubmitForPayment}}
              <div class="flex items-center my-4">
                <Button
                  class="w-full max-w-72"
                  {{on
                    "click"
                    (transitionTo
                      "authenticated.tuition-assistance.programs.instance.course-applications.show.complete.review"
                      @model.id
                    )
                  }}
                  disabled={{this.disableSubmitForReview}}
                >
                  {{t "tuition_assistance.program_details.status.submit_for_review"}}
                </Button>
              </div>
            {{/if}}
          </TuitionAssistanceProgramDetailsAppDetailsCoursesAndScholarships>
          <TuitionAssistanceProgramDetailsAppDetailsAppHistory
            @application={{@model}}
            @viewType="employee"
          />
          <TuitionAssistanceProgramDetailsCancelRequest
            @application={{@model}}
            @modelTypes={{array "tas-application"}}
            @linkText={{t "tuition_assistance.program_details.cancel_app.delete_course"}}
            @canCancel={{@model.canCancelApplication}}
          />
        </:body>
      </Section>
    </VStack>
  </template>
}

export default RouteTemplate(CourseApplicationsShowIndexComponent);
