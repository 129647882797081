import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import Tile from '../tile';
import { t } from 'ember-intl';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

const DashboardWidgetsCommonQuestionsComponent: TOC = <template>
  <a
    href="https://tio.zendesk.com/hc/en-us/categories/360000194112-FAQ"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Tile @headerText={{t "dashboard_tiles.common_questions"}}>
      <:description>
        <div class="flex flex-row">
          {{t "dashboard_tiles.get_answers_to"}}
        </div>
      </:description>
      <:image>
        {{svgJar
          "social-media-marketing"
          width="100%"
          height="100%"
          role="img"
          desc=(t "svg.social_media_marketing")
        }}
      </:image>
    </Tile>
  </a>
</template>;

export default DashboardWidgetsCommonQuestionsComponent;
