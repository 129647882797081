import { action } from '@ember/object';
import { enqueueTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import { t } from 'ember-intl';
import type TasApplication from 'tio-common/models/tas-application';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import TioConfirmAlert from 'tio-common/components/tio/confirm-alert';
import TioErrorMessages from 'tio-common/components/tio/error-messages';

export interface ProgramDetailsCancelRequestSignature {
  Args: {
    application: TasApplication | TASProgramInstanceModel;
    canCancel: boolean;
    linkText: string;
    modelTypes: string[];
    renderInPlace?: boolean;
  };
}

interface actionMapSignature {
  [key: string]: string;
}

export default class ProgramDetailsCancelRequestComponent extends Component<ProgramDetailsCancelRequestSignature> {
  @service declare store: typeof Store;
  @service declare router: RouterService;

  @tracked showCancelDialog = false;
  @tracked hasSubmitted = false;
  @tracked submitError = null;

  get adapterMethods(): string[] {
    const actionMap: actionMapSignature = {
      'tas-program-instance': 'abandonProgram',
      'tas-application': 'abandonCourse',
    };

    return this.args.modelTypes.map(
      (modelType) => actionMap[modelType as keyof typeof actionMap] ?? ''
    );
  }

  @action
  async cancelAction() {
    try {
      await Promise.all(
        this.args.modelTypes.map((modelType) => this.submitTask.perform(modelType))
      );

      const targetRoute = this.args.application.isDependentProgram
        ? 'authenticated.tas.dependent-programs'
        : this.args.modelTypes.includes('tasApplication')
          ? 'authenticated.tuition-assistance.programs.instance.index'
          : 'authenticated.tas.dashboard';

      this.router.transitionTo(targetRoute);
    } catch (e) {
      console.error(e);
    }
  }

  @action
  setModel(modelType: string) {
    if (modelType === this.args.application.modelName) {
      return this.args.application;
    } else if (modelType === this.args.application.tasProgramInstance.modelName) {
      return this.args.application.tasProgramInstance;
    }
  }

  submitTask = enqueueTask(async (modelType: string) => {
    const adapter = this.store.adapterFor(modelType);
    const index = this.args.modelTypes.indexOf(modelType);
    const method = this.adapterMethods[index] as string;
    const model = this.setModel(modelType);

    try {
      this.hasSubmitted = true;
      this.submitError = null;
      await adapter[method](model);
    } catch (e) {
      console.error(e);
      this.submitError = e;
    }
  });

  <template>
    {{#if @canCancel}}
      <div class="flex justify-center mt-12">
        <button
          type="button"
          class="tio-copy px-8 py-2"
          {{on "click" (fn (mut this.showCancelDialog) true)}}
          data-test-cancel-request-button
        >
          {{@linkText}}
        </button>
      </div>
    {{/if}}

    <TioConfirmAlert
      @show={{this.showCancelDialog}}
      @onCancel={{fn (mut this.showCancelDialog) false}}
      @onConfirm={{this.cancelAction}}
      @disabled={{false}}
      @renderInPlace={{@renderInPlace}}
    >
      <div class="mx-12" data-test-cancel-request-dialog>
        <h3 class="text-center mb-6 font-semibold text-tio-gray-700 text-lg md:text-xl">
          {{t "tuition_assistance.program_details.cancel_app.header"}}
        </h3>
        <p>
          {{t "tuition_assistance.program_details.cancel_app.body"}}
        </p>

        <TioErrorMessages
          @showErrors={{this.hasSubmitted}}
          @error={{this.submitError}}
          class="text-center"
          data-test-cancel-request-errors
        />
      </div>
    </TioConfirmAlert>
  </template>
}
