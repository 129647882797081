import { concat } from '@ember/helper';
import Component from '@glimmer/component';
import lowercase from 'ember-cli-string-helpers/helpers/lowercase';
import { t } from 'ember-intl';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { eq, not } from 'tio-ui/utilities';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import ProgramReadonlyField from 'tio-common/components/tuition-assistance/program-readonly-field';
import formatDateString from 'tio-common/helpers/format-date-string';
import type ProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';

export interface TuitionAssistanceFormsPreApprovalApplicationReviewSubmittedSignature {
  Args: {
    programInstance: ProgramInstanceModel;
  };
}

export default class TuitionAssistanceFormsPreApprovalApplicationReviewSubmitted extends Component<TuitionAssistanceFormsPreApprovalApplicationReviewSubmittedSignature> {
  programFields = [
    { name: 'SCHOOL_INSTITUTION_NAME', mask: null },
    { name: 'PROGRAM_SPECIALIZATION', mask: null },
    { name: 'ESTIMATED_PROGRAM_BEGIN', mask: 'date' },
    { name: 'ESTIMATED_PROGRAM_COMPLETION', mask: 'date' },
  ];

  get programApprovalRequired() {
    return this.args.programInstance?.tasProgramTemplate.programApprovalRequired;
  }

  <template>
    <div class="flex justify-center my-4">
      {{svgJar "mail-sent" width="212px" height="auto" role="img" desc=(t "svg.mail_sent")}}
    </div>
    <section class="my-10">
      <h1 class="text-4xl mb-6 text-center">
        {{t "tuition_assistance.submitted.application_submitted"}}
      </h1>
      <h3 class="font-semibold mb-4 text-center">
        {{t "tuition_assistance.submitted.what_happens_now"}}
      </h3>

      <div class="grid md:grid-cols-6 mx-8">
        <ul class="md:col-start-3 md:col-span-4 list-disc text-sm md:text-base">
          {{#if this.programApprovalRequired}}
            <li class="my-1.5">
              {{t "tuition_assistance.submitted.your_application_will_be_reviewed"}}
            </li>
            <li class="my-1.5">
              {{t "tuition_assistance.submitted.you_can_review_your_application"}}
            </li>
            <li class="my-1.5">
              {{t "tuition_assistance.submitted.your_application_may_have_multiple_reviewers"}}
            </li>
            <li class="my-1.5">
              {{t "tuition_assistance.submitted.we_will_notify_you"}}
            </li>
          {{else if (not @programInstance.tasProgramTemplate.isTrackingOnlyProgram)}}
            <li class="my-1.5 font-semibold">
              {{t "tuition_assistance.submitted.your_next_step"}}
            </li>
          {{/if}}
          {{#each this.programFields as |programField|}}
            <ProgramReadonlyField
              {{! @glint-expect-error: need better type on field }}
              @name={{programField.name}}
              @programTemplate={{@programInstance.tasProgramTemplate}}
              @fields={{@programInstance.fields}}
              as |field|
            >
              <li class="my-1.5">
                {{concat "Your " (lowercase field.programLabel) " is "}}
                <span class="font-semibold">
                  {{#if (eq programField.mask "date")}}
                    {{! @glint-expect-error }}
                    {{formatDateString field.value}}
                  {{else}}
                    {{! @glint-expect-error }}
                    {{field.value}}
                  {{/if}}
                </span>
              </li>
            </ProgramReadonlyField>
          {{/each}}
        </ul>
      </div>
      <div class="flex justify-center">
        <Button
          @intent="primary"
          {{on "click" (transitionTo "authenticated.tuition-assistance.programs.instance.index")}}
          class="m-8 w-48"
        >
          {{if this.programApprovalRequired (t "done") (t "next")}}
        </Button>
      </div>
    </section>
  </template>
}
