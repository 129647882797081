import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import Tile from '../tile';
import type IntlService from 'ember-intl/services/intl';
import type SessionContextService from 'tio-employee/services/session-context';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';

export default class DashboardWidgetTuitionAssistance extends Component {
  @service declare tuitionAssistance: TuitionAssistanceService;
  @service declare sessionContext: SessionContextService;
  @service declare intl: IntlService;

  // this eligibility period is for the whole of TA
  get taEligibilityWaitingPeriod() {
    return (
      this.sessionContext.currentRole?.company.companySetting?.tuitionAssistance
        ?.eligibilityWaitingPeriodDays || 0
    );
  }

  get tasParticipant() {
    return this.sessionContext.currentEmployee.tasParticipant;
  }

  get employeeStartDate() {
    return !!this.tasParticipant?.employmentStartDate;
  }

  get disableLink() {
    if (!this.employeeStartDate) return false;

    if (this.taEligibilityWaitingPeriod > 0) {
      return this.ineligibleBasedOnWaitingPeriod;
    }
    return false;
  }

  get ineligibleBasedOnWaitingPeriod() {
    return this.tuitionAssistance.ineligibleBasedOnWaitingPeriod(
      this.taEligibilityWaitingPeriod,
      this.tasParticipant
    );
  }

  get dateEligible() {
    const date = this.tuitionAssistance.waitingPeriodEndDate(
      this.taEligibilityWaitingPeriod,
      this.tasParticipant
    );
    return date
      ? this.intl.formatDate(date, { month: 'short', day: 'numeric', year: 'numeric' })
      : '';
  }

  <template>
    <LinkTo @route="authenticated.tas.dashboard" @disabled={{this.disableLink}}>
      <Tile @headerText={{t "dashboard_tiles.tuition_assistance"}}>
        <:description>
          {{#if this.disableLink}}
            {{t
              "tuition_assistance.program_eligibility_warnings.dashboard_description"
              time=this.taEligibilityWaitingPeriod
              date=this.dateEligible
              htmlSafe=true
            }}
          {{else}}
            {{t "dashboard_tiles.apply_for_assistance" htmlSafe=true}}
          {{/if}}
        </:description>
        <:image>
          {{svgJar "working" width="100%" height="100%" role="img" desc=(t "svg.working")}}
        </:image>
        <:footer>
        </:footer>
      </Tile>
    </LinkTo>
  </template>
}
