import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import type ObservablesService from 'tio-common/services/observables';
import type CableService from 'tio-common/services/cable';
import type SessionContextService from './session-context';
import type RemoteEventLoggerService from 'tio-employee/services/remote-event-logger';
import ENV from '../config/environment';

export type ProviderParam = 'nslds' | 'statement' | 'transaction_history';

export type ObservableSource = keyof typeof OBSERVABLE_PRODUCT_ROUTES;
type BatchStatus = {
  id: string;
  recordAttributes: ObservableDocumentModel;
  transition: { event: string };
};

const SERVICERS = ['nelnet', 'mohela', 'aidvantage', 'edfinancial', 'americaned'];

const OBSERVABLE_PRODUCT_ROUTES = {
  slr: 'authenticated.slr.dashboard',
  pslf: 'authenticated.pslf.dashboard',
  syf: 'authenticated.syf.dashboard',
};

export const getObservableProductRoute = (
  source?: keyof typeof OBSERVABLE_PRODUCT_ROUTES
): string => {
  if (source && source in OBSERVABLE_PRODUCT_ROUTES) {
    return OBSERVABLE_PRODUCT_ROUTES[source];
  }
  return 'authenticated.dashboard';
};

export const getInstitutionMatch = (institutionName = ''): string | undefined => {
  const keyMatch = SERVICERS.find((key) =>
    institutionName.toLowerCase().replace(/\s+/g, '').includes(key)
  );
  return keyMatch;
};

// how long to wait once a document is processed to transition to the confirmation
// page, so that the user can see the progress bar fill and a success message
const CONFIRMATION_TRANSITION_TIMEOUT = 1000;

export default class ObservabilityService extends Service {
  @service declare router: RouterService;
  @service declare sessionContext: SessionContextService;
  @service declare cable: CableService;
  @service declare observables: ObservablesService;
  @service declare remoteEventLogger: RemoteEventLoggerService;

  @tracked subscription: ReturnType<typeof this.cable.consumer>['subscriptions']['create'] | null =
    null;

  @action
  navigateToConfirmation(document: ObservableDocumentModel, source?: string): void {
    this.router.transitionTo('authenticated.observability.confirm', document.id, {
      queryParams: { source },
    });
  }

  @action
  unsubscribeFromBatchStatus(batchId: string): void {
    const channel = 'ObservableDocumentBatchStatusChannel';
    const batch = batchId;
    if (this.subscription) {
      this.subscription.unsubscribe({ channel, batch });
    }
  }

  @action
  subscribeToBatchStatus(batchId: string, source?: string): void {
    const accessToken = this.sessionContext.session.data.authenticated.access_token;
    this.cable.connect(ENV.apiHost, ENV.apiKey, accessToken);

    const received = (msg: BatchStatus) => {
      const id = msg.id;
      const results = this.observables.updateObservableDocument({
        ...msg.recordAttributes,
        id,
      });

      const document = results[0];

      const extractionFailed = ['ExtractionState.FAILED', 'ExtractionState.INVALID'].includes(
        msg.recordAttributes.extractionState
      );

      if (extractionFailed) {
        this.remoteEventLogger.logObservabilityEvent({
          eventName: 'PROCESSING_FAILURE',
          component: 'ObservabilityService',
          document,
        });
      }

      const reflectionSuccessful = msg.transition.event === 'content_reflected';
      const reflectionFailed = msg.transition.event === 'reflection_failed';
      const reflectionTerminal = reflectionSuccessful || reflectionFailed;

      if (reflectionTerminal) {
        this.unsubscribeFromBatchStatus(batchId);

        if (reflectionSuccessful) {
          if (msg.recordAttributes.reflectionState === 'ReflectionState.APPLIED') {
            this.remoteEventLogger.logObservabilityEvent({
              eventName: 'PROCESSING_SUCCESS',
              component: 'ObservabilityService',
              document,
            });
          } else if (msg.recordAttributes.reflectionState === 'ReflectionState.PARTIALLY_APPLIED') {
            this.remoteEventLogger.logObservabilityEvent({
              eventName: 'PROCESSING_PARTIAL_SUCCESS',
              component: 'ObservabilityService',
              document,
            });
          }
        }

        if (reflectionFailed) {
          this.remoteEventLogger.logObservabilityEvent({
            eventName: 'PROCESSING_FAILURE',
            component: 'ObservabilityService',
            document,
          });
        }

        setTimeout(() => {
          this.navigateToConfirmation(document, source);
        }, CONFIRMATION_TRANSITION_TIMEOUT);
      }
    };

    const channel = 'ObservableDocumentBatchStatusChannel';
    const batch = batchId;

    this.subscription = this.cable.consumer.subscriptions.create({ channel, batch }, { received });
  }
}
