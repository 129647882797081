import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import authRegisterPayrollIdSchema from 'tio-common/validation-schema/forms/auth-register-payroll-id-form';
import CompanySettingModel from 'tio-common/models/company-setting';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import errorsForField from 'tio-common/helpers/errors-for-field';
import formatSubdomainRedirectUrl from 'tio-common/utils/format-subdomain-redirect-url';
import FormInput from '@frontile/forms-legacy/components/form-input';
import LoginHere from '../login-here';
import type { IntlService } from 'ember-intl';
import type { NotificationsService } from 'tio-ui/components/notifications';
import type PreRegisterService from '../../../services/pre-register.ts';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';
import type Owner from '@ember/owner';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';

interface AuthRegisterPayrollIdFormComponentSignature {
  Args: {
    settings: CompanySettingModel;
    email: string;
  };
}

export default class AuthRegisterPayrollIdForm extends Component<AuthRegisterPayrollIdFormComponentSignature> {
  @service declare intl: IntlService;
  @service declare store: StoreService;
  @service declare preRegister: PreRegisterService;
  @service declare router: RouterService;
  @service declare notifications: NotificationsService;

  @tracked email = this.emailFromQueryParams;
  @tracked payrollId = '';
  @tracked lastName = '';

  @tracked isLoading = false;

  constructor(owner: Owner, args: AuthRegisterPayrollIdFormComponentSignature['Args']) {
    super(owner, args);
    this.email = args.email;
  }

  get emailFromQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    return decodeURIComponent(urlParams.get('email') || '');
  }

  get company() {
    return this.preRegister.sparseCompany;
  }

  get formModel() {
    return {
      email: this.email,
      payrollId: this.payrollId,
      lastName: this.lastName,
    };
  }

  get validationErrors() {
    try {
      authRegisterPayrollIdSchema?.validateSync?.(this.formModel, { abortEarly: false });
    } catch (err) {
      return err.inner || [];
    }
    return [];
  }

  get registerPageCopy() {
    return (
      this.args.settings.payrollCustomText ||
      this.intl.t('login.register_page_default', { companyName: this.companyName })
    );
  }

  get companyName() {
    return this.args.settings.companyName || '';
  }

  get isDisabled() {
    return !this.email || !this.payrollId || !this.lastName || this.validationErrors.length;
  }

  registerTask = dropTask(async () => {
    const { host } = this.store.adapterFor('application');

    const response = await fetch(`${host}/preregister`, {
      method: 'POST',
      body: JSON.stringify({
        email: this.email,
        company_id: this.company?.id || null,
        payroll_id: this.payrollId,
        last_name: this.lastName,
      }),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': ENV.apiKey,
      },
    });

    if (response.ok) {
      const res = await response.json();
      if (res.sub_domain) {
        const redirectUrl = formatSubdomainRedirectUrl({
          subDomain: res.sub_domain,
          domain: this.preRegister.parseHostname(window.location.hostname).domain,
          email: this.email,
          route: 'register',
        });

        window.location.href = redirectUrl;
      } else {
        this.router.transitionTo('register.confirmation');
      }
    } else {
      const res = await response.json();
      this.notifications.add(res.error || 'An Error Occurred', {
        appearance: 'error',
      });
    }
  });

  @action
  submit(e?: Event) {
    e?.preventDefault();
    this.isLoading = true;
    this.registerTask.perform();
    this.isLoading = false;
  }

  <template>
    <form>
      <h1 class="text-lg mb-6 text-center"> {{this.registerPageCopy}}</h1>
      <div class="mb-10">
        <FormInput
          data-legacy-input
          @label="Payroll ID"
          @value={{this.payrollId}}
          @onInput={{fn (mut this.payrollId)}}
          @errors={{errorsForField "payrollId" schemaErrors=this.validationErrors}}
        />
      </div>
      <div class="mb-10">
        <FormInput
          data-legacy-input
          @label="Last Name"
          @value={{this.lastName}}
          @onInput={{fn (mut this.lastName)}}
          @errors={{errorsForField "lastName" schemaErrors=this.validationErrors}}
        />
      </div>
      <div class="mb-10">
        <FormInput
          data-legacy-input
          @label={{t "login.email"}}
          @value={{this.email}}
          @hint="Please provide your personal email address. This is where you will receive your invite."
          @onInput={{fn (mut this.email)}}
          @type="email"
          @errors={{errorsForField "email" schemaErrors=this.validationErrors}}
        />
      </div>

      <div class="flex flex-col items-center">
        <Button
          @intent="primary"
          @appearance="outlined"
          {{on "click" this.submit}}
          disabled={{this.isDisabled}}
          class="w-36 mb-10"
        >
          {{t "submit"}}
        </Button>
      </div>
      <LoginHere />
    </form>
  </template>
}
