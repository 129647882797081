import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';
import type ObservableDocumentModel from 'tio-common/models/observable-document';

type RouteParams = {
  // route
  observable_document_id: string;
};

export default class AuthenticatedObservabilityDisputeRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;

  async model(params: RouteParams) {
    // fetch loan here for account information section
    const observableDocument = await this.store.findRecord(
      'observable-document',
      params['observable_document_id'],
      { include: 'observable-asset' }
    );

    return observableDocument;
  }

  async afterModel(model: { observableDocument: ObservableDocumentModel }) {
    const { observableDocument } = model;

    const providerTypeDisputable = [
      'ObservableProvider.ACCOUNT_STATEMENT',
      'ObservableProvider.NSLDS',
    ].includes(observableDocument?.provider);

    const subsistenceStateDisputable =
      observableDocument.subsistenceState !== 'SubsistenceState.IMPLICIT';

    if (providerTypeDisputable && subsistenceStateDisputable) {
      this.router.transitionTo(
        'authenticated.observability.documents.show',
        model.observableDocument
      );
    }
  }
}
