import ApplicationAdapter from './application';
import type EmployeeModel from 'tio-common/models/employee';

// TODO: this is dumb typing.
type Options = Partial<{ changelog: Record<string, unknown> }>;

type SelfRegisterFormData = {
  email: string;
  firstName: string;
  lastName: string;
};

/**
 * An extended adapter for the `Employee` model.
 *
 * @memberof adapters
 */
class EmployeeAdapter extends ApplicationAdapter {
  /**
   * @param {string}  employeeId - an ID of an employee record
   *
   * @return {object}
   */
  async getAccountingPaymentContributions(employeeId: string) {
    // TODO: can we just remove this check once all consuming files are TS?
    if (!employeeId) {
      return;
    }
    const url = this.buildURL('employee', employeeId) + '/accounting_payments';
    const response = await this.ajax(url, 'GET');
    return response;
  }

  async getAccountingFuturePayments(employeeId: string) {
    if (!employeeId) {
      return;
    }
    const url = this.buildURL('employee', employeeId) + '/accounting_preview';
    const response = await this.ajax(url, 'GET');
    return response;
  }

  async getAnnualPreview(employeeId: string) {
    if (!employeeId) {
      return;
    }
    const url = this.buildURL('employee', employeeId) + '/accounting_annual_preview';
    const response = await this.ajax(url, 'GET');
    return response;
  }

  async reinstateEmployee(model: EmployeeModel, options: Options = {}) {
    const url = this.buildURL('employees', model.id) + '/reinstate';
    const { changelog } = options;

    await this.ajax(url, 'PATCH', {
      data: { data: { attributes: { _changelog: changelog } } },
    });
    const employee = await model.reload();
    return employee;
  }

  async terminateEmployee(model: EmployeeModel, options: Options = {}) {
    const url = this.buildURL('employees', model.id);
    const { changelog } = options;

    await this.ajax(url, 'DELETE', {
      data: { data: { attributes: { _changelog: changelog } } },
    });
    const employee = await model.reload();
    return employee;
  }

  async selfRegister(data: SelfRegisterFormData) {
    const url = this.buildURL('employees') + '/self-register';

    return await this.ajax(url, 'POST', {
      data,
    });
  }

  async updateOverrides(
    employeeId: string,
    overrides: Record<string, unknown>,
    options: { changelog: string }
  ) {
    const { changelog } = options;

    const url = this.buildURL('employees', employeeId) + '/update-overrides';
    return await this.ajax(url, 'PATCH', {
      data: {
        data: {
          attributes: {
            overrides: [overrides],
            _changelog: changelog,
          },
        },
      },
    });
  }

  async revertOverrides(employeeId: string, overrides: Record<string, unknown>[]) {
    const url = this.buildURL('employees', employeeId) + '/update-overrides';
    return await this.ajax(url, 'PUT', {
      data: {
        data: {
          attributes: {
            overrides: overrides,
          },
        },
      },
    });
  }
}

export default EmployeeAdapter;
