import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { not, and } from 'tio-ui/utilities';
import { object, string } from 'yup';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import type EmailAddressModel from 'tio-common/models/email-address';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormInput from '@frontile/forms-legacy/components/form-input';
import type Store from '@ember-data/store';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import Pencil from 'ember-static-heroicons/components/outline-24/pencil';
import { Divider } from 'tio-ui/components/utilities';
import type MatchParticipantModel from 'tio-common/models/match-participant';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
import type Owner from '@ember/owner';
import { on } from '@ember/modifier';
import { Button } from 'tio-ui/components/buttons';
import Check from 'ember-static-heroicons/components/outline-24/check';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';

const emailValidationSchema = object({
  email: string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email format'),
});

interface SyfDashboardPersonalEmailSignature {
  Args: {
    matchParticipant: MatchParticipantModel;
  };
  Element: HTMLDivElement;
}

class SyfDashboardPersonalEmailComponent extends Component<SyfDashboardPersonalEmailSignature> {
  @service declare partner: PartnerService;
  @service declare store: typeof Store;
  @service declare sessionContext: SessionContextService;
  @tracked emailAddress!: EmailAddressModel;
  @tracked isEditing: boolean = false;
  @tracked submitError = null;
  @tracked originalEmail: string;

  constructor(owner: Owner, args: SyfDashboardPersonalEmailSignature['Args']) {
    super(owner, args);
    this.setEmailAddress();
    this.originalEmail = this.args.matchParticipant?.emailAddress?.email || '';
  }

  get emailValidationError() {
    const schema = emailValidationSchema;

    try {
      schema?.validateSync?.({ email: this.emailAddress.email }, { abortEarly: false });
    } catch (err) {
      return err.inner || [];
    }
    return [];
  }

  get disableSaveEmail() {
    const valueChanged = this.originalEmail !== this.emailAddress.email;
    return !valueChanged || !!this.emailValidationError.length;
  }

  saveEmail = dropTask(async () => {
    if (!this.emailValidationError) {
      return;
    }
    this.submitError = null;
    this.args.matchParticipant.emailAddress = this.emailAddress;

    try {
      await this.args.matchParticipant.emailAddress.save();
      await this.args.matchParticipant.save();
    } catch (e) {
      this.submitError = e;
      console.error(e);
    } finally {
      this.originalEmail = this.emailAddress.email;
      this.toggleEdit();
    }
  });

  @action
  toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  @action
  cancel() {
    this.isEditing = false;
    this.emailAddress.email = this.originalEmail;
  }

  @action
  setEmailAddress() {
    if (this.args.matchParticipant?.emailAddress) {
      // if found, register existing personal email address as tracked var
      this.emailAddress = this.args.matchParticipant.emailAddress;
    } else if (this.sessionContext.user.personalEmail) {
      this.emailAddress = this.sessionContext.user.personalEmail;
    } else {
      // if not, create a draft email address in the store and track
      this.emailAddress = this.store.createRecord('email-address', {
        contactType: 'PERSONAL',
        user: this.sessionContext.user,
        email: '',
        isActive: true,
      });
    }
  }

  <template>
    <AppContentCard @titleClass="uppercase" @title={{t "syf.dashboard.email.header"}} ...attributes>
      <Divider class="my-2" />
      <div class="text-sm mb-4">
        {{t "syf.dashboard.email.content"}}
      </div>
      <div class="flex justify-center lg:justify-start my-2 mx-4">
        {{#if (and (not this.emailAddress.email) (not this.isEditing))}}
          <TioClickableText
            @icon="add"
            @iconLeft={{true}}
            @outlined={{true}}
            @onClick={{this.toggleEdit}}
            @textClass="font-semibold uppercase text-sm text-tio-gray-700"
          >
            {{t "syf.dashboard.email.add_email"}}
          </TioClickableText>
        {{else}}
          <FormInput
            data-legacy-input
            title={{this.emailAddress.email}}
            @value={{this.emailAddress.email}}
            @onInput={{fn (mut this.emailAddress.email)}}
            @containerClass="grow max-w-xl"
            @errors={{errorsForField "email" schemaErrors=this.emailValidationError}}
            disabled={{not this.isEditing}}
            class="text-center"
          />

          {{#if this.isEditing}}
            <div class="flex justify-end">
              <Button
                @appearance="outlined"
                {{on "click" this.cancel}}
                disabled={{this.saveEmail.isRunning}}
                title={{t "cancel"}}
              >
                <XMark class="w-5" />
              </Button>
              <Button
                {{on "click" this.saveEmail.perform}}
                disabled={{this.disableSaveEmail}}
                title={{t "save"}}
              >
                <Check class="w-5" />
              </Button>
            </div>
          {{else}}
            <Button
              @appearance="outlined"
              @intent="primary"
              {{on "click" this.toggleEdit}}
              title={{t "common.edit"}}
              class="mx-1"
            >
              <Pencil class="w-5" />
            </Button>
          {{/if}}
        {{/if}}
      </div>
    </AppContentCard>
  </template>
}

export default SyfDashboardPersonalEmailComponent;
