import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { dropTask } from 'ember-concurrency';
import { eq, and, not, gte } from 'tio-ui/utilities';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { Select } from 'tio-ui/components/forms';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { type TBodyOptions } from 'tio-common/components/table/index';
import Check from 'ember-static-heroicons/components/outline-24/check';
import Component from '@glimmer/component';
import errorsForField from 'tio-common/helpers/errors-for-field';
import ExclamationTriangle from 'ember-static-heroicons/components/outline-24/exclamation-triangle';
import missingInfoLinkedLoanFormSchema from 'tio-common/validation-schema/forms/missing-info-linked-loans';
import Pencil from 'ember-static-heroicons/components/outline-24/pencil';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import safeT from 'tio-common/helpers/safe-t';
import type { ErrorSignature } from 'tio-employee/types/errors';
import type ErrorsSignature from 'tio-employee/types/errors';
import type LoanModel from 'tio-common/models/loan';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type StrategyFinderService from 'tio-common/services/strategy-finder';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';

export interface AccountsLinkedLoansSummaryLoanDetailSignature {
  Args: {
    loan: LoanModel;
    allowOverrideEdits: boolean;
    loanErrors: ErrorsSignature;
    style: 'row' | 'list';
    options?: typeof TBodyOptions;
  };
}

// TODO: Is this something that should be shared?
type LoanTypeOption = string;

// TODO: Is this something that should be shared?
type RepaymentPlanOption = string;

// TODO: Is this something that should be shared?
type InterestRateOption = string;

type Error = {
  loanId: string;
  fields: string[];
};

const Warning = <template>
  <div class="text-red-500 flex items-center gap-2">
    <ExclamationTriangle class="w-4" />
    <p>{{t "pslf_dashboard.actions.dialog.need_more_info"}}</p>
  </div>
</template>;

export default class AccountsLinkedLoansSummaryLoanDetail extends Component<AccountsLinkedLoansSummaryLoanDetailSignature> {
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare strategyFinder: StrategyFinderService;

  @tracked isEditing = false;
  @tracked selectedLoanTypeOption?: LoanTypeOption;
  @tracked selectedRepaymentPlanOption?: RepaymentPlanOption;
  @tracked customInterestRate?: InterestRateOption;
  @tracked loanSummaryErrors?: Record<string, unknown>[];

  get loanErrors() {
    return this.loanSummaryErrors || this.args.loanErrors || [];
  }

  get customLoanType() {
    return this.selectedLoanTypeOption || '';
  }

  get customRepaymentPlan() {
    return this.selectedRepaymentPlanOption || '';
  }

  get allowOverrideEdits() {
    // This dialog is also used for read-only display (for now).
    // If this is false, we don't want to allow for edits.
    return this.args.allowOverrideEdits || false;
  }

  get canEdit() {
    // @ts-expect-error: seems like a bug or are loans using a differnt format?
    return this.allowOverrideEdits && this.loanErrors.length;
  }

  get missingFields() {
    // @ts-expect-error: seems like a bug or are loans using a differnt format?
    const missingFieldArrays = this.loanErrors.map?.(
      // @ts-expect-error: seems like a bug or are loans using a differnt format?
      (loanError: ErrorSignature) => loanError.fields
    );
    return missingFieldArrays.flat();
  }

  get isMissingLoanType() {
    return this.missingFields.includes('loanClass');
  }

  get isMissingInterestRate() {
    return this.missingFields.includes('interestRate');
  }

  get isMissingRepaymentPlan() {
    // TODO: The loan summary needs a complete overhaul. This isn't ideal but will work for now.
    return !this.args.loan.repaymentPlan;
    // return this.missingFields.includes('repaymentPlan');
  }

  get observationOverridesForLoanType() {
    if (!this.customLoanType) {
      return {};
    }

    if (this.customLoanType === 'PLUS') {
      return {
        loanClass: 'DIRECT',
        parentPlus: 'YES',
      };
    }
    return {
      loanClass: this.customLoanType,
    };
  }

  get observationOverridesForInterestRate() {
    if (!this.customInterestRate) {
      return {};
    }

    return {
      interestRate: this.customInterestRate,
    };
  }

  get observationOverridesForRepaymentPlan() {
    if (!this.customRepaymentPlan) {
      return {};
    }

    return {
      repaymentPlan: this.customRepaymentPlan,
    };
  }

  get repaymentPlanOptions() {
    return this.strategyFinder.repaymentPlanOptions;
  }

  get loanTypeOptions() {
    return [
      { label: 'Direct', key: 'DIRECT' },
      { label: 'Federal Family Education Loan (FFEL)', key: 'FFEL' },
      { label: 'Parent PLUS', key: 'PLUS' },
      { label: 'Private', key: 'PRIVATE' },
    ];
  }

  get interestRateOptions() {
    return [
      { label: '2%', key: 2 },
      { label: '3%', key: 3 },
      { label: '4%', key: 4 },
      { label: '5%', key: 5 },
      { label: '6%', key: 6 },
      { label: '7%', key: 7 },
      { label: '8%', key: 8 },
    ];
  }

  get inputModel() {
    return {
      loanType: this.customLoanType || '',
      interestRate: this.customInterestRate || '',
      repaymentPlan: this.customRepaymentPlan || '',
    };
  }

  // Currently these validations do not prevent submit, TODO
  get inputValidationErrors() {
    const schema = missingInfoLinkedLoanFormSchema;
    try {
      schema?.validateSync?.(this.inputModel, { abortEarly: false });
    } catch (err) {
      return err.inner || [];
    }
    return [];
  }

  save = dropTask(async () => {
    const payload = {
      ...this.observationOverridesForLoanType,
      ...this.observationOverridesForInterestRate,
      ...this.observationOverridesForRepaymentPlan,
    };

    await this.store.adapterFor('loan').setCustomObservationData(this.args.loan.id, payload);
    await this.args.loan.reload();
    const newSummary = await this.store
      .adapterFor('user')
      .getLoanSummary(this.sessionContext.user.id);
    this.loanSummaryErrors = newSummary.errors?.filter(
      (error: Error) => error.loanId === this.args.loan.id
    );
    this.isEditing = false;
  });

  @action
  cancel() {
    this.isEditing = false;
    delete this.customInterestRate;
    delete this.selectedLoanTypeOption;
    delete this.selectedRepaymentPlanOption;
    delete this.loanSummaryErrors;
  }

  @action
  toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  <template>
    {{#if (eq @style "row")}}
      <@options.tr class="align-top">
        <@options.td>{{@loan.name}}</@options.td>
        <@options.td>
          {{#if (and this.isEditing this.isMissingRepaymentPlan)}}
            <Select
              @label=""
              @items={{this.repaymentPlanOptions}}
              @onAction={{fn (mut this.selectedRepaymentPlanOption)}}
              @placeholder="Select"
              @isRequired={{true}}
              @errors={{errorsForField "repaymentPlan" schemaErrors=this.inputValidationErrors}}
              disabled={{not this.isEditing}}
            />
          {{else if this.isMissingRepaymentPlan}}
            <Warning />
          {{else}}
            {{safeT @loan.repaymentPlan nestedPath="common.repayment_plans" defaultToBlank=true}}
          {{/if}}
        </@options.td>
        <@options.td>
          {{#if (and this.isEditing this.isMissingLoanType)}}
            <Select
              @label=""
              @items={{this.loanTypeOptions}}
              @onAction={{fn (mut this.selectedLoanTypeOption)}}
              @placeholder="Select"
              @isRequired={{true}}
              @errors={{errorsForField "loanType" schemaErrors=this.inputValidationErrors}}
              @isDisabled={{not this.isEditing}}
            />
          {{else if this.isMissingLoanType}}
            <Warning />
          {{else}}
            {{@loan.loanTypeDisplayName}}
          {{/if}}
        </@options.td>
        <@options.td>
          {{#if (and this.isEditing this.isMissingInterestRate)}}
            <Select
              @label=""
              @items={{this.interestRateOptions}}
              @onAction={{fn (mut this.customInterestRate)}}
              @isRequired={{true}}
              @placeholder="Select"
              @errors={{errorsForField "interestRate" schemaErrors=this.inputValidationErrors}}
              disabled={{not this.isEditing}}
            />
          {{else if this.isMissingInterestRate}}
            <Warning />
          {{else}}
            {{@loan.interestRate}}
          {{/if}}
        </@options.td>
        <@options.td class="p-2 text-right">
          {{#if (gte @loan.currentBalance 0)}}
            {{safeFormatNumber @loan.currentBalance style="currency" currency="USD"}}
          {{/if}}
        </@options.td>
        <@options.td>
          <div class="flex justify-end">
            {{#if this.isEditing}}
              <Button
                {{on "click" this.save.perform}}
                @isRunning={{this.save.isRunning}}
                title={{t "save"}}
                @appearance="outlined"
                @intent="primary"
              >
                <Check class="w-4" />
              </Button>

              <Button
                {{on "click" this.cancel}}
                @isRunning={{this.save.isRunning}}
                title={{t "cancel"}}
                @appearance="outlined"
                @intent="danger"
                class="ml-4"
              >
                <XMark class="w-4" />
              </Button>
            {{else if this.canEdit}}
              <Button
                {{on "click" this.toggleEdit}}
                title={{t "edit"}}
                @appearance="outlined"
                @intent="primary"
              >
                <Pencil class="w-4" />
              </Button>
            {{/if}}
          </div>
        </@options.td>
      </@options.tr>
    {{else if (eq @style "list")}}
      <li class="border rounded-md p-2 bg-white mb-2">
        <div class="grid grid-cols-7 auto-rows-min gap-1 items-start relative">
          <h2 class="col-span-3 text-right font-semibold">
            {{t "pslf_dashboard.actions.dialog.loan_name"}}:
          </h2>
          <p class="col-span-4 truncate">
            {{@loan.name}}
          </p>

          <h2 class="col-span-3 text-right font-semibold">
            {{t "pslf_dashboard.actions.dialog.repayment_plan"}}:
          </h2>
          <p class="col-span-4">
            {{#if (and this.isEditing this.isMissingRepaymentPlan)}}
              <Select
                @label=""
                @items={{this.repaymentPlanOptions}}
                @onAction={{fn (mut this.selectedRepaymentPlanOption)}}
                @placeholder="Select"
                @isRequired={{true}}
                @errors={{errorsForField "repaymentPlan" schemaErrors=this.inputValidationErrors}}
                disabled={{not this.isEditing}}
              />
            {{else if this.isMissingRepaymentPlan}}
              <Warning />
            {{else}}
              {{safeT @loan.repaymentPlan nestedPath="common.repayment_plans" defaultToBlank=true}}
            {{/if}}
          </p>

          <h2 class="col-span-3 text-right font-semibold">
            {{t "pslf_dashboard.actions.dialog.loan_type"}}:
          </h2>
          <p class="col-span-4">
            {{#if (and this.isEditing this.isMissingLoanType)}}
              <Select
                @label=""
                @items={{this.loanTypeOptions}}
                @onAction={{fn (mut this.selectedLoanTypeOption)}}
                @placeholder="Select"
                @isRequired={{true}}
                @errors={{errorsForField "loanType" schemaErrors=this.inputValidationErrors}}
                @isDisabled={{not this.isEditing}}
              />
            {{else if this.isMissingLoanType}}
              <div class="text-red-500">
                <ExclamationTriangle class="w-4" />
                <p>{{t "pslf_dashboard.actions.dialog.need_more_info"}}</p>
              </div>
            {{else}}
              {{@loan.loanTypeDisplayName}}
            {{/if}}
          </p>

          <h2 class="col-span-3 text-right font-semibold">
            {{t "pslf_dashboard.actions.dialog.interest_rate"}}:
          </h2>
          <p class="col-span-4">
            {{#if (and this.isEditing this.isMissingInterestRate)}}
              <Select
                @label=""
                @items={{this.interestRateOptions}}
                @onAction={{fn (mut this.customInterestRate)}}
                @isRequired={{true}}
                @placeholder="Select"
                @errors={{errorsForField "interestRate" schemaErrors=this.inputValidationErrors}}
                disabled={{not this.isEditing}}
              />
            {{else if this.isMissingInterestRate}}
              <Warning />
            {{else}}
              {{@loan.interestRate}}
            {{/if}}
          </p>

          <h2 class="col-span-3 text-right font-semibold">
            {{t "pslf_dashboard.actions.dialog.current_balance"}}:
          </h2>
          <p class="col-span-3">
            {{#if (gte @loan.currentBalance 0)}}
              {{safeFormatNumber @loan.currentBalance style="currency" currency="USD"}}
            {{/if}}
          </p>

          {{#if this.isEditing}}
            <div class="col-span-7 flex justify-center">
              <Button
                {{on "click" this.cancel}}
                disabled={{this.save.isRunning}}
                @appearance="outlined"
                @intent="danger"
                class="m-1 w-32"
              >{{t "cancel"}}
              </Button>
              <Button
                @intent="primary"
                {{on "click" this.save.perform}}
                @isRunning={{this.save.isRunning}}
                class="m-1 w-32"
              >
                {{t "save"}}
              </Button>
            </div>
          {{else if this.canEdit}}
            <div class="col-span-1 place-self-end">
              <Button
                {{on "click" this.toggleEdit}}
                title={{t "edit"}}
                @appearance="outlined"
                @intent="primary"
                class="m-1"
              >
                <Pencil class="w-4" />
              </Button>
            </div>
          {{/if}}
        </div>
      </li>
    {{/if}}
  </template>
}
