import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type PartnerService from 'tio-employee/services/partner';
import type RouterService from '@ember/routing/router-service';

export default class AuthenticatedTasRoute extends Route {
  @service declare router: RouterService;
  @service declare partner: PartnerService;

  async beforeModel() {
    if (this.partner.hasLegacyTuitionAssistance) {
      // removes the current route /tas from the history stack
      return this.router.replaceWith('authenticated.tuition-assistance');
    }
  }
}
