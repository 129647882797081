import { service } from '@ember/service';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import TasApplicationsReport from 'tio-common/components/reporting/tas/applications';
import type SessionContext from 'tio-employee/services/session-context';
import type CompanyModel from 'tio-common/models/company';
import type TasApplicationsController from 'tio-employee/controllers/authenticated/admin/reporting/tas-applications';

export interface ReportingTasApplicationsRouteSignature {
  Args: {
    model: CompanyModel;
    controller: TasApplicationsController;
  };
}

class ReportingTasApplicationsRouteComponent extends Component<ReportingTasApplicationsRouteSignature> {
  @service declare sessionContext: SessionContext;

  <template>
    <TasApplicationsReport
      @returnRoute="authenticated.admin.reporting.index"
      @detailRoute="authenticated.admin.tuition-assistance.applications.show"
      @company={{@model}}
      @returnWithoutModel={{true}}
      @applicationStatus={{@controller.applicationStatus}}
      @applicationCreatedAtStart={{@controller.applicationCreatedAtStart}}
      @coursesEndDate={{@controller.coursesEndDate}}
      @includeCourses={{@controller.includeCourses}}
      @includePayments={{@controller.includePayments}}
      @terminatedEmployees={{@controller.terminatedEmployees}}
      @includeInvoiceDate={{@controller.includeInvoiceDate}}
    />
  </template>
}

export default RouteTemplate(ReportingTasApplicationsRouteComponent);
