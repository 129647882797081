import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { fn } from '@ember/helper';
import { Form, Input } from 'tio-ui/components/forms';
import { object, number, string } from 'yup';
import { Section, VStack } from 'tio-ui/components/layout';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import inputmask from 'tio-common/modifiers/inputmask';
import type Owner from '@ember/owner';
import divide from 'ember-math-helpers/helpers/div';
import { currencyStringToNumber } from 'tio-common/utils/format';

type FormType = {
  email: string;
  reportedSalary: string;
};

interface SyfPostEnrollmentSignature {
  Args: {
    reportedSalary: number;
    emailAddress: string;
    reportedSalaryFieldLabel: string;
    loading: boolean;
    onSubmit: (emailAddress: string, reportedSalary: string) => void;
    onSkip: () => void;
  };
}

class SyfEnrollmentConfirmationComponent extends Component<SyfPostEnrollmentSignature> {
  @tracked emailAddress: string = '';
  @tracked reportedSalary: number = 0;

  @tracked errors: Partial<Record<keyof FormType, string>> = {};

  constructor(owner: Owner, args: SyfPostEnrollmentSignature['Args']) {
    super(owner, args);
    // TODO: still seems like a weird patter. We could bind value to the args,
    // and then the onChange / onSubmit would validate. Thinking about this.
    // Can consumers of form components be responsible for validation? more of
    // a provider pattern
    this.emailAddress = args.emailAddress || '';
    this.reportedSalary = args.reportedSalary || 0;
  }

  @action
  onSubmit(data: FormType, eventType: 'input' | 'submit', _event: Event | SubmitEvent) {
    // This is where doing a change event on the specific input is also useful.
    // We want to show error on submit, but then clear error for specific field when
    // the change it.

    // Errors could also be just an object passed in and no getter is actually needed.
    // This is perfectly valid {{@args.errors.form-element-name}}
    if (eventType === 'submit') {
      let { email, reportedSalary } = data;
      const errors = {
        email: email ? undefined : 'Please enter an email',
        reportedSalary: reportedSalary ? undefined : 'Please enter a salary',
      };

      if (Object.values(errors).some((error) => error)) {
        this.errors = Object.fromEntries(
          Object.entries(errors).filter(([_, value]) => value !== undefined)
        ) as Record<string, string>;
        return;
      }

      this.args.onSubmit(email, reportedSalary);
    }
  }

  @action
  updateSalary(value: string) {
    this.reportedSalary = currencyStringToNumber(value);
  }

  @action
  onSkip() {
    this.args.onSkip();
  }

  formSchema = object().shape({
    reportedSalary: number().nullable(),
    email: string().email().nullable().required(),
  });

  <template>
    {{! template-lint-disable no-bare-strings }}
    <Section>
      <:header>
        We need a few more optional pieces of information to maximize your retirement benefits.
      </:header>
      <:body>
        {{! @glint-expect-error: how do we tell the method a narrow type and work with generic Data}}
        <Form @onChange={{this.onSubmit}} class="md:w-1/2">
          <VStack>
            <Input
              @value="{{divide this.reportedSalary 100}}"
              @onChange={{this.updateSalary}}
              @name="reportedSalary"
              @label={{@reportedSalaryFieldLabel}}
              @description="This information helps us estimate your benefit"
              @errors={{this.errors.reportedSalary}}
              {{inputmask alias="currency" prefix="$" unmaskAsNumber=true digits="2"}}
            />
            <Input
              @value={{this.emailAddress}}
              @onInput={{fn (mut this.emailAddress)}}
              @name="email"
              @type="email"
              @label="Personal Email"
              @description="It is important for us to have a personal email for benefit communications"
              @errors={{this.errors.email}}
            />

            <div class="flex justify-center">
              <Button
                class="m-auto px-8 mb-4"
                type="submit"
                @intent="primary"
                disabled={{if this.emailAddress.length false true}}
              >
                Continue
              </Button>
            </div>
          </VStack>
        </Form>
      </:body>
    </Section>
    {{! template-lint-enable no-bare-strings }}
  </template>
}

export default SyfEnrollmentConfirmationComponent;
