import { action, get } from '@ember/object';
import { array } from '@ember/helper';
import employeeSchema from 'tio-common/validation-schema/models/employee';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { TrackedObject } from 'tracked-built-ins';
import Component from '@glimmer/component';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import type EmployeeModel from 'tio-common/models/employee';
import type PlanModel from 'tio-common/models/plan';
import type RouterService from '@ember/routing/router';
import type Store from '@ember-data/store';
import { and } from 'tio-ui/utilities';
import type Owner from '@ember/owner';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';

export interface EmployeesCreateEmployeeSignature {
  Args: {
    model: EmployeeModel;
  };
}

export default class EmployeesCreateEmployeeComponent extends Component<EmployeesCreateEmployeeSignature> {
  @service declare store: typeof Store;
  @service declare employee: EmployeeModel;
  @service declare router: RouterService;

  customAttributes: TrackedObject;

  constructor(owner: Owner, args: EmployeesCreateEmployeeSignature['Args']) {
    super(owner, args);

    // @ts-expect-error: is this an ED error?
    this.customAttributes = new TrackedObject(this.args.model.customAttributes);
  }

  // TODO: This should be a computed property shared across entire project
  states = [
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Marshall Islands',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virgin Island',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ];

  // this plan model is implemented as a tracked variable here because of the way
  // we handle the new employee belongs_to association with a plan; see for
  // reference the application adapter and the employee serializer - we don't allow
  // simultaneous creation of an employee with a plan association in one request
  // via tio-api in an attempt to ensure that API consumers are safely intentional
  // about this association. see employee creation method below for usage
  // this pattern subject to future examination

  @tracked plan!: PlanModel;
  @tracked hasSubmitted = false;

  // avoiding the `mut` helper here on the recommendation of this linter rule:
  // https://github.com/ember-template-lint/ember-template-lint/blob/master/docs/rule/no-mut-helper.md
  @action
  onSelectPlan(plan: PlanModel) {
    this.plan = plan;
    // this.args.model.plan = plan;
  }

  get formValidationErrors() {
    const schema = employeeSchema;
    const formModel = this.args.model;
    try {
      schema?.validateSync?.(formModel, { abortEarly: false });
    } catch (err) {
      if (!err.inner) {
        console.error(err);
      }
      return err.inner || [];
    }
    return [];
  }
  get isDisabled() {
    return this.formValidationErrors.length ? true : false;
  }
  get birthYears() {
    const currentYear = new Date().getFullYear();
    const years = [];
    let startYear = 1920;

    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.reverse();
  }

  @action
  saveAttr(key: unknown, value: string) {
    // @ts-expect-error: how do we unify tracked object with custom attrs?
    this.customAttributes[key] = value;
  }

  @action
  async makeNewEmployee() {
    if (this.formValidationErrors.length) {
      // TODO: this could probably use some more communicative
      // error handling instead of logging validation results
      console.log(this.formValidationErrors);
      return;
    }

    // create and employee record
    // @ts-expect-error: how do we unify tracked object with custom attrs?
    this.args.model.customAttributes = this.customAttributes;
    const newEmployee = await this.args.model.save();

    // ...then patch plan association if a plan is selected
    if (this.plan) {
      // 2 round trips here if the user specifies a plan; see notes above
      // for explanation
      this.plan.employees.push(newEmployee);
      // see application adapter for `adapterOptions` usage
      await this.plan.save({ adapterOptions: { createHasMany: [newEmployee] } });
    }
    this.router.transitionTo('authenticated.admin.employees.show', newEmployee.id);
  }

  @action
  cancel() {
    this.router.transitionTo('authenticated.admin.employees.index');
  }

  <template>
    <form class="grid grid-cols-3">
      <FormInput
        data-legacy-input
        @label={{t "required_label" label=(t "partner.employee.first_name") htmlSafe=true}}
        @value={{@model.firstName}}
        @onInput={{fn (mut @model.firstName)}}
        @errors={{errorsForField "firstName" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        @type="text"
      />
      <FormInput
        data-legacy-input
        @label={{t "required_label" label=(t "partner.employee.last_name") htmlSafe=true}}
        @value={{@model.lastName}}
        @onInput={{fn (mut @model.lastName)}}
        @errors={{errorsForField "lastName" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        @type="text"
      />
      <FormInput
        data-legacy-input
        @label={{t "required_label" label=(t "partner.employee.payroll_id") htmlSafe=true}}
        @value={{@model.payrollId}}
        @onInput={{fn (mut @model.payrollId)}}
        @errors={{errorsForField "payrollId" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        @type="text"
      />
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.employee_status"}}
        @value={{@model.employeeStatus}}
        @onInput={{fn (mut @model.employeeStatus)}}
        @errors={{errorsForField "employeeStatus" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3"
        @type="text"
      />
      <FormSelect
        data-legacy-input
        @label={{t "partner.employee.employment_type"}}
        @options={{array "" "FULL_TIME" "PART_TIME" "EXEMPT" "NON_EXEMPT"}}
        @selected={{@model.employeeType}}
        @onChange={{fn (mut @model.employeeType)}}
        @errors={{errorsForField "employeeType" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        as |option|
      >
        {{! @glint-expect-error: Would need to pass in options from code}}
        {{option}}
      </FormSelect>
      <FormSelect
        data-legacy-input
        @label={{t "partner.employee.birth_year"}}
        @selected={{@model.birthYear}}
        @options={{this.birthYears}}
        @onChange={{fn (mut @model.birthYear)}}
        @errors={{errorsForField "birthYear" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        as |option|
      >
        {{! @glint-expect-error: Would need to pass in options from code}}
        {{option}}
      </FormSelect>
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.phone_number"}}
        @value={{@model.phoneNumber}}
        @onInput={{fn (mut @model.phoneNumber)}}
        @errors={{errorsForField "phoneNumber" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        type="tel"
      />
      <FormSelect
        data-legacy-input
        @label="Plan"
        @selected={{this.plan}}
        @options={{@model.company.plans}}
        @onChange={{this.onSelectPlan}}
        @errors={{errorsForField "plan" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        as |option|
      >
        {{! @glint-expect-error: Not sure why type is lost here}}
        {{option.name}}
      </FormSelect>
      <hr class="col-span-3 pb-8 mt-8" />
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.address_line1"}}
        @value={{@model.addressLine1}}
        @onInput={{fn (mut @model.addressLine1)}}
        @errors={{errorsForField "addressLine1" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        type="text"
      />
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.address_line2"}}
        @value={{@model.addressLine2}}
        @onInput={{fn (mut @model.addressLine2)}}
        @errors={{errorsForField "addressLine2" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        type="text"
      />
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.city"}}
        @value={{@model.city}}
        @onInput={{fn (mut @model.city)}}
        @errors={{errorsForField "city" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        type="text"
      />
      <FormSelect
        data-legacy-input
        {{! @glint-expect-error: issue in form select}}
        @allowClear={{true}}
        @label={{t "partner.employee.state"}}
        @searchEnabled={{true}}
        @options={{this.states}}
        @selected={{@model.state}}
        @onChange={{fn (mut @model.state)}}
        @errors={{errorsForField "state" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        as |option|
      >
        {{! @glint-expect-error: Not sure why type is lost here}}
        {{option}}
      </FormSelect>
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.postal_code"}}
        @value={{@model.postalCode}}
        @onInput={{fn (mut @model.postalCode)}}
        @errors={{errorsForField "postalCode" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        type="text"
      />
      <FormInput
        data-legacy-input
        @label={{t "required_label" label=(t "partner.employee.email") htmlSafe=true}}
        @value={{@model.email}}
        @onInput={{fn (mut @model.email)}}
        @errors={{errorsForField "email" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3"
        @type="email"
      />
      <FormInput
        data-legacy-input
        @label={{t "common.employment_start_date"}}
        @value={{@model.employmentStartDate}}
        @onInput={{fn (mut @model.employmentStartDate)}}
        @errors={{errorsForField "employmentStartDate" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        @type="date"
      />
      <FormInput
        data-legacy-input
        @label={{t "common.employment_end_date"}}
        @value={{@model.employmentEndDate}}
        @onInput={{fn (mut @model.employmentEndDate)}}
        @errors={{errorsForField "employmentEndDate" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        @type="date"
      />
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.salary"}}
        @value={{@model.salary}}
        @onInput={{fn (mut @model.salary)}}
        @errors={{errorsForField "salary" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3"
        @type="number"
      />
      <FormSelect
        data-legacy-input
        @label={{t "partner.employee.rate_period"}}
        @selected={{@model.ratePeriod}}
        @options={{array "HOURLY" "WEEKLY" "BI_WEEKLY" "MONTHLY" "YEARLY"}}
        @onChange={{fn (mut @model.ratePeriod)}}
        @errors={{errorsForField "ratePeriod" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        as |option|
      >
        {{! @glint-expect-error: Not sure why type is lost here }}
        {{option}}
      </FormSelect>
      <hr class="col-span-3 pb-8 mt-8" />
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.title"}}
        @value={{@model.title}}
        @onInput={{fn (mut @model.title)}}
        @errors={{errorsForField "title" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
      />
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.department"}}
        @value={{@model.department}}
        @onInput={{fn (mut @model.department)}}
        @errors={{errorsForField "department" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
      />
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.cost_center"}}
        @value={{@model.costCenter}}
        @onInput={{fn (mut @model.costCenter)}}
        @errors={{errorsForField "costCenter" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
      />
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.eligibility_start_date"}}
        @value={{@model.eligibilityStartDate}}
        @onInput={{fn (mut @model.eligibilityStartDate)}}
        @errors={{errorsForField "eligibilityStartDate" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        @type="date"
      />
      <FormInput
        data-legacy-input
        @label={{t "partner.employee.eligibility_end_date"}}
        @value={{@model.eligibilityEndDate}}
        @onInput={{fn (mut @model.eligibilityate)}}
        @errors={{errorsForField "eligibilityEndDate" schemaErrors=this.formValidationErrors}}
        @hasSubmitted={{this.hasSubmitted}}
        @containerClass="m-3 col-span-1"
        @type="date"
      />
      <hr class="col-span-3 pb-8 mt-8" />
      {{#each @model.company.companySetting.employeeCustomAttributes as |attribute|}}
        {{#if (and attribute.custom @model.customAttributes)}}
          <FormInput
            data-legacy-input
            @label={{attribute.field}}
            {{! @glint-expect-error: Return to this. Custom attributes need a real type}}
            @value={{get @model.customAttributes attribute.field}}
            @onInput={{fn this.saveAttr attribute.field}}
            @errors={{errorsForField "customData" schemaErrors=this.formValidationErrors}}
            @hasSubmitted={{this.hasSubmitted}}
            @containerClass="m-3 col-span-1"
            @type="text"
          />
        {{/if}}
      {{/each}}
      <div class="flex gap-2 mb-8 col-span-3 justify-end">
        <Button @intent="danger" @appearance="outlined" {{on "click" this.cancel}}>
          {{t "partner.employee.cancel"}}
        </Button>
        <Button @intent="primary" disabled={{this.isDisabled}} {{on "click" this.makeNewEmployee}}>
          {{t "partner.employee.create_employee"}}
        </Button>
      </div>
    </form>
  </template>
}
