import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import AuthLoginRoleSelect from 'tio-employee/components/auth/login/role-select';
import { service } from '@ember/service';
import type SessionContextService from 'tio-employee/services/session-context';

class RoleSelectRoute extends Component {
  @service declare sessionContext: SessionContextService;

  <template>
    <main class="h-screen mx-auto pb-20 sm:pb-0 overflow-y-auto">
      <AuthLoginRoleSelect @onSelect={{this.sessionContext.setCurrentRole}} />
    </main>
  </template>
}

export default RouteTemplate(RoleSelectRoute);
