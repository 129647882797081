import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';

const DashboardWidgetsIncomeDrivenRepaymentComponent: TOC = <template>
  <LinkTo @route="authenticated.idr.dashboard">
    <Tile @headerText={{t "dashboard_tiles.income_driven_repayment"}}>
      <:description>
        {{t "dashboard_tiles.idr_description" htmlSafe=true}}
      </:description>
      <:image>
        {{svgJar "target" width="100%" height="100%" role="img" desc=(t "svg.bullseye")}}
      </:image>
    </Tile>
  </LinkTo>
</template>;

export default DashboardWidgetsIncomeDrivenRepaymentComponent;
