import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { Section, VStack } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import PslfSingleForm from 'tio-employee/components/pslf/dashboard/start-new/single-form';
import RouteTemplate from 'ember-route-template';
import StartNewFormFaqs from 'tio-employee/components/pslf/dashboard/start-new/form-faqs';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type { IntlService } from 'ember-intl';
import type EmployeeModel from 'tio-common/models/employee';
import type PartnerService from 'tio-employee/services/partner';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type RouterService from '@ember/routing/router-service';

export interface S {
  Args: {
    model: {
      inProgressCurrentEmployerForm: PslfFormModel;
      inProgressPriorEmployerForm: PslfFormModel;
      employee: EmployeeModel;
    };
  };
}

class PslfDashboardStartNewRoute extends Component<S> {
  @service declare router: RouterService;
  @service declare partner: PartnerService;
  @service declare intl: IntlService;

  @tracked borrowerFormType = 'currentEmployer';
  @tracked showForm = false;

  @action
  scrollToElement() {
    let element = document.getElementById('form-div');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  @action
  startNewForm() {
    this.showForm = true;
    setTimeout(() => {
      this.scrollToElement;
    }, 2);
  }

  get isPriorSelected() {
    return this.borrowerFormType === 'priorEmployer';
  }

  get showFormTypeRadioGroup() {
    return this.partner.isEnabledPriorEmployerPslf;
  }

  get startNewButtonLabel() {
    return this.intl.t('pslf.start_new.start_my_form');
  }

  <template>
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.pslf.dashboard.index" @label="Dashboard" />
      <b.crumb
        @route="authenticated.pslf.dashboard.start-new"
        @label={{t "pslf.start_new.start_new_form"}}
      />
    </TioPageBreadcrumbs>

    <Section>
      <:header>{{t "pslf.start_new.pslf_form_header"}}</:header>
      <:body>
        <VStack>
          <p>{{t "pslf.start_new.pslf_preamble"}}</p>
          {{#if this.showForm}}
            <div class="w-full max-w-screen-md" id="form-div">
              <PslfSingleForm @employee={{@model.employee}} />
            </div>
          {{else}}
            <Button @intent="primary" {{on "click" this.startNewForm}}>
              {{t "pslf.start_new.start_my_form"}}
            </Button>
          {{/if}}
        </VStack>
      </:body>
    </Section>
    {{#unless this.showForm}}
      <StartNewFormFaqs />
    {{/unless}}
  </template>
}

export default RouteTemplate(PslfDashboardStartNewRoute);
