import { service } from '@ember/service';
import {
  getSingleValueForTasField,
  setSingleValueForTasField,
  type FieldName,
} from 'tio-common/utils/tuition-assistance/fields';
import Route from '@ember/routing/route';
import type { IntlService } from 'ember-intl';
import type Owner from '@ember/owner';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TASCourseModel from 'tio-common/models/tas-course';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';

export default class AuthenticatedTasApplicationsTypeThreeNewRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  // @service declare tuitionAssistance: TuitionAssistanceService;

  constructor(owner: Owner) {
    super(owner);

    // If a user is transitioning away without saving, delete any unsaved records from the store
    this.router.on('routeDidChange', (transition) => {
      // @ts-expect-error: this might be a bug. name doesnt appear on route.
      if (!transition.to.find((route: Route) => route.name === this.routeName)) {
        const courses = this.store.peekAll('tas-course') as TASCourseModel[];
        const unsaved = courses.filter((course) => course.isNew);
        unsaved.forEach((course) => {
          course.rollbackAttributes();
        });
      }
    });
  }

  includes = [
    'tas-courses',
    'tas-courses.tas-assets',
    'tas-program-instance.dependent',
    'tas-program-instance.tas-program-template.tas-groups',
    'tas-program-instance.tas-program-template.conditions.public-assets',
    'tas-program-instance.tas-program-template.tas-field-options',
    'tas-program-instance.employee.person',
    'tas-program-instance.employee.tas-participant',
    'tas-assets',
    'tas-assistances',
    'tags',
  ];

  queryParams = {
    date: {
      refreshModel: false,
    },
  };

  saveDate(date: string, application: TasApplicationModel, lockStatus: string) {
    const field: FieldName =
      lockStatus === 'COURSE_END_DATE' ? 'COURSES_END_DATE' : 'COURSES_BEGIN_DATE';

    setSingleValueForTasField(field, date, application.fields);
  }

  async model({ tas_application_id, date = '' }: { tas_application_id: string; date?: string }) {
    const application = await this.store.findRecord<TasApplicationModel>(
      'tas-application',
      tas_application_id,
      {
        include: this.includes.join(','),
        reload: true,
      }
    );
    // Will need to double check if loading of the tasParticipant is necessary
    // like we have to do in the other routes
    // await this.tuitionAssistance.loadEligibilitiesForCurrentEmployee();
    const programInstance = application.tasProgramInstance;

    // Set fields for application and instance if empty
    if (Object.keys(application.fields).length === 0) {
      const programTemplate = application.tasProgramInstance.tasProgramTemplate;
      application.fields = programTemplate.applicationFieldsHash;
      application.customFields = programTemplate.applicationCustomFields;
      programInstance.customFields = programTemplate.instanceCustomFields;

      //set fields on instance
      programInstance.setInitialEmployeeFields();

      if (programInstance.isDependentProgram) {
        programInstance.setInitialDependentFields();
      }

      const displayName = this.intl.formatDate(Date.now(), {
        month: 'long',
        year: 'numeric',
      });
      setSingleValueForTasField('APPLICATION_NAME', displayName, application.fields);
      // The application id is supposed to be set on the program instance
      setSingleValueForTasField('APPLICATION_ID', programInstance.id, programInstance.fields);

      if (date) {
        const lockStatus: string = getSingleValueForTasField(
          'LOCK_EMPLOYEE_STATUS_BASED_ON',
          programInstance.tasProgramTemplate.fields
        );
        this.saveDate(date, application, lockStatus);
      }

      try {
        await application.save();
        await programInstance.save();
      } catch (error) {
        console.error('Failed to save application or instance:', error);
        application.rollbackAttributes();
        programInstance.rollbackAttributes();
      }
    }

    // Load previously selected school if available
    let selectedSchool: TasUnifiedInstitutionModel | undefined;
    // Load previously selected school if available
    try {
      const tasInstitutionId = programInstance.fields['TAS_INSTITUTION_ID']?.values?.[0];
      const dapipInstitutionId = programInstance.fields['DAPIP_INSTITUTION_ID']?.values?.[0];

      if (tasInstitutionId || dapipInstitutionId) {
        const filter: Record<string, string | number | undefined> = {
          'tas-program-template': programInstance.tasProgramTemplate.id,
        };

        if (dapipInstitutionId) {
          filter['dapip-id'] = dapipInstitutionId;
        }

        if (tasInstitutionId) {
          filter['institution-id'] = tasInstitutionId;
        }
        const results = await this.store.query<TasUnifiedInstitutionModel[]>(
          'tas-unified-institution',
          {
            filter,
            page: { limit: 1, offset: 0 },
          }
        );
        selectedSchool = results[0];
      }
    } catch (e) {
      console.error('Error attempting to load previously selected school', e);
    }

    return {
      application: application,
      programInstance: programInstance,
      selectedSchool: selectedSchool,
    };
  }
}
