import { action } from '@ember/object';
import { and, eq, gte, or } from 'tio-ui/utilities';
import { concat } from '@ember/helper';
import { fn } from '@ember/helper';
import { Footer, Modal } from 'tio-ui/components/modal';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ForgivenessEstimatorResultInstructions from './result-instructions';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import TioDownloadButton from 'tio-common/components/tio/download-button';
import TioPdfPrintButton from 'tio-common/components/tio/pdf/print-button';
import type IntlService from 'ember-intl/services/intl';
import type RecommendationModel from 'tio-common/models/recommendation';
import type StrategyFinderService from 'tio-common/services/strategy-finder';
import { Section } from 'tio-ui/components/layout';

export interface FEResultsDashboardTableSignature {
  Args: {
    programType: string;
    recommendation: RecommendationModel;
  };
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

export default class ResultsDashboardTable extends Component<FEResultsDashboardTableSignature> {
  @service declare intl: IntlService;
  @service declare strategyFinder: StrategyFinderService;

  @tracked toggleDialog = false;

  get estimatedMonthlyPayment() {
    return this.args.recommendation?.estimatedMonthlyPayment;
  }

  get estimatedForgivenessAmount() {
    return this.args.recommendation?.estimatedForgivenessAmount;
  }

  get estimatedForgivenessDate() {
    return this.args.recommendation?.estimatedForgivenessDate;
  }

  get currentPlanResults() {
    return this.args.recommendation?.formattedCurrentPlanSummary;
  }

  get bestPlanResults() {
    return this.args.recommendation?.formattedBestPlanSummary;
  }

  get workflowRoute() {
    return this.args.programType === 'pslf'
      ? 'authenticated.pslf.dashboard.forgiveness-estimator'
      : this.args.programType === 'sf'
        ? 'authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire'
        : 'authenticated.idr.dashboard.forgiveness-estimator';
  }

  get verticalHeaderRows() {
    return [
      {
        title: this.intl.t('recommendations_estimator.estimated_total_to_pay'),
        dataKey: 'payoffAmount',
        formatter: 'formatMoney',
      },
      {
        title: this.intl.t('recommendations_estimator.years_left_to_repay'),
        dataKey: 'termMonths',
        formatter: 'formatTermMonths',
      },
      {
        title: this.intl.t('recommendations_estimator.monthly_payment'),
        dataKey: 'monthlyPayments',
        formatter: 'formatMoney',
        asterisk: true,
      },
      {
        title: this.intl.t('recommendations_estimator.estimated_total_interest_paid'),
        dataKey: 'totalInterestPaid',
        formatter: 'formatMoney',
      },
      {
        title: this.intl.t('recommendations_estimator.estimated_amount_forgiven'),
        subtitle: this.intl.t(
          'recommendations_estimator.estimated_amount_forgiven_includes_interest'
        ),
        dataKey: 'remLoanBalance',
        formatter: 'formatMoney',
      },
    ];
  }

  get horizontalHeaderColumns() {
    switch (this.args.programType) {
      case 'pslf':
        return [
          { title: this.intl.t('recommendations_estimator.best_plan') },
          { title: this.intl.t('recommendations_estimator.current_plan') },
        ];
      case 'idr':
        return [
          { title: this.intl.t('idr.abbreviated') },
          { title: this.intl.t('recommendations_estimator.current_plan') },
        ];
      case 'sf':
        return [
          { title: this.intl.t('common.strategy_finder.default') },
          { title: this.intl.t('recommendations_estimator.current_plan') },
        ];
      default:
        return [];
    }
  }

  get tableRowData() {
    return this.verticalHeaderRows.map((row) => {
      // @ts-expect-error: need real types
      const col1Value = this.bestPlanResults?.[row.dataKey];
      // @ts-expect-error: need real types
      const col2Value = this.currentPlanResults?.[row.dataKey];

      return {
        title: row.title,
        subtitle: row.subtitle,
        col1Data: this.formatData(row.formatter, col1Value),
        col2Data: this.formatData(row.formatter, col2Value),
        asterisk: row.asterisk,
      };
    });
  }

  @action
  formatData(formatFunctionName: string, data: unknown) {
    // @ts-expect-error: I have no idea
    return this[formatFunctionName]?.(data) || data;
  }

  @action
  formatMoney(cents = []) {
    return this.strategyFinder.formatCentsRange(cents);
  }

  @action
  formatTermMonths(months = []) {
    return this.strategyFinder.formatMonthsRange(months);
  }

  <template>
    <Section>
      <:header>{{t "pslf_dashboard.results.default"}}</:header>
      <:body>
        <div class="grid grid-cols-7 gap-4">
          {{#if (eq @programType "idr")}}
            <p class="col-span-7">{{t "idr.results.description" htmlSafe=true}}</p>
          {{/if}}
          <div
            class="col-span-7
              {{if (or (eq @programType 'idr') (eq @programType 'sf')) 'md:col-span-4'}}"
          >
            {{#if @recommendation.hasErrorsInCalcs}}
              <div class="m-2">
                {{t "pslf_dashboard.results.error"}}
                <LinkTo @route={{this.workflowRoute}} class="tio-copy">
                  {{#if (eq @programType "sf")}}
                    {{t "common.strategy_finder.default"}}
                  {{else}}
                    {{t "recommendations_estimator.forgiveness_estimator"}}
                  {{/if}}
                </LinkTo>
                {{concat ". " (t "pslf_dashboard.results.thank_you")}}
              </div>
            {{else}}
              {{#if (eq @programType "idr")}}
                <div class="font-semibold my-6">
                  <p>
                    {{t "idr.results.estimated_monthly_payment"}}
                    <span class="ml-1 text-midnight">
                      {{#if (gte this.estimatedMonthlyPayment 0)}}
                        {{safeFormatNumber
                          this.estimatedMonthlyPayment
                          style="currency"
                          currency="USD"
                          maximumFractionDigits=0
                        }}
                      {{/if}}
                    </span>
                  </p>
                  <p>
                    {{t "idr.results.estimated_loan_forgiveness"}}
                    <span class="ml-1 text-midnight">
                      {{#if (gte this.estimatedForgivenessAmount 0)}}
                        {{safeFormatNumber
                          this.estimatedForgivenessAmount
                          style="currency"
                          currency="USD"
                          maximumFractionDigits=0
                        }}
                      {{/if}}
                    </span>
                  </p>
                </div>
              {{else}}
                <p class="mb-6">{{t "pslf_dashboard.results.description"}}</p>
                <div class="font-semibold mb-6">
                  <p class="mb-1">
                    {{t "pslf_dashboard.results.estimated_monthly_payment_is"}}
                    <span class="ml-1 text-midnight">
                      {{#if (gte this.estimatedMonthlyPayment 0)}}
                        {{safeFormatNumber
                          this.estimatedMonthlyPayment
                          style="currency"
                          currency="USD"
                        }}*
                      {{/if}}
                    </span>
                  </p>
                  <p class="mb-1">
                    {{t "pslf_dashboard.results.estimated_loan_forgiveness_amount_is"}}
                    <span class="ml-1 text-midnight">
                      {{#if (gte this.estimatedForgivenessAmount 0)}}
                        {{safeFormatNumber
                          this.estimatedForgivenessAmount
                          style="currency"
                          currency="USD"
                        }}
                      {{/if}}
                    </span>
                  </p>
                  <p class="mb-1">
                    {{t "pslf_dashboard.results.estimated_loan_forgiveness_date_is"}}
                    <span class="ml-1 text-midnight">
                      {{this.estimatedForgivenessDate}}
                    </span>
                  </p>
                </div>
              {{/if}}

              <table class="pslf-dashboard-results-table">
                <thead>
                  <tr>
                    <th></th>
                    {{#each this.horizontalHeaderColumns as |column|}}
                      <th
                        scope="col"
                        class="text-center even:bg-ocean-600 even:text-white p-4"
                      >{{column.title}}</th>
                    {{/each}}
                  </tr>
                </thead>
                <tbody>
                  {{#each this.tableRowData as |row|}}
                    <tr>
                      <td class="text-left align-top text-gray-700 p-4">
                        {{row.title}}
                        {{#if row.subtitle}}
                          <div class="text-xs font-normal">{{row.subtitle}}</div>
                        {{/if}}
                      </td>
                      <td class="text-center align-top bg-ocean-50 p-4">{{row.col1Data}}
                        {{#if (and row.asterisk (eq @programType "pslf"))}}
                          <span>*</span>
                        {{/if}}
                      </td>
                      <td class="text-center align-top p-4">
                        {{row.col2Data}}
                      </td>
                    </tr>
                  {{/each}}
                </tbody>
              </table>
              <button
                type="button"
                {{on "click" (fn (mut this.toggleDialog) true)}}
                class="tio-copy my-4"
              >
                {{t "recommendations_estimator.step_by_step_instructions"}}
              </button>
              <div class="flex gap-5">
                <TioPdfPrintButton
                  @url={{@recommendation.downloadUrl}}
                  @filename={{@recommendation.downloadFilename}}
                  class="w-full max-w-48"
                />
                <TioDownloadButton
                  @url={{@recommendation.downloadUrl}}
                  @filename={{@recommendation.downloadFilename}}
                  class="w-full max-w-48"
                />
              </div>
            {{/if}}
          </div>
          {{yield}}
        </div>
      </:body>
    </Section>

    <Modal
      @isOpen={{this.toggleDialog}}
      @onClose={{fn (mut this.toggleDialog) false}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>{{t "recommendations_estimator.step_by_step_instructions"}}</m.Header>
      <m.Body>
        <ForgivenessEstimatorResultInstructions @recommendation={{@recommendation}} />
      </m.Body>
      <m.Footer>
        <Footer @isRunning={{false}} @onSubmit={{fn (mut this.toggleDialog) false}} />
      </m.Footer>
    </Modal>
  </template>
}
