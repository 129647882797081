import QuestionsLoanForgiveness from 'tio-employee/components/questions/loan-forgiveness';
import RouteTemplate from 'ember-route-template';

export default RouteTemplate(
  <template>
    <div>
      <QuestionsLoanForgiveness
        @indexRoute="authenticated.questions-you-have.loan-forgiveness.index"
        @incomeRoute="authenticated.questions-you-have.loan-forgiveness.income-driven"
        @teacherRoute="authenticated.questions-you-have.loan-forgiveness.teacher"
        @stateRoute="authenticated.questions-you-have.loan-forgiveness.state-and-local"
      >
        {{outlet}}
      </QuestionsLoanForgiveness>
    </div>
  </template>
);
