import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';

interface TasApplicationShowRoute {
  application: TasApplicationModel;
  dynamicEligibility: boolean;
  programEligibility: string[];
}

export default class AuthenticatedTasApplicationsShowRoute extends Route {
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  includes = [
    'tas-courses',
    'tas-courses.tas-assets',
    'tas-program-instance',
    'tas-program-instance.tas-program-template',
    'tas-program-instance.tas-program-template.conditions.public-assets',
    'tas-program-instance.tas-program-template.tas-field-options',
    'tas-program-instance.employee.person',
    'tas-program-instance.employee.tas-participant',
    'tas-assets',
    'tas-assistances',
    'tags',
  ];

  queryParams = {
    applicationSubmitted: { refreshModel: true },
  };

  async model({ tas_application_id }: { tas_application_id: string }) {
    const application = await this.store.findRecord('tas-application', tas_application_id, {
      include: this.includes.join(','),
      reload: true,
    });

    await this.tuitionAssistance.loadEligibilitiesForCurrentEmployee();

    await application.loadHistory();

    const { id } = application.tasProgramInstance.employee.tasParticipant;
    const { code } = application.tasProgramInstance.tasProgramTemplate;

    const dynamicEligibility = await this.store
      .adapterFor('tas-participant')
      .dynamicEligibility(id, code);

    const programEligibility = await this.store
      .adapterFor('tas-participant')
      .periodOfEligibility(id, code);

    return { application, dynamicEligibility, programEligibility };
  }

  afterModel(model: TasApplicationShowRoute) {
    const { state } = model.application;
    const type = model.application.tasProgramInstance.tasProgramTemplate.typeClassification;

    // Mapping of types and states to routes
    const routeMappings: Record<string, string> = {
      'TAS.ProgramType.2-TAS.ApplicationState.ATTEND':
        'authenticated.tas.applications.type-two.new',
      'TAS.ProgramType.3-TAS.ApplicationState.DEFINE_COURSES':
        'authenticated.tas.applications.type-three.new',
      'TAS.ProgramType.4.2-TAS.ApplicationState.ATTEND':
        'authenticated.tas.applications.type-four.new',
      'TAS.ProgramType.4.3-TAS.ApplicationState.DEFINE_COURSES':
        'authenticated.tas.applications.type-four.new',
    };

    // Create a key from type and state
    const key = `${type}-${state}`;

    // Transition to the appropriate route if it matches, otherwise allow /show route to render
    const route = routeMappings[key];
    if (route) {
      this.router.transitionTo(route, model.application.id);
    }
  }
}
