import { action } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { Button } from 'tio-ui/components/buttons';
import { courseBadgeState } from 'tio-employee/utils/tas/applicationStateMap';
import { Divider } from 'tio-ui/components/utilities';
import { toLocaleDateString } from 'tio-common/utils/format';
import {
  getAllValuesForTasField,
  getSingleValueForTasField,
  setSingleValueForTasField,
} from 'tio-common/utils/tuition-assistance/fields';
import { fn } from '@ember/helper';
import { Footer, Modal } from 'tio-ui/components/modal';
import { Input, Select } from 'tio-ui/components/forms';
import { inputsForFields } from 'tio-common/utils/data/inputs-for-fields';
import { LinkTo } from '@ember/routing';
import { not, or, and } from 'tio-ui/utilities';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { VStack, HStack, Section, Header } from 'tio-ui/components/layout';
import AcceptConditions from 'tio-employee/components/tuition-assistance/forms/accept-conditions';
import ActionableList from 'tio-ui/components/actionable-list';
import Alert from 'tio-employee/components/tas/applications/alert';
import TasFaqs from 'tio-common/components/tas/faqs';
import TioAlert from 'tio-common/components/tio/alert';
import ApplicationProcess from 'tio-employee/components/tuition-assistance/application/application-process';
import AttachmentList from 'tio-common/components/tuition-assistance/forms/upload-attachments/attachment-list';
import Badge from 'tio-ui/components/badge';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import CheckCircleSolid from 'ember-static-heroicons/components/solid-24/check-circle';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import ClipboardDocumentList from 'ember-static-heroicons/components/outline-24/clipboard-document-list';
import Component from '@glimmer/component';
import CourseSubmitted from 'tio-employee/components/tas/applications/course-submitted';
import DescriptionList from 'tio-ui/components/description-list';
import divide from 'ember-math-helpers/helpers/div';
import NavTabs from 'tio-ui/components/nav-tabs';
import PaymentDetailsCard from 'tio-common/components/tas/payment-details-card';
import PencilSquare from 'ember-static-heroicons/components/outline-24/pencil-square';
import ProgramCustomReadonlyField from 'tio-common/components/tuition-assistance/program-custom-readonly-field';
import ProgramReadonlyField from 'tio-common/components/tuition-assistance/program-readonly-field';
import RouteTemplate from 'ember-route-template';
import SafeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import ShowCourseDrawer from 'tio-employee/components/tas/show-course-drawer';
import Table from 'tio-common/components/table/index';
import TasApplicationsShowController from 'tio-employee/controllers/authenticated/tas/applications/show';
import TASCourseModel from 'tio-common/models/tas-course';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import Trash from 'ember-static-heroicons/components/outline-24/trash';
import type { Intent } from 'tio-ui/utilities';
import type { TransitionLogSignature } from 'tio-common/types/tuition-assistance';
import type ConditionModel from 'tio-common/models/condition';
import type Owner from '@ember/owner';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TASProgramTemplateModel from 'tio-common/models/tas-program-template';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import UploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';

const string = String;

interface RouteSignature {
  Args: {
    model: {
      application: TasApplicationModel;
      dynamicEligibility: boolean;
      programEligibility: [string, string][];
    };
    controller: TasApplicationsShowController;
  };
}

type LockEmployeeStatusBasedOnType =
  | 'COURSE_START_DATE'
  | 'COURSE_END_DATE'
  | 'FULFILLED_DATE'
  | 'ON_COURSES_SUBMISSION'
  | 'ON_SUBMISSION';

class AuthenticatedTasApplicationsShowComponent extends Component<RouteSignature> {
  @tracked acceptedConditions: ConditionModel[] = [];
  @tracked submitError = null;
  @tracked hasSubmitted = false;
  @tracked isGradeModalOpen = false;
  @tracked courseToEdit: null | TASCourseModel = null;
  @tracked isCourseDrawerOpen = false;
  @tracked courseShowing: null | TASCourseModel = null;
  @tracked deleteApplicationOpen = false;
  @tracked gpaBasedAmount = 0;

  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare sessionContext: SessionContextService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  courseApprovalStates = [
    'TAS.ApplicationState.PENDING_COURSES_APPROVAL',
    'TAS.ApplicationState.COURSES_APPROVED',
    'TAS.ApplicationState.COURSES_NOT_APPROVED',
    'TAS.ApplicationState.COURSES_REJECTED',
  ];

  programTemplate: TASProgramTemplateModel;

  constructor(owner: Owner, args: RouteSignature['Args']) {
    super(owner, args);
    this.programTemplate = args.model.application.tasProgramInstance.tasProgramTemplate;

    if (this.showGPAField) {
      this.setGPA(
        getSingleValueForTasField('GRADE_POINT_AVERAGE', args.model.application.fields) || '0'
      );
    }
  }

  get typeClassification() {
    return this.programTemplate.typeClassification;
  }

  get applicationApprovalAttachments() {
    return this.args.model.application.applicationApprovalAssets || [];
  }

  get evidenceAttachments() {
    return this.args.model.application.evidenceAssets || [];
  }

  get applicationAttachments() {
    return this.courseApprovalStates.includes(this.args.model.application.state)
      ? []
      : [...this.applicationApprovalAttachments];
  }

  get attachmentsListDescription() {
    return (
      getAllValuesForTasField(
        'COMPLETION_DOCUMENTS_ATTACHMENT_FIELD',
        this.programTemplate.fields
      ).join(', ') || 'None Required'
    );
  }

  get coursesApprovalAttachments() {
    return (
      getAllValuesForTasField(
        'COURSE_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD',
        this.programTemplate.fields
      ).join(', ') || 'None Required'
    );
  }

  get requireAttachmentUploads() {
    const typeClassificationsToCheck = [
      'TAS.ProgramType.3',
      'TAS.ProgramType.4',
      'TAS.ProgramType.4.3',
    ];
    if (typeClassificationsToCheck.includes(this.typeClassification)) {
      return this.programTemplate.requireAttachmentsForCourseApproval;
    }
    return false;
  }

  get conditionsForSubmit() {
    return this.programTemplate.applicationApproveEvidenceConditions || [];
  }

  get showSubmitForEvidenceReview() {
    return this.args.model.application.state === 'TAS.ApplicationState.ATTEND';
  }

  get setRequestedTotalAmount() {
    return Math.min(this.gpaBasedAmount, this.args.model.application.requestedTotal);
  }

  submitForEvidenceApproval = dropTask(async () => {
    this.submitError = null;
    this.hasSubmitted = true;
    try {
      await this.saveAgreementsForConditions.perform();
      await this.store
        .adapterFor('tas-application')
        .requestCourseEvidence(this.args.model.application);

      const tasType = this.typeClassification === 'TAS.ProgramType.3' ? 'three' : 'four';
      this.router.transitionTo('authenticated.tas.confirmation', {
        queryParams: {
          tasType: tasType,
          applicationId: this.args.model.application.id,
          evidence: 'true',
        },
      });
    } catch (error) {
      console.error('Failed to submit application:', error);
      this.submitError = error;
    }
  });

  saveAgreementsForConditions = dropTask(async () => {
    const user = this.sessionContext.user;
    const agreements = this.acceptedConditions.map((condition) => {
      return this.store.createRecord('agreement', {
        user: user,
        condition: condition,
      });
    });
    const promises = agreements.map((agreement) => {
      return agreement.save();
    });
    const saved = await all(promises);
    return saved;
  });

  get hasAgreedAllConditions() {
    if (!this.conditionsForSubmit.length) {
      return true;
    }
    return this.conditionsForSubmit.length === this.acceptedConditions.length;
  }

  get hasRequiredAttachments() {
    return this.args.model.application.hasRequiredEvidenceAttachments;
  }

  get canSubmitApplication() {
    if (this.args.model.application.ignorePolicies) {
      return true;
    }
    const { isCourseGradeRequired, isMissingCourseGrades, state } = this.args.model.application;

    const sharedConditions =
      this.isActiveParticipant &&
      this.hasAgreedAllConditions &&
      this.hasRequiredAttachments &&
      !this.inEligible &&
      state === 'TAS.ApplicationState.ATTEND' &&
      this.hasGpaIfRequired; // TAS.TODO: rework when updating validation

    return isCourseGradeRequired ? sharedConditions && !isMissingCourseGrades : sharedConditions;
  }

  get hasGpaIfRequired(): boolean {
    // checks if the GPA field is required
    if (!this.showGPAField) {
      return true;
    }

    const hasField = Boolean(
      getSingleValueForTasField('GRADE_POINT_AVERAGE', this.args.model.application.fields)
    );

    return hasField;
  }

  get expenseType(): string {
    const expenseType = getSingleValueForTasField('EXPENSE_TYPE', this.programTemplate.fields);

    return expenseType ? `${expenseType}` : `Education`;
  }

  get courseBadge() {
    return courseBadgeState(this.args.model.application.state) as Record<string, Intent>;
  }

  get showInstructions() {
    const typeClassificationsToHide = [
      'TAS.ProgramType.3',
      'TAS.ProgramType.4',
      'TAS.ProgramType.4.3',
    ];
    return (
      typeClassificationsToHide.includes(this.typeClassification) &&
      this.args.model.application.state === 'TAS.ApplicationState.ATTEND'
    );
  }

  get grades() {
    const exclusiveGradeOptions = getAllValuesForTasField(
      'EXCLUSIVE_ACADEMIC_PERFORMANCE_OPTIONS',
      this.programTemplate.fields
    ) as string[];
    if (exclusiveGradeOptions.length > 0) {
      return exclusiveGradeOptions;
    }

    return inputsForFields['COURSE_GRADE'].options.map((option) => option.value);
  }

  get selectedGrade() {
    const grade = getSingleValueForTasField('COURSE_GRADE', this.courseToEdit!.fields) as string;
    return grade ? [grade] : [];
  }

  get programCustomFields() {
    const customFields = this.args.model.application.tasProgramInstance.customFields;
    return customFields.filter((field: { province: string }) => field.province === 'PROGRAM');
  }

  get isTypeThreeApplication() {
    return this.typeClassification === 'TAS.ProgramType.3';
  }

  get showAddResult() {
    return this.args.model.application.state === 'TAS.ApplicationState.ATTEND';
  }

  get showCompletedGrade() {
    const showGradeStates = [
      'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL',
      'TAS.ApplicationState.EVIDENCE_APPROVED',
      'TAS.ApplicationState.EVIDENCE_REJECTED',
      'TAS.ApplicationState.FULFILLED',
      'TAS.ApplicationState.ABANDONED',
    ];
    return showGradeStates.includes(this.args.model.application.state);
  }

  get applicationAwaitingApproval() {
    const awaitingStates = [
      'TAS.ApplicationState.PENDING_COURSES_APPROVAL',
      'TAS.ApplicationState.COURSES_APPROVED',
      'TAS.ApplicationState.COURSES_NOT_APPROVED',
      'TAS.ApplicationState.COURSES_REJECTED',
    ];
    return awaitingStates.includes(this.args.model.application.state);
  }

  get assetClassificationString() {
    return this.courseApprovalStates.includes(this.args.model.application.state)
      ? 'COURSE_APPROVAL_DOCUMENTS'
      : 'COMPLETION_DOCUMENTS';
  }

  get showDependentInfo() {
    const typeClassificationsToCheck = ['TAS.ProgramType.2', 'TAS.ProgramType.3'];
    return (
      this.programTemplate.isDependentProgram &&
      typeClassificationsToCheck.includes(this.typeClassification)
    );
  }

  get lockEmployeeStatusBasedOn() {
    return this.programTemplate.lockEmployeeStatusBasedOn as LockEmployeeStatusBasedOnType;
  }

  get lockStatus() {
    const lockType: Record<LockEmployeeStatusBasedOnType, string> = {
      COURSE_START_DATE: 'start',
      COURSE_END_DATE: 'end',
      FULFILLED_DATE: 'fulfilled',
      ON_COURSES_SUBMISSION: 'submission',
      ON_SUBMISSION: 'submission',
    };
    return this.lockEmployeeStatusBasedOn in lockType
      ? lockType[this.lockEmployeeStatusBasedOn]
      : '';
  }

  get startOrEndDate(): string {
    if (this.lockStatus === 'start') {
      return getSingleValueForTasField('COURSES_BEGIN_DATE', this.args.model.application.fields);
    }
    if (this.lockStatus === 'end') {
      return getSingleValueForTasField('COURSES_END_DATE', this.args.model.application.fields);
    }
    return new Date().toLocaleDateString('en-CA');
  }

  calculateEligibility() {
    const { programEligibility } = this.args.model;
    if (!programEligibility.length) {
      return false;
    }
    // Use today's date for 'submission' lock status, otherwise use the provided start or end date.
    const enteredDate = new Date(this.startOrEndDate);

    return programEligibility.some(([start, end]) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return enteredDate >= startDate && enteredDate <= endDate;
    });
  }

  get inEligible() {
    const { application, dynamicEligibility } = this.args.model;
    const { ignorePolicies, state } = application;

    if (ignorePolicies || !this.lockStatus) return false;

    const completedStates = [
      'TAS.ApplicationState.FULFILLED',
      'TAS.ApplicationState.ABANDONED',
      'TAS.ApplicationState.REJECTED_PER_POLICY',
    ];
    if (completedStates.includes(state)) return false;
    if (['fulfilled', 'submission'].includes(this.lockStatus)) return false;

    return !(dynamicEligibility && this.calculateEligibility());
  }

  get requestedChanges() {
    const findNotApprovedDetails = (transitions: TransitionLogSignature[]) => {
      // Filter transitions for "NOT_APPROVED" states
      const notApprovedStates = transitions.filter((transition: TransitionLogSignature) =>
        transition.to.includes('NOT_APPROVED')
      );

      // If there are no "NOT_APPROVED" states, return null or an empty result
      if (notApprovedStates.length === 0) {
        return null;
      }

      // Get the last occurrence based on the timestamp
      const lastNotApproved = notApprovedStates.reduce((latest, current) =>
        new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest
      );

      // Extract the relevant details
      return {
        rejectedBy: lastNotApproved.reason?.by?.name || 'Unknown',
        note: lastNotApproved.reason?.note || 'No note provided',
      };
    };
    // spreeeaaad operator
    return { ...findNotApprovedDetails(this.args.model.application.transitionLog) };
  }

  get changesNeeded() {
    const lastTransition = this.args.model.application.transitionLog.slice(-1)[0];
    return lastTransition?.from.includes('NOT_APPROVED') || false;
  }

  get calculateTotalByGPA() {
    return this.programTemplate.calculateTotalByGPA;
  }

  get showGPAField() {
    return (
      this.calculateTotalByGPA &&
      (this.typeClassification === 'TAS.ProgramType.3' ||
        this.typeClassification === 'TAS.ProgramType.4.3')
    );
  }

  get requestedTotal() {
    if (this.showGPAField) {
      return this.setRequestedTotalAmount;
    } else {
      return this.args.model.application.requestedTotal;
    }
  }

  get isPaid() {
    return this.args.model.application.isPaid;
  }

  get isActiveParticipant() {
    return (
      this.args.model.application.tasProgramInstance.employee?.tasParticipant?.isActive || false
    );
  }

  @action
  async setGPA(value: string) {
    const gpa = Number(value);
    setSingleValueForTasField('GRADE_POINT_AVERAGE', gpa, this.args.model.application.fields);
    this.gpaBasedAmount = this.tuitionAssistance.setTotalBasedOnGPA(gpa);
    setSingleValueForTasField(
      'FINAL_APPROVED_TOTAL',
      this.setRequestedTotalAmount,
      this.args.model.application.fields
    );

    await this.args.model.application.save();
  }

  @action
  closeApplicationSubmittedModal() {
    this.args.controller.applicationSubmitted = false;
  }

  @action
  didUpdateAcceptedConditions(conditions: ConditionModel[] = []) {
    this.acceptedConditions = conditions;
  }

  @action
  openGradeModal(course: TASCourseModel) {
    this.isGradeModalOpen = true;
    this.courseToEdit = course;
  }

  @action
  closeGradeModal() {
    this.isGradeModalOpen = false;
    this.courseToEdit = null;
  }

  @action
  onSelectionChange(key: string[]) {
    setSingleValueForTasField('COURSE_GRADE', key[0]!, this.courseToEdit!.fields);
    this.courseToEdit!.save();
  }

  @action
  openCourseDrawer(course: TASCourseModel) {
    this.courseShowing = course;
    this.isCourseDrawerOpen = true;
  }

  @action
  closeCourseDrawer() {
    this.courseShowing = null;
    this.isCourseDrawerOpen = false;
  }

  @action
  toggleDeleteApplicationModal() {
    this.deleteApplicationOpen = !this.deleteApplicationOpen;
  }

  deleteApplication = dropTask(async () => {
    try {
      if (this.isPaid) {
        this.toggleDeleteApplicationModal();
        return;
      } else {
        if (['TAS.ProgramType.2', 'TAS.ProgramType.3'].includes(this.typeClassification)) {
          await Promise.all([
            this.store.adapterFor('tas-application').abandonCourse(this.args.model.application),
            this.store
              .adapterFor('tas-program-instance')
              .abandonProgram(this.args.model.application.tasProgramInstance),
          ]);
        }
        await this.store.adapterFor('tas-application').abandonCourse(this.args.model.application);
      }
    } catch (error) {
      console.error('Failed to delete application:', error);
    }
    // TAS.TODO: temporary routing until tas dashboard is complete
    this.router.transitionTo('authenticated.tas.dashboard');
  });

  <template>
    <VStack>
      <Header>
        <div>
          {{! TAS.TODO: temporary routing until tas dashboard is complete }}
          <LinkTo class="flex justify-left items-center gap-4" @route="authenticated.tas.dashboard">
            <ChevronLeft class="w-5 inline" />
            {{t "tas.default"}}
          </LinkTo>
        </div>
      </Header>
      <HStack>
        <VStack class="!w-full">
          <NavTabs @model={{@model.application}} class="w-full mb-4" as |navtabs|>
            <navtabs.item
              @route="authenticated.tas.applications.show"
              @model={{@model.application}}
            >
              {{t "tas.applications.default"}}
            </navtabs.item>
            <navtabs.item
              @route="authenticated.tas.applications.history"
              @model={{@model.application}}
            >
              {{t "tas.history.default"}}
            </navtabs.item>
          </NavTabs>
        </VStack>
        <VStack class="!w-16 !self-end">
          <Button
            @intent="danger"
            @appearance="outlined"
            {{on "click" this.toggleDeleteApplicationModal}}
          >
            <Trash class="w-5 inline" />
          </Button>
        </VStack>
      </HStack>
      <HStack>
        <VStack class="lg:!w-2/3 md:!w-1/2">
          <Section>
            <:header>{{string @model.application.displayNameOrSemesterCode}}</:header>
            <:body>
              {{#if this.inEligible}}
                <TioAlert @type="error" @allowDismiss={{false}} class="mb-4">
                  <:header>
                    <p class="font-bold" data-test-eligibility-error>
                      {{t "start_date_eligibility.errorHeader" startEndSubmission=this.lockStatus}}
                    </p>
                  </:header>
                  <:body>
                    <p class="text-sm">
                      {{t "start_date_eligibility.errorText"}}
                    </p>
                  </:body>
                </TioAlert>
              {{/if}}
              {{#if this.changesNeeded}}
                <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
                  <:header>
                    <p class="font-bold" data-test-requested-changes>
                      {{t
                        "tas.application.requested_changes"
                        name=this.requestedChanges.rejectedBy
                      }}
                    </p>
                  </:header>
                  <:body>
                    <p class="text-sm">
                      {{this.requestedChanges.note}}
                    </p>
                  </:body>
                </TioAlert>
              {{/if}}

              {{#unless this.isActiveParticipant}}
                <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
                  <:header>
                    <p class="font-bold">
                      {{t "tuition_assistance.inactive_participant_warnings.dashboard_title"}}
                    </p>
                  </:header>
                  <:body>
                    <p class="text-sm">
                      {{t "tuition_assistance.inactive_participant_warnings.dashboard_description"}}
                    </p>
                  </:body>
                </TioAlert>
              {{/unless}}
              <Alert @application={{@model.application}} />
              <h2 class="font-medium text-tio-gray-700 my-4">
                {{@model.application.tasProgramInstance.programName}}
              </h2>
              {{! APPLICANT TABLE }}
              <Table @isLoading={{false}}>
                <:thead as |options|>
                  <options.tr>
                    <options.th>
                      {{t "tas.applications.applicant"}}
                    </options.th>
                    <options.th>{{t "tas.applications.amount"}}</options.th>
                    <options.th>{{t "tas.applications.submission_date"}}</options.th>
                    <options.th>{{t "tas.applications.status"}}</options.th>
                  </options.tr>
                </:thead>
                <:tbody as |options|>
                  <options.tr>
                    <options.td>{{@model.application.applicant}}</options.td>
                    <options.td>{{safeFormatNumber
                        (divide this.requestedTotal 100)
                        style="currency"
                        currency="USD"
                      }}</options.td>
                    <options.td>{{toLocaleDateString
                        @model.application.courseSubmittedDate
                      }}</options.td>
                    <options.td>
                      {{#if @model.application.inRepayment}}
                        <Badge @intent="warning">
                          {{t "tuition_assistance.application_details.in_repayment"}}
                        </Badge>
                      {{else}}
                        <Badge @intent={{@model.application.badgeIntent}}>
                          {{@model.application.badgeLabel}}
                        </Badge>
                      {{/if}}
                    </options.td>
                  </options.tr>
                </:tbody>
              </Table>
            </:body>
          </Section>
          <Section>
            <:header>{{t "tas.applications.details"}}</:header>
            <:body>
              {{! APPLICATION DETAILS }}
              <DescriptionList class="w-2/3 md:w-full" as |List|>
                <List.Group>
                  <List.Term>{{t "tas.applications.application_name"}}</List.Term>
                  <List.Details>{{@model.application.applicationName}}</List.Details>
                </List.Group>
                {{#if this.showDependentInfo}}
                  <List.Group>
                    <List.Term>{{t "tas.applications.dependent"}}</List.Term>
                    <List.Details
                    >{{@model.application.tasProgramInstance.dependentName}}</List.Details>
                  </List.Group>
                {{/if}}
                <List.Group>
                  <List.Term>{{t "tas.applications.start_date"}}</List.Term>
                  <List.Details>
                    {{toLocaleDateString @model.application.coursesBeginDate}}
                  </List.Details>
                </List.Group>
                {{#if this.isTypeThreeApplication}}
                  <ProgramReadonlyField
                    @name="SCHOOL_INSTITUTION_NAME"
                    @programTemplate={{this.programTemplate}}
                    @fields={{@model.application.tasProgramInstance.fields}}
                    as |field|
                  >
                    <List.Group>
                      <List.Term>{{field.programLabel}}</List.Term>
                      {{! @glint-expect-error: field.value is null or record }}
                      <List.Details>{{field.value}}</List.Details>
                    </List.Group>
                  </ProgramReadonlyField>
                  <ProgramReadonlyField
                    @name="PROGRAM_MAJOR"
                    @programTemplate={{this.programTemplate}}
                    @fields={{@model.application.tasProgramInstance.fields}}
                    as |field|
                  >
                    <List.Group>
                      <List.Term>{{field.programLabel}}</List.Term>
                      {{! @glint-expect-error: field.value is null or record }}
                      <List.Details>{{field.value}}</List.Details>
                    </List.Group>
                  </ProgramReadonlyField>
                  <ProgramReadonlyField
                    @name="PROGRAM_SPECIALIZATION"
                    @programTemplate={{this.programTemplate}}
                    @fields={{@model.application.tasProgramInstance.fields}}
                    as |field|
                  >
                    <List.Group>
                      <List.Term>{{field.programLabel}}</List.Term>
                      {{! @glint-expect-error: field.value is null or record }}
                      <List.Details>{{field.value}}</List.Details>
                    </List.Group>
                  </ProgramReadonlyField>
                  <ProgramReadonlyField
                    @name="ESTIMATED_PROGRAM_BEGIN"
                    @programTemplate={{this.programTemplate}}
                    @fields={{@model.application.tasProgramInstance.fields}}
                    as |field|
                  >
                    <List.Group>
                      <List.Term>{{field.programLabel}}</List.Term>
                      {{! @glint-expect-error: field.value is null or record }}
                      <List.Details>{{SafeFormatDate field.value}}</List.Details>
                    </List.Group>
                  </ProgramReadonlyField>
                  <ProgramReadonlyField
                    @name="PROGRAM_SPECIALIZATION"
                    @programTemplate={{this.programTemplate}}
                    @fields={{@model.application.tasProgramInstance.fields}}
                    as |field|
                  >
                    <List.Group>
                      <List.Term>{{field.programLabel}}</List.Term>
                      {{! @glint-expect-error: field.value is null or record }}
                      <List.Details>{{field.value}}</List.Details>
                    </List.Group>
                  </ProgramReadonlyField>
                  <ProgramReadonlyField
                    @name="ESTIMATED_PROGRAM_COMPLETION"
                    @programTemplate={{this.programTemplate}}
                    @fields={{@model.application.tasProgramInstance.fields}}
                    as |field|
                  >
                    <List.Group>
                      <List.Term>{{field.programLabel}}</List.Term>
                      {{! @glint-expect-error: field.value is null or record }}
                      <List.Details>{{SafeFormatDate field.value}}</List.Details>
                    </List.Group>
                  </ProgramReadonlyField>
                  <ProgramReadonlyField
                    @name="ENROLLMENT_OBJECTIVE"
                    @programTemplate={{@model.application.programInstance.tasProgramTemplate}}
                    @fields={{@model.application.tasProgramInstance.fields}}
                    as |field|
                  >
                    <List.Group>
                      <List.Term>{{field.programLabel}}</List.Term>
                      {{! @glint-expect-error: field.value is null or record }}
                      <List.Details>{{field.value}}</List.Details>
                    </List.Group>
                  </ProgramReadonlyField>
                  {{#each this.programCustomFields as |field|}}
                    <ProgramCustomReadonlyField @customField={{field}} as |field|>
                      <List.Group>
                        {{! @glint-expect-error: field.value is null or record }}
                        <List.Term>{{field.programLabel}}</List.Term>
                        {{! @glint-expect-error: field.value is null or record }}
                        <List.Details>{{field.value}}</List.Details>
                      </List.Group>
                    </ProgramCustomReadonlyField>
                  {{/each}}
                {{/if}}
              </DescriptionList>
            </:body>
          </Section>
          {{! COURSE DETAILS - EXPENSES }}
          <Section>
            <:header>{{this.expenseType}}</:header>
            <:body>
              <ActionableList @striped={{true}} as |l|>
                {{#each @model.application.activeCourses as |course|}}
                  <l.Row class="w-full">
                    <l.Term
                      class="w-96 cursor-pointer text-ocean-600 underline"
                      {{on "click" (fn this.openCourseDrawer course)}}
                    >
                      {{course.courseName}}
                    </l.Term>
                    <l.Item
                      class="w-96 cursor-pointer"
                      {{on "click" (fn this.openCourseDrawer course)}}
                    >
                      {{safeFormatNumber
                        (divide course.courseTotal 100)
                        style="currency"
                        currency="USD"
                      }}
                    </l.Item>
                    {{#if this.applicationAwaitingApproval}}
                      <l.Item
                        class="ml-0 mr-2 flex items-center font-semibold text-ocean-600 cursor-pointer md:ml-auto"
                      >
                        <Badge @intent={{this.courseBadge.intent}}>
                          {{this.courseBadge.label}}
                        </Badge>
                      </l.Item>
                    {{else if this.showAddResult}}
                      <l.Item
                        class="ml-0 mr-2 flex items-center font-semibold text-ocean-600 cursor-pointer md:ml-auto"
                        {{on "click" (fn this.openGradeModal course)}}
                      >
                        {{#if course.courseGrade}}
                          {{course.courseGrade}}
                          <PencilSquare class="ml-2 h-6 w-6 text-tio-gray-700" />
                        {{else}}
                          {{t "tas.benefits_summary.add_result_simple"}}
                        {{/if}}
                      </l.Item>
                    {{else if this.showCompletedGrade}}
                      <l.Item
                        class="ml-0 mr-2 flex items-center font-semibold text-ocean-600 md:ml-auto"
                      >
                        {{course.courseGrade}}
                      </l.Item>
                    {{/if}}
                  </l.Row>
                {{/each}}
              </ActionableList>
            </:body>
          </Section>
          {{#if (and this.programTemplate.scholarshipsAreVisible @model.application.scholarships)}}
            <Section>
              <:header>
                {{t "tas.new.scholarships.default"}}
              </:header>
              <:body>
                {{! SCHOLARSHIPS }}
                <ActionableList @striped={{true}} as |l|>
                  {{#each @model.application.scholarships as |scholarship|}}
                    <l.Row class="w-full">
                      <l.Term class="w-96">{{scholarship.scholarshipName}}</l.Term>
                      <l.Item class="w-96">
                        {{safeFormatNumber
                          (divide scholarship.scholarshipAmount 100)
                          style="currency"
                          currency="USD"
                        }}
                      </l.Item>
                    </l.Row>
                  {{/each}}
                </ActionableList>
              </:body>
            </Section>
          {{/if}}

          {{! ATTACHMENTS }}
          <Section>
            <:header>
              <div>{{t "attachments"}}</div>
              <div class="font-normal">
                {{t "tas.application.type_two.new.attachment_description"}}
                {{#if this.requireAttachmentUploads}}
                  <span class="text-error-400 text-xl">*</span>
                {{/if}}
              </div>
            </:header>
            <:body>
              <UploadAttachments
                @programTemplate={{this.programTemplate}}
                @classification={{this.assetClassificationString}}
                @attachable={{@model.application}}
                @attachableType="tasApplication"
                @useDropzone={{true}}
                @hideInstructions={{not this.showInstructions}}
                @hideAttachments={{false}}
                @disabled={{false}}
              />
              <div class="flex items-center w-full">
                <AttachmentList
                  @assets={{this.applicationAttachments}}
                  @disabled={{true}}
                  class="w-full"
                />
              </div>
            </:body>
          </Section>
        </VStack>
        {{! Evidence Submission and Payment Details }}
        <VStack class="lg:!w-1/3 md:!w-1/2">
          {{! Submit For Evidence Review - ASIDE }}
          {{#if this.showSubmitForEvidenceReview}}
            <Section
              class="w-full"
              @headerClass="bg-tio-gray-100 flex text-sm items-center gap-2 px-5 py-2 font-medium tracking-wide"
            >
              <:header>
                <ClipboardDocumentList class="h-8 w-8" />
                <p>{{t "tas.application.submit_evidence_for_review"}}</p>
              </:header>
              <:body>
                <section>
                  {{#if this.requireAttachmentUploads}}
                    <div>
                      <div class="flex flex-row justify-between items-center">
                        <p class="font-medium">{{t "tas.benefits_summary.attachments"}}
                          <span class="text-error-400 ml-1">
                            *
                          </span>
                        </p>
                        <div>
                          <UploadAttachments
                            @programTemplate={{this.programTemplate}}
                            @classification="COMPLETION_DOCUMENTS"
                            @attachable={{@model.application}}
                            @attachableType="tasApplication"
                            @useDropzone={{false}}
                            @iconOnlyUpload={{true}}
                            @hideAttachments={{true}}
                            @hideInstructions={{true}}
                          />
                        </div>
                      </div>

                      <div class="flex items-center gap-3">
                        {{#if @model.application.isMissingRequiredEvidence}}
                          <CheckCircle class="h-6 w-6 text-tio-gray-200 shrink-0" />
                        {{else}}
                          <CheckCircleSolid class="h-6 w-6 text-violet-500 shrink-0" />
                        {{/if}}
                        <p>{{this.attachmentsListDescription}}</p>
                      </div>
                    </div>
                    <Divider class="mt-4" />
                  {{/if}}

                  {{#if this.conditionsForSubmit.length}}
                    <AcceptConditions
                      @conditions={{this.conditionsForSubmit}}
                      @onChange={{this.didUpdateAcceptedConditions}}
                    />
                  {{/if}}
                </section>

                <div class="py-4">
                  <Button
                    class="flex justify-center w-full py-3 px-6 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg hover:bg-ocean-400"
                    disabled={{not this.canSubmitApplication}}
                    @isRunning={{this.submitForEvidenceApproval.isRunning}}
                    {{on "click" this.submitForEvidenceApproval.perform}}
                  >
                    {{t "tas.benefits_summary.submit_evidence"}}
                  </Button>
                  <p class="text-sm font-light text-center">
                    {{t "tas.benefits_summary.helper_text"}}
                  </p>
                  {{#if this.submitError}}
                    <TioErrorMessages
                      @showErrors={{this.hasSubmitted}}
                      @error={{this.submitError}}
                      class="text-sm"
                      @icon="warning"
                    />
                  {{/if}}
                </div>
              </:body>
            </Section>
          {{/if}}
          <PaymentDetailsCard
            @isPaid={{@model.application.isPaid}}
            @requestedTotal={{this.requestedTotal}}
            @payments={{@model.application.paymentsHistory}}
          />

          <ApplicationProcess
            @application={{@model.application}}
            @programTemplate={{this.programTemplate}}
          />
          <TasFaqs @template={{this.programTemplate}} />
        </VStack>
      </HStack>
    </VStack>

    <Modal
      @isOpen={{@controller.applicationSubmitted}}
      @onClose={{this.closeApplicationSubmittedModal}}
      class="min-h-64 !max-w-screen-sm"
      @backdrop="faded"
      as |m|
    >
      <m.Header class="text-midnight font-semibold">
        {{t "tuition_assistance.submitted.application_submitted"}}</m.Header>
      <m.Body>
        <CourseSubmitted @application={{@model.application}} @courseApprovalRequired={{true}} />
        <div class="mb-4 flex justify-center">
          <Button
            {{on "click" this.closeApplicationSubmittedModal}}
            @appearance="outlined"
            class="w-48"
          >
            {{t "common.close"}}
          </Button>
        </div>
      </m.Body>
    </Modal>
    <Modal
      @isOpen={{this.isGradeModalOpen}}
      @onClose={{this.closeGradeModal}}
      class="!max-w-screen-sm"
      @backdrop="faded"
      as |m|
    >
      <m.Header>
        <h1 class="text-midnight font-semibold">{{t "tas.benefits_summary.add_result"}}</h1>
      </m.Header>
      <m.Body>
        <div class="flex justify-between mb-6">
          <h2 class="text-lg font-semibold text-gray-700">
            {{string (getSingleValueForTasField "COURSE_NAME" this.courseToEdit.fields)}}
          </h2>
          <div class="w-72">
            <Select
              @items={{this.grades}}
              @selectedKeys={{this.selectedGrade}}
              @onSelectionChange={{this.onSelectionChange}}
            >
              <:item as |o|>
                <o.Item @key={{o.item}} @intent="default" @appearance="faded">
                  {{o.item}}
                </o.Item>
              </:item>
            </Select>
          </div>
        </div>
        {{#if this.showGPAField}}
          <div class="flex flex-wrap justify-between gap-y-4 items-center">
            <label
              for="grade-point-average"
              class="font-semibold block mb-1 text-balance text-gray-600"
            >
              {{t "tas.application.grade_point_average"}}
              <span class="text-error-400">*</span>
            </label>
            <Input
              size="lg"
              type="number"
              min="0"
              max="4.0"
              step="0.01"
              data-test-grade-point-average
              id="grade-point-average"
              @value={{string
                (or (getSingleValueForTasField "GRADE_POINT_AVERAGE" @model.application.fields) 0)
              }}
              class="!bg-transparent !w-1/2"
              @onChange={{this.setGPA}}
            />
          </div>
        {{/if}}
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onCancel={{this.closeGradeModal}}
          @onSubmit={{this.closeGradeModal}}
        />
      </m.Footer>
    </Modal>
    <Modal
      @isOpen={{this.deleteApplicationOpen}}
      @onClose={{this.toggleDeleteApplicationModal}}
      class="!max-w-screen-sm"
      @backdrop="faded"
      as |m|
    >
      {{#if this.isPaid}}
        <m.Body>
          <p class="font-bold text-red-800 mb-4">
            {{t "tas.application.delete_modal.attention"}}
          </p>

          <p class="text-red-700">
            {{t "tas.application.delete_modal.prepaid_warning"}}
          </p>
        </m.Body>
        <m.Footer>
          <Footer
            @onSubmit={{this.toggleDeleteApplicationModal}}
            @submitText={{t "common.close"}}
          />
        </m.Footer>
      {{else}}
        <m.Header>{{t "tas.application.delete_modal.title"}}</m.Header>
        <m.Body>
          <p class="mb-4">{{t "tas.application.delete_modal.content"}}</p>
        </m.Body>
        <m.Footer>
          <Footer
            @isRunning={{this.deleteApplication.isRunning}}
            @onCancel={{this.toggleDeleteApplicationModal}}
            @onSubmit={{this.deleteApplication.perform}}
            @submitText={{t "common.delete"}}
          />
        </m.Footer>
      {{/if}}
    </Modal>
    {{#if this.isCourseDrawerOpen}}
      <ShowCourseDrawer
        @isOpen={{this.isCourseDrawerOpen}}
        @onClose={{this.closeCourseDrawer}}
        @course={{this.courseShowing}}
        @programTemplate={{this.programTemplate}}
        {{! @glint-ignore: To reuse this for all courses course needs to be set to null on close }}
        @header={{this.courseShowing.courseName}}
      />
    {{/if}}
  </template>
}

export default RouteTemplate(AuthenticatedTasApplicationsShowComponent);
