import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';

interface ApplicationData {
  application_approved_amount_to_be_paid: number;
  application_created_at: string;
  application_id: string;
  application_name: string;
  assistance_state: string;
  employee_name: string;
  evidence_approved_date: string;
  paid_amount: number;
  paid_date: string | null;
  payment_state: string;
  payroll_id: string;
  program_instance_id: string;
  program_name: string | null;
  program_specialization: string;
  program_template_type: string;
  school: string;
  state: string;
}

export type GroupedData = {
  application_id: string;
  application_state: string;
  assistance_state: string;
  paid_date: string | null;
  payment_state: string;
  program_instance_id: string;
  total_approved_amount: number;
  total_paid_amount: number;
  items: ApplicationData[];
};

type Params = { paymentYear: string };

const groupData = (data: ApplicationData[]) => {
  return data.reduce(
    (acc, curr) => {
      const key = `${curr.application_id}-${curr.state}-${curr.assistance_state}`;

      if (!acc[key]) {
        acc[key] = {
          application_id: curr.application_id,
          application_state: curr.state,
          assistance_state: curr.assistance_state,
          paid_date: curr.paid_date,
          payment_state: curr.payment_state,
          program_instance_id: curr.program_instance_id,
          total_approved_amount: 0,
          total_paid_amount: 0,
          items: [],
        };
      }

      acc[key]!.total_approved_amount = curr.application_approved_amount_to_be_paid;
      acc[key]!.total_paid_amount += curr.paid_amount;
      acc[key]!.items.push(curr);

      return acc;
    },
    {} as Record<string, GroupedData>
  );
};

export default class AuthenticatedTuitionAssistanceMyPaymentDetailsRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  queryParams = {
    paymentYear: {
      refreshModel: true,
      replace: true,
    },
  };

  get currentCompanyId() {
    return this.sessionContext.currentEmployee.company.id;
  }

  get currentEmployeeId() {
    return this.sessionContext.currentEmployee.id;
  }

  async model(params: Params) {
    const { paymentYear } = params;

    const queryParameters = [
      'report-type=applications',
      `filter[include_payments]=true`,
      `filter[employee_id]=${this.currentEmployeeId}`,
      paymentYear && `filter[payment_year]=${paymentYear}`,
      `filter[include_program_template_type]=true`,
    ]
      .filter(Boolean)
      .join('&');

    const records: ApplicationData[] = await this.store
      .adapterFor('reporting')
      .tasPayments(this.currentCompanyId, queryParameters);

    const groupedData = groupData(records);

    return Object.values(groupedData);
  }
}
