import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { eq } from 'tio-ui/utilities';
import { on } from '@ember/modifier';
import { Section, VStack } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { Textarea } from '@ember/component';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import TioAlert from 'tio-common/components/tio/alert';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type IntlService from 'ember-intl/services/intl';
import type Owner from '@ember/owner';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type SessionService from 'tio-employee/services/session';

export interface WhatNextComponentSignature {
  Args: {
    model: PslfFormModel;
  };
}

export default class WhatNextComponent extends Component<WhatNextComponentSignature> {
  @service declare intl: IntlService;
  @service declare session: SessionService;

  @tracked textInput = '';
  @tracked dictionaryKey = '';
  @tracked keyIndex = 0;
  @tracked clipboardError = '';

  constructor(owner: Owner, args: WhatNextComponentSignature['Args']) {
    super(owner, args);
    this.decideDictionaryKey();
  }

  get studentLoanCoachRoute() {
    return '/repaying-student-debt/askjeni';
  }

  get companyName() {
    let name;
    const form = this.args.model;
    const employerName = form.employerDisplayName;
    switch (this.dictionaryKey) {
      case 'currentEmployer':
        name = employerName;
        break;
      case 'priorCertifyEmployment':
        name = employerName;
        break;
      case 'priorSendFormYourself':
        name = employerName.length == 0 ? 'your former employer' : employerName;
        break;
      case 'currentSendFormYourself':
        name = employerName.length == 0 ? 'your employer' : employerName;
        break;
      default:
        break;
    }

    return name || '';
  }

  get form() {
    return this.args.model;
  }

  get formAttachment() {
    // @ts-expect-error: nothing in this code sets this.attachments. Is this copy / paste leftover?
    if (!this.form || !this.attachments?.length) {
      return null;
    }

    return (
      this.form.attachments.find((attachment) => {
        return attachment.tags.includes(`document_id:${this.form?.documentId}`);
      }) || null
    );
  }

  get formDownloadUrl() {
    return this.formAttachment?.signedUrl || '';
  }

  get showDownloadButton() {
    return !!this.formDownloadUrl && this.isMailInForm;
  }

  get isMailInForm() {
    return ['priorSendFormYourself', 'currentSendFormYourself'].includes(this.dictionaryKey);
  }

  get keys() {
    const { companyName } = this;
    const dictionary = {
      currentEmployer: {
        title: 'pslf.what_next.what_next',
        description: this.intl.t('pslf.what_next.current_description', {
          companyName,
        }),
        iconText: 'pslf.what_next.check_your_email',
        bodyHeading: this.intl.t('pslf.what_next.current_body_heading'),
        bodyDescription: this.intl.t('pslf.what_next.current_body_description', {
          companyName,
        }),
        bulletMsg: 'pslf.what_next.current_bullet_msg',
        bulletpoints: [
          'pslf.what_next.current_bulletpoint_1',
          'pslf.what_next.current_bulletpoint_2',
          'pslf.what_next.current_bulletpoint_3',
        ],
      },
      priorCertifyEmployment: {
        title: 'pslf.what_next.what_next',
        description: this.intl.t('pslf.what_next.prior_certify_description', {
          companyName,
        }),
        iconText: 'pslf.what_next.check_your_email',
        bodyHeading: this.intl.t('pslf.what_next.prior_certify_body_heading', {
          companyName,
        }),
        bodyDescription: this.intl.t('pslf.what_next.prior_certify_body_description'),
        bulletMsg: 'pslf.what_next.prior_certify_bullet_msg',
        bulletpoints: [
          'pslf.what_next.prior_certify_bulletpoint_1',
          'pslf.what_next.prior_certify_bulletpoint_2',
          'pslf.what_next.prior_certify_bulletpoint_3',
        ],
      },
      priorClosed: {
        title: 'pslf.what_next.what_next',
        description: this.intl.t('pslf.what_next.prior_closed_description'),
        iconText: 'pslf.what_next.prior_closed_icon_text',
        bodyHeading: this.intl.t('pslf.what_next.prior_closed_body_heading'),
        bodyDescription: this.intl.t('pslf.what_next.prior_closed_body_description'),
        bulletMsg: 'pslf.what_next.prior_certify_bullet_msg',
        bulletpoints: [
          'pslf.what_next.prior_closed_bulletpoint_1',
          'pslf.what_next.prior_closed_bulletpoint_2',
        ],
        bulletPointLink: this.studentLoanCoachRoute,
        bulletPointLinkText: 'pslf.what_next.prior_closed_bulletpoint_link_text',
        bulletpointDescriptions: [
          'pslf.what_next.prior_closed_bullet_desc_1',
          'pslf.what_next.prior_closed_bullet_desc_2',
          'pslf.what_next.prior_closed_bullet_desc_3',
        ],
      },
      currentSendFormYourself: {
        title: 'pslf.what_next.what_next',
        description: this.intl.t('pslf.what_next.send_description', {
          companyName,
        }),
        iconText: 'pslf.what_next.send_icon_text',
        bodyHeading: this.intl.t('pslf.what_next.send_body_heading', {
          companyName,
        }),
        bulletMsg: 'pslf.what_next.send_bullet_msg',
        bulletpoints: [
          'pslf.what_next.send_bulletpoint_1_current',
          'pslf.what_next.send_bulletpoint_2',
          'pslf.what_next.send_bulletpoint_3',
        ],
        footer: 'pslf.what_next.send_footer',
      },
      priorSendFormYourself: {
        title: 'pslf.what_next.what_next',
        description: this.intl.t('pslf.what_next.send_description', {
          companyName,
        }),
        iconText: 'pslf.what_next.send_icon_text',
        bodyHeading: this.intl.t('pslf.what_next.send_body_heading', {
          companyName,
        }),
        bulletMsg: 'pslf.what_next.send_bullet_msg',
        bulletpoints: [
          'pslf.what_next.send_bulletpoint_1_prior',
          'pslf.what_next.send_bulletpoint_2',
          'pslf.what_next.send_bulletpoint_3',
        ],
        footer: 'pslf.what_next.send_footer',
      },
    };
    // @ts-expect-error: need to make object a type and then key off of that type
    return dictionary[this.dictionaryKey];
  }

  copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(this.textInput);
    } catch (e) {
      this.clipboardError = 'Error copying to clipboard.';
    }
  };

  @action
  hideClipboardError() {
    this.clipboardError = '';
  }

  @action
  computeBulletPointText(arg: string) {
    const bulletPointLink = this.keys.bulletPointLink;
    const bulletPointLinkText =
      this.keys.bulletPointLinkText && this.intl.t(this.keys.bulletPointLinkText);
    const result = this.intl.t(arg, {
      link:
        bulletPointLink &&
        bulletPointLinkText &&
        `<a class="underline" target="_blank" href=${bulletPointLink}>${bulletPointLinkText}</a>`,
      companyName: this.companyName,
    });

    return result;
  }

  addEmailText() {
    const translationKey =
      this.args.model.borrowerFormType === 'priorEmployer'
        ? 'pslf.what_next.send_text_input_prior'
        : 'pslf.what_next.send_text_input_current';

    this.textInput = this.intl
      .t(translationKey, {
        contactName: this.args.model.employerFields['employer-contact-name'] || '{employer}',
        companyName: this.args.model.employerFields['employer-name'] || '{your company}',
        startDate: this.args.model.employerFields['employment-start-date'] || '{start date}',
        endDate: this.args.model.employerFields['employment-end-date'] || '{end date}',
        borrowerName: this.args.model.borrowerFields['borrower-name'] || '{your employee}',
      })
      // This needs to be manually replaced because the intl library mishandles newlines
      .replaceAll('\\n', '\n');
  }

  decideDictionaryKey() {
    const form = this.args.model;
    const isCurrentEmployerForm = form.isCurrentEmployer;
    this.addEmailText();
    if (form.borrowerFields['borrower-print-and-mail'] === true) {
      this.dictionaryKey = isCurrentEmployerForm
        ? 'currentSendFormYourself'
        : 'priorSendFormYourself';
      return;
    }

    if (isCurrentEmployerForm) {
      this.dictionaryKey = 'currentEmployer';
      return;
    }

    if (form.borrowerFields['borrower-prior-employer-closed']) {
      this.dictionaryKey = 'priorClosed';
    } else if (form.borrowerFields['borrower-employer-email']) {
      this.dictionaryKey = 'priorCertifyEmployment';
    } else {
      this.dictionaryKey = 'priorSendFormYourself';
    }
  }

  <template>
    <Section>
      <:header>{{t this.keys.title}}</:header>
      <:body>
        <VStack>
          <div class="text-lg"> {{this.keys.description}}</div>
          <div class="text-xl flex-col items-center mr-10 md:flex min-w-[182px]">
            <MaterialIcon @icon="info_outline" class="text-[36px] text-ocean-600" />
            <h3 class="text-center mt-4 font-semibold uppercase">
              {{t this.keys.iconText}}
            </h3>
          </div>
          <h3 class="text-2xl font-semibold"> {{this.keys.bodyHeading}}</h3>
          <div class="mt-4"> {{this.keys.bodyDescription}} </div>
          <div class="font-semibold my-8"> {{t this.keys.bulletMsg}}</div>

          {{#each this.keys.bulletpoints as |bulletpoint index|}}
            <div class="flex flex-col my-4">
              <div class="flex">
                <div class="flex">
                  <MaterialIcon @icon="check" class="mr-4 text-ocean-600 font-size-[24px]" />
                </div>
                <span>
                  {{! template-lint-disable no-triple-curlies }}
                  {{{this.computeBulletPointText bulletpoint}}}
                </span>
              </div>
              {{#if (eq index 0)}}
                <ul class="list-disc list-inside mb-2 ml-[4em] marker:text-ocean-600">
                  {{#each this.keys.bulletpointDescriptions as |bpDescription|}}
                    <li class="align-center mt-1">
                      {{t bpDescription}}
                    </li>
                  {{/each}}
                </ul>
              {{/if}}
            </div>
          {{/each}}

          <div class="what-next-actions">
            {{#unless this.isMailInForm}}
              <Button
                @intent="primary"
                @appearance="outlined"
                {{on "click" (transitionTo "authenticated.pslf.dashboard.index")}}
              >
                {{t "continue"}}
              </Button>
            {{/unless}}
            {{#if this.isMailInForm}}
              <Button @intent="primary" @appearance="outlined" {{on "click" this.copyToClipboard}}>
                {{t "pslf.what_next.send_button_2"}}
              </Button>
            {{/if}}
            {{#if this.clipboardError}}
              <TioAlert @type="error" @allowDismiss={{true}} @onDismiss={{this.hideClipboardError}}>
                <:body>
                  {{this.clipboardError}}
                </:body>
              </TioAlert>
            {{/if}}

            {{#if this.showDownloadButton}}
              <a rel="noopener noreferrer" target="_blank" href={{this.formDownloadUrl}}>
                <MaterialIcon @icon="file_download" />
                <h6>
                  {{t "pslf.what_next.print_mail"}}
                </h6>
              </a>
            {{/if}}
          </div>
          {{#if this.keys.footer}}
            <div class="font-semibold my-4"> {{t this.keys.footer}}</div>
          {{/if}}
          {{#if this.isMailInForm}}
            <Textarea
              id="mail-in"
              area-label="Mail in"
              @value={{this.textInput}}
              class="p-4 text-area mt-4 border-gray w-full h-[380px] border-2 rounded text-lg"
            />
            <Button
              @appearance="outlined"
              @intent="primary"
              {{on "click" (transitionTo "authenticated.pslf.dashboard.index")}}
            >
              {{t "continue"}}
            </Button>
          {{/if}}
        </VStack>
      </:body>
    </Section>
  </template>
}
