import { formatAsDollars } from 'tio-common/utils/format';
import { service } from '@ember/service';
import Alert from 'tio-ui/components/alert';
import Component from '@glimmer/component';
import type { Intent } from 'tio-ui/utilities';
import type IntlService from 'ember-intl/services/intl';
import type TasApplicationModel from 'tio-common/models/tas-application';

export interface TasApplicationAlertSignature {
  Args: {
    application: TasApplicationModel;
  };
}

export default class TasApplicationAlert extends Component<TasApplicationAlertSignature> {
  @service declare intl: IntlService;

  get alertDetails(): {
    intent: Intent | null;
    title: string;
    body: string;
  } {
    // Handle refund request separately
    if (this.args.application.isRefundRequested) {
      return {
        intent: 'warning',
        title: this.intl.t('tas.applications.alert.REFUND_REQUESTED.title'),
        body: this.intl.t('tas.applications.alert.REFUND_REQUESTED.body', {
          amount: formatAsDollars(this.args.application.amountOwed || 0),
        }),
      };
    }

    // Handle PAID_IN_FULL separately as it includes the amount
    if (this.args.application.paymentState === 'TAS.ApplicationPaymentState.PAID_IN_FULL') {
      return {
        intent: 'success',
        title: this.intl.t('tas.applications.alert.PAID_IN_FULL.title'),
        body: this.intl.t('tas.applications.alert.PAID_IN_FULL.body', {
          amount: formatAsDollars(this.args.application.approvedTotal || 0),
        }),
      };
    }

    const stateKey = this.args.application.state.split('.').pop();
    const baseKey = `tas.applications.alert.${stateKey}`;

    // Define intent based on state
    let intent: Intent | null = null;
    switch (stateKey) {
      case 'COURSES_APPROVED':
      case 'EVIDENCE_APPROVED':
      case 'FULFILLED':
        intent = 'success';
        break;
      case 'PENDING_COURSES_APPROVAL':
      case 'PENDING_EVIDENCE_APPROVAL':
        intent = 'info';
        break;
    }

    return {
      intent,
      title: intent ? this.intl.t(`${baseKey}.title`) : '',
      body: intent ? this.intl.t(`${baseKey}.body`) : '',
    };
  }

  <template>
    {{#if this.alertDetails.intent}}<Alert @intent={{this.alertDetails.intent}}>
        <h3 class="font-semibold">{{this.alertDetails.title}}</h3>
        <p>{{this.alertDetails.body}}</p>
      </Alert>
    {{/if}}
  </template>
}
