import { service } from '@ember/service';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type SessionContext from 'tio-employee/services/session-context';
import TasPayablesReport from 'tio-common/components/reporting/tas/payables';
import { VStack } from 'tio-ui/components/layout';

class ReportingTasPayablesRouteComponent extends Component {
  @service declare sessionContext: SessionContext;

  <template>
    <VStack>
      <TasPayablesReport
        @returnRoute="authenticated.admin.reporting.index"
        @sessionContext={{this.sessionContext}}
      />
    </VStack>
  </template>
}

export default RouteTemplate(ReportingTasPayablesRouteComponent);
