import { action } from '@ember/object';
import { createConsumer } from '@rails/actioncable';
import { Divider } from 'tio-ui/components/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import type { IntlService } from 'ember-intl';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type SessionService from 'tio-employee/services/session';
import type Store from '@ember-data/store';
import type Owner from '@ember/owner';

export interface PslfFormPageSignature {
  Args: {
    form: PslfFormModel;
    signer: string;
  };
}

type ConsumerType = ReturnType<typeof createConsumer>;

type ReceivedMessageType = {
  status: string;
};

export default class PslfSignFormPageComponent extends Component<PslfFormPageSignature> {
  @service declare intl: IntlService;
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;
  @service declare store: typeof Store;

  @tracked pslfFormStatus = '';

  consumer!: ConsumerType;
  unreadMessagesCountSubscription!: ConsumerType['subscriptions']['subscriptions'][0];

  constructor(owner: Owner, args: PslfFormPageSignature['Args']) {
    super(owner, args);
    this.setupCable();
  }

  get isBorrower() {
    const signer = this.args.signer;
    const isBorrower = signer === 'borrower';
    return isBorrower;
  }

  get alreadySigned() {
    const status = this.args.form.status;
    const isSigned = this.isBorrower
      ? !['created', 'pending'].includes(status)
      : !['created', 'borrower_signed'].includes(status);
    return isSigned;
  }

  get borrowerName() {
    return this.args.form.borrowerFields['borrower-name'];
  }

  get employerMessage() {
    return this.intl.t('pslf.complete_pslf.done_employer', {
      date: this.args.form.borrowerCompletedAt,
      name: this.borrowerName,
    });
  }

  get previousPage() {
    return this.isBorrower ? 'authenticated.pslf.dashboard.start-new' : 'authenticated.admin.pslf';
  }

  setupCable() {
    if (!this.sessionContext?.user) {
      return;
    }

    const KEY = encodeURIComponent(ENV.apiKey);
    const TOKEN = encodeURIComponent(this.session.data.authenticated.access_token);
    const URL = `${ENV.apiHost}/cable?apiKey=${KEY}&token=${TOKEN}`;
    this.consumer = createConsumer(URL);
    this.consumer.connect();
    this.subscribeToPslfFormStatus(this.args.form.id);
  }

  @action
  subscribeToPslfFormStatus(pslfFormId: string) {
    this.unreadMessagesCountSubscription = this.consumer.subscriptions.create(
      {
        channel: 'PslfFormStatusChannel',
        pslf_form_id: pslfFormId,
      },
      {
        received: (data: ReceivedMessageType) => {
          this.pslfFormStatus = data.status;
          const isSigned = this.isBorrower
            ? !['created', 'pending'].includes(data.status)
            : !['created', 'borrowerSigned'].includes(data.status);
          if (isSigned) {
            this.unreadMessagesCountSubscription.unsubscribe();
            delete this.unreadMessagesCountSubscription;
            if (this.isBorrower) {
              this.router.transitionTo('authenticated.pslf.form.what-next', {
                queryParams: { formId: pslfFormId },
              });
            } else if (data.status === 'employerSigned') {
              this.router.transitionTo('authenticated.admin.pslf.form.success', pslfFormId);
            }
          }
        },
      }
    );
  }

  embedUrlData = trackedFunction(this, async () => {
    if (this.isBorrower) {
      return this.args.form.borrowerFields['embed-url'];
    }
    const response = await this.store
      .adapterFor('pslf-form')
      .prepareForEmployerSignature(this.args.form);
    return response.embedUrl;
  });

  get embedUrl() {
    return this.embedUrlData.value || '';
  }

  <template>
    <div class="flex flex-col">
      {{#if this.alreadySigned}}
        <h2 class="text-xl">{{t "pslf.complete_pslf.already_signed"}}</h2>
      {{else}}
        <h2 class="text-xl">{{t "pslf.complete_pslf.title"}}</h2>
        <Divider class="my-2" />
        <h3 class="mb-8">
          {{t "pslf.complete_pslf.note"}}
        </h3>
        {{#if this.embedUrl}}
          <iframe
            class="w-full h-[1000px]"
            title={{t "pslf.complete_pslf.title"}}
            src={{this.embedUrl}}
          />
        {{/if}}
      {{/if}}
    </div>

    <hr class="my-4" />
  </template>
}
