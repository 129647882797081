import ApplicationAdapter from './application';

export default class PersonAdapter extends ApplicationAdapter {
  async updateContributionAccount(personId: string, accountIds: string[]) {
    const url = `${this.buildURL('person', personId)}/contribution-account`;
    return this.ajax(url, 'PATCH', {
      data: {
        data: {
          type: 'person',
          id: personId,
          attributes: { account_order: accountIds },
        },
      },
    });
  }
}
