import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import TuitionAssistanceFormsApprovalCoursesCompletionReview from 'tio-employee/components/tuition-assistance/forms/approval/courses-completion-review';
import type TasApplicationModel from 'tio-common/models/tas-application';

interface S {
  Args: {
    model: TasApplicationModel;
  };
}

export default RouteTemplate<S>(
  <template>
    <h3 class="font-semibold my-2 text-midnight">
      {{t "tuition_assistance.preapproval_app.application_review"}}
    </h3>

    <TuitionAssistanceFormsApprovalCoursesCompletionReview @model={{@model}} />
  </template>
);
