import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { Header, VStack } from 'tio-ui/components/layout';

interface SyfRouteSignature {
  Blocks: {
    default: [];
  };
}

export default RouteTemplate<SyfRouteSignature>(
  <template>
    <VStack>
      <Header>{{t "syf.default"}}</Header>
      {{outlet}}
    </VStack>
  </template>
);
