import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import TioLoadingSpinner from 'tio-common/components/tio/loading-spinner';

export default RouteTemplate(
  <template>
    <div class="flex flex-col h-screen container mx-auto">
      <h2 class="mt-6 text-center text-lg">{{t "login.in_progress"}}</h2>

      <div class="grow flex items-center">
        <TioLoadingSpinner />
      </div>
    </div>
  </template>
);
