import RouteTemplate from 'ember-route-template';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import RecommendationsResultsRecommendationHistory from 'tio-employee/components/recommendations/results/recommendation-history';
import type RecommendationModel from 'tio-common/models/recommendation';
import { VStack } from 'tio-ui/components/layout';

interface S {
  model: {
    allRecommendations: RecommendationModel[];
  };
}

export default RouteTemplate<S>(
  <template>
    <VStack>
      <TioPageBreadcrumbs as |b|>
        <b.crumb
          @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.index"
          @label="Dashboard"
        />
        <b.crumb
          @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.recommendation-history"
          @label="Recommendation History"
        />
      </TioPageBreadcrumbs>
      <RecommendationsResultsRecommendationHistory
        @allRecommendations={{@model.allRecommendations}}
        @programType="sf"
      />
    </VStack>
  </template>
);
