import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type PersonModel from 'tio-common/models/person';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedSlrDashboard extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;

  async model() {
    const personId = this.sessionContext.user.person.id;

    return this.store.findRecord('person', personId, {
      include: 'accounts.financial-institution,accounts.loans',
      reload: true,
    });
  }

  // redirect to link loans if they try to access without an account
  async afterModel(model: PersonModel) {
    if (!model?.accounts?.length) {
      this.router.transitionTo('authenticated.observability.upload', {
        source: 'slr',
        providers: 'statement,nslds',
      });
    }
  }
}
