import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type StoreService from 'tio-common/services/store';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';

type Params = {
  formType: string;
  order: string;
  search: string;
  group: string;
  status: string;
  page: number;
  limit: number;
};

type Filter = {
  'search-term'?: string;
  'employer-group'?: string;
  'employer-group-legacy'?: string;
  status?: string;
  'model-type': string;
  'company-id': string;
  'form-type'?: string;
};

export default class AuthenticatedAdminPslfFormsRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare partner: PartnerService;

  queryParams = {
    formType: { refreshModel: true, replace: true },
    group: { refreshModel: true, replace: true },
    limit: { refreshModel: true, replace: true },
    page: { refreshModel: true, replace: true },
    search: { refreshModel: true, replace: true },
    order: { refreshModel: true, replace: true },
    status: { refreshModel: true, replace: true },
  };

  async model(params: Params) {
    const { order, search, group, formType, status, page, limit } = params;

    const filter: Filter = {
      'search-term':
        search
          ?.replace(/[,%$#"']/g, '')
          .replace(/\s+/g, ' ')
          .trim() || undefined,
      'model-type': 'PslfForm',
      'employer-group-legacy': group || undefined,
      status: status || undefined,
      'form-type': formType || undefined,
      'company-id': this.sessionContext.currentRole?.company.id,
    };

    const query = {
      filter,
      page: {
        limit,
        offset: (page - 1) * limit,
      },
      order,
    };

    // Return a Promise that resolves with the array of fetched data
    // and the total available records
    return this.store.adapterFor('search').search(query);
  }
}
