import { action } from '@ember/object';
import { array, fn } from '@ember/helper';
import {
  copyFieldsAndUpdatePerProgramTemplate,
  setSingleValueForTasField,
} from 'tio-common/utils/tuition-assistance/fields';
import { dropTask, all } from 'ember-concurrency';
import { on } from '@ember/modifier';
import { not } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { TrackedObject } from 'tracked-built-ins';
import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import TioAlert from 'tio-common/components/tio/alert';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import TuitionAssistanceFormsAcceptConditions from '../accept-conditions';
import TuitionAssistanceFormsCoursesCourseDetails from 'tio-common/components/tuition-assistance/forms/courses/course-details';
import TuitionAssistanceFormsCoursesCourseDuration from 'tio-common/components/tuition-assistance/forms/courses/course-duration';
import TuitionAssistanceFormsPreApprovalEmployeeInformation from '../pre-approval/employee-information';
import TuitionAssistanceFormsPreApprovalProgramInformation from '../pre-approval/program-information';
import TuitionAssistanceProgramDetailsCancelRequest from 'tio-employee/components/tuition-assistance/program-details/cancel-request';
import HeldParticipantWarning from 'tio-common/components/tuition-assistance/forms/courses/held-participant-warning';
import type { TasFieldsOrCustomFields } from 'tio-common/types/tuition-assistance';
import type ConditionModel from 'tio-common/models/condition';
import type IntlService from 'ember-intl/services/intl';
import type SessionContextService from 'tio-employee/services/session-context';
import type TasApplication from 'tio-common/models/tas-application';
import type TasCourse from 'tio-common/models/tas-course';
import type TasProgramInstance from 'tio-common/models/tas-program-instance';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import type Owner from '@ember/owner';
import { Button } from 'tio-ui/components/buttons';

interface CertificateDetailsComponentSignature {
  Args: {
    model: {
      instance: TasProgramInstance;
      application: TasApplication;
      course: TasCourse;
      selectedSchool?: TasUnifiedInstitutionModel;
    };
  };
}

export type TemplateKeyForDidUpdateCallback =
  keyof TuitionAssistanceFormsCertificateCertificateDetails;

interface SectionsValidation {
  employeeInfo: boolean | null;
  programInfo: boolean | null;
  courseDuration: boolean | null;
  courseDetails: boolean | null;
}

class TuitionAssistanceFormsCertificateCertificateDetails extends Component<CertificateDetailsComponentSignature> {
  @service declare router: RouterService;
  @service declare sessionContext: SessionContextService;
  @service declare tuitionAssistance: TuitionAssistanceService;
  @service declare store: typeof Store;
  @service declare intl: IntlService;

  @tracked hasSubmitted = false;
  @tracked submitError = '';
  @tracked tasProgramInstanceFields: TasProgramInstance['fields'];
  @tracked tasProgramInstanceCustomFields: TasProgramInstance['customFields'];
  @tracked tasApplicationFields: TasApplication['fields'];
  @tracked tasApplicationCustomFields: TasApplication['customFields'];
  @tracked tasCourseFields: TasCourse['fields'];
  @tracked tasCourseCustomFields: TasCourse['customFields'];
  @tracked acceptedConditions: ConditionModel[] = [];
  sectionsValidation: SectionsValidation = new TrackedObject({
    employeeInfo: null,
    programInfo: null,
    courseDuration: null,
    courseDetails: null,
  });

  constructor(owner: Owner, args: CertificateDetailsComponentSignature['Args']) {
    super(owner, args);
    const programTemplate = args.model.instance.tasProgramTemplate;
    this.tasProgramInstanceFields = new TrackedObject(this.args.model.instance.fields);
    this.tasProgramInstanceCustomFields = copyFieldsAndUpdatePerProgramTemplate(
      this.args.model.instance.customFields,
      programTemplate.instanceCustomFields || []
    );
    this.tasApplicationFields = new TrackedObject(this.args.model.application.fields);
    this.tasApplicationCustomFields = copyFieldsAndUpdatePerProgramTemplate(
      this.args.model.application.customFields,
      programTemplate.applicationCustomFields || []
    );
    this.tasCourseFields = new TrackedObject(this.args.model.course.fields);
    this.tasCourseCustomFields = copyFieldsAndUpdatePerProgramTemplate(
      this.args.model.course.customFields,
      programTemplate.courseCustomFields || []
    );
  }

  get isClaimsFinancialsApplication() {
    return this.args.model.instance.tasProgramTemplate.calculateTotalByClaimsFinancials;
  }

  get conditionsForSubmit() {
    return this.args.model.instance.tasProgramTemplate.applicationApproveCoursesConditions || [];
  }

  get hasAgreedAllConditions() {
    if (!this.conditionsForSubmit.length) {
      return true;
    }
    return this.conditionsForSubmit.length === this.acceptedConditions.length;
  }

  get saveTaskIsRunning() {
    return this.saveFields.isRunning || this.saveAgreementsForConditions.isRunning;
  }

  get showHeldParticipantWarning() {
    return !!this.args.model.instance?.employee?.tasParticipant?.isHeld;
  }

  @action
  onUpdate(fields: TasFieldsOrCustomFields, key: unknown) {
    switch (key) {
      case 'tasProgramInstanceFields':
        // @ts-expect-error: not sure about this one
        this.tasProgramInstanceFields = fields;
        break;
      case 'tasProgramInstanceCustomFields':
        // @ts-expect-error: not sure about this one
        this.tasProgramInstanceCustomFields = fields;
        break;
      case 'tasApplicationFields':
        // @ts-expect-error: not sure about this one
        this.tasApplicationFields = fields;
        break;
      case 'tasApplicationCustomFields':
        // @ts-expect-error: not sure about this one
        this.tasApplicationCustomFields = fields;
        break;
      case 'tasCourseFields':
        // @ts-expect-error: not sure about this one
        this.tasCourseFields = fields;
        break;
      case 'tasCourseCustomFields':
        // @ts-expect-error: not sure about this one
        this.tasCourseCustomFields = fields;
        break;
      default:
        console.warn(`Unexpected fieldType: ${String(key)}`);
        break;
    }
  }

  get calculationFormulaNoteIfApplicable() {
    const application = this.args.model.application;
    if (application.tasProgramInstance?.tasProgramTemplate?.calculateTotalByCredit) {
      return `${application.requestedTotalCalculationFormulaNote} (Pre-calculated requested total before scholarships)`;
    }

    if (application.tasProgramInstance?.tasProgramTemplate?.calculateTotalByPercent) {
      return `${application.requestedTotalCalculationFormulaNote} (Pre-calculated requested total)`;
    }

    return '';
  }

  @action
  didUpdateAcceptedConditions(conditions: ConditionModel[] = []) {
    this.acceptedConditions = conditions;
  }

  @action
  cancel() {
    this.router.transitionTo('authenticated.tas.dashboard');
  }

  @action
  async saveForLater() {
    try {
      await this.saveFields.perform();
      this.router.transitionTo('authenticated.tas.dashboard');
    } catch (e) {
      console.error(e);
    }
  }

  @action
  async saveAndNext() {
    this.hasSubmitted = true;
    this.submitError = '';

    if (!this.isFormValid) {
      return;
    }

    try {
      // Save all models so we have proper IDs if they are new
      await this.saveFields.perform();

      // Validate limits against newly saved application
      const limitsValidationResponse = await this.validateLimits.perform(
        this.args.model.application
      );
      if (!limitsValidationResponse.can_submit) {
        this.displayLimitsValidationError(limitsValidationResponse);
        return;
      }

      // Save agreements for conditions
      await this.saveAgreementsForConditions.perform();

      // Request courses approval
      await this.store
        .adapterFor('tas-application')
        .requestCoursesApproval(
          this.args.model.application,
          this.calculationFormulaNoteIfApplicable
        );

      this.router.transitionTo(
        'authenticated.tuition-assistance.programs.instance.course-applications.show.certificate',
        this.args.model.application.id
      );
    } catch (e) {
      console.error(e);
      this.submitError = e;
    }
  }

  saveFields = dropTask(async () => {
    this.args.model.instance.fields = this.tasProgramInstanceFields;
    this.args.model.instance.customFields = this.tasProgramInstanceCustomFields;

    this.args.model.application.fields = this.tasApplicationFields;
    this.args.model.application.customFields = this.tasApplicationCustomFields;

    this.args.model.course.fields = this.tasCourseFields;
    this.args.model.course.customFields = this.tasCourseCustomFields;

    // Store pre-approved total upon submission for book-keeping
    // Claims financials applications are initialized with 0 since the requested total is irrelevant
    setSingleValueForTasField(
      'PRE_APPROVED_TOTAL',
      this.isClaimsFinancialsApplication ? 0 : this.args.model.application.requestedTotal,
      this.args.model.application.fields
    );

    await this.args.model.instance.save();
    await this.args.model.application.save();
    await this.args.model.course.save();
  });

  saveAgreementsForConditions = dropTask(async () => {
    const user = this.sessionContext.user;
    const agreements = this.acceptedConditions.map((condition) => {
      return this.store.createRecord('agreement', {
        user: user,
        condition: condition,
      });
    });
    const promises = agreements.map((agreement) => {
      return agreement.save();
    });
    const saved = await all(promises);
    return saved;
  });

  validateLimits = dropTask(async (application: TasApplicationModel) => {
    const results = await this.tuitionAssistance.validateAmountRequestedAgainstCurrentLimitsStatus(
      application,
      application.tasProgramInstance.employee
    );
    return results;
  });

  formatMoney(cents: number) {
    const dollars = cents / 100;
    return this.intl.formatNumber(dollars, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    });
  }

  displayLimitsValidationError(validationResponse = {}) {
    // TODO: Eventually this message will be configurable via the program template.
    this.submitError = `You have exceeded your employer's maximum limit. Please reduce your requested amount by ${this.formatMoney(
      // @ts-expect-error: not sure about this one
      validationResponse.reduction_needed
    )}.`;
  }

  @action
  setSectionValidation(section: keyof SectionsValidation, value: boolean) {
    this.sectionsValidation[section] = value;
  }

  get isFormValid() {
    if (this.showHeldParticipantWarning || !this.hasAgreedAllConditions) {
      return false;
    }
    return !Object.values(this.sectionsValidation).includes(false);
  }

  get isActiveEligibility() {
    return this.tuitionAssistance.hasActiveEligibilityForProgramTemplate(
      this.args.model.instance.tasProgramTemplate
    );
  }

  <template>
    {{#unless this.isActiveEligibility}}
      <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
        <:header>
          <p class="font-bold">
            {{t "tuition_assistance.inactive_participant_warnings.dashboard_title"}}
          </p>
        </:header>
        <:body>
          <p class="text-sm">
            {{t "tuition_assistance.inactive_participant_warnings.dashboard_description"}}
          </p>
        </:body>
      </TioAlert>
    {{/unless}}
    <TuitionAssistanceFormsPreApprovalEmployeeInformation
      @programInstance={{@model.instance}}
      @showActionButtons={{false}}
      @didUpdate={{this.onUpdate}}
      @sectionValid={{fn this.setSectionValidation "employeeInfo"}}
    />
    <TuitionAssistanceFormsPreApprovalProgramInformation
      @programInstance={{@model.instance}}
      @selectedSchool={{@model.selectedSchool}}
      @showActionButtons={{false}}
      @didUpdate={{this.onUpdate}}
      @sectionValid={{fn this.setSectionValidation "programInfo"}}
    />
    <TuitionAssistanceFormsCoursesCourseDuration
      @application={{@model.application}}
      @showActionButtons={{false}}
      @didUpdate={{this.onUpdate}}
      @sectionValid={{fn this.setSectionValidation "courseDuration"}}
      @hasSubmitted={{this.hasSubmitted}}
    />
    <TuitionAssistanceFormsCoursesCourseDetails
      @course={{@model.course}}
      @showActionButtons={{false}}
      @didUpdate={{this.onUpdate}}
      @sectionValid={{fn this.setSectionValidation "courseDetails"}}
      @hasSubmitted={{this.hasSubmitted}}
    />

    {{#if this.conditionsForSubmit.length}}
      <TuitionAssistanceFormsAcceptConditions
        @conditions={{this.conditionsForSubmit}}
        @onChange={{this.didUpdateAcceptedConditions}}
      />
    {{/if}}

    {{#if this.showHeldParticipantWarning}}
      <HeldParticipantWarning class="my-4 mx-auto max-w-md" />
    {{/if}}

    <section class="my-8">
      <div class="flex flex-col justify-center items-center">
        <TioErrorMessages
          @showErrors={{this.hasSubmitted}}
          @error={{this.submitError}}
          @icon="warning"
          class="mb-2"
        />
      </div>
      <div class="flex flex-col md:flex-row md:justify-center items-center gap-4">
        <Button
          {{on "click" this.cancel}}
          @intent="danger"
          @appearance="outlined"
          disabled={{this.saveTaskIsRunning}}
          class="mx-8 w-48"
        >
          {{t "cancel"}}
        </Button>

        <Button
          @intent="primary"
          {{on "click" this.saveAndNext}}
          disabled={{not this.isFormValid}}
          @isRunning={{this.saveTaskIsRunning}}
          class="mx-8 w-48"
        >
          {{t "submit"}}
        </Button>
      </div>
      <div class="flex justify-center">
        <button
          type="button"
          {{on "click" this.saveForLater}}
          class="text-xs tio-copy text-center my-4"
        >{{t "common.save_for_later"}}</button>
      </div>
    </section>
    <TuitionAssistanceProgramDetailsCancelRequest
      @application={{@model.application}}
      @modelTypes={{array "tas-application" "tas-program-instance"}}
      @linkText={{t "tuition_assistance.program_details.cancel_app.delete_application"}}
      @canCancel={{true}}
    />
  </template>
}

export default TuitionAssistanceFormsCertificateCertificateDetails;
