import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { concat } from '@ember/helper';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { not } from 'tio-ui/utilities';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import ProgramCard from 'tio-common/components/tuition-assistance/program-card';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import type { TasProgramInstanceState } from 'tio-common/types/tuition-assistance';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import type { Pill } from 'tio-common/components/tuition-assistance/program-card';
import Plus from 'ember-static-heroicons/components/outline-24/plus';

export interface TuitionAssistanceProgramDetailsApplicationsSignature {
  Args: {
    programInstance: TasProgramInstanceModel;
    isHeldParticipant: boolean;
  };
}

export default class TuitionAssistanceProgramDetailsApplications extends Component<TuitionAssistanceProgramDetailsApplicationsSignature> {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  @tracked showMarkCompleteDialog = false;
  @tracked showWithdrawDialog = false;

  @tracked hasSubmitted = false;
  @tracked didSubmitSuccessfully = false;
  @tracked submitError = null;
  @tracked acceptedConditions = [];

  @tracked action = null;

  applicationButtonRoute =
    'authenticated.tuition-assistance.programs.instance.course-applications.show';

  get programTemplate() {
    return this.args.programInstance.tasProgramTemplate;
  }

  get isActiveEligibility() {
    return this.tuitionAssistance.hasActiveEligibilityForProgramTemplate(this.programTemplate);
  }

  get canCreateApplications() {
    const { programApprovalRequired } = this.programTemplate;

    const validStates: TasProgramInstanceState[] = [
      'TAS.ProgramInstanceState.PRIMED',
      'TAS.ProgramInstanceState.PENDING_COMPLETION_APPROVAL',
    ];

    if (!programApprovalRequired) {
      validStates.push(
        'TAS.ProgramInstanceState.PROGRAM_APPROVED',
        'TAS.ProgramInstanceState.PENDING_PROGRAM_APPROVAL'
      );
    }

    return validStates.includes(this.args.programInstance.state) && this.isActiveEligibility;
  }

  get enableApplyForCoursesButton() {
    if (this.programTemplate.state === 'TAS.ProgramTemplateState.SUSPENDED') {
      return false;
    } else if (this.isSingleApplicationProgram) {
      return this.canCreateApplications && !this.programHasExistingApplications;
    }
    return this.canCreateApplications;
  }

  get showHeldParticipantWarning() {
    return this.args.isHeldParticipant;
  }

  get disabledCoursesButtonText(): string {
    const { state } = this.args.programInstance;
    const programApprovalRequired =
      this.args.programInstance?.tasProgramTemplate?.programApprovalRequired;

    if (this.programTemplate.state === 'TAS.ProgramTemplateState.SUSPENDED')
      return (
        getSingleValueForTasField(
          'SUSPENDED_PROGRAM_DISCLOSURE',
          this.args.programInstance?.tasProgramTemplate?.fields
        ) || this.intl.t('tuition_assistance.program_details.suspended_program_disclosure')
      );

    if (state === 'TAS.ProgramInstanceState.PENDING_PROGRAM_APPROVAL' && programApprovalRequired)
      return this.intl.t('tuition_assistance.pending_program_approval');

    if (this.isSingleApplicationProgram && this.programHasExistingApplications)
      return this.intl.t('tuition_assistance.is_single_application_program_disclaimer');

    return '';
  }

  get isSingleApplicationProgram() {
    return !!getSingleValueForTasField(
      'SINGLE_APPLICATION_PROGRAM',
      this.args.programInstance?.tasProgramTemplate?.fields
    );
  }

  get programHasExistingApplications() {
    return !!this.args.programInstance?.tasApplications?.length;
  }

  get sortedApplications() {
    const apps = this.args.programInstance?.tasApplications?.slice() || [];

    const filteredAndSortedApps = apps
      .reduce((filteredApp: TasApplicationModel[], app) => {
        if (app.state !== 'TAS.ApplicationState.ABANDONED') {
          filteredApp.push(app);
        }
        return filteredApp;
      }, [])
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        return 0;
      });

    return filteredAndSortedApps;
  }

  @action
  createNewApplication() {
    this.router.transitionTo(
      'authenticated.tuition-assistance.programs.instance.course-applications.new'
    );
  }

  @action
  mapApplicationPills(application: TasApplicationModel) {
    const tags = application.tags || [];
    const mapped = tags.map((tag) => {
      return {
        label: tag.name,
        intent: 'success',
      };
    });
    if (application.isRefundRequested) {
      mapped.unshift({
        label: this.intl.t('tuition_assistance.application_details.in_repayment'),
        intent: 'danger',
      });
    }
    return mapped as Pill[];
  }

  @action
  statusText(state: string): string {
    if (this.intl.exists(`common.status_message.to.${state}`)) {
      return this.intl.t(`common.status_message.to.${state}`);
    }

    return this.intl.t('common.status_message.to.TAS.ProgramInstanceState.UPDATED_ON');
  }

  <template>
    <div class="my-4 md:my-8 font-semibold">
      {{t "tuition_assistance.program_details.applications"}}
    </div>

    <div class="flex flex-wrap md:flex-no-wrap my-4 md:my-8 gap-4">
      <Button
        @intent="primary"
        {{on "click" this.createNewApplication}}
        disabled={{not this.enableApplyForCoursesButton}}
        class="w-64"
      >
        <Plus class="w-5 mr-2" />
        {{t "tuition_assistance.program_details.apply_for_courses"}}
      </Button>

      {{#if this.disabledCoursesButtonText}}
        <div class="flex items-center">
          <MaterialIcon @icon="info_outline" class="text-lg text-ocean-600 mr-1" />
          <p class="text-sm text-tio-gray-600">{{this.disabledCoursesButtonText}}</p>
        </div>
      {{/if}}
    </div>

    {{#if this.showHeldParticipantWarning}}
      <div class="flex my-4">
        <MaterialIcon @icon="warning" class="text-error-400 mr-2" />
        <p class="text-error-400 font-medium">
          {{t "tuition_assistance.program_details.held_participant_warning"}}
        </p>
      </div>
    {{/if}}

    <div class="flex flex-wrap justify-center md:justify-start gap-4 md:gap-y-8 mt-6">
      {{#each this.sortedApplications as |app|}}
        {{#if app.id}}
          <ProgramCard
            @buttonText={{t "tuition_assistance.details"}}
            @buttonRoute={{this.applicationButtonRoute}}
            @buttonRouteModel={{app.id}}
            @showMessagesLink={{true}}
            {{! @glint-expect-error}}
            @title={{app.displayNameOrSemesterCode}}
            @titleClass="font-semibold"
            @pills={{this.mapApplicationPills app}}
          >
            <div class="flex">
              <p class="font-semibold">
                {{t "common.status"}}:
              </p>
              <p class="text-violet-800 font-semibold pl-1">
                {{t (concat "common.status_message.to." app.state)}}
              </p>
            </div>

            <div class="grid grid-cols-7 gap-y-4 mt-6 mb-4">
              <p class="col-start-2 col-end-5">
                {{this.statusText app.latestStateChangeLabel}}
                {{t "common.on"}}
              </p>
              <p class="font-semibold col-span-3">
                {{safeFormatDate app.latestStateChangeDate}}
              </p>
              <p class="col-start-2 col-end-5">{{t
                  "tuition_assistance.program_details.status.created"
                }}</p>
              <p class="font-semibold col-span-3">
                {{safeFormatDate app.createdAt}}</p>
            </div>
          </ProgramCard>
        {{/if}}
      {{/each}}

      {{#if @programInstance.tasProgramTemplate.isPreApprovalEnabled}}
        <ProgramCard
          @buttonText={{t "tuition_assistance.details"}}
          @buttonRoute="authenticated.tuition-assistance.program-overview"
          @buttonRouteModel={{@programInstance.tasProgramTemplate.id}}
          @title="Program Application"
          @titleClass="font-semibold"
        >
          <div class="flex">
            <p class="font-semibold">
              {{t "common.status"}}:
            </p>
            <p class="text-violet-800 font-semibold pl-1">
              {{this.statusText @programInstance.state}}
            </p>
          </div>
          <div class="grid grid-cols-7 gap-y-4 mt-6 mb-4">
            <p class="col-start-2 col-end-5">
              {{this.statusText @programInstance.latestStateChangeLabel}}
            </p>
            <p class="font-semibold col-span-3">
              {{safeFormatDate @programInstance.latestStateChangeDate}}
            </p>
            <p class="col-start-2 col-end-5">
              {{t "tuition_assistance.program_details.status.created"}}
            </p>
            <p class="font-semibold col-span-3">{{safeFormatDate @programInstance.createdAt}}</p>
          </div>
        </ProgramCard>
      {{/if}}
    </div>
  </template>
}
