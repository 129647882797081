import { service } from '@ember/service';
import {
  getSingleValueForTasField,
  setSingleValueForTasField,
  type FieldName,
} from 'tio-common/utils/tuition-assistance/fields';
import Route from '@ember/routing/route';
import type { IntlService } from 'ember-intl';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';

export default class AuthenticatedTasApplicationsTypeFourNewRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  includes = [
    'tas-courses',
    'tas-courses.tas-assets',
    'tas-program-instance',
    'tas-program-instance.tas-program-template.tas-groups',
    'tas-program-instance.tas-program-template.conditions.public-assets',
    'tas-program-instance.tas-program-template.tas-field-options',
    'tas-program-instance.employee.person',
    'tas-program-instance.employee.tas-participant',
    'tas-assets',
    'tas-assistances',
    'tags',
  ];

  queryParams = {
    date: {
      refreshModel: false,
    },
  };

  saveDate(date: string, application: TasApplicationModel, lockStatus: string) {
    const field: FieldName =
      lockStatus === 'COURSE_END_DATE' ? 'COURSES_END_DATE' : 'COURSES_BEGIN_DATE';

    setSingleValueForTasField(field, date, application.fields);
  }

  async model({ tas_application_id, date = '' }: { tas_application_id: string; date?: string }) {
    const application = await this.store.findRecord<TasApplicationModel>(
      'tas-application',
      tas_application_id,
      {
        include: this.includes.join(','),
        reload: true,
      }
    );

    // Set fields for application and instance if empty
    if (Object.keys(application.fields).length === 0) {
      const programTemplate = application.tasProgramInstance.tasProgramTemplate;
      application.fields = programTemplate.applicationFieldsHash;
      application.customFields = programTemplate.applicationCustomFields;

      const displayName = this.intl.formatDate(Date.now(), {
        month: 'long',
        year: 'numeric',
      });
      setSingleValueForTasField('APPLICATION_NAME', displayName, application.fields);
      if (date) {
        const lockStatus: string = getSingleValueForTasField(
          'LOCK_EMPLOYEE_STATUS_BASED_ON',
          application.tasProgramInstance.tasProgramTemplate.fields
        );
        this.saveDate(date, application, lockStatus);
      }

      try {
        await application.save();
      } catch (error) {
        console.error('Failed to save application, instance, or course:', error);
        application.rollbackAttributes();
      }
    }

    return {
      application: application,
      programInstance: application.tasProgramInstance,
    };
  }
}
