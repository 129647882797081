import { action } from '@ember/object';
import { array } from '@ember/helper';
import { dropTask } from 'ember-concurrency';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { TrackedObject } from 'tracked-built-ins';
import ApproverComments from 'tio-common/components/tuition-assistance/approver-comments';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import ProgressTracker from 'tio-common/components/tio/progress-tracker';
import RouteTemplate from 'ember-route-template';
import TasAppDetailsEmployeeEligibility from 'tio-common/components/tuition-assistance/application-details/employee-eligibility';
import TioAlert from 'tio-common/components/tio/alert';
import TioAppearance from 'tio-common/components/tio/appearance';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TuitionAssistanceApplicationDetailsApprovalQueueStatus from 'tio-common/components/tuition-assistance/application-details/approval-queue-status';
import TuitionAssistanceApplicationDetailsApproverOverview from 'tio-common/components/tuition-assistance/application-details/approver-overview';
import TuitionAssistanceApplicationDetailsCoursesStatusApproval from 'tio-common/components/tuition-assistance/application-details/courses-status-approval';
import TuitionAssistanceApplicationDetailsEmployeeInformation from 'tio-common/components/tuition-assistance/application-details/employee-information';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import TuitionAssistanceProgramDetailsAppDetailsApproverCourseCard from 'tio-common/components/tuition-assistance/program-details/app-details/approver-course-card';
import TuitionAssistanceProgramDetailsAppDetailsReadonlyCourseCard from 'tio-common/components/tuition-assistance/program-details/app-details/readonly-course-card';
import TuitionAssistanceProgramDetailsAppDetailsScholarshipCard from 'tio-common/components/tuition-assistance/program-details/app-details/scholarship-card';
import TuitionAssistanceProgramDetailsApplicationInformation from 'tio-common/components/tuition-assistance/program-details/application-information';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import type { ReceivedScholarship } from 'tio-common/types/tuition-assistance';
import type { Step } from 'tio-common/components/tio/progress-tracker';
import type Owner from '@ember/owner';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type SessionContextService from 'tio-employee/services/session-context';
import type SessionService from 'tio-employee/services/session';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';

interface RouteSignature {
  Args: {
    model: TasApplicationModel;
  };
}

class TuitionAssistanceApplicationsShowIndexRouteComponent extends Component<RouteSignature> {
  @service declare sessionContext: SessionContextService;
  @service declare session: SessionService;
  @service declare progressBuilder: ProgressBuilder;
  @service declare store: StoreService;
  @tracked dynamicEligibility?: boolean;
  @tracked programEligibility: [string, string][] = [];

  applicationFieldsCopy!: TasApplicationModel['fields'];

  constructor(owner: Owner, args: RouteSignature['Args']) {
    super(owner, args);
    this.applicationFieldsCopy = new TrackedObject(this.args.model.fields);
    this.loadEligibility();
  }

  async loadEligibility() {
    const { id } = this.args.model.tasProgramInstance.employee.tasParticipant;
    const { code } = this.args.model.tasProgramInstance.tasProgramTemplate;

    const dynamicEligibility = this.store
      .adapterFor('tas-participant')
      .dynamicEligibility(id, code);

    const programEligibility = this.store
      .adapterFor('tas-participant')
      .periodOfEligibility(id, code);

    this.dynamicEligibility = await dynamicEligibility;
    this.programEligibility = await programEligibility;
  }

  get lockEmployeeStatusBasedOn() {
    return this.args.model.tasProgramInstance.tasProgramTemplate.lockEmployeeStatusBasedOn;
  }

  calculateEligibility() {
    if (!this.programEligibility.length) {
      return false;
    }
    const startDate = getSingleValueForTasField(
      'COURSES_BEGIN_DATE',
      this.args.model.fields
    ) as string;

    const endDate = getSingleValueForTasField('COURSES_END_DATE', this.args.model.fields) as string;
    const datesToCheck = {
      COURSE_START_DATE: new Date(startDate),
      COURSE_END_DATE: new Date(endDate),
      FULFILLED_DATE: new Date(),
    };
    const dateBasedOnLock = this.lockEmployeeStatusBasedOn
      ? datesToCheck[this.lockEmployeeStatusBasedOn as keyof typeof datesToCheck]
      : new Date();

    return this.programEligibility.some(([start, end]) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return dateBasedOnLock >= startDate && dateBasedOnLock <= endDate;
    });
  }

  get applicationIsIneligible() {
    const eligibleLocks = ['ON_SUBMISSION', 'ON_COURSES_SUBMISSION'];
    if (eligibleLocks.includes(this.lockEmployeeStatusBasedOn as string)) {
      return false;
    }
    if (
      (this.lockEmployeeStatusBasedOn === 'FULFILLED_DATE' || !this.lockEmployeeStatusBasedOn) &&
      this.args.model.state !== 'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL'
    ) {
      return false;
    }
    return !(this.dynamicEligibility && this.calculateEligibility());
  }

  get canEditTasApplication() {
    return this.sessionContext.canEditTasApplication && this.args.model.approverCanEditApplication;
  }

  get approverName() {
    return this.sessionContext.user?.person?.fullName || '';
  }

  get steps() {
    return this.progressBuilder.applicationStepList(this.args.model) as Step[];
  }

  @action
  didUpdateScholarships(editableScholarships: ReceivedScholarship[], changelog?: string) {
    this.applicationFieldsCopy['SCHOLARSHIPS_RECEIVED'] = {
      values: editableScholarships,
    };
    if (changelog) {
      this.saveScholarships.perform(changelog);
    }
  }

  saveScholarships = dropTask(async (changelog: string) => {
    this.args.model.fields = this.applicationFieldsCopy;
    try {
      await this.args.model.save({ adapterOptions: { changelog: changelog } });
    } catch (e) {
      console.error(e);
    }
  });

  <template>
    <div class="flex justify-between items-center sticky top-0 bg-tio-gray-50">
      <TioPageBreadcrumbs as |b|>
        <b.crumb
          @route="authenticated.admin.tuition-assistance.applications.index"
          @label="Applications"
        />
        <b.crumb
          @route="authenticated.admin.tuition-assistance.applications.show.index"
          @model={{@model.id}}
          @label={{@model.displayName}}
        />

      </TioPageBreadcrumbs>
      <TioAppearance
        @apiKey={{ENV.apiKey}}
        @apiHost={{ENV.apiHost}}
        @token={{this.session.data.authenticated.access_token}}
        @type="tas_course_application"
        @documentId={{@model.id}}
        class="inline-block"
        @label={{t "common.also_viewing"}}
      />
    </div>
    {{#if @model.tasProgramInstance.employee.tasParticipant.isHeld}}
      <TioAlert @type="warning" @allowDismiss={{false}} class="max-w-8xl">
        <:header>
          <p class="font-semibold">{{t "tuition_assistance.alert.employee_held_pt_1"}}</p>
        </:header>
        <:body>
          <p class="text-sm">{{t "tuition_assistance.alert.employee_held_pt_2"}}</p>
        </:body>
      </TioAlert>
    {{/if}}
    <ProgressTracker class="hidden md:block" @steps={{this.steps}} />
    <HStack>
      <Section>
        <VStack @collapsed={{true}}>
          <TuitionAssistanceApplicationDetailsEmployeeInformation
            @instance={{@model.tasProgramInstance}}
            @profileRoute="authenticated.admin.employees.show"
            @routeModel={{@model.tasProgramInstance.employee.id}}
          />
          <TuitionAssistanceProgramDetailsApplicationInformation
            @application={{@model}}
            @isAdminView={{true}}
            @canRequestRepayment={{this.sessionContext.user.isAccountOwnerOrEmployeeAdmin}}
            @canEditTasApplication={{this.canEditTasApplication}}
            @isReadonlyView={{false}}
            @requestingApp="employee"
          />
          <TuitionAssistanceProgramDetailsProgramInfoCard
            @programInstance={{@model.tasProgramInstance}}
            @showAttachments={{true}}
            @canEditTasApplication={{this.canEditTasApplication}}
          />
        </VStack>
      </Section>
      <Section>
        <VStack @collapsed={{true}}>
          <ApproverComments
            @commentable={{@model}}
            @commentableType="TasApplication"
            @authoredByPerson={{this.sessionContext.user.person}}
            @commentTags={{array "approver"}}
          />
          <TuitionAssistanceApplicationDetailsApproverOverview
            @programTemplate={{@model.tasProgramInstance.tasProgramTemplate}}
          />
        </VStack>
      </Section>
    </HStack>
    <Section>
      <:body>
        <div class="flex flex-wrap mb-4 gap-5 justify-center md:justify-start">
          {{#each @model.activeCourses as |course|}}
            {{#if this.canEditTasApplication}}
              <div>
                <TuitionAssistanceProgramDetailsAppDetailsApproverCourseCard
                  @course={{course}}
                  @showCustomFields={{true}}
                  @approverName={{this.approverName}}
                />
              </div>
            {{else}}
              <div>
                <TuitionAssistanceProgramDetailsAppDetailsReadonlyCourseCard
                  @course={{course}}
                  @showCustomFields={{true}}
                />
              </div>
            {{/if}}
          {{/each}}
          <div>
            <TuitionAssistanceProgramDetailsAppDetailsScholarshipCard
              @application={{@model}}
              @isEditable={{this.canEditTasApplication}}
              @isAdminView={{true}}
              @onUpdateScholarships={{this.didUpdateScholarships}}
            />
          </div>
        </div>
      </:body>
    </Section>
    <TuitionAssistanceApplicationDetailsApprovalQueueStatus
      @application={{@model}}
      @consumingApp="employee"
      @isReadonlyView={{false}}
    />
    <TasAppDetailsEmployeeEligibility @programInstance={{@model.tasProgramInstance}} />
    <HStack>
      <Section>
        {{! TODO: This is a good example of over componentization. The lmits summary component inside
          this component makes it nearly impossible to have the functionality laid out properly in
          various UI contexts. Specifically tio-admin and partner-admin use this, but need different
          sectioning.
        }}
        {{#if this.applicationIsIneligible}}
          <div class="p-8">
            <TioAlert @type="error" @allowDismiss={{false}}>
              <:header>
                <p class="font-semibold">{{t "tuition_assistance.alert.application_ineligible"}}</p>
              </:header>
              <:body>
                <p class="text-sm">
                  {{t "tuition_assistance.alert.application_ineligible_reason"}}
                </p>
              </:body>
            </TioAlert>
          </div>
        {{/if}}
        <TuitionAssistanceApplicationDetailsCoursesStatusApproval
          @application={{@model}}
          @programInstance={{@model.tasProgramInstance}}
          @routePrefix="authenticated.admin"
          @isReadonlyView={{false}}
        />
      </Section>
    </HStack>
    <TuitionAssistanceProgramDetailsAppDetailsAppHistory
      @application={{@model}}
      @viewType="admin"
    />
  </template>
}

export default RouteTemplate(TuitionAssistanceApplicationsShowIndexRouteComponent);
