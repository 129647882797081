import { action } from '@ember/object';
import { formatDuration } from 'tio-common/utils/date/format-duration';
import { registerDestructor } from '@ember/destroyable';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type SessionContextService from 'tio-employee/services/session-context';
import type SessionService from 'tio-employee/services/session';
import { Footer, Modal } from 'tio-ui/components/modal';
import type Owner from '@ember/owner';

export default class AuthSessionExpiringDialogComponent extends Component {
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;

  #intervalId?: ReturnType<typeof setInterval>;

  @tracked isShowing = false;
  @tracked timeRemainingFormatted = '';

  constructor(owner: Owner, args: never) {
    super(owner, args);

    registerDestructor(this, this.teardown);
    this.setup();
  }

  setup() {
    // NOTE: The user needs to be able to renew their session and close this dialog, then have it
    //       reappear when `isSessionExpiring` changes to `true` again. I couldn't find any way to
    //       do this with tracked or computed properties and an observer is the logical choice for
    //       this. In this use case, `isShowing` is usually computed from `isSessionExpiring`, but
    //       is also programmatically set to `false` in the `close` method.[twl 18.Oct.23]
    // eslint-disable-next-line ember/no-observers
    this.session.addObserver('isSessionExpiring', this.isSessionExpiringChanged);
    this.isShowing = this.session.isSessionExpiring;
  }

  @action
  teardown() {
    this.session.removeObserver('isSessionExpiring', this.isSessionExpiringChanged);
  }

  @action
  isSessionExpiringChanged() {
    this.isShowing = this.session.isSessionExpiring;
  }

  @action
  startCountdown() {
    this.#intervalId = setInterval(this.updateTimeRemaining, 1000);
  }

  @action
  updateTimeRemaining() {
    this.timeRemainingFormatted = formatDuration(
      Math.floor((this.session.expireAt - Date.now()) / 1000)
    );
  }

  @action
  close() {
    this.isShowing = false;
  }

  @action
  renew() {
    this.session.renew();
    this.#stopCountdown();
    this.close();
  }

  @action
  logout() {
    this.#stopCountdown();
    this.sessionContext.logout();
    this.close();
  }

  #stopCountdown() {
    if (this.#intervalId) {
      clearInterval(this.#intervalId);

      this.#intervalId = undefined;
    }
  }

  <template>
    <div>
      <Modal
        @isOpen={{this.isShowing}}
        @onOpen={{this.startCountdown}}
        class="min-h-64"
        @backdrop="faded"
        @closeOnOutsideClick={{false}}
        @closeOnEscapeKey={{false}}
        @allowCloseButton={{false}}
        as |m|
      >
        <m.Header>{{t "auth.session_expiring.title"}}</m.Header>
        <m.Body>
          {{t "auth.session_expiring.message" time=this.timeRemainingFormatted}}
        </m.Body>
        <m.Footer>
          <Footer
            @isRunning={{false}}
            @onSubmit={{this.renew}}
            @onCancel={{this.logout}}
            @submitText={{t "header.user_menu.keep_alive"}}
            @cancelText={{t "header.user_menu.logout"}}
          />
        </m.Footer>
      </Modal>
    </div>
  </template>
}
