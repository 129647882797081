import RouteTemplate from 'ember-route-template';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import { t } from 'ember-intl';
import { Header, Section, VStack } from 'tio-ui/components/layout';

export default RouteTemplate(
  <template>
    <VStack>
      <Header>{{t "repayment_options.default"}}</Header>
      <TioPageTabs as |tabs|>
        <tabs.tab
          @label={{t "consolidation.default"}}
          @route="authenticated.repaying-student-debt.repayment-options.index"
        />
        <tabs.tab
          @label={{t "refinancing.default"}}
          @route="authenticated.repaying-student-debt.repayment-options.refinancing"
        />
      </TioPageTabs>
      <Section>
        <:body>
          {{outlet}}
        </:body>
      </Section>
    </VStack>
  </template>
);
