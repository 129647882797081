import RouteTemplate from 'ember-route-template';
import { VStack } from 'tio-ui/components/layout';

interface SlrRouteSignature {
  Blocks: {
    default: [];
  };
}

export default RouteTemplate<SlrRouteSignature>(
  <template>
    <VStack>
      {{outlet}}
    </VStack>
  </template>
);
