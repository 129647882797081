import { LinkTo } from '@ember/routing';
import Component from '@glimmer/component';
import LoanSummaryChart from 'tio-employee/components/charts/loan-summary-chart';
import { t } from 'ember-intl';
import Tile from '../tile';
import type UserModel from 'tio-common/models/user';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';

export interface LoanSummarySignature {
  Args: {
    user: UserModel;
  };
}

export default class LoanSummary extends Component<LoanSummarySignature> {
  get person() {
    return this.args.user?.person;
  }

  get loans() {
    return this.person?.activeAccounts.map((account) => account.activeLoans).flat() || [];
  }

  get lastUpdatedOn() {
    return this.loans[0]?.lastUpdatedOn;
  }

  get loansTotal() {
    return this.loans.reduce((sum, loan) => {
      return (sum += loan.currentBalance);
    }, 0);
  }

  <template>
    <LinkTo @route="authenticated.account-activity.accounts">
      <Tile @headerText={{t "dashboard_tiles.loan_summary"}}>
        <:body>
          <p class="font-semibold">
            <span class="block uppercase text-sm">{{t "current_balance"}}</span>
            <span class="text-2xl">
              {{safeFormatNumber this.loansTotal style="currency" currency="USD"}}
            </span>
          </p>
          <LoanSummaryChart @loans={{this.loans}} />
          {{#if this.lastUpdatedOn}}
            <p class="text-xs text-gray-500">
              {{t "dashboard_tiles.last_updated"}}
              {{safeFormatDate this.lastUpdatedOn month="short" day="2-digit" year="numeric"}}
            </p>
          {{/if}}
        </:body>
      </Tile>
    </LinkTo>
  </template>
}
