import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { Header, Section, VStack } from 'tio-ui/components/layout';

export interface S {
  Args: {
    model: TasProgramInstanceModel;
  };
}
export default RouteTemplate<S>(
  <template>
    {{pageTitle (t "tuition_assistance.preapproval_app.default")}}
    <VStack>
      <Header>{{t "tuition_assistance.default"}}</Header>
      <Section>
        {{outlet}}
      </Section>
    </VStack>
  </template>
);
