import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
// NOT USED
export default RouteTemplate(
  <template>
    <div class="flex flex-col h-screen container mx-auto">
      <nav class="bg-white">
        <img
          src="https://tuitionio-production-images.s3.amazonaws.com/companies/tuitionio.png"
          alt={{t "login.tio_company"}}
          width="180"
          height="50"
          class="mx-auto p-1 mt-6"
          data-test-logo
        />
      </nav>

      <h2 class="mt-6 text-center text-lg">{{t "logout.in_progress"}}</h2>
    </div>
  </template>
);
