import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { Footer, Modal } from 'tio-ui/components/modal';
import { formatAsDollars } from 'tio-common/utils/format';
import { isEmpty, not, or } from 'tio-ui/utilities';
import { sumByAttribute } from 'tio-common/utils/math';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import Table from 'tio-common/components/table/index';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import TioConfirmAlert from 'tio-common/components/tio/confirm-alert';
import type MatchPaymentModel from 'tio-common/models/match-payment';
import type MatchPlanModel from 'tio-common/models/match-plan';
import { Section } from 'tio-ui/components/layout';

type MatchSummarySignature = {
  Args: {
    qualifiedPayments: Array<MatchPaymentModel>;
    certifiedPayments: Array<MatchPaymentModel>;
    unqualifiedPayments: Array<MatchPaymentModel>;
    onCertifyPayments: (payments: Array<MatchPaymentModel>) => Promise<void>;
    matchPlan: MatchPlanModel;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
};

class MatchSummaryComponent extends Component<MatchSummarySignature> {
  @tracked showExplainAlert = false;
  @tracked showCertifyPaymentsModal = false;
  @tracked loanPaymentsConfirmed = false;
  @tracked selectedPayments: Array<MatchPaymentModel> = [];
  @tracked certifyingPayments = false;

  @action
  onToggleExplainAlert(): void {
    this.showExplainAlert = !this.showExplainAlert;
  }

  @action
  onClickCertifyPayments(): void {
    this.showCertifyPaymentsModal = true;
  }

  paymentIsSelected = (payment: MatchPaymentModel): boolean => {
    return this.selectedPayments.includes(payment);
  };

  // O-O pattern won out in my head over the functional one here in my head; seems like
  // ember data wants it done this way anyway
  @action
  togglePaymentSelected(payment: MatchPaymentModel): void {
    // copy selected payments array
    const newSelectedPayments = [...this.selectedPayments];
    // look for a payment in it
    const paymentIdx = newSelectedPayments.findIndex((current) => current === payment);
    if (paymentIdx > -1) {
      // found the payment, splice it out of the new array
      newSelectedPayments.splice(paymentIdx, 1);
    } else {
      // didn't find the payment, push it onto the new array
      newSelectedPayments.push(payment);
    }
    // uncheck confirmation if last payment was deselected
    if (isEmpty(newSelectedPayments)) this.loanPaymentsConfirmed = false;
    // set component array to the new one
    this.selectedPayments = newSelectedPayments;
  }

  @action
  openCertifyPayments(): void {
    this.showCertifyPaymentsModal = true;
  }

  @action
  closeCertifyPayments(): void {
    this.showCertifyPaymentsModal = false;
    this.selectedPayments = [];
    this.loanPaymentsConfirmed = false;
  }

  @action
  toggleLoanPaymentsConfirmed(): void {
    this.loanPaymentsConfirmed = !this.loanPaymentsConfirmed;
  }

  @action
  async certifySelectedPayments(): Promise<void> {
    try {
      this.certifyingPayments = true;
      await this.args.onCertifyPayments(this.selectedPayments);
      this.closeCertifyPayments();
      this.selectedPayments = [];
      this.loanPaymentsConfirmed = false;
    } catch (e) {
      console.log(e);
    } finally {
      this.certifyingPayments = false;
    }
  }

  get deadlineValue() {
    const today = new Date();
    const thisYear = today.getFullYear();
    const urlParams = new URLSearchParams(window.location.search);
    const year = decodeURIComponent(urlParams.get('benefitYear') || '') || thisYear;
    const yearsKey = 'benefit_years';
    const dateKey = 'final_certification_date';
    return this.args.matchPlan.metadata[yearsKey][year.toString()]?.[dateKey];
  }

  <template>
    <Section>
      <:header>
        {{! TODO: shouldn't need to inject this class into a meaningless div, should
        have a way for the GenericCard to handle this if these are the actual styles we
        want the title section to have }}
        <div class="mb-4 text-2xl">
          {{t "syf.dashboard.match.summary.title"}}
        </div>
      </:header>
      <:body>
        <dl class="mb-3 inline-block">
          <div class="flex flex-row">
            <dt class="mr-6">{{t "syf.dashboard.match.summary.deadline_label"}}</dt>
            {{! @glint-expect-error}}
            <dd class="mr-6 font-semibold">{{this.deadlineValue}}</dd>
          </div>
        </dl>
        {{#unless (isEmpty @qualifiedPayments)}}
          <TioClickableText
            @onClick={{this.openCertifyPayments}}
            @icon="priority_high"
            @iconLeft={{true}}
            @outlined={{true}}
            @iconClass="!text-error-400 !border-error-400 text-sm !h-5 !w-5"
            @textClass="tio-anchor"
            class="inline-block"
          >
            {{t "syf.dashboard.match.summary.certify_link_text"}}
          </TioClickableText>
        {{/unless}}
        <Table @isLoading={{false}}>
          <:thead as |options|>
            <options.tr>
              <options.th />
              <options.th>{{t "syf.dashboard.match.summary.table.count_header"}}</options.th>
              <options.th>{{t "syf.dashboard.match.summary.table.amount_header"}}</options.th>
            </options.tr>
          </:thead>
          <:tbody as |options|>
            <options.tr>
              <options.td>{{t "syf.dashboard.match.summary.table.certified_label"}}</options.td>
              <options.td>{{@certifiedPayments.length}}</options.td>
              <options.td>{{formatAsDollars
                  (sumByAttribute @certifiedPayments "transactionAmount")
                }}</options.td>
            </options.tr>
            <options.tr>
              <options.td>{{t "syf.dashboard.match.summary.table.qualified_label"}}</options.td>
              <options.td>{{@qualifiedPayments.length}}</options.td>
              <options.td>{{formatAsDollars
                  (sumByAttribute @qualifiedPayments "transactionAmount")
                }}</options.td>
            </options.tr>
            <options.tr>
              <options.td>{{t "syf.dashboard.match.summary.table.unqualified_label"}}</options.td>
              <options.td>{{@unqualifiedPayments.length}}</options.td>
              <options.td>{{formatAsDollars
                  (sumByAttribute @unqualifiedPayments "transactionAmount")
                }}</options.td>
            </options.tr>
          </:tbody>
        </Table>
        <TioClickableText
          @onClick={{this.onToggleExplainAlert}}
          @icon="question_mark"
          @iconLeft={{true}}
          @textClass="text-sm text-ocean-600"
          @iconClass="text-sm !h-5 !w-5"
        >
          {{t "syf.dashboard.match.summary.explanation.label"}}
        </TioClickableText>
      </:body>
    </Section>
    <Modal
      @isOpen={{this.showCertifyPaymentsModal}}
      @onClose={{this.closeCertifyPayments}}
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>{{t "syf.dashboard.match.summary.certify_payments.header"}}</m.Header>
      <m.Body>
        <p>{{t "syf.dashboard.match.summary.certify_payments.subheader"}}</p>
        <table class="w-full mb-6 text-lg text-tio-gray-700">
          <thead class="bg-ocean-50">
            <tr class="py-3">
              <th class="px-1 py-1">
                {{t "syf.dashboard.match.summary.certify_payments.table.select"}}
              </th>
              <th class="px-1 py-1">
                {{t "syf.dashboard.match.summary.certify_payments.table.servicer"}}
              </th>
              <th class="px-1 py-1">
                {{t "syf.dashboard.match.summary.certify_payments.table.date"}}
              </th>
            </tr>
          </thead>
          <tbody>
            {{#each @qualifiedPayments as |payment|}}
              <tr class="py-2">
                <td class="py-2 text-center">
                  <FormCheckbox
                    data-legacy-input
                    @onChange={{fn this.togglePaymentSelected payment}}
                    @checked={{this.paymentIsSelected payment}}
                    class="cursor-pointer"
                    @containerClass="inline-block"
                  />
                </td>
                <td
                  class="py-2 text-center"
                >{{payment.accountTransaction.financialInstitution.name}}</td>
                <td class="py-2 text-center">{{payment.transactionDate}}</td>
              </tr>
            {{/each}}
          </tbody>
        </table>
        <FormCheckbox
          data-legacy-input
          @onChange={{this.toggleLoanPaymentsConfirmed}}
          @checked={{this.loanPaymentsConfirmed}}
          disabled={{isEmpty this.selectedPayments}}
          class="cursor-pointer"
        >
          {{t "syf.shared.match_plan.default_verify_payments_statement"}}
        </FormCheckbox>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.certifyingPayments}}
          @onSubmit={{this.certifySelectedPayments}}
          @submitText="Certify"
          @onCancel={{this.closeCertifyPayments}}
          @submitDisabled={{or (not this.loanPaymentsConfirmed) this.certifyingPayments}}
        />
      </m.Footer>
    </Modal>
    <TioConfirmAlert
      @show={{this.showExplainAlert}}
      {{! this should probably be optional & false by default }}
      @disabled={{false}}
      @hideCancel={{true}}
      {{! this should be also optional if cancel button can be hidden }}
      @onCancel={{this.onToggleExplainAlert}}
      @onConfirm={{this.onToggleExplainAlert}}
      @confirmButtonText="close"
    >
      <h1 class="mb-2 text-left font-semibold text-tio-gray-700">
        {{t "syf.dashboard.match.summary.explanation.section.one.title"}}
      </h1>
      <p class="mb-4 text-left text-tio-gray-700">
        {{t "syf.dashboard.match.summary.explanation.section.one.content"}}
      </p>
      <h1 class="mb-2 text-left font-semibold text-tio-gray-700">
        {{t "syf.dashboard.match.summary.explanation.section.two.title"}}
      </h1>
      <p class="mb-4 text-left text-tio-gray-700">
        {{t "syf.dashboard.match.summary.explanation.section.two.content"}}
      </p>
      <h1 class="mb-2 text-left font-semibold text-tio-gray-700">
        {{t "syf.dashboard.match.summary.explanation.section.three.title"}}
      </h1>
      <p class="mb-4 text-left text-tio-gray-700">
        {{t "syf.dashboard.match.summary.explanation.section.three.content"}}
      </p>
    </TioConfirmAlert>
  </template>
}

export default MatchSummaryComponent;
