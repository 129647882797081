import { t } from 'ember-intl';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';

interface AuthRegisterNotLaunchedComponentSignature {
  Args: {
    companyName: string;
    date: string;
    days: string;
  };
}

const AuthRegisterNotLaunchedComponent: TOC<AuthRegisterNotLaunchedComponentSignature> = <template>
  <div class="p-5">
    <p class="text-xl mb-6">
      {{t
        "login.not_launched"
        companyName=@companyName
        date=(safeFormatDate @date month="short" day="2-digit" year="numeric")
        days=@days
      }}
    </p>
  </div>
</template>;

export default AuthRegisterNotLaunchedComponent;
