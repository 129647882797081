import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import PslfSignFormPage from 'tio-employee/components/pslf/sign-form-page';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type PslfFormSignController from 'tio-employee/controllers/authenticated/pslf/form/sign';
import { Header, Section, VStack } from 'tio-ui/components/layout';

export interface S {
  Args: {
    model: PslfFormModel;
    controller: PslfFormSignController;
  };
}

export default RouteTemplate<S>(
  <template>
    <VStack>
      <Header>{{t "pslf.default_full"}}</Header>
      <Section>
        <:body>
          <PslfSignFormPage @form={{@model}} @signer={{@controller.signerType}} />
        </:body>
      </Section>
    </VStack>
  </template>
);
