import Route from '@ember/routing/route';
import type StoreService from 'tio-common/services/store';
import { service } from '@ember/service';
import type SessionContextService from 'tio-employee/services/session-context';

export default class AuthenticatedCompaniesReportingTasApplicationsRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  queryParams = {
    applicationStatus: { refreshModel: false },
    applicationCreatedAtStart: { refreshModel: false },
    coursesEndDate: { refreshModel: false },
    includeCourses: { refreshModel: false },
    includePayments: { refreshModel: false },
    terminatedEmployees: { refreshModel: false },
    includeInvoiceDate: { refreshModel: false },
    excludeTermVerified: { refreshModel: false },
  };

  async model() {
    const id = this.sessionContext.currentRole?.company.id;

    return (
      this.store.peekRecord('company', id) ||
      (await this.store.findRecord('company', id, { include: 'company-setting' }))
    );
  }
}
