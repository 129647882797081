import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AuthLoginLoginForm from 'tio-employee/components/auth/login/login-form';
import Component from '@glimmer/component';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import emailSchema from 'tio-common/validation-schema/models/email';
import IntlService from 'ember-intl/services/intl';
import NotLaunched from 'tio-employee/components/auth/register/not-launched';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import type LoginController from 'tio-employee/controllers/login';
import type Owner from '@ember/owner';
import type PreRegisterService from '../services/pre-register';

export interface LoginRouteComponentSignature {
  Args: {
    controller: LoginController;
  };
}

class LoginRoute extends Component<LoginRouteComponentSignature> {
  @service declare preRegister: PreRegisterService;
  @service declare intl: IntlService;

  @tracked email = '';
  @tracked emailLookupError: string | null = null;
  @tracked emailLookupIsLoading = false;

  constructor(owner: Owner, args: LoginRouteComponentSignature['Args']) {
    super(owner, args);
    this.email = decodeURIComponent(this.args.controller.email || '');
  }

  get validationErrors() {
    try {
      // @ts-expect-error: same as email-field.gts
      emailSchema.fields.email?.validateSync?.(this.email);
    } catch (err) {
      return err.errors || [];
    }
    return [];
  }

  get settings() {
    return this.preRegister.settings;
  }

  get company() {
    return this.preRegister.sparseCompany;
  }

  get companyName() {
    return this.company?.name || '';
  }

  get daysUntilLaunch() {
    const days = this.preRegister.daysUntilLaunch || 0;
    return `${days} day${days === 1 ? '' : 's'}`;
  }

  get isUsingSso() {
    return !!(
      this.settings?.ssoAuthUrl &&
      this.settings?.idProvider &&
      this.settings?.idProvider !== 'NONE'
    );
  }

  get selfRegisterCompany() {
    return this.preRegister.settings.register['isSelfRegisterAllowed'] || false;
  }

  get signUpRoute() {
    return this.selfRegisterCompany ? '/self-registration' : '/register';
  }

  get showNotLaunched() {
    return !this.preRegister.isLaunched && !!this.preRegister.launchDate;
  }

  <template>
    {{pageTitle (t "login.page_title")}}
    <EmailLookupWrapper @route="login">
      {{#if this.showNotLaunched}}
        <NotLaunched
          @companyName={{this.companyName}}
          @date={{this.preRegister.launchDate}}
          @days={{this.daysUntilLaunch}}
        />
      {{else if this.preRegister.settings.ssoAuthUrl}}
        <div class="text-center mb-6">
          <h1 class="text-2xl my-2">
            {{t "login.sso_redirect"}}
          </h1>
        </div>
      {{else}}
        <div class="text-center mb-6">
          <h1 class="text-2xl my-2">
            {{t "login.log_in"}}
          </h1>

          <div class="mb-6 text-md">
            {{t "login.new_to_tuition"}}
            <a class="underline text-ocean-600" href={{this.signUpRoute}}>
              {{t "login.sign_up"}}
            </a>
          </div>
        </div>
        <h2 class="text-lg text-center mb-4">
          {{t "login.enter_email_and_pw"}}
        </h2>
        <AuthLoginLoginForm @email={{this.email}} />
      {{/if}}
    </EmailLookupWrapper>
  </template>
}

export default RouteTemplate(LoginRoute);
