import { action } from '@ember/object';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import PslfDashboardCardInfo from 'tio-employee/components/pslf/dashboard/card-info';
import PslfDashboardTodo from 'tio-employee/components/pslf/dashboard/todo';
import PslfDashboardTodoCheckbox from 'tio-employee/components/pslf/dashboard/todo/checkbox';
import type AnswerProfileModel from 'tio-common/models/answer-profile';

export interface RecommendationsRecertifyTodoSignature {
  Args: {
    answerProfile: AnswerProfileModel;
  };
}

export default class RecommendationsRecertifyTodoComponent extends Component<RecommendationsRecertifyTodoSignature> {
  get answerProfile() {
    return this.args.answerProfile;
  }

  get isCompleted() {
    if (this.answerProfile?.hasExpiredRecertification) {
      return false;
    }
    return !!this.answerProfile?.recertifiedIncomeAt;
  }

  @action
  async toggleCompleted() {
    const profile = this.answerProfile;
    try {
      profile.recertifiedIncomeAt = this.isCompleted ? '' : new Date().toISOString();

      await profile.save();
    } catch (e) {
      console.error(e);
      profile?.rollbackAttributes();
    }
  }

  <template>
    <PslfDashboardTodo
      @title={{t "recommendations.recertify_income"}}
      @isComplete={{this.isCompleted}}
    >
      <:leftHeader>
        <PslfDashboardTodoCheckbox
          @checked={{this.isCompleted}}
          @onClick={{this.toggleCompleted}}
        />
      </:leftHeader>
      <:body>
        <PslfDashboardCardInfo
          @icon="calculate"
          @text={{t "recommendations.need_recertify_income"}}
          class="w-fit"
        />
      </:body>
      <:actions>
        <a
          href="https://studentaid.gov/idr/"
          target="_blank"
          rel="noreferrer noopener"
          class="text-ocean-500 hover:text-ocean-700"
        >
          {{t "recommendations.recertify_income"}}
        </a>
      </:actions>
    </PslfDashboardTodo>
  </template>
}
