import NewTypeOneOrFourProgram from 'tio-employee/components/tas/programs/new/type-one-or-four';
import RouteTemplate from 'ember-route-template';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasGroupModel from 'tio-common/models/tas-group';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';
import { VStack } from 'tio-ui/components/layout';

export interface S {
  Args: {
    model: {
      activeInstances: TASProgramInstanceModel[];
      programInstance: TASProgramInstanceModel;
      selectedSchool: TasUnifiedInstitutionModel | undefined;
      tasGroups: TasGroupModel[];
    };
  };
}

export default RouteTemplate<S>(
  <template>
    <VStack>
      <NewTypeOneOrFourProgram @model={{@model}} />
    </VStack>
  </template>
);
