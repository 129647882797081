import { eq } from 'tio-ui/utilities';
import { Section, VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import PlanContributionCard from 'tio-employee/components/plan/contribution-card';
import PlanWellnessCard from 'tio-employee/components/plan/wellness-card';
import RouteTemplate from 'ember-route-template';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type PlanModel from 'tio-common/models/plan';

interface S {
  Args: {
    model: PlanModel;
  };
}

export default RouteTemplate<S>(
  <template>
    <VStack>
      <TioPageBreadcrumbs as |b|>
        <b.crumb @route="authenticated.admin.employees.index" @label="Employees" />
        <b.crumb
          @route="authenticated.admin.plans"
          @label={{t "plans.plans"}}
          @model={{@model.id}}
        />
      </TioPageBreadcrumbs>
      <Section>
        <:header>{{t "plans.wellness_plan"}}</:header>
        <:body>
          {{#each @model.plans as |plan|}}
            {{#if (eq plan.cadence "NEVER")}}
              <PlanWellnessCard @plan={{plan}} />
            {{/if}}
          {{/each}}
        </:body>
      </Section>
      <Section>
        <:header>{{t "plans.contribution_plans"}}</:header>
        <:body>
          {{#each @model.plans as |plan|}}
            {{#unless (eq plan.cadence "NEVER")}}
              <PlanContributionCard @plan={{plan}} />
            {{/unless}}
          {{/each}}
        </:body>
      </Section>
    </VStack>
  </template>
);
