import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';

export default class TuitionAssistanceDashboardProgramHeader extends Component {
  @service declare router: RouterService;
  @service declare sessionContext: SessionContextService;

  get isDependentProgramsEnabled() {
    return (
      this.sessionContext.currentEmployee?.company.companySetting.tuitionAssistance
        ?.isDependentProgramsEnabled || false
    );
  }

  get showDependentProgramsButton(): boolean {
    return (
      this.router.currentRouteName === 'authenticated.tas.dashboard' &&
      this.isDependentProgramsEnabled
    );
  }

  <template>
    <div class="flex justify-between items-center">
      <h2 class="text-lg font-semibold">
        {{t "common.tuition_assistance.dashboard.program_header"}}
      </h2>
      {{#if this.showDependentProgramsButton}}
        <div>
          <Button
            @intent="primary"
            @appearance="outlined"
            {{on "click" (transitionTo "authenticated.tas.dependent-programs")}}
            data-test-dependent-programs-button
          >
            <span class="flex items-end">{{svgJar "people-outline-24" class="mr-2"}}
              {{t "common.tuition_assistance.dependent_programs_button"}}
            </span>
          </Button>
        </div>
      {{/if}}
    </div>
  </template>
}
