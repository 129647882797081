import RouteTemplate from 'ember-route-template';
import QuestionsLoanForgiveness from 'tio-employee/components/questions/loan-forgiveness';

export default RouteTemplate(
  <template>
    <QuestionsLoanForgiveness
      @indexRoute="authenticated.repaying-student-debt.loan-forgiveness.index"
      @incomeRoute="authenticated.repaying-student-debt.loan-forgiveness.income-driven"
      @teacherRoute="authenticated.repaying-student-debt.loan-forgiveness.teacher"
      @stateRoute="authenticated.repaying-student-debt.loan-forgiveness.state-and-local"
    >
      {{outlet}}
    </QuestionsLoanForgiveness>
  </template>
);
