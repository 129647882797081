import { t } from 'ember-intl';
import AppPageHeroSection from 'tio-employee/components/app/page/hero-section';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { Section, VStack } from 'tio-ui/components/layout';

interface QuestionsLoanForgivenessSignature {
  Args: {
    incomeRoute: string;
    indexRoute: string;
    stateRoute: string;
    teacherRoute: string;
  };
  Blocks: {
    default: [];
  };
}

const QuestionsLoanForgivenessComponent: TOC<QuestionsLoanForgivenessSignature> = <template>
  <VStack>
    <Section>
      <:body>
        <AppPageHeroSection class="-mx-4 -mt-4">
          <:title>
            {{t "pslf.questions.what_is_loan_forgiveness_q"}}
          </:title>
          <:body>
            {{t "pslf.questions.what_is_loan_forgiveness_a"}}
          </:body>
        </AppPageHeroSection>
        <h1 class="pt-6 text-lg font-semibold text-gray-600">
          {{t "pslf.questions.whats_the_catch.title"}}
        </h1>
        <div class="flex flex-col sm:grid sm:grid-cols-3 gap-4">
          <p>{{t "pslf.questions.whats_the_catch.catch_1"}}</p>
          <p>{{t "pslf.questions.whats_the_catch.catch_2"}}</p>
          <p>{{t "pslf.questions.whats_the_catch.catch_3"}}</p>
        </div>
        <TioPageTabs class="pt-5" as |tabs|>
          <tabs.tab
            @route={{@indexRoute}}
            @label="{{t 'pslf.questions.public_service.tab_title'}}"
          />
          <tabs.tab
            @route={{@incomeRoute}}
            @label="{{t 'pslf.questions.income_driven.tab_title'}}"
          />
          <tabs.tab @route={{@teacherRoute}} @label="{{t 'pslf.questions.teacher.tab_title'}}" />
          <tabs.tab
            @route={{@stateRoute}}
            @label="{{t 'pslf.questions.state_and_local.tab_title'}}"
          />
        </TioPageTabs>
        {{yield}}
      </:body>
    </Section>
  </VStack>
</template>;

export default QuestionsLoanForgivenessComponent;
