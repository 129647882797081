import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class ObservabilityConfirmController extends Controller {
  @tracked source = '';
  @tracked providers = '';

  // NB: see https://guides.emberjs.com/v5.8.0/typescript/core-concepts/routing/#toc_controllers
  // for query param definition here
  queryParams = [
    {
      source: { type: 'string' as const },
      // NB: providers param intended as comma delimited list
      providers: { type: 'string' as const },
    },
  ];
}
