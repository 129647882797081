import Component from '@glimmer/component';
import { t } from 'ember-intl';
import DescriptionList from 'tio-ui/components/description-list';
import { VStack } from 'tio-ui/components/layout';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import {
  getProvider,
  getServicerName,
  getBalance,
  getAccountNumber,
  getLoans,
  getTransactions,
} from 'tio-common/helpers/observability';
import Table from 'tio-common/components/table/index';

const numberize = (val: unknown): number => Number(val);

const formatTransactionType = (transactionType: string): string => {
  switch (transactionType) {
    case 'TransactionType.PAYMENT':
    case 'payment':
      return 'account_transaction.transaction_types.payment';
    case 'TransactionType.INTEREST':
    case 'interest':
      return 'account_transaction.transaction_types.interest';
    default:
      return 'account_transaction.transaction_types.ambiguous';
  }
};

interface ExtractionSummarySignature {
  Args: {
    document: ObservableDocumentModel;
  };
  Element: HTMLElement;
}

class ExtractionSummary extends Component<ExtractionSummarySignature> {
  <template>
    <article ...attributes>
      <header class="tio-h2 my-4">
        {{t "observable_document.provider" provider=(getProvider @document)}}
      </header>
      <body>
        <VStack class="px-0">
          <DescriptionList @compact={{true}} as |dl|>
            {{#if (getServicerName @document)}}
              <dl.Group>
                <dl.Term class="font-semibold">{{t
                    "observability.document.summary.servicer"
                  }}</dl.Term>
                <dl.Details>{{getServicerName @document}}</dl.Details>
              </dl.Group>
            {{/if}}
            {{#if (getBalance @document)}}
              <dl.Group>
                <dl.Term class="font-semibold">{{t
                    "observability.document.summary.balance"
                  }}</dl.Term>
                <dl.Details>{{safeFormatNumber
                    (getBalance @document)
                    style="currency"
                    currency="USD"
                  }}</dl.Details>
              </dl.Group>
            {{/if}}
            {{#if (getAccountNumber @document)}}
              <dl.Group>
                <dl.Term class="font-semibold">{{t
                    "observability.document.summary.account"
                  }}</dl.Term>
                <dl.Details>{{getAccountNumber @document}}</dl.Details>
              </dl.Group>
            {{/if}}
          </DescriptionList>
          {{#let (getLoans @document) (getTransactions @document) as |loans transactions|}}
            {{#if loans.length}}
              <Table @isLoading={{false}}>
                <:thead as |head|>
                  <head.tr>
                    <head.th>{{t "observability.document.summary.loans.name"}}</head.th>
                    <head.th>{{t "observability.document.summary.loans.balance"}}</head.th>
                    <head.th>{{t "observability.document.summary.loans.originated"}}</head.th>
                  </head.tr>
                </:thead>
                <:tbody as |body|>
                  {{#each loans as |loan|}}
                    <body.tr>
                      <body.td>{{loan.name}}</body.td>
                      <body.td>{{safeFormatNumber
                          loan.balance
                          style="currency"
                          currency="USD"
                        }}</body.td>
                      <body.td>{{loan.originated}}</body.td>
                    </body.tr>
                  {{/each}}
                </:tbody>
              </Table>
            {{/if}}
            {{#if transactions.length}}
              <Table @isLoading={{false}}>
                <:thead as |head|>
                  <head.tr>
                    <head.th>{{t "account_transaction.transaction_date"}}</head.th>
                    <head.th>{{t "account_transaction.transaction_type"}}</head.th>
                    <head.th>{{t "account_transaction.amount"}}</head.th>
                  </head.tr>
                </:thead>
                <:tbody as |body|>
                  {{#each transactions as |transaction|}}
                    <body.tr>
                      <body.td>{{transaction.transaction_date}}</body.td>
                      <body.td>{{t (formatTransactionType transaction.transaction_type)}}</body.td>
                      <body.td>{{safeFormatNumber
                          (numberize transaction.amount)
                          style="currency"
                          currency="USD"
                        }}</body.td>
                    </body.tr>
                  {{/each}}
                </:tbody>
              </Table>
            {{/if}}
          {{/let}}
        </VStack>
      </body>
    </article>
  </template>
}

export default ExtractionSummary;
