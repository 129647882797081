import RouteTemplate from 'ember-route-template';
import TuitionAssistanceFormsCertificateCertificateDetails from '../../../../../../components/tuition-assistance/forms/certificate/certificate-details';
import type TasApplication from 'tio-common/models/tas-application';
import type TasCourse from 'tio-common/models/tas-course';
import type TasProgramInstance from 'tio-common/models/tas-program-instance';

interface TAProgramInstanceCertificateNewRouteComponentSignature {
  Args: {
    model: {
      instance: TasProgramInstance;
      application: TasApplication;
      course: TasCourse;
    };
  };
}

export default RouteTemplate<TAProgramInstanceCertificateNewRouteComponentSignature>(
  <template><TuitionAssistanceFormsCertificateCertificateDetails @model={{@model}} /></template>
);
