import { action } from '@ember/object';
import { service } from '@ember/service';
import AppHeaderBanner from './header/banner';
import AppHeaderCurrentUserMenu from './header/current-user-menu';
import Component from '@glimmer/component';
import TioHeaderMenuButton from 'tio-common/components/tio/header/menu-button';
import type SessionContextService from 'tio-employee/services/session-context';
import type SideDrawerService from 'tio-common/services/side-drawer';
import type RoleViewModel from 'tio-common/models/role-view';
import { tracked } from '@glimmer/tracking';
import type Owner from '@ember/owner';

interface AppHeaderSignature {
  Element: HTMLHeadElement;
}

export default class AppHeaderComponent extends Component<AppHeaderSignature> {
  @service declare sessionContext: SessionContextService;
  @service declare sideDrawer: SideDrawerService;

  @tracked currentRole?: RoleViewModel;

  constructor(owner: Owner, args: never) {
    super(owner, args);
    this.currentRole = this.sessionContext.currentRole;
  }

  @action
  toggleDrawer() {
    this.sideDrawer.toggleDrawer();
  }

  @action
  setCurrentRole(role: RoleViewModel) {
    this.sessionContext.setCurrentRole(role);
    this.currentRole = role;
  }

  <template>
    <header class="isolate top-0 bg-white z-5" ...attributes>
      <div class="flex items-center justify-between border-b border-gray-200 px-2">
        {{! template-lint-disable simple-unless }}
        <TioHeaderMenuButton @onClick={{this.toggleDrawer}} class="lg:hidden" />
        <AppHeaderBanner @company={{this.sessionContext.currentRole.company}} />

        <div class="h-14 header-right">
          <div class="relative inline-block text-left">
            <AppHeaderCurrentUserMenu
              @user={{this.sessionContext.user}}
              @roles={{this.sessionContext.roles}}
              {{! @glint-expect-error: current role should never be undefined}}
              @currentRole={{this.currentRole}}
              @onRoleChange={{this.setCurrentRole}}
              @onLogout={{this.sessionContext.logout}}
            />
          </div>
        </div>
      </div>
    </header>
  </template>
}
