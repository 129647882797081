import MaterialIcon from 'tio-common/components/material-icon';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';

interface PslfDashboardCardInfoSignature {
  Args: {
    data?: string;
    icon: string;
    text?: string;
  };
  Blocks: {
    info: [];
  };
  Element: HTMLDivElement;
}

const PslfDashboardCardInfoComponent: TOC<PslfDashboardCardInfoSignature> = <template>
  <div class="flex flex-col items-center w-full" ...attributes>
    <MaterialIcon @icon={{@icon}} class="text-ocean-600 text-4xl leading-none" />
    {{#if (has-block "info")}}
      {{yield to="info"}}
    {{else}}
      <p>{{@text}}</p>
      <p class="font-bold">{{@data}}</p>
    {{/if}}
  </div>
</template>;

export default PslfDashboardCardInfoComponent;
