import { action } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { not } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AcceptConditions from '../accept-conditions';
import Component from '@glimmer/component';
import programLabelFor from 'tio-common/helpers/program-label-for';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import type ConditionModel from 'tio-common/models/condition';
import type RouterService from '@ember/routing/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';

const string = String;

export interface TuitionAssistanceFormsApprovalCoursesCompletionReviewSignature {
  Args: {
    model: TasApplicationModel;
  };
}

export default class TuitionAssistanceFormsApprovalCoursesCompletionReview extends Component<TuitionAssistanceFormsApprovalCoursesCompletionReviewSignature> {
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare router: RouterService;

  @tracked acceptedConditions: ConditionModel[] = [];
  @tracked hasSubmitted = false;
  @tracked didSubmitSuccessfully = false;
  @tracked submitError = null;

  get conditionsForSubmit() {
    return (
      this.args.model.tasProgramInstance?.tasProgramTemplate
        ?.applicationApproveEvidenceConditions || []
    );
  }

  get hasAgreedAllConditions() {
    if (!this.conditionsForSubmit.length) {
      return true;
    }
    return this.conditionsForSubmit.length === this.acceptedConditions.length;
  }

  get isFormValid() {
    return this.hasAgreedAllConditions;
  }

  @action
  didUpdateAcceptedConditions(conditions: ConditionModel[] = []) {
    this.acceptedConditions = conditions;
  }

  submitTask = dropTask(async () => {
    if (!this.isFormValid || !this.hasAgreedAllConditions) {
      return;
    }
    try {
      this.hasSubmitted = true;
      this.submitError = null;
      await this.saveAgreementsForConditions.perform();
      await this.store.adapterFor('tas-application').requestCourseEvidence(this.args.model);
      this.didSubmitSuccessfully = true;
      this.router.transitionTo(
        'authenticated.tuition-assistance.programs.instance.course-applications.show'
      );
    } catch (e) {
      console.error(e);
      this.submitError = e;
    }
  });

  saveAgreementsForConditions = dropTask(async () => {
    const user = this.sessionContext.user;
    const agreements = this.acceptedConditions.map((condition) => {
      return this.store.createRecord('agreement', {
        user: user,
        condition: condition,
      });
    });
    const promises = agreements.map((agreement) => {
      return agreement.save();
    });
    const saved = await all(promises);
    return saved;
  });

  <template>
    <div class="grid grid-cols-5">
      <p class="font-semibold sm:col-start-2 col-span-3 uppercase">
        {{t "tuition_assistance.program_details.courses_added"}}
      </p>
      <div class="col-start-1 sm:col-start-2 col-span-3">
        <table class="table-auto">
          <thead>
            <tr class="text-left">
              <th class="text-left px-3">
                {{programLabelFor
                  "COURSE_NUMBER"
                  programTemplate=@model.tasProgramInstance.tasProgramTemplate
                }}
              </th>
              <th class="text-left px-3 min-w-32">
                {{programLabelFor
                  "COURSE_DESCRIPTION"
                  programTemplate=@model.tasProgramInstance.tasProgramTemplate
                }}
              </th>
              <th class="text-center px-3">
                {{programLabelFor
                  "COURSE_GRADE"
                  programTemplate=@model.tasProgramInstance.tasProgramTemplate
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            {{#each @model.activeCourses as |course|}}
              <tr>
                <td class="p-3">{{course.courseNumber}}</td>
                <td class="p-3">{{course.courseDescription}}</td>
                <td class="p-3 text-center">
                  {{string (getSingleValueForTasField "COURSE_GRADE" course.fields)}}
                </td>
              </tr>
            {{/each}}
          </tbody>
        </table>
      </div>
    </div>

    <section class="mt-10">
      {{#if this.conditionsForSubmit.length}}
        <AcceptConditions
          @conditions={{this.conditionsForSubmit}}
          @onChange={{this.didUpdateAcceptedConditions}}
        />
      {{/if}}

      <TioErrorMessages
        @showErrors={{this.hasSubmitted}}
        @error={{this.submitError}}
        class="text-center"
      />

      <div class="flex justify-center my-5">
        <Button
          @intent="primary"
          {{on "click" this.submitTask.perform}}
          @isRunning={{this.submitTask.isRunning}}
          disabled={{not this.isFormValid}}
          class="mx-8 w-48"
        >
          {{t "submit"}}
        </Button>
      </div>
    </section>
  </template>
}
