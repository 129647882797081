import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import { Header, Section, VStack } from 'tio-ui/components/layout';

export default RouteTemplate(
  <template>
    <VStack>
      <Header>{{t "askjeni.student_loan_coaches"}}</Header>
      <TioPageTabs as |tabs|>
        <tabs.tab
          @label={{t "askjeni.about.tab_title"}}
          @route="authenticated.repaying-student-debt.askjeni.index"
        />
        <tabs.tab
          @label={{t "askjeni.schedule_time.tab_title"}}
          @route="authenticated.repaying-student-debt.askjeni.schedule"
        />
      </TioPageTabs>
      <Section>
        <:body>
          {{outlet}}
        </:body>
      </Section>
    </VStack>
  </template>
);
