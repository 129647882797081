import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type StoreService from 'tio-common/services/store';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type { AuthenticatedSyfRouteModel } from 'tio-employee/routes/authenticated/syf';
import { getTranslationKeyForReportedSalaryForMatchPlan } from 'tio-common/utils/syf';
import PostEnrollment from 'tio-employee/components/syf/post-enrollment';
import type EmailAddressModel from 'tio-common/models/email-address';
import type Owner from '@ember/owner';
import { VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import { currencyStringToNumber } from 'tio-common/utils/format';

interface SyfPostEnrollmentRouteComponentSignature {
  Blocks: {
    default: [];
  };
  Args: {
    model: AuthenticatedSyfRouteModel;
  };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
class SyfPostEnrollmentRouteComponent extends Component<SyfPostEnrollmentRouteComponentSignature> {
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare router: RouterService;

  @tracked loading: boolean = false;
  @tracked emailAddress!: EmailAddressModel;

  constructor(owner: Owner, args: SyfPostEnrollmentRouteComponentSignature['Args']) {
    super(owner, args);

    if (this.args.model.emailAddress?.isPersonal) {
      // if found, register existing personal email address as tracked var
      this.emailAddress = this.args.model.emailAddress;
    } else if (this.sessionContext.user?.personalEmail) {
      this.emailAddress = this.sessionContext.user?.personalEmail;
    }
  }

  @action
  async onSubmitPostEnrollmentData(emailAddress: string, reportedSalary: string) {
    try {
      if (!this.emailAddress) {
        this.emailAddress = this.store.createRecord('email-address', {
          contactType: 'PERSONAL',
          user: this.sessionContext.user,
          email: '',
          isActive: true,
        });
      }

      this.emailAddress.email = emailAddress;
      this.args.model.reportedSalary = currencyStringToNumber(reportedSalary);

      this.loading = true;
      // associate email address and match participant model
      this.args.model.emailAddress = this.emailAddress;
      await this.emailAddress.save();

      // does this need to be 2 round trips?
      await this.args.model.save();
      this.router.transitionTo('authenticated.syf.dashboard');
    } catch (e) {
      // TODO: handle this if it fails, something better than logging it
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  @action
  onSkipPostEnrollmentData() {
    this.router.transitionTo('authenticated.syf.dashboard');
  }

  <template>
    <VStack>
      <PostEnrollment
        @reportedSalaryFieldLabel={{t
          (getTranslationKeyForReportedSalaryForMatchPlan @model.matchPlan)
        }}
        @reportedSalary={{@model.reportedSalary}}
        @emailAddress={{this.emailAddress.email}}
        @loading={{this.loading}}
        @onSubmit={{this.onSubmitPostEnrollmentData}}
        @onSkip={{this.onSkipPostEnrollmentData}}
      />
    </VStack>
  </template>
}

export default RouteTemplate(SyfPostEnrollmentRouteComponent);
