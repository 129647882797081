import { action } from '@ember/object';
import { and } from 'tio-ui/utilities';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import changeApproverFormSchema from 'tio-common/validation-schema/forms/change-approver-form';
import Component from '@glimmer/component';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import TioAlert from 'tio-common/components/tio/alert';
import type { IntlService } from 'ember-intl';
import type Errors from 'tio-employee/types/errors';
import type PslfFormModel from 'tio-common/models/pslf-form';
import { Footer, Modal } from 'tio-ui/components/modal';
import type Owner from '@ember/owner';

export interface PslfMyDocumentsMyFormsTableChangeApproverSignature {
  Args: {
    form: PslfFormModel;
    didSave: (form: PslfFormModel) => Promise<void>;
    didCancel: () => void;
  };
}

export default class PslfMyDocumentsMyFormsTableChangeApproverComponent extends Component<PslfMyDocumentsMyFormsTableChangeApproverSignature> {
  @service declare intl: IntlService;

  @tracked borrowerEmployerEmail = '';
  @tracked borrowerEmployerContactName = '';
  @tracked borrowerEmployerContactTitle = '';
  @tracked borrowerEmployerContactPhone = '';
  @tracked borrowerPrintAndMail = false;

  @tracked hasSubmitted = false;
  @tracked submitError?: Errors;

  get hasEmail() {
    return !this.borrowerPrintAndMail;
  }

  constructor(owner: Owner, args: PslfMyDocumentsMyFormsTableChangeApproverSignature['Args']) {
    super(owner, args);
    const editableForm = this.args.form;

    if (editableForm.borrowerFields['borrower-employer-email']) {
      this.borrowerEmployerEmail = editableForm.borrowerFields['borrower-employer-email'];
    }
    if (editableForm.borrowerFields['borrower-employer-contact-name']) {
      this.borrowerEmployerContactName =
        editableForm.borrowerFields['borrower-employer-contact-name'];
    }
    if (editableForm.borrowerFields['borrower-employer-contact-title']) {
      this.borrowerEmployerContactTitle =
        editableForm.borrowerFields['borrower-employer-contact-title'];
    }
    if (editableForm.borrowerFields['borrower-employer-contact-phone']) {
      this.borrowerEmployerContactPhone =
        editableForm.borrowerFields['borrower-employer-contact-phone'];
    }
    if (editableForm.borrowerFields['borrower-print-and-mail']) {
      this.borrowerPrintAndMail = editableForm.borrowerFields['borrower-print-and-mail'];
    }
  }

  submitTask = dropTask(async () => {
    delete this.submitError;
    this.hasSubmitted = true;
    if (this.formValidationErrors.length) {
      return;
    }
    try {
      const saved = await this.saveFormValues.perform();
      this.hasSubmitted = false;
      this.args.didSave?.(saved);
    } catch (e) {
      this.submitError = e;
      console.error(e);
    }
  });

  saveFormValues = dropTask(async () => {
    const form = await this.args.form;

    const existingBorrowerFields = form.borrowerFields;
    const updatedBorrowerFields = {
      ...existingBorrowerFields,
      'borrower-employer-email': this.borrowerEmployerEmail,
      'borrower-employer-contact-name': this.borrowerEmployerContactName,
      'borrower-employer-contact-title': this.borrowerEmployerContactTitle,
      'borrower-employer-contact-phone': this.borrowerEmployerContactPhone,
      'borrower-print-and-mail': this.borrowerPrintAndMail,
    };
    form.borrowerFields = updatedBorrowerFields;

    // This preserves the behavior of the Vue app, however,
    // now that we have automatic group assignment for some partners,
    // the rest of the employer fields are auto-filled (not just email).
    //
    // TODO: Investigate if we should remove the entire employerFields object
    // when a user sets their form to print and mail, because they cannot undo it.
    // - Julia 12/13/2022
    if (this.borrowerPrintAndMail) {
      const existingEmployerFields = form.employerFields;
      const updatedEmployerFields = {
        ...existingEmployerFields,
        'employer-email': '',
      };
      form.employerFields = updatedEmployerFields;
    }

    await form.save();
    return form;
  });

  @action
  didChangePrintAndMail(value: unknown) {
    this.borrowerPrintAndMail = !!value;
    if (value) {
      this.borrowerEmployerContactName = '';
      this.borrowerEmployerContactPhone = '';
      this.borrowerEmployerContactTitle = '';
      this.borrowerEmployerEmail = '';
    }
  }

  get formValidationErrors() {
    const schema = changeApproverFormSchema;
    const formModel = {
      borrowerEmployerEmail: this.borrowerEmployerEmail,
    };

    try {
      schema?.validateSync?.(formModel, { abortEarly: false });
    } catch (err) {
      return err.inner || [];
    }
    return [];
  }

  get serverErrorMessages() {
    const genericErrorMessage = this.intl.t('error_occurred');
    if (this.submitError?.errors) {
      return this.submitError.errors.map((err) => {
        return err?.detail || genericErrorMessage;
      });
    } else if (this.submitError) {
      return [genericErrorMessage];
    }
    return [];
  }

  <template>
    <Modal
      @isOpen={{true}}
      @onClose={{@didCancel}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>{{t "pslf.workflow_prior_employer.send_your_document"}}</m.Header>

      <m.Body>
        <form class="mx-1 md:mx-20">
          <FormRadioGroup
            data-legacy-input
            @label={{t "pslf.workflow_prior_employer.send_electronically"}}
            required="true"
            @value={{this.borrowerPrintAndMail}}
            class="text-lg change-approver-radio"
            @onChange={{this.didChangePrintAndMail}}
            as |Radio|
          >
            {{! @glint-expect-error}}
            <Radio @value={{false}} @label={{t "pslf.workflow_prior_employer.i_have_email"}} />
            {{! @glint-expect-error}}
            <Radio @value={{true}} @label={{t "pslf.workflow_prior_employer.i_dont_have_email"}} />
          </FormRadioGroup>

          {{#if this.hasEmail}}
            <div class="mt-4">
              <p class="text-lg font-medium py-2">
                {{t "pslf.workflow_prior_employer.please_provide"}}
              </p>
              <p class="text-sm text-tio-gray-600">
                {{t "pslf.workflow_prior_employer.please_provide_hint"}}
              </p>
            </div>

            <TioAlert @type="info" @allowDismiss={{false}}>
              <:body>
                <p class="text-sm">
                  {{t "pslf.workflow_prior_employer.correct_email_alert"}}
                </p>
              </:body>
            </TioAlert>

            <div class="mx-6">
              <FormInput
                data-legacy-input
                @label={{t "pslf.workflow_prior_employer.employer_email"}}
                @value={{this.borrowerEmployerEmail}}
                @onInput={{fn (mut this.borrowerEmployerEmail)}}
                @containerClass="mb-4 w-full"
                @hint={{t "pslf.workflow_prior_employer.employer_email_hint"}}
                @errors={{errorsForField
                  "borrowerEmployerEmail"
                  schemaErrors=this.formValidationErrors
                }}
              />

              <FormInput
                data-legacy-input
                @label={{t "pslf.workflow_prior_employer.contact_name"}}
                @value={{this.borrowerEmployerContactName}}
                @onInput={{fn (mut this.borrowerEmployerContactName)}}
                @containerClass="mb-4 w-full"
                @hint={{t "pslf.workflow_prior_employer.optional_not_required"}}
              />

              <FormInput
                data-legacy-input
                @label={{t "pslf.workflow_prior_employer.contact_title"}}
                @value={{this.borrowerEmployerContactTitle}}
                @onInput={{fn (mut this.borrowerEmployerContactTitle)}}
                @containerClass="mb-4 w-full"
                @hint={{t "pslf.workflow_prior_employer.optional"}}
              />

              <FormInput
                data-legacy-input
                @label={{t "pslf.workflow_prior_employer.contact_phone"}}
                @value={{this.borrowerEmployerContactPhone}}
                @onInput={{fn (mut this.borrowerEmployerContactPhone)}}
                @containerClass="mb-4 w-full"
                @hint={{t "pslf.workflow_prior_employer.optional"}}
              />
            </div>
          {{/if}}
        </form>

        {{#if (and this.hasSubmitted this.serverErrorMessages.length)}}
          <ul class="text-red-600 my-2">
            {{#each this.serverErrorMessages as |message|}}
              <li class="whitespace-normal">{{message}}</li>
            {{/each}}
          </ul>
        {{/if}}
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.submitTask.isRunning}}
          @onSubmit={{this.submitTask.perform}}
          @submitText={{if
            this.borrowerPrintAndMail
            (t "save")
            (t "pslf.workflow_prior_employer.submit_for_approval")
          }}
          @onCancel={{@didCancel}}
        />
      </m.Footer>
    </Modal>
  </template>
}
