import Component from '@glimmer/component';
import AppContentCard from 'tio-common/components/app/content-card';
import { t } from 'ember-intl';
import { Divider } from 'tio-ui/components/utilities';
import MaterialIcon from 'tio-common/components/material-icon';
import SyfDashboardInsightsParticipantFormDialog from './insights/participant-form-dialog';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import { concat } from '@ember/helper';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { LinkTo } from '@ember/routing';
import type { IntlService } from 'ember-intl';
import type MatchParticipantModel from 'tio-common/models/match-participant';
import type MatchPlanModel from 'tio-common/models/match-plan';
import type PartnerService from 'tio-employee/services/partner';
import type AccountModel from 'tio-common/models/account';
import { getTranslationKeyForReportedSalaryForMatchPlan } from 'tio-common/utils/syf';
import formatCentsToDollars from 'tio-common/helpers/format-cents-to-dollars';
import { gt } from 'tio-ui/utilities';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import Pencil from 'ember-static-heroicons/components/outline-24/pencil';
import { Section } from 'tio-ui/components/layout';

interface SyfDashboardInsightsSignature {
  Args: {
    matchParticipant: MatchParticipantModel;
    matchPlan: MatchPlanModel;
    accounts: AccountModel[];
  };
  Element: HTMLDivElement;
}

class SyfDashboardInsightsComponent extends Component<SyfDashboardInsightsSignature> {
  @service declare partner: PartnerService;
  @service declare intl: IntlService;

  @tracked isEditingParticipantInfo = false;

  @action
  toggleEditParticipantInfo() {
    this.isEditingParticipantInfo = !this.isEditingParticipantInfo;
  }

  @action
  didSaveParticipantInfo() {
    this.toggleEditParticipantInfo();
    this.args.matchParticipant.fetchInsights();
  }

  get otherRepaymentOptionsLink() {
    return this.partner.isPslfEnabled
      ? 'authenticated.syf.insights.pslf'
      : 'authenticated.syf.insights.idr';
  }

  get qualifyingIncomeFieldLabel() {
    return this.intl.t(getTranslationKeyForReportedSalaryForMatchPlan(this.args.matchPlan));
  }

  get isMissingInfoForDeferralInsight() {
    const { matchParticipant } = this.args;

    return !matchParticipant.hasDataForInsights;
  }

  get showDeferralInsightCard() {
    return !this.isMissingInfoForDeferralInsight;
  }

  <template>
    <AppContentCard
      @titleClass="uppercase"
      @title={{t "syf.dashboard.insights.header"}}
      ...attributes
    >
      <Divider class="my-2" />

      <div class="flex flex-col gap-4">
        {{t "syf.dashboard.insights.section.one.discover"}}

        <dl>
          <dt class="font-semibold mb-1">{{t
              "syf.dashboard.insights.estimated_monthly_payment"
            }}</dt>
          <dd class="flex items-center justify-between mb-2">
            {{#if @matchParticipant.reportedMonthlyPaymentInDollars}}
              {{safeFormatNumber
                @matchParticipant.reportedMonthlyPaymentInDollars
                style="currency"
                currency="USD"
              }}
            {{else}}
              {{t "common.not_provided"}}
            {{/if}}
            <Button
              @appearance="outlined"
              title={{concat
                (t "common.edit")
                " "
                (t "syf.dashboard.insights.section.one.estimated")
              }}
              {{on "click" this.toggleEditParticipantInfo}}
            >
              <Pencil class="w-5" />
            </Button>
          </dd>

          <dt class="font-semibold mb-1">{{this.qualifyingIncomeFieldLabel}}</dt>
          <dd class="flex items-center justify-between mb-2">
            {{#if @matchParticipant.reportedSalaryInDollars}}
              {{safeFormatNumber
                @matchParticipant.reportedSalaryInDollars
                style="currency"
                currency="USD"
              }}
            {{else}}
              {{t "common.not_provided"}}
            {{/if}}
            <Button
              @appearance="outlined"
              title={{concat (t "common.edit") " " this.qualifyingIncomeFieldLabel}}
              {{on "click" this.toggleEditParticipantInfo}}
            >
              <Pencil class="w-5" />
            </Button>
          </dd>
        </dl>

        {{#if this.isMissingInfoForDeferralInsight}}
          <div class="flex text-red-700">
            <MaterialIcon @icon="warning" />
            <div class="ml-4">
              {{t "syf.dashboard.insights.section.one.missing_both"}}
            </div>
          </div>
        {{else}}
          <div class="flex">
            <MaterialIcon @icon="info" @style="outlined" />
            <div class="ml-4">
              {{t "syf.dashboard.insights.section.one.changes_will_impact"}}
            </div>
          </div>
        {{/if}}

        {{#if this.showDeferralInsightCard}}
          <Section>
            <:header>
              {{t "syf.dashboard.insights.section.one.header"}}
              <MaterialIcon @icon="lightbulb" @style="outlined" class="text-yellow-400 float-end" />
            </:header>
            <:body>
              <p>
                {{#if (gt @matchParticipant.insights.defermentDeltaPerMonth 0)}}
                  {{t
                    "syf.dashboard.insights.section.one.content_below_max"
                    deferment_amount=(formatCentsToDollars
                      @matchParticipant.insights.defermentDeltaPerMonth defaultValue="--"
                    )
                  }}
                {{else}}
                  {{t "syf.dashboard.insights.section.one.content_at_max"}}
                {{/if}}
              </p>

              <LinkTo
                @route="authenticated.syf.insights.four-oh-one-k"
                class="tio-anchor block mt-2"
              >
                {{t "syf.dashboard.insights.section.one.read_more"}}
              </LinkTo>
            </:body>
          </Section>
        {{/if}}

        <Section>
          <:header>
            {{t "syf.dashboard.insights.section.two.header"}}
            <MaterialIcon @icon="lightbulb" @style="outlined" class="text-yellow-400 float-end" />
          </:header>
          <:body>
            <p>{{t "syf.dashboard.insights.section.two.content"}}</p>

            <LinkTo @route={{this.otherRepaymentOptionsLink}} class="tio-anchor block mt-2">
              {{t "syf.dashboard.insights.section.two.read_more"}}
            </LinkTo>
          </:body>
        </Section>
      </div>
    </AppContentCard>

    {{#if this.isEditingParticipantInfo}}
      <SyfDashboardInsightsParticipantFormDialog
        @matchParticipant={{@matchParticipant}}
        @matchPlan={{@matchPlan}}
        @onCancel={{this.toggleEditParticipantInfo}}
        @didSubmit={{this.didSaveParticipantInfo}}
      />
    {{/if}}
  </template>
}

export default SyfDashboardInsightsComponent;
