import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioEmbedVue from 'tio-common/components/tio/embed-vue';
import type PartnerService from 'tio-employee/services/partner';

class AuthenticatedTuitionAssistanceRouteComponent extends Component {
  @service declare partner: PartnerService;

  get isNewTuitionAssistance() {
    return !this.partner.hasLegacyTuitionAssistance;
  }

  <template>
    {{pageTitle (t "tuition_assistance.default")}}

    <TioEmbedVue @route="/tuition-assistance" @disabled={{this.isNewTuitionAssistance}}>
      {{outlet}}
    </TioEmbedVue>
  </template>
}

export default RouteTemplate(AuthenticatedTuitionAssistanceRouteComponent);
