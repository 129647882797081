import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type { AuthenticatedSyfRouteModel } from '../syf';
import type { MatchPlanBenefitYear } from 'tio-common/types/secure-your-future';
import type AccountTransactionModel from 'tio-common/models/account-transaction';
import type MatchPayment from 'tio-common/models/match-payment';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedSyfDashboardRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;

  queryParams = {
    benefitYear: { refreshModel: true, replace: true },
  };

  beforeModel() {
    if (!this.sessionContext.currentEmployee.matchParticipant?.isEnrolled) {
      this.router.transitionTo('authenticated.syf.enrollment');
    }
  }

  async model(params: { benefitYear: string }) {
    const person = await this.store.findRecord('person', this.sessionContext.user.person.id, {
      include: 'accounts.financial-institution',
      reload: true,
    });

    const matchParticipant = this.modelFor('authenticated.syf') as AuthenticatedSyfRouteModel;
    await matchParticipant.fetchInsights();

    let { benefitYear } = params;
    if (!benefitYear) {
      const currentDate = new Date();
      const metadata = matchParticipant.matchPlan.metadata;

      // Find the most recent benefit year that has not yet ended
      const applicableBenefitYear = Object.values(metadata.benefit_years).find(
        (yearData: MatchPlanBenefitYear) => {
          const cutoffDate = new Date(yearData.final_certification_date);
          return currentDate <= cutoffDate;
        }
      );

      benefitYear = applicableBenefitYear?.benefit_year.toString() ?? '2024';

      this.router.transitionTo({ queryParams: { benefitYear } });
    }

    const filteredMatchPayments = matchParticipant.matchPayments.filter((payment: MatchPayment) => {
      const paymentYear = new Date(payment.transactionDate).getFullYear().toString();
      return paymentYear === benefitYear;
    });

    const filteredAccountTransactions = matchParticipant.person.accountTransactions.filter(
      (transaction: AccountTransactionModel) => {
        const transactionYear = new Date(transaction.transactionDate).getFullYear().toString();
        return transactionYear === benefitYear;
      }
    );

    return {
      accounts: person.activeAccounts,
      matchParticipant: matchParticipant,
      matchPlan: matchParticipant.matchPlan,
      matchPayments: filteredMatchPayments,
      accountTransactions: filteredAccountTransactions,
    };
  }
}
