import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type RouterService from '@ember/routing/router-service';
import type PslfEmployerController from 'tio-employee/controllers/pslf-employer';
import type Owner from '@ember/owner';

interface S {
  Args: {
    model: {
      form: PslfFormModel;
      documentId: string;
    };
    controller: PslfEmployerController;
  };
}

class PslfEmployerRoute extends Component<S> {
  @tracked partnerEmployer = false;
  @service declare router: RouterService;

  constructor(owner: Owner, args: S['Args']) {
    super(owner, args);
    this.partnerEmployer = args.controller.approval ? false : true;

    const id = args.model.documentId;
    this.partnerEmployer
      ? this.router.transitionTo(`/authenticated/admin/pslf/form/${id}/review`)
      : this.router.transitionTo(`/pslf-employer/${id}/non-partner-verification`, {
          queryParams: { approval: args.controller.approval },
        });
  }

  <template>
    {{pageTitle (t "pslf.employer_sign_page_title")}}

    <div class="flex flex-col h-screen container mx-auto">
      {{outlet}}
    </div>
  </template>
}

export default RouteTemplate(PslfEmployerRoute);
