import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { debounce } from '@ember/runloop';
import { Dropdown } from 'tio-ui/components/collections';
import { dropTask, all } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { not, or } from 'tio-ui/utilities';
import { on } from '@ember/modifier';
import { Section, VStack } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import EllipsisVertical from 'ember-static-heroicons/components/outline-24/ellipsis-vertical';
import TioConfirmAlert from 'tio-common/components/tio/confirm-alert';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import TuitionAssistanceFormsAcceptConditions from '../forms/accept-conditions';
import type ConditionModel from 'tio-common/models/condition';
import type ErrorsSignature from 'tio-employee/types/errors';
import type Intl from 'ember-intl/services/intl';
import type SessionContextService from 'tio-employee/services/session-context';
import type Store from '@ember-data/store';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';

export interface WithdrawCompleteSignature {
  Args: {
    programInstance: TasProgramInstanceModel;
    isHeldParticipant: boolean;
  };
  Element: HTMLDivElement;
}

export default class TuitionAssistanceProgramDetailsWithdrawCompleteComponent extends Component<WithdrawCompleteSignature> {
  @service declare sessionContext: SessionContextService;
  @service declare store: typeof Store;
  @service declare intl: Intl;

  @tracked hasSubmitted = false;
  @tracked didSubmitSuccessfully = false;
  @tracked submitError?: ErrorsSignature;
  @tracked acceptedConditions: ConditionModel[] = [];
  @tracked action: string | null = null;
  @tracked showModal = false;
  @tracked modalText = { header: '', body: '' };

  get conditionsForSubmit() {
    return (
      this.args.programInstance?.tasProgramTemplate?.programApproveWithdrawCompleteConditions || []
    );
  }

  get showConditionsForSubmit() {
    return this.conditionsForSubmit.length && !this.isHeldParticipant;
  }

  get hasAgreedAllConditions() {
    if (!this.conditionsForSubmit.length || this.isHeldParticipant) {
      return true;
    }
    return this.conditionsForSubmit.length === this.acceptedConditions.length;
  }

  get adapterMethod() {
    const actionMap = {
      withdraw: 'requestProgramWithdrawal',
      complete: 'requestProgramCompletion',
    };
    return this.action ? actionMap[this.action as keyof typeof actionMap] : '';
  }

  get markProgramCompleteModalText() {
    return this.isHeldParticipant
      ? {
          header: this.intl.t(
            'tuition_assistance.program_details.mark_program_complete.held_participant.header'
          ),
          body: this.intl.t(
            'tuition_assistance.program_details.mark_program_complete.held_participant.body'
          ),
        }
      : {
          header: this.intl.t('tuition_assistance.program_details.mark_program_complete.header'),
          body: this.intl.t('tuition_assistance.program_details.mark_program_complete.body'),
        };
  }

  get withdrawProgramModalText() {
    return this.isHeldParticipant
      ? {
          header: this.intl.t(
            'tuition_assistance.program_details.withdraw_program.held_participant.header'
          ),
          body: this.intl.t(
            'tuition_assistance.program_details.withdraw_program.held_participant.body'
          ),
        }
      : {
          header: this.intl.t('tuition_assistance.program_details.withdraw_program.header'),
          body: this.intl.t('tuition_assistance.program_details.withdraw_program.body'),
        };
  }

  get isHeldParticipant() {
    return this.args.isHeldParticipant;
  }

  async markProgramComplete() {
    if (!this.isHeldParticipant) {
      await this.submitTask.perform();
    }
  }

  async withdrawProgram() {
    if (!this.isHeldParticipant) {
      await this.submitTask.perform();
    }
  }

  @action
  didUpdateAcceptedConditions(conditions: ConditionModel[] = []) {
    this.acceptedConditions = conditions;
  }

  @action
  handleCompleteWithdrawAction() {
    switch (this.action) {
      case 'complete':
        this.markProgramComplete();
        break;
      case 'withdraw':
        this.withdrawProgram();
        break;
      default:
        break;
    }
  }

  submitTask = dropTask(async () => {
    if (!this.hasAgreedAllConditions) {
      return;
    }
    try {
      const method = this.adapterMethod;
      this.hasSubmitted = true;
      delete this.submitError;
      await this.saveAgreementsForConditions.perform();
      await this.store.adapterFor('tas-program-instance')[method](this.args.programInstance);
      await this.args.programInstance.reload();
      this.didSubmitSuccessfully = true;
      this.closeModal();
    } catch (e) {
      console.error(e);
      this.submitError = e;
    }
  });

  saveAgreementsForConditions = dropTask(async () => {
    const user = this.sessionContext.user;
    const agreements = this.acceptedConditions.map((condition) => {
      return this.store.createRecord('agreement', {
        user: user,
        condition: condition,
      });
    });
    const promises = agreements.map((agreement) => {
      return agreement.save();
    });
    const saved = await all(promises);
    return saved;
  });

  @action
  closeModal() {
    this.showModal = false;
    this.action = null;
    this.submitError = undefined;
    this.hasSubmitted = false;
    this.acceptedConditions = [];
  }

  @action
  setShowModal() {
    this.showModal = true;
  }

  @action
  openModal(value: string) {
    this.action = value;
    this.modalText =
      value === 'complete' ? this.markProgramCompleteModalText : this.withdrawProgramModalText;
    debounce(this.setShowModal, 300);
  }

  <template>
    {{#if (or @programInstance.canMarkComplete @programInstance.canWithdraw)}}
      <div ...attributes>
        <div class="flex lg:hidden gap-4">
          <Dropdown @placement="bottom-end" as |d|>
            <d.Trigger @size="sm" @appearance="outlined" data-test-complete-menu>
              <EllipsisVertical class="w-6 h-6" />
            </d.Trigger>

            <d.Menu @onAction={{this.openModal}} as |Item|>
              {{#if @programInstance.canMarkComplete}}
                <Item
                  @key="complete"
                  @intent="primary"
                  @withDivider={{true}}
                  data-test="complete-button"
                >
                  {{t "tuition_assistance.program_details.mark_program_complete.default"}}
                </Item>
              {{/if}}
              {{#if @programInstance.canWithdraw}}
                <Item
                  @key="withdraw"
                  @intent="danger"
                  @class="text-danger"
                  data-test="withdraw-button"
                >
                  {{t "tuition_assistance.program_details.withdraw_program.default"}}
                </Item>
              {{/if}}
            </d.Menu>
          </Dropdown>
        </div>

        <div class="hidden lg:flex gap-4">
          {{#if @programInstance.canMarkComplete}}
            <Button
              @size="sm"
              @appearance="outlined"
              @intent="primary"
              {{on "click" (fn this.openModal "complete")}}
            >
              {{t "tuition_assistance.program_details.mark_program_complete.default"}}
            </Button>
          {{/if}}
          {{#if @programInstance.canWithdraw}}
            <Button
              @size="sm"
              @appearance="outlined"
              @intent="danger"
              {{on "click" (fn this.openModal "withdraw")}}
            >
              {{t "tuition_assistance.program_details.withdraw_program.default"}}
            </Button>
          {{/if}}
        </div>
      </div>
    {{/if}}

    <TioConfirmAlert
      @show={{this.showModal}}
      @onCancel={{this.closeModal}}
      @onConfirm={{this.handleCompleteWithdrawAction}}
      @confirmButtonText={{if this.isHeldParticipant "Close" "Confirm"}}
      @hideCancel={{if this.isHeldParticipant true false}}
      @disabled={{not this.hasAgreedAllConditions}}
    >
      <Section>
        <:header>
          {{this.modalText.header}}
        </:header>
        <:body>
          <VStack>
            <p>{{this.modalText.body}}</p>
            {{#if this.showConditionsForSubmit}}
              <TuitionAssistanceFormsAcceptConditions
                @conditions={{this.conditionsForSubmit}}
                @onChange={{this.didUpdateAcceptedConditions}}
              />
            {{/if}}
            <TioErrorMessages @showErrors={{this.hasSubmitted}} @error={{this.submitError}} />
          </VStack>
        </:body>
      </Section>
    </TioConfirmAlert>
  </template>
}
