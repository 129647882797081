import { service } from '@ember/service';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type RouterService from '@ember/routing/router';
import type SessionContextService from '../services/session-context';
import type SessionService from '../services/session';
import pageTitle from 'ember-page-title/helpers/page-title';
import { t } from 'ember-intl';
import { NotificationsContainer } from 'tio-ui/components/notifications';
import DrawerTarget from 'tio-ui/components/drawer-target';

class ApplicationRouteComponent extends Component {
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;

  get isFullyAuthenticatedPage() {
    // TODO: Once `role-select` has been fully migrated over from Vue, we *may*
    //       be able to remove the test for `currentURL` below. This exists
    //       because after the user selects a role on `role-select`, the
    //       session context will be fully authenticated, but the route will
    //       still be on `/role-select`. Since `application.hbs` uses two
    //       outlets based on the result of this property, when it changes, it
    //       causes the `role-select.hbs` page to re-mount, sending a navigate
    //       message to Vue. Ember immediately redirects to `/dashboard`, but
    //       the rapid-fire navigation messages cause the Vue integration to
    //       get stuck on `/role-select` with the iframe visible, essentially
    //       showing a blank iframe that covers the main contents of the page.
    //       Once `/role-select` is entirely within Ember, we can add an
    //       exclusion so these navigation messages are never sent (or are
    //       ignored if they are received). [twl 24.Jul.23]
    return this.sessionContext.isFullyAuthenticated && this.router.currentURL !== '/role-select';
  }

  <template>
    <DrawerTarget />
    {{pageTitle (t "application_page_title")}}

    {{outlet}}

    <NotificationsContainer @placement="top-right" />
  </template>
}

export default RouteTemplate(ApplicationRouteComponent);
