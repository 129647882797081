import type StoreService from 'tio-common/services/store';
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { trackedFunction } from 'reactiveweb/function';
import { Chip } from 'tio-ui/components/buttons';
import Table from 'tio-common/components/table/index';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import divide from 'ember-math-helpers/helpers/div';
import type EmployeeModel from 'tio-common/models/employee';
import type IntlService from 'ember-intl/services/intl';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { Section } from 'tio-ui/components/layout';

const number = Number;

export interface TuitionAssistanceProfileTuitionAssistanceSignature {
  Args: {
    employee: EmployeeModel;
  };
  Element: HTMLElement;
}

export default class TuitionAssistanceProfileTuitionAssistanceComponent extends Component<TuitionAssistanceProfileTuitionAssistanceSignature> {
  @service declare intl: IntlService;
  @service declare store: StoreService;

  get employee() {
    return this.args.employee;
  }

  routeForModelName(modelName: string) {
    if (modelName === 'tas-program-instance') {
      return 'authenticated.admin.tuition-assistance.program-instances.show.readonly';
    }
    if (modelName === 'tas-application') {
      return 'authenticated.admin.tuition-assistance.applications.show.readonly';
    }
    return '';
  }

  labelForModelName(modelName: string) {
    if (modelName === 'tas-program-instance') {
      return 'Program Application';
    }
    if (modelName === 'tas-application') {
      return 'Courses Application';
    }
    return '';
  }

  tasProgramInstancesData = trackedFunction(this, async () => {
    if (!this.employee) {
      return [];
    }

    const instances = await this.store.query('tas-program-instance', {
      filter: { employee: this.employee.id },
      include: 'tas-program-template,tas-applications.tas-courses,tas-applications.tas-assistances',
    });

    return instances;
  });

  get isLoading() {
    return this.tasProgramInstancesData.isLoading;
  }

  get tasProgramInstances() {
    return this.tasProgramInstancesData.value || [];
  }

  @action
  tasApplications(instance?: TasProgramInstanceModel) {
    return instance?.tasApplications.slice() || [];
  }

  @action
  tasCourseNames(application?: TasApplicationModel) {
    const courses = application?.activeCourses.slice() || [];
    const courseNames = courses.map((course) => {
      return course.displayName;
    });

    return courseNames.join(', ');
  }

  @action
  statusText(state: string): string {
    if (this.intl.exists(`common.status_message.to.${state}`)) {
      return this.intl.t(`common.status_message.to.${state}`);
    }

    return this.intl.t('common.status_message.to.TAS.ProgramInstanceState.UPDATED_ON');
  }

  get records() {
    return [...this.tasProgramInstances];
  }

  <template>
    <Section @collapsed={{true}}>
      <:header>{{t "tuition_assistance.default"}}</:header>
      <:body>
        <Table @isLoading={{this.isLoading}}>
          <:thead as |options|>
            <options.tr>
              <options.th>{{t "id"}}</options.th>
              <options.th>{{t "tuition_assistance.application_name"}}</options.th>
              <options.th>
                {{t "tuition_assistance.program_details.application_history.state"}}
              </options.th>
              <options.th>
                {{t "tuition_assistance.program_details.status.paid_date"}}
              </options.th>
              <options.th>
                {{t "tuition_assistance.program_details.status.paid_total"}}
              </options.th>
              <options.th>
                {{t "tuition_assistance.program_details.status.approved_total"}}
              </options.th>
            </options.tr>
          </:thead>
          <:tbody as |options|>
            {{#each this.records as |record|}}
              <options.tr class="align-top">
                <options.td class="text-ocean-600 underline">
                  <LinkTo @route={{this.routeForModelName record.modelName}} @model={{record.id}}>
                    {{record.id}}
                  </LinkTo>
                </options.td>
                <options.td>{{record.adminDisplayName}}</options.td>
                <options.td>{{this.statusText record.state}}</options.td>
                <options.td>-</options.td>
                <options.td>-</options.td>
                <options.td>-</options.td>
              </options.tr>
              {{#each (this.tasApplications record) as |application|}}
                <options.tr class="bg-tio-gray-100">
                  <options.td class="pl-9 pr-3 text-ocean-600">
                    <LinkTo
                      @route={{this.routeForModelName application.modelName}}
                      @model={{application.id}}
                      class="underline"
                    >
                      {{application.id}}
                    </LinkTo>
                    {{#if application.isRefundRequested}}
                      <Chip @size="sm" @intent="danger" class="ml-2 text-nowrap">
                        {{t "tuition_assistance.application_details.in_repayment"}}
                      </Chip>
                    {{/if}}
                  </options.td>
                  <options.td>
                    {{application.displayName}}
                    {{#if (this.tasCourseNames application)}}
                      ({{this.tasCourseNames application}})
                    {{/if}}
                  </options.td>
                  <options.td>{{this.statusText application.state}}</options.td>
                  {{#if application.isPaid}}
                    <options.td>
                      {{safeFormatDate (if application.paidDate application.paidDate "")}}
                    </options.td>
                  {{else}}
                    <options.td>-</options.td>
                  {{/if}}
                  {{#if application.isPaid}}
                    <options.td>
                      {{safeFormatNumber
                        (divide application.paidTotal 100)
                        style="currency"
                        currency="USD"
                      }}
                    </options.td>
                  {{else}}
                    <options.td>-</options.td>
                  {{/if}}
                  {{#if application.isApproved}}
                    <options.td>
                      {{safeFormatNumber
                        (divide (number application.approvedTotal) 100)
                        style="currency"
                        currency="USD"
                      }}
                    </options.td>
                  {{else}}
                    <options.td>-</options.td>
                  {{/if}}
                </options.tr>
              {{/each}}
            {{else}}
              <options.tr>
                <options.td colspan="5">
                  {{t "common.no_records_found"}}
                </options.td>
              </options.tr>
            {{/each}}
          </:tbody>
        </Table>
      </:body>
    </Section>
  </template>
}
