import RouteTemplate from 'ember-route-template';
import embedAwsQuicksight from 'tio-common/modifiers/embed-aws-quicksight';

interface S {
  Args: {
    model: {
      url: string;
    };
  };
}

export default RouteTemplate<S>(
  <template>
    <div class="w-full">
      <div {{embedAwsQuicksight @model.url}}></div>
    </div>
  </template>
);
