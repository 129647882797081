import { action } from '@ember/object';
import { array } from '@ember/helper';
import { Button } from 'tio-ui/components/buttons';
import { Changeset, EmberChangeset } from 'ember-changeset';
import { ChangesetForm } from 'tio-ui/components/changeset-form';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import changeset from 'ember-changeset/helpers/changeset';
import Component from '@glimmer/component';
import lookupValidator from 'ember-changeset-validations/';
import TioLoadingMessages from 'tio-common/components/tio/loading-messages';
import type PreRegisterService from 'tio-employee/services/pre-register';
import type RouterService from 'tio-employee/services/router';
import type SessionService from 'tio-employee/services/session';
import validateFormat from 'ember-changeset-validations/validators/format';
import validatePresence from 'ember-changeset-validations/validators/presence';
import type Owner from '@ember/owner';

interface AuthLoginLoginFormComponentSignature {
  Args: {
    email: string;
  };
}

export default class AuthLoginLoginFormComponent extends Component<AuthLoginLoginFormComponentSignature> {
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare preRegister: PreRegisterService;

  @tracked isLoading = false;
  @tracked errorMessage = '';

  formChangeset: EmberChangeset;

  constructor(owner: Owner, args: AuthLoginLoginFormComponentSignature['Args']) {
    super(owner, args);
    const initialFormData = { email: args.email, password: '' };

    // @ts-expect-error: ember-changeset types
    this.formChangeset = new Changeset(
      initialFormData,
      lookupValidator(this.formValidations),
      this.formValidations
    );
  }

  formValidations = {
    email: validateFormat({ type: 'email' }),
    password: validatePresence({ presence: true }),
  };

  @action
  async authenticate() {
    this.isLoading = true;

    const email = this.formChangeset.get('email');
    const password = this.formChangeset.get('password');

    this.router.detectRedirectParameter();

    try {
      await this.session.authenticate('authenticator:database', email, password, {
        noTransitionAfterAuth: true,
      });
      console.log('Authenticated with Ember');

      if (this.session.isAuthenticated) {
        this.router.transitionTo('/dashboard');
      }
    } catch (error) {
      if (error.reasons) {
        console.error('Vue login errors:', error.reasons);
      } else if (error.details) {
        console.error(
          `Ember login error (${error.message}):`,
          error.details.error ?? error.details
        );
      }

      this.errorMessage = error.details?.error ?? error.message ?? error.error ?? error;
    } finally {
      this.isLoading = false;
    }
  }

  get isDisabled() {
    const email = this.formChangeset.get('email');
    const password = this.formChangeset.get('password');

    return !email || !password || this.formChangeset.isInvalid || this.isLoading;
  }

  <template>
    <ChangesetForm
      @changeset={{this.formChangeset}}
      @onSubmit={{this.authenticate}}
      class="px-2"
      as |Form|
    >
      <Form.Input
        @label="Email"
        @fieldName="email"
        type="email"
        @changeset={{this.formChangeset}}
        @value={{changeset this.formChangeset.email}}
        @containerClass="mb-10"
        data-test-email-input
      />

      <Form.Input
        @label="Password"
        @fieldName="password"
        type="password"
        @changeset={{this.formChangeset}}
        @value={{changeset this.formChangeset.password}}
        @containerClass="mb-4"
        data-test-password-input
      />

      <div class="flex justify-end mb-6">
        <a class="underline text-ocean-600" href="/forgot-password">
          {{t "login.forgot_password_question"}}
        </a>
      </div>

      {{#if this.isLoading}}
        <div class="mt-6 text-center">
          <TioLoadingMessages
            @i18nPath="login.loading_messages"
            @messageKeys={{array
              "verify_credentials"
              "wait_for_system_warmup"
              "thank_you_for_your_patience"
              "gather_account_details"
              "fetch_company_data"
            }}
          />
        </div>
      {{/if}}

      <div class="flex justify-center my-8">
        <Button
          @type="submit"
          @intent="primary"
          @isRunning={{this.isLoading}}
          @class="px-12"
          disabled={{this.isDisabled}}
          data-test-submit-button
        >
          {{t "login.form.submit"}}
        </Button>
      </div>
    </ChangesetForm>
    {{#if this.errorMessage}}
      <div class="mt-4 text-red-600 text-center">{{this.errorMessage}}</div>
    {{/if}}
  </template>
}
