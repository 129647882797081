import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { Modal } from 'tio-ui/components/modal';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';

export interface ErrorModalComponentSignature {
  Args: {
    isOpen: boolean;
    onSubmit: () => void;
    renderInPlace?: boolean;
  };
}

const ErrorModalComponent: TOC<ErrorModalComponentSignature> = <template>
  <Modal
    data-test-error-modal
    @isOpen={{@isOpen}}
    @allowCloseButton={{false}}
    @closeOnEscapeKey={{false}}
    @closeOnOutsideClick={{false}}
    @renderInPlace={{@renderInPlace}}
    class="min-h-64"
    @backdrop="faded"
    as |m|
  >
    <m.Header>{{t "login.reset_password_error"}}</m.Header>
    <m.Body>{{t "login.reset_password_error_body"}}</m.Body>
    <m.Footer>
      <Button {{on "click" @onSubmit}} @intent="primary">
        {{t "login.back_to_login"}}
      </Button>
    </m.Footer>
  </Modal>
</template>;

export default ErrorModalComponent;
