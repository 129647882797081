import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { getObservableProductRoute } from 'tio-employee/services/observability';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import { Modal } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { Textarea } from 'tio-ui/components/forms';
import { tracked } from '@glimmer/tracking';
import Alert from 'tio-ui/components/alert';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import DocumentArrowUp from 'ember-static-heroicons/components/outline-24/document-arrow-up';
import Envelope from 'ember-static-heroicons/components/outline-24/envelope';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { getStatus } from 'tio-common/helpers/observability';
import ExtractionSummary from 'tio-employee/components/observability/extraction-summary';
import type { ObservableSource } from 'tio-employee/services/observability';
import type IntlService from 'ember-intl/services/intl';
import type ObservabilityService from 'tio-employee/services/observability';
import ObservabilityDisputeController from 'tio-employee/controllers/authenticated/observability/upload';
import type RemoteEventLoggerService from 'tio-employee/services/remote-event-logger';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import Badge from 'tio-ui/components/badge';
import type { Intent } from 'tio-ui/utilities';

interface ObservabilityDisputeRouteSignature {
  Args: {
    controller: ObservabilityDisputeController;
    model: ObservableDocumentModel;
  };
  Element: HTMLDivElement;
}

const getStatusIntent = (status: string): Intent | undefined => {
  switch (status) {
    case 'PROCESSING':
      return 'primary';
    case 'INVALID':
    case 'FAILED':
      return 'error';
    case 'PROCESSED':
      return 'success';
    case 'IN_DISPUTE':
    case 'PARTIALLY_APPLIED':
      return 'warning';
  }
};

const getSubmittedIcon = (via: string) => {
  if (via === 'EMAIL') {
    return Envelope;
  }
  return DocumentArrowUp;
};

class ObservabilityDisputeRoute extends Component<ObservabilityDisputeRouteSignature> {
  @service declare intl: IntlService;
  @service declare observability: ObservabilityService;
  @service declare remoteEventLogger: RemoteEventLoggerService;

  @tracked note?: string;
  @tracked loading = false;
  @tracked submitted = false;
  @tracked submissionError?: Error;

  get productSource(): ObservableSource {
    return <ObservableSource>this.args.controller.source;
  }

  get documentCreated() {
    const observableDocument = this.args.model;
    const createdAtDate = new Date(observableDocument.createdAt);
    const date = createdAtDate.toLocaleDateString();
    const time = createdAtDate.toLocaleTimeString();
    const via = this.intl.t(`observability.${observableDocument.via}`);
    return { via, date, time };
  }

  get submitDisabled() {
    if ((!this.note && !this.submissionError) || this.loading || this.submitted) {
      return true;
    }
    return false;
  }

  get document() {
    return this.args.model;
  }

  @action
  setNote(_value: string, e: Event) {
    this.note = (<HTMLTextAreaElement>e.target).value;
  }

  @action
  async submitDispute(e: Event) {
    e.preventDefault();
    const observableDocument = this.args.model;
    this.loading = true;
    try {
      // button is disabled if this.note is unset
      await observableDocument.dispute(this.note!);
      this.remoteEventLogger.logObservabilityEvent({
        eventName: 'PROCESSING_SUCCESS',
        component: 'ObservabilityService',
        document: observableDocument,
      });
      this.submitted = true;
    } catch (e) {
      this.submissionError = e;
    } finally {
      this.loading = false;
    }
  }

  <template>
    <VStack>
      <Header>
        <LinkTo
          @route="authenticated.observability.confirm"
          @model={{@model.id}}
          class="align-bottom text-gray-900 font-medium"
        >
          <ChevronLeft class="w-5 inline" />
          {{t "back"}}
        </LinkTo>
      </Header>
      <Section>
        <:header>
          <h1 class="mb-4">{{t "observability.dispute.header"}}</h1>
          <h2 class="inline">{{@model.observableAsset.filename}}</h2>
          <Badge @intent={{getStatusIntent (getStatus @model)}} class="ml-4">
            {{t "observable_document.status" state=(getStatus @model)}}
          </Badge>
          <p class="italic flex items-center text-small font-normal mt-2">
            {{#let (getSubmittedIcon @model.via) as |SubmittedIcon|}}
              <SubmittedIcon class="w-6 mr-2" />
            {{/let}}
            {{t
              "observability.dispute.document.uploaded"
              via=this.documentCreated.via
              date=this.documentCreated.date
              time=this.documentCreated.time
            }}
          </p>
        </:header>
        <:body>
          <Alert @intent="warning" class="my-4">{{t "observability.dispute.review_alert"}}</Alert>
          <ExtractionSummary @document={{@model}} class="w-full md:w-2/3" />
          <p class="my-8">{{t "observability.dispute.form.prompt"}}</p>
          <form {{on "submit" this.submitDispute}} class="pb-16">
            <label>
              {{t "observability.dispute.form.note.label"}}
              <Textarea
                @onInput={{this.setNote}}
                @value={{this.note}}
                placeholder={{t "observability.dispute.form.note.placeholder"}}
                class="sm:w-1/2"
              />
            </label>
            <div class="flex flex-wrap sm:flex-nowrap sm:w-1/2 gap-2">
              <Button
                type="submit"
                disabled={{this.submitDisabled}}
                @intent="primary"
                @isRunning={{this.loading}}
                class="w-full sm:shrink *:justify-center"
              >
                {{t "submit"}}
              </Button>
              <LinkTo
                @route="authenticated.observability.confirm"
                @model={{@model.id}}
                class="tio-btn tio-btn-default tio-btn-outlined w-full sm:shrink text-center"
              >
                {{t "cancel"}}
              </LinkTo>
            </div>
          </form>
        </:body>
      </Section>
    </VStack>
    <Modal @isOpen={{this.submitted}} @allowCloseButton={{false}} @size="xl" as |m|>
      <m.Body>
        <h1 class="tio-h2">{{t "observability.dispute.confirmed.header"}}</h1>
        <p class="my-8">{{t "observability.dispute.confirmed.message"}}</p>
        <LinkTo
          @route={{getObservableProductRoute @model.source}}
          class="tio-btn tio-btn-primary w-56 text-center"
        >
          {{t "ok"}}
        </LinkTo>
      </m.Body>
    </Modal>
  </template>
}

export default RouteTemplate(ObservabilityDisputeRoute);
