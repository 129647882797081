import RouteTemplate from 'ember-route-template';
import TuitionAssistanceFormsCertificateCertificateDetails from 'tio-employee/components/tuition-assistance/forms/certificate/certificate-details';
import type { AuthenticatedTuitionAssistanceProgramsInstanceCertificateEditRouteModel } from 'tio-employee/routes/authenticated/tuition-assistance/programs/instance/certificate/edit';

interface TAProgramInstanceCertificateEditRouteComponentSignature {
  Args: {
    model: AuthenticatedTuitionAssistanceProgramsInstanceCertificateEditRouteModel;
  };
}

export default RouteTemplate<TAProgramInstanceCertificateEditRouteComponentSignature>(
  <template>
    {{! @glint-expect-error something is wierd with the types on route}}
    <TuitionAssistanceFormsCertificateCertificateDetails @model={{@model}} />
  </template>
);
